import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogActions, Switch, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { images } from "../../../../assets/images";

import { AttachmentType, VideoOrientation } from "../../../../generated";
import { AppContext, AuthContext } from "../../../context";
import { ActionType } from "../../../context/AppContextReducer";
import { CreateVideoModalProps, UploadVideoModalProps } from "../../../interfaceTypes";
import { backdropDialog } from "../../../theme/styleConstants";
import { createVideoSchema } from "../../../validationSchema";
import { CustomController } from "../../common/CustomController";
import { CustomModal } from "../../common/CustomModal";
import { UploadVideoDocument } from "../../common/UploadVideoDocument";
import UploadVideoThumbnail from "../../common/UploadVideoThumbnail";
import { ConfirmationToSendVideo } from "../../task/ConfirmationToSendVideo";

export const CreateVideoModalForJobs: FC<UploadVideoModalProps> = ({ isOpen, handleClose, currentVideo, videoType, entityType, typeId, refetchJob, attachmentId, videoOrientation }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isPortrait, setIsPortrait] = useState<boolean>(false)
  const [videoData, setVideoData] = useState<CreateVideoModalProps>()
  const { dispatch, isEdit } = useContext(AppContext);
  const { currentUser } = useContext(AuthContext);

  const methods = useForm<CreateVideoModalProps>({
    resolver: yupResolver(createVideoSchema),

    defaultValues: {
      title: "",
      description: "",
      thumbnailId: "",
    },
  });

  const { handleSubmit, reset, control } = methods
  const { id: videoId } = currentVideo || {}

  const handleOrientation = () => {
    setIsPortrait(!isPortrait)
  }

  const onSubmit = async (data: CreateVideoModalProps) => {
    const { title: videoTitle, description, thumbnailId, videoOrientationBoolean } = data

    if (files.length !== 0 && typeId) {
      setVideoData({
        title: videoTitle,
        description,
        file: files[0],
        typeId,
        thumbnailId,
        type: videoType,
        attachmentType: 'video',
        metaType: 'job',
        videoOrientation: !videoOrientationBoolean ? VideoOrientation.Landscape : VideoOrientation.Portrait
      })

      dispatch({ type: ActionType.SET_IS_EDIT, isEdit: true })
      handleClose()
    }
  }

  const handleConfirmationClose = () => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: false })
    handleClose()
  }

  const filesLength = files.length === 0
  const { attachments: userAttachments } = currentUser || {}
  const userThumbnails = userAttachments?.filter(attachment => attachment.type === AttachmentType.Thumbnail)

  return (
    <>
      <CustomModal maxWidth="800px" isOpen={isOpen}
        handleClose={handleClose} title="Create Video" hideBackdrop>
        <Box paddingTop='25px'>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off">
              <Box>
                <UploadVideoDocument
                  entityType={entityType || "brands"}
                  typeId={typeId || ""}
                  attachmentType={videoType}
                  attachmentId={videoId || undefined}
                  fieldName="videoUrl"
                  files={files}
                  setFiles={setFiles}
                />
              </Box>

              <Box display='flex' mt={1} alignItems='center'>
                <Typography variant="body1" display='flex'><Box component='img' src={images.LANDSCAPE} mr={1} />Landscape</Typography>

                <Controller
                  control={control}
                  name='videoOrientationBoolean'
                  defaultValue={isPortrait}
                  render={({ field }) => (
                    <Switch
                      sx={{ marginRight: '1rem' }}
                      color="secondary"
                      value={field.value}
                      checked={isPortrait}
                      onChange={(_event, value) => {
                        field.onChange(value)
                        handleOrientation()
                      }}
                    />
                  )}
                />

                <Typography variant="body1" display='flex'><Box component='img' src={images.PORTRAIT} mr={1} />Portrait</Typography>
              </Box>

              <Box>
                <CustomController
                  controllerName="title"
                  controllerLabel="Video Title"
                  fieldType='text'
                  tooltipText="This will appear on the customer end on the widget."
                />
              </Box>

              <Box sx={{ marginBottom: '1rem' }}>
                <CustomController
                  controllerName="description"
                  controllerLabel="Description"
                  fieldType='text'
                />
              </Box>

              <UploadVideoThumbnail isLandscape={true} userThumbnails={userThumbnails || []} fieldName="thumbnailId" />

              <DialogActions sx={{ pt: 2 }}>
                <Button type="submit" variant="contained" color="primary" disabled={filesLength}>Finish</Button>
              </DialogActions>
            </form>
          </FormProvider>
        </Box>
      </CustomModal>

      {isOpen &&
        <Box sx={backdropDialog} />
      }

      <ConfirmationToSendVideo
        isOpen={isEdit}
        entity={entityType}
        handleClose={handleConfirmationClose}
        videoData={videoData}
        refetchJob={refetchJob}
        attachmentId={attachmentId}
        resetForm={reset}
      />
    </>
  )
}