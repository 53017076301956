import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, MouseEvent, useContext, useState } from 'react';
import { BRAND_ID, DELETE, EDIT } from '../../constants';
import { UserProps } from '../../interfaceTypes';
import palette from '../../theme/palette';
import { SetDefaultUser } from '../users/SetDefaultUserModal';
import { UserRemove } from '../users/UserRemove';
import { UserUpdate } from '../users/UserUpdate';
import { AuthContext } from '../../context/AuthContext';

export const Users: FC<UserProps> = ({ currentUserData, dispatch, usersData, refetch, state }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [removeUser, setRemoveUser] = useState(false);
  const [defaultUser, setDefaultUser] = useState(false);
  const [editUser, setEditUser] = useState<boolean>(false);
  const { isAdmin } = useContext(AuthContext)
  const open = Boolean(anchorEl);
  const { selectedBrand } = state || {}
  const { id, userBrands } = currentUserData || {}
  const isDefaultAssignee = isAdmin ? userBrands?.filter(userBrand => userBrand?.brandId === selectedBrand?.id) : userBrands?.filter(userBrand => userBrand?.brandId === localStorage.getItem(BRAND_ID))

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditUserOpen = () => {
    setEditUser(true)
    setAnchorEl(null);
  };

  const handleDefaultUserOpen = async () => {
    setDefaultUser(true)
    setAnchorEl(null);
  };

  const handleRemoveClickOpen = () => {
    setRemoveUser(true);
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        id={id}
        aria-controls={open ? 'header-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="firstHeaderMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: { minWidth: "200px", }
        }}
      >
        <MenuItem onClick={handleEditUserOpen}>{EDIT} User</MenuItem>
        {!isDefaultAssignee?.pop()?.defaultAssignee && <MenuItem onClick={handleDefaultUserOpen}>Set default assignee</MenuItem>}
        <MenuItem sx={{ color: palette.redTwo }} onClick={handleRemoveClickOpen}>{DELETE} User</MenuItem>
      </Menu>

      <UserUpdate currentUserData={currentUserData} usersData={usersData} editUser={editUser} setEditUser={setEditUser} dispatch={dispatch} />

      <UserRemove
        refetch={refetch}
        currentUserData={currentUserData}
        usersData={usersData}
        dispatch={dispatch}
        removeUser={removeUser}
        setRemoveUser={setRemoveUser}
      />

      <SetDefaultUser
        refetch={refetch}
        currentUserData={currentUserData}
        usersData={usersData}
        dispatch={dispatch}
        setDefaultUser={setDefaultUser}
        defaultUser={defaultUser}
        brandId={selectedBrand?.id || ""}
      />
    </Box>
  )
}
