import { Box, Typography, useMediaQuery } from '@mui/material'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { VideoLibraryProps } from '../../../interfaceTypes'
import { isLandscapeMobileStyle, isLandscapeStyle, isPortraitMobileStyle, isPortraitStyle, videoIndexBox } from '../../../theme/styleConstants'

export const VideoLibrary: FC<VideoLibraryProps> = ({ imageUrl, title, index, isChecked, isPortrait }) => {
  const { watch } = useFormContext()
  const isPortraitValue = watch('isPortrait')
  const mediumViewport = useMediaQuery('(max-width:575px)');

  return (
    <>
      {mediumViewport ?
        <Box display='flex' justifyContent='start' alignItems='center'>
          <Box sx={(isPortraitValue || isPortrait) ? isPortraitMobileStyle : isLandscapeMobileStyle} component='img' src={imageUrl} mr={2} />

          <Typography variant="h4" textAlign='center' noWrap>
            {title}
          </Typography>
        </Box>
        :
        <Box width='100%' border='1px solid #E0E0E0' borderRadius='4px'>
          <Box sx={(isPortraitValue || isPortrait) ? isPortraitStyle : isLandscapeStyle} component='img' src={imageUrl} />

          {isChecked &&
            <Box sx={videoIndexBox}>{index + 1}</Box>
          }

          <Box padding="5px 10px 8px">
            <Typography variant="h4" textAlign='center' noWrap>
              {title}
            </Typography>
          </Box>
        </Box>
      }
    </>
  )
}
