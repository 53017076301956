//packages block
import { FC } from "react";
import { Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { yupResolver } from '@hookform/resolvers/yup';
// component block
import { CustomController } from "../common/CustomController"
// others
import { AUTH_LINKS, FORGET_PASSWORD_SUCCESS, NOT_FOUND_EMAIL_MESSAGE, NOT_FOUND_EXCEPTION, SEND_INSTRUCTION } from "../../constants";
import { forgotPasswordValidationSchema } from "../../validationSchema";
import { useForgotPasswordMutation } from "../../../generated";
import { Alert } from "../common/Alert";
import { firstLetterUppercase } from "../../utils";

export const ForgotPasswordForm: FC = (): JSX.Element => {

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(forgotPasswordValidationSchema),
    defaultValues: {
      email: ''
    }
  });

  const { handleSubmit } = methods;

  const [forgotPassword, { loading }] = useForgotPasswordMutation({
    onError({ message }) {
      if (message.toLowerCase() === NOT_FOUND_EXCEPTION) {
        return Alert.error(NOT_FOUND_EMAIL_MESSAGE)
      }

      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data.forgotPassword) {
        Alert.success(FORGET_PASSWORD_SUCCESS)
      }
    }
  })

  const onSubmit = async (data: any) => {
    await forgotPassword({
      variables: {
        forgotPasswordInput: data
      }
    })
  }

  return (
    <Box marginTop='1rem' width='100%'>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <CustomController
            controllerName='email'
            controllerLabel='Email'
            fieldType='email'
          />

          <Box marginBottom='20px' marginTop='1rem' >
            <Typography component={Link} to={AUTH_LINKS.LOGIN_LINK} color="primary" variant="h4">
              Back to Login
            </Typography>
          </Box>

          <Button variant="contained" type="submit" fullWidth color="primary" disabled={loading}
            endIcon={loading && <CircularProgress size={20} color="inherit" />}>
            {SEND_INSTRUCTION}
          </Button>
        </form>
      </FormProvider>
    </Box>
  )
}