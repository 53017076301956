import { Box } from "@mui/material";
import { FC } from "react";
import { PageHeading } from "../../components/common/PageHeading";
import { Videos } from '../../components/recordedVideos/Videos';
import { apiKeyHeader, apiKeyHeading } from "../../theme/styleConstants";

/**
 * Component for displaying recorded videos and highlights with tabs navigation.
 * @returns {JSX.Element} RecordedVideos component.
 */
export const RecordedVideos: FC = (): JSX.Element => {
  /**
   * Handles the "Add Videos" button click event.
   */
  // const handleCreateVideo = () => {
  //   // Implement your logic for creating videos here
  // };

  /**
   * Handles tab change.
   * @param event - The event representing the change.
   * @param newValue - The new value (index) of the selected tab.
   */
  return (
    <>
      <Box sx={apiKeyHeader}>
        <Box sx={apiKeyHeading}>
          <PageHeading marginTop='0' title='All Videos' subTitle='View all recorded videos here.' />
        </Box>

        {/* <Button startIcon={<AddIcon />} variant='contained' color='primary' onClick={handleCreateVideo}>
          Add Videos
        </Button> */}
      </Box>

      <Videos />
    </>
  );
};
