import { FC, useContext, useEffect, useState, useReducer, Reducer } from "react";
import { Box, Button, Typography, CircularProgress, useMediaQuery } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useSearchParams } from "react-router-dom";

import { images } from "../../../../assets/images";
import { VideoSettings } from "../videoLibrary/VideoSettings"
import { LandingPageWidget } from "../landingPageWidget"
import { ADD, BRAND_ID, CREATE_CUSTOMIZED_WIDGET, GRAPHQL_QUERY_POLICY, KIOSK_BLOCK, NO_WIDGET_FOUND, SOMETHING_WENT_WRONG } from "../../../constants";
import { WidgetBox } from "../../../theme/styleComponents";
import palette from "../../../theme/palette";
import { brandImages } from "../../../theme/styleConstants";
import { useCreateWidgetMutation, useGetAllWidgetsLazyQuery, VideoBox, VideoGridType, VideoLayout, Widget, WidgetType } from "../../../../generated";
import customTheme from "../../../theme";
import { EmptyData } from "../../common/EmptyData";
import { Alert } from "../../common/Alert";
import { SiteWidgetSettings } from "../siteWidget/SiteWidgetSettings";
import { SiteWidgetBubble } from "../siteWidget/SiteWidgetBubble";
import { AppContext } from "../../../context";
import { ActionType } from "../../../context/AppContextReducer";
import { WidgetsSkeleton } from "./WidgetsSkeleton";
import { WidgetCard } from "./WidgetCard";
import { DeleteWidgetModal } from "./DeleteWidgetModal";
import { WidgetAction, WidgetActionType, widgetInitialState, widgetReducer, WidgetState } from "../../../context/WidgetContextReducer";
import { KioskWidgetSettings } from "../kioskWidgetSettings";
import { PopupWidgetSettings } from "../popupWidget";
import { getWidgetLinkBase } from "../../../utils";

export const WidgetSettings: FC = () => {
  const { selectedBrand, widgetLoader, dispatch } = useContext(AppContext)
  const [widgetState, widgetDispatch] = useReducer<Reducer<WidgetState, WidgetAction>>(widgetReducer, widgetInitialState);
  const { currentWidgetData: widgetData, widgetsData } = widgetState;
  const mediumViewport = useMediaQuery('(max-width:574px)');
  const [searchParams] = useSearchParams();
  const widgetType = searchParams.get('widgetType')
  const navigate = useNavigate()

  const [blockWidgets, setBlockWidgets] = useState<Widget[]>([])
  const [kioskWidgets, setKioskWidgets] = useState<Widget[]>([])
  const [brandWidgets, setBrandWidgets] = useState<Widget[]>([])
  const [popupWidgets, setPopupWidgets] = useState<Widget[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const cabaBrandId = localStorage.getItem(BRAND_ID);

  const [getAllWidgets, { refetch: refetchAllWidgets, loading: widgetsLoading }] = useGetAllWidgetsLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      Alert.error(SOMETHING_WENT_WRONG)
      setIsLoading(false)
    },

    onCompleted(data) {
      const { getAllWidgets } = data
      widgetDispatch({ type: WidgetActionType.SET_WIDGETS_DATA, widgetsData: getAllWidgets })
      const otherWidgets = getAllWidgets.filter(widget => (widget.type === WidgetType.Site || widget.type === WidgetType.LandingPage))
      const blockWidgets = getAllWidgets.filter(widget => widget.type === WidgetType.Block)
      const kiosk = getAllWidgets.filter(widget => widget.type === WidgetType.Kiosk);
      const popupWidget = getAllWidgets.filter(widget => widget.type === WidgetType.PopupWidget)

      setBrandWidgets(otherWidgets)
      setBlockWidgets(blockWidgets)
      setKioskWidgets(kiosk)
      setPopupWidgets(popupWidget)
      setIsLoading(false)
    }
  })

  useEffect(() => {
    if (widgetsData)
      setBlockWidgets(widgetsData.filter(widget => widget.type === WidgetType.Block))
  }, [widgetsData])

  useEffect(() => {
    if (cabaBrandId) {
      getAllWidgets({
        variables: {
          brandId: cabaBrandId
        }
      })
    }
    //eslint-disable-next-line
  }, [cabaBrandId])

  const [createWidget, { loading }] = useCreateWidgetMutation({
    onError({ message }) {
      Alert.error(SOMETHING_WENT_WRONG)
    },

    onCompleted(data) {
      const { createWidget: { id, type } } = data

      if (id) {
        navigate(`/brand-settings/?widgetType=${type}&activeTab=2&widgetId=${id}`)
      }

      Alert.success('Widget created successfully')
      dispatch({ type: ActionType.SET_WIDGET_LOADER, widgetLoader: false })
      refetchAllWidgets()
    }
  })

  const createWidgetData = (name: string, widgetType: WidgetType) => {
    dispatch({ type: ActionType.SET_WIDGET_LOADER, widgetLoader: !widgetLoader })
    setIsLoading(false)

    createWidget({
      variables: {
        createWidgetInput: {
          brandId: cabaBrandId || "",
          name: name,
          tellingFormDescription: "Tell us what kind of help you need.",
          tellingFormHeading: "Our worker is ready to help you.",
          videoBoundingBox: VideoBox.Regular,
          videoGridType: VideoGridType.Massonary,
          videoLayout: VideoLayout.FiveVideos,
          type: widgetType,
          heading: "More about Coffee Table",
          description: "See how to manage"
        }
      }
    })
  }

  const createKioskWidgetData = () => {
    dispatch({ type: ActionType.SET_WIDGET_LOADER, widgetLoader: !widgetLoader })
    setIsLoading(false)

    createWidget({
      variables: {
        createWidgetInput: {
          brandId: cabaBrandId || "",
          name: "Kiosk block",
          tellingFormDescription: "Tell us what kind of help you need.",
          tellingFormHeading: "Our worker is ready to help you.",
          videoBoundingBox: VideoBox.Regular,
          videoGridType: VideoGridType.Kiosk,
          videoLayout: VideoLayout.InfiniteVideos,
          type: WidgetType.Kiosk,
          heading: "More about Coffee Table",
          description: "See how to manage"
        }
      }
    })
  }

  const getWidgetLayout = (videoLayout: VideoLayout, videoGridType: VideoGridType) => {
    switch (videoLayout) {
      case VideoLayout.SingleVideo:
        return <Box component='img' src={images.SINGLE_LAYOUT} />

      case VideoLayout.ThreeVideos:
        switch (videoGridType) {
          case VideoGridType.Massonary:
            return <Box component='img' src={images.MASSORY_THREE_LAYOUT} />

          case VideoGridType.Carousell:
            return <Box
              component='img' src={images.CAROUSELL_THREE_LAYOUT} />

          case VideoGridType.Gallery:
            return <Box component='img' src={images.GALLERY_THREE_LAYOUT} />

          default:
            return
        }

      case VideoLayout.FiveVideos:
        switch (videoGridType) {
          case VideoGridType.Massonary:
            return <Box component='img' src={images.MASSORY_FIVE_LAYOUT} />

          case VideoGridType.Carousell:
            return <Box component='img' src={images.CAROUSELL_FIVE_LAYOUT} />

          case VideoGridType.Gallery:
            return <Box component='img' src={images.GALLERY_THREE_LAYOUT} />

          default:
            return
        }

      case VideoLayout.InfiniteVideos:
        return (
          videoGridType === VideoGridType.Kiosk ?
            <Box component='img' src={images.GALLERY_THREE_LAYOUT} />
            :
            <Box component='img' src={images.PORTRAIT_GALLERY} />
        )
    }
  }

  const noBlockWidgets = !widgetsLoading && blockWidgets && blockWidgets.length > 0
  const noKioskWidgets = !widgetsLoading && kioskWidgets && kioskWidgets.length > 0
  const noPopupWidgets = !widgetsLoading && popupWidgets && popupWidgets.length > 0

  return (
    <>
      {!widgetType &&
        <>
          <WidgetBox>
            <Box display='flex' justifyContent='space-between'>
              <Box mb={4}>
                <Typography variant="subtitle1">Widgets</Typography>

                <Typography variant="h3" sx={{ color: palette.lightColorThree, opacity: '0.6' }}>Create widgets to be displayed on your brand website pages.</Typography>
              </Box>
            </Box>

            {widgetsLoading ?
              <WidgetsSkeleton noOfRows={5} />
              :
              <>
                {brandWidgets.map((brandWidget) => {
                  const { id, type, idleBackgroundColor, attachment } = brandWidget
                  const widgetType = type === WidgetType.Site ? WidgetType.Site : WidgetType.LandingPage
                  const { url: logoUrl } = attachment || {}
                  const widgetLinkBase = getWidgetLinkBase(widgetType)
                  const widgetName = type === WidgetType.Site ? 'Site Widget' : 'Landing Page Widget'
                  const widgetDescription = type === WidgetType.Site ? `It's a global widget on the store.` : `It's a widget on the customer landing page.`

                  return (
                    <WidgetCard
                      name={widgetName}
                      id={id}
                      right='-16px'
                      paddingRight='0'
                      key={id}
                      widgetType={type === WidgetType.Site ? 'site-widget' : 'landing-page-widget'}
                      widgetScriptType={type === WidgetType.Site ? 'SITE WIDGET' : 'LANDING PAGE WIDGET'}
                      description={widgetDescription}
                      linkBaseUrl={widgetLinkBase}
                      mediumViewport={mediumViewport}
                      widget={brandWidget}
                      ImageComponent={
                        type === WidgetType.Site ?
                          <SiteWidgetBubble
                            idleBackgroundColor={idleBackgroundColor || ""}
                            logoUrl={logoUrl || ""}
                          />
                          :
                          <Box sx={brandImages}>
                            <Box component='img' src={images.LANDING_PAGE_WIDGET} />
                          </Box>
                      }
                    />
                  )
                })}
              </>
            }
          </WidgetBox>

          {!widgetsLoading && !isLoading &&
            <WidgetBox>
              <Box mb={4} display='flex' justifyContent='space-between' sx={{ [customTheme.breakpoints.down('sm')]: { flexDirection: 'column' } }}>
                <Box>
                  <Typography variant="subtitle1">Popup Widgets</Typography>
                  <Typography variant="h3" sx={{ color: palette.lightColorThree, opacity: '0.6' }}>Create widgets to be displayed on your brand website pages.</Typography>
                </Box>

                <Box>
                  <Button color='primary' onClick={() => createWidgetData("Popup Widget", WidgetType.PopupWidget)} variant="contained"
                    sx={{ [customTheme.breakpoints.down('sm')]: { width: '100%', marginTop: '1rem' } }}
                    startIcon={loading ? <CircularProgress color="inherit" size={20} /> : <AddIcon />}
                    disabled={loading}
                  >
                    Add Widget
                  </Button>
                </Box>
              </Box>

              {noPopupWidgets ?
                <Box maxHeight='336px' overflow='auto' pr={1}>
                  {popupWidgets.map((popupWidget) => {
                    const { id, name, showClientFormHookVideo } = popupWidget;

                    return (
                      <WidgetCard
                        name={name}
                        id={id}
                        right='-16px'
                        paddingRight='0'
                        key={id}
                        widgetType={'pop-up-widget'}
                        widgetScriptType='POPUP PAGE WIDGET'
                        description='POPUP WIDGET'
                        linkBaseUrl={getWidgetLinkBase(WidgetType.PopupWidget)}
                        mediumViewport={mediumViewport}
                        widget={popupWidget}
                        widgetsData={widgetsData}
                        widgetDispatch={widgetDispatch}
                        ImageComponent={
                          <Box sx={brandImages}>
                            <Box component='img' src={showClientFormHookVideo ? images.POP_WIDGET_ICON : images.POP_WIDGET_WITHOUT_HOOK_ICON} />
                          </Box>
                        }
                      />
                    )
                  })}
                </Box>
                :
                <EmptyData
                  title={NO_WIDGET_FOUND}
                  description={CREATE_CUSTOMIZED_WIDGET}
                />
              }
            </WidgetBox>
          }

          {!widgetsLoading && !isLoading &&
            <WidgetBox>
              <Box mb={4} display='flex' justifyContent='space-between' sx={{ [customTheme.breakpoints.down('sm')]: { flexDirection: 'column' } }}>
                <Box>
                  <Typography variant="subtitle1">Blocks</Typography>
                  <Typography variant="h3" sx={{ color: palette.lightColorThree, opacity: '0.6' }}>Create widgets to be displayed on your brand website pages.</Typography>
                </Box>

                <Box>
                  <Button color='primary' onClick={() => createWidgetData("Block", WidgetType.Block)} variant="contained"
                    sx={{ [customTheme.breakpoints.down('sm')]: { width: '100%', marginTop: '1rem' } }}
                    startIcon={loading ? <CircularProgress color="inherit" size={20} /> : <AddIcon />}
                    disabled={loading}
                  >
                    Add Block
                  </Button>
                </Box>
              </Box>

              {noBlockWidgets ?
                <Box maxHeight='336px' overflow='auto' pr={1}>
                  {blockWidgets.map(blockWidget => {
                    const { id, name, description, videoGridType, videoLayout } = blockWidget
                    const pageBlockLinkBase = `/brand-settings/?widgetType=${WidgetType.Block}&activeTab=2`

                    return (
                      <WidgetCard
                        name={name}
                        id={id}
                        right='0'
                        key={id}
                        paddingRight='0'
                        paddingLeft="8px"
                        widgetType='page-block-widget'
                        widgetScriptType='PAGE BLOCK WIDGET'
                        description={description || ""}
                        linkBaseUrl={pageBlockLinkBase}
                        mediumViewport={mediumViewport}
                        widget={blockWidget}
                        widgetDispatch={widgetDispatch}
                        widgetsData={widgetsData}
                        ImageComponent={
                          <Box sx={brandImages}>
                            {videoLayout && videoGridType && getWidgetLayout(videoLayout, videoGridType)}
                          </Box>
                        }
                      />
                    )
                  })
                  }
                </Box>
                :
                <EmptyData
                  title={NO_WIDGET_FOUND}
                  description={CREATE_CUSTOMIZED_WIDGET}
                />
              }
            </WidgetBox>
          }

          {!widgetsLoading && !isLoading &&
            <WidgetBox>
              <Box mb={4} display='flex' justifyContent='space-between' sx={{ [customTheme.breakpoints.down('sm')]: { flexDirection: 'column' } }}>
                <Box>
                  <Typography variant="subtitle1">{KIOSK_BLOCK}s</Typography>
                  <Typography variant="h3" sx={{ color: palette.lightColorThree, opacity: '0.6' }}>Create widgets to be displayed on your brand website pages.</Typography>
                </Box>

                <Box>
                  <Button color='primary' onClick={createKioskWidgetData} variant="contained"
                    sx={{ [customTheme.breakpoints.down('sm')]: { width: '100%', marginTop: '1rem' } }}
                    startIcon={loading ? <CircularProgress color="inherit" size={20} /> : <AddIcon />}
                    disabled={loading}
                  >
                    {ADD} {KIOSK_BLOCK}
                  </Button>
                </Box>
              </Box>

              {noKioskWidgets ?
                <Box maxHeight='336px' overflow='auto' pr={1}>
                  {kioskWidgets.map(kioskWidget => {
                    const { id, name, description, videoGridType, videoLayout } = kioskWidget
                    const pageBlockLinkBase = getWidgetLinkBase(WidgetType.Kiosk)

                    return (
                      <WidgetCard
                        name={name}
                        id={id}
                        right='0'
                        key={id}
                        paddingRight='0'
                        paddingLeft="8px"
                        widgetType='kiosk-widget'
                        widgetScriptType='KIOSK WIDGET'
                        description={description || ""}
                        linkBaseUrl={pageBlockLinkBase}
                        isKiosk
                        mediumViewport={mediumViewport}
                        widget={kioskWidget}
                        widgetDispatch={widgetDispatch}
                        widgetsData={widgetsData}
                        ImageComponent={
                          <Box sx={brandImages}>
                            {videoLayout && videoGridType && getWidgetLayout(videoLayout, videoGridType)}
                          </Box>
                        }
                      />
                    )
                  })}
                </Box>
                :
                <EmptyData
                  title={NO_WIDGET_FOUND}
                  description={CREATE_CUSTOMIZED_WIDGET}
                />
              }
            </WidgetBox>
          }
        </>
      }

      <Box pb={3} />

      <DeleteWidgetModal
        widgetDispatch={widgetDispatch}
        widgetsData={widgetsData}
        widget={widgetData || null}
        refetchAllWidgets={refetchAllWidgets}
      />

      {widgetType === WidgetType.Kiosk && <KioskWidgetSettings brandData={selectedBrand || null} />}

      {widgetType === WidgetType.Site && <SiteWidgetSettings />}

      {widgetType === WidgetType.PopupWidget && <PopupWidgetSettings />}

      {widgetType === WidgetType.Block && <VideoSettings brandData={selectedBrand || null} />}

      {widgetType === WidgetType.LandingPage && <LandingPageWidget />}
    </>
  )
}