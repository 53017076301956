import { Box, Button, DialogActions } from '@mui/material';
import { FC, useContext } from 'react'
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { FullDescriptionModalProps } from '../../interfaceTypes';
import { CustomModal } from '../common/CustomModal'
import DOMPurify, { sanitize } from 'dompurify';

export const FullDescriptionModal: FC<FullDescriptionModalProps> = ({ script }) => {
  const { isScript, dispatch } = useContext(AppContext)
  DOMPurify.setConfig({ ADD_ATTR: ['target'] });

  const handleFullDescriptionClose = () => {
    dispatch({ type: ActionType.SET_IS_SCRIPT, isScript: false })
  };

  return (
    <CustomModal
      title="Script"
      maxWidth='700px'
      isOpen={isScript}
      handleClose={handleFullDescriptionClose}
    >
      <Box maxHeight={500} overflow='auto' mb={2}>
        <Box mt='10px' mb='20px'
          dangerouslySetInnerHTML={{
            __html: sanitize(script || 'N/A'),
          }}
        />
      </Box>

      <DialogActions>
        <Button variant='outlined' color='primary' onClick={handleFullDescriptionClose}>Close</Button>
      </DialogActions>
    </CustomModal>
  )
}
