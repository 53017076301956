import { FetchResult } from '@apollo/client';
import { Alert, Box, Button, Fade, Grid, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from "react-player";
import { OnProgressProps } from 'react-player/base';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { PlayerIcon } from "../../../assets/svgs";
import { GetSignedUrlWithKeyMutation, ScheduleMeeting, useGetScheduleMeetingLazyQuery, useGetSignedUrlWithKeyMutation } from '../../../generated';
import { BRAND_ID, GRAPHQL_QUERY_POLICY } from '../../constants';
import { AuthContext } from '../../context';
import { RecordedVideoContext } from '../../context/RecordedVideoContext';
import { VideoDetailActionType } from '../../context/VideoDetailContextReducer';
import { CustomVideoPlayer, VideoCommentBox } from "../../theme/styleComponents";
import { containerStyle, tabsSticky, transcriptGridStyle, videoDetailParentBox } from '../../theme/styleConstants';
import { formatDate, getCapitalize, getInitials, handleTabs, hasNativeHLS } from '../../utils';
import { Alert as ALERT } from '../common/Alert';
import { AvatarIcon } from '../common/AvatarIcon';
import { BackButton } from '../common/BackButton';
import { NoDataFound } from '../common/NoDataFound';
import { SpeakerCommentComponent } from './SpeakerCommentComponent';
import { VideoDetailSkeleton } from './VideoDetailSkeleton';
import { TabPanel } from '../common/TabPanel';
import { TranscriptionSummary } from './TranscriptionSummary';

/**
 * Component for displaying detailed information about a video.
 * @returns {JSX.Element}
 */
export const VideoDetail = (): JSX.Element => {
  const playerRef = useRef<ReactPlayer>(null)
  const { id } = useParams();
  const { search } = useLocation()
  const seekTo = new URLSearchParams(search).get('seekTo')
  const [getSingedUrlWithKey] = useGetSignedUrlWithKeyMutation()
  const { isOperator, isAdmin, isBrandManager } = useContext(AuthContext)
  const { dispatch, isScrolling, transcription, isVideoLoading, showAlert, videoUrl, activeSentenceIndex, isCommentOpened } = useContext(RecordedVideoContext)
  const [scheduleMeetingData, setScheduleMeetingData] = useState<ScheduleMeeting | undefined>()
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab')
  const [value, setValue] = useState(parseInt(activeTab || '') || 0);
  const [seekingTime, setSeekingTime] = useState<string | null | undefined>("")
  const transcriptionBoxRef = useRef<HTMLDivElement | null>(null)

  const handleScroll = () => {
    dispatch({ type: VideoDetailActionType.SET_IS_SCROLLING, isScrolling: true })
    dispatch({ type: VideoDetailActionType.SET_IS_COMMENT_OPENED, isCommentOpened: true })
  };

  const handleVideoProgress = (videoProgress: OnProgressProps) => {
    if (!isScrolling) {
      const currentTime = videoProgress.playedSeconds;
      const activeIndex = transcription.findIndex((sentence) => {
        const { startTime, endTime } = sentence;

        if (!!startTime && !!endTime) {
          return currentTime >= parseInt(startTime) && currentTime <= parseInt(endTime);
        }

        return false;
      });

      dispatch({ type: VideoDetailActionType.SET_ACTIVE_SENTENCE_INDEX, activeSentenceIndex: activeIndex })

      if (activeIndex !== -1) {
        const activeSentenceElement = document.getElementById(`sentence-${activeIndex}`);
        if (activeSentenceElement) {
          activeSentenceElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    } else {
      dispatch({ type: VideoDetailActionType.SET_ACTIVE_SENTENCE_INDEX, activeSentenceIndex: -1 })
    }
  };

  const [getScheduleMeeting, { loading: isTranscriptLoading, error }] = useGetScheduleMeetingLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError({ message }) {
      ALERT.error(message);
      setScheduleMeetingData(undefined)
    },

    onCompleted(data) {
      const { getScheduleMeeting } = data

      if (getScheduleMeeting) setScheduleMeetingData(getScheduleMeeting)
    }
  })

  const getVideoSingedUrl = async (videoFileKey: string) => {
    const result: FetchResult<GetSignedUrlWithKeyMutation> = await getSingedUrlWithKey({ variables: { fileKey: videoFileKey } })
    const { data } = result
    const { getSignedUrlWithKey } = data || {}
    getSignedUrlWithKey && dispatch({ type: VideoDetailActionType.SET_VIDEO_URL, videoUrl: getSignedUrlWithKey });
  }

  const handlePlay = async (time: string | undefined | null) => {
    if (!!time) {
      const [hours, minutes, seconds] = time.split(":").map(parseFloat);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      const playerElement = playerRef?.current

      if (playerElement) {
        const videoWrapper = document.getElementById('recorded-video-player');

        if (videoWrapper) {
          const videoElement = videoWrapper.querySelector('video');

          if (videoElement) {
            videoElement.addEventListener('loadedmetadata', () => {
              videoElement.currentTime = totalSeconds;
            });

            videoElement.currentTime = totalSeconds;
            await videoElement.play()
          }
        }
      }
    }
  };

  const handleAlertShow = () => {
    dispatch({ type: VideoDetailActionType.SET_SHOW_ALERT, showAlert: false })
  }

  const init = async () => {
    if (seekTo && !isVideoLoading && scheduleMeetingData) {
      await handlePlay(seekTo)
    }
  }

  const handleScrollEvent = () => {
    dispatch({ type: VideoDetailActionType.SET_IS_SCROLLING, isScrolling: false })
  }

  const resumeScrollHandler = () => {
    dispatch({ type: VideoDetailActionType.SET_IS_SCROLLING, isScrolling: false })
    dispatch({ type: VideoDetailActionType.SET_IS_COMMENT_OPENED, isCommentOpened: false })
  }

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSearchParams('')
    setValue(newValue);
  };

  useEffect(() => {
    if (id) {
      getScheduleMeeting({
        variables: {
          meetingId: id
        }
      })
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (scheduleMeetingData) {
      const { attachments, transcription } = scheduleMeetingData
      const videoFile = attachments?.find(meeting => meeting.key?.includes('.mp4'))
      const { key, url } = videoFile || {}
      key && !url && getVideoSingedUrl(key)
      url && dispatch({ type: VideoDetailActionType.SET_VIDEO_URL, videoUrl: url })

      if (transcription?.length) {
        const transcriptionSort = [...transcription]?.sort((a, b) => (a?.orderNumber || 0) - (b?.orderNumber || 0));
        dispatch({ type: VideoDetailActionType.SET_TRANSCRIPTION, transcription: transcriptionSort })
      }
    }
    // eslint-disable-next-line
  }, [scheduleMeetingData?.id])

  useEffect(() => {
    if (!!seekingTime) {
      (async () => await handlePlay(seekingTime))()
    }
    (async () => await init())()
    const playerElement = playerRef?.current

    return () => {
      setSeekingTime(undefined);
      if (playerElement) {
        const videoWrapper = document.getElementById('recorded-video-player');

        if (videoWrapper) {
          const videoElement = videoWrapper.querySelector('video');

          if (videoElement) {
            videoElement.removeEventListener('loadedmetadata', () => {
              videoElement.currentTime = 0
            });
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [seekTo, isVideoLoading, scheduleMeetingData?.id, seekingTime])

  useEffect(() => {
    const transcriptionBox = transcriptionBoxRef?.current
    transcriptionBox?.addEventListener('wheel', handleScrollEvent)

    return () => transcriptionBox?.removeEventListener('wheel', handleScrollEvent);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return () => {
      dispatch({ type: VideoDetailActionType.SET_IS_SCROLLING, isScrolling: false })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return () => {
      dispatch({ type: VideoDetailActionType.SET_TRANSCRIPTION, transcription: [] })
    }
    // eslint-disable-next-line
  }, [])

  const { user, customerName, eventCreatedAt, widget } = scheduleMeetingData || {}
  const { fullName } = user || {}
  const { name: widgetName, type: widgetType } = widget || {}
  const brandId = localStorage.getItem(BRAND_ID)
  const conditionalHeightRender = (showAlert && isOperator) ? 'calc(100vh - 596px)' : (!showAlert && isOperator) ? 'calc(100vh - 548px)' : (showAlert && !isOperator) ? 'calc(100vh - 652px)' : 'calc(100vh - 605px)'
  const stringToAppend = 'sp_full_hd'
  const updatedVideoUrl = videoUrl?.split('/upload').join(`/upload/${stringToAppend}`).replace(/mp4$/, 'm3u8')

  if (isTranscriptLoading && !scheduleMeetingData) return (
    <Box sx={{ ...videoDetailParentBox, mt: '70px' }}>
      <VideoDetailSkeleton />
    </Box>
  )

  return (
    <Box pb='32px'>
      <Box p='20px 0 20px 0'>
        {(isAdmin || isBrandManager) &&
          <Link to={`/brand/${brandId}/recorded-videos`}>
            <BackButton />
          </Link>
        }
      </Box>

      {!error ? <Box sx={videoDetailParentBox}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item md={6} xs={12}>
            <Box>
              <Box display='flex' alignItems='center'>
                <AvatarIcon bgColor='#cc474721' color='#CC4747' text={getInitials(fullName || "--")} />

                <Box pl={1}>
                  <Typography>{getCapitalize(fullName || "--")}</Typography>

                  <Box display='flex' alignItems='center'>
                    <Typography fontSize="14px" component='span'>{formatDate(new Date(eventCreatedAt))}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box pt='14px'>
                <Typography component='span' fontSize='12px'>DESCRIPTION</Typography>

                <Typography>Added by {fullName} - {widgetName} - {widgetType}</Typography>

                <Box pt={1}>
                  <Typography fontSize="14px">{customerName ? `This video session has been attended with ${customerName}` : "No customer found"}</Typography>
                </Box>
              </Box>
            </Box>

          </Grid>

          <Grid item md={6} xs={12}>
            {!!videoUrl?.length &&
              <CustomVideoPlayer id="recorded-video-player">
                <ReactPlayer className='react-player'
                  width='100%'
                  height='308px'
                  config={{
                    file: {
                      forceHLS: !hasNativeHLS(),
                      forceVideo: true,
                      hlsVersion: '1.3.0',
                      attributes: {
                        autoPlay: true,
                        playsInline: true,
                        controlsList: 'fullscreen',
                        disablePictureInPicture: true,
                      },
                    },
                  }}
                  url={updatedVideoUrl}
                  playIcon={<Box sx={{ zIndex: '99' }}><PlayerIcon /></Box>}
                  playsinline
                  controls={true}
                  ref={playerRef}
                  onReady={() => dispatch({ type: VideoDetailActionType.SET_VIDEO_LOADING, isVideoLoading: false })}
                  preload='auto'
                  muted={true}
                  onProgress={handleVideoProgress}
                />
              </CustomVideoPlayer>}
          </Grid>
        </Grid>

        <Box pt='20px' pb='8px' pr='18px'>
          <Box sx={tabsSticky}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
              <Tab sx={{ textTransform: 'capitalize' }} label="Transcript" {...handleTabs(0)} />

              <Tab sx={{ textTransform: 'capitalize' }} label="Summary" {...handleTabs(1)} />
            </Tabs>

          </Box>

          <TabPanel value={value} index={0}>
            {showAlert && <Alert severity="info" onClose={handleAlertShow} sx={{ marginTop: '10px' }}>
              {value !== 1 ? `You will get the transcription of the video in the section below what you've talked about at that time!` : `You will get the summary of the video in the section below what you've talked about at that time!`}
            </Alert>}
            {!!transcription.length &&
              <Box sx={{ ...transcriptGridStyle, maxHeight: conditionalHeightRender, minHeight: '30vh' }} onWheel={handleScroll} ref={transcriptionBoxRef}>
                {transcription?.map((transcriptObject, index) => (
                  <VideoCommentBox
                    key={`sentence-${index}`}
                    id={`sentence-${index}`}
                    sx={{
                      backgroundColor: activeSentenceIndex === index ? "rgba(255, 89, 150, 0.1)" : "transparent",
                    }}
                  >
                    <SpeakerCommentComponent
                      speakerData={transcriptObject}
                      handlePlay={handlePlay}
                    />
                  </VideoCommentBox>
                ))}

                <Fade in={isScrolling && isCommentOpened}>
                  <Box
                    sx={containerStyle}
                    onClick={resumeScrollHandler}
                  >
                    <Button sx={{ backgroundColor: 'rgba(95, 64, 166, 1)' }} variant="contained">
                      Resume Autoscroll
                    </Button>
                  </Box>
                </Fade>
              </Box>
            }
          </TabPanel>

          <TabPanel value={value} index={1}>
            <TranscriptionSummary scheduleMeetingData={scheduleMeetingData} setSeekingTime={setSeekingTime} />
          </TabPanel>
        </Box>
      </Box>
        :
        <NoDataFound height='calc(100vh - 300px)' description='No Record found' />
      }
    </Box>
  )
}