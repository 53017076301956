
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, CircularProgress, DialogActions, Typography } from "@mui/material";
import axios from "axios";
import { FC, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { images } from "../../../../assets/images";

import { AttachmentType, MetaType, VideoOrientation } from "../../../../generated";
import { SOMETHING_WENT_WRONG } from "../../../constants";
import { AppContext, AuthContext } from "../../../context";
import { ActionType } from "../../../context/AppContextReducer";
import { CreateVideoModalProps, UploadVideoModalProps } from "../../../interfaceTypes";
import { getToken } from "../../../utils";
import { createVideoSchema } from "../../../validationSchema";
import { Alert } from "../../common/Alert";
import { CustomController } from "../../common/CustomController";
import { CustomModal } from "../../common/CustomModal";
import { UploadVideoDocument } from "../../common/UploadVideoDocument";
import UploadVideoThumbnail from "../../common/UploadVideoThumbnail";

export const CreateVideoModal: FC<UploadVideoModalProps> = ({ isOpen, handleClose, currentVideo, videoType, entityType, typeId, isLandscape, refetchWidget }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { selectedBrand, refetchBrand, dispatch } = useContext(AppContext);
  const { currentUser } = useContext(AuthContext);
  const { id: brandId, name, attachments, createdAt, updatedAt } = selectedBrand || {}

  const methods = useForm<CreateVideoModalProps>({
    resolver: yupResolver(createVideoSchema),

    defaultValues: {
      title: "",
      description: "",
      thumbnailId: "",
    },
  });

  const { handleSubmit, reset } = methods
  const { id: videoId } = currentVideo || {}
  const token = getToken()

  const onSubmit = async (data: CreateVideoModalProps) => {
    const formData = new FormData();
    const { title: videoTitle, description, thumbnailId } = data
    const orientation = isLandscape ? VideoOrientation.Landscape : VideoOrientation.Portrait
    const orientationToSend = orientation.toLowerCase()

    if (files.length !== 0) {
      videoId && formData.append('id', videoId)
      typeId && formData.append('typeId', typeId);
      formData.append("type", videoType);
      formData.append('title', videoTitle)
      formData.append('description', description)
      formData.append('thumbnailId', thumbnailId)
      formData.append("file", files[0]);
      formData.append('videoOrientation', orientationToSend)

      try {
        setIsLoading(true)
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/${entityType}/upload-file`,
          formData,
          {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        )

        const { data, status } = response
        if (status === 201 && data) {
          const { metaType, type, status: statusInData, message } = data

          if (statusInData === 200) {
            Alert.success(message);
          } else {
            Alert.success('File uploaded successfully');
          }

          if (metaType === MetaType.Brand) {
            const updatedBrand = {
              ...selectedBrand,
              id: typeId || "",
              name: name || "",
              createdAt: createdAt!,
              updatedAt: updatedAt!,
            }

            if (type === AttachmentType.HookVideo) {
              const otherAttachments = attachments?.filter((attachment) => attachment.id !== data.id)

              const updatedBrandWithHookVideo = {
                ...updatedBrand,
                attachments: otherAttachments && otherAttachments.length > 0 ? [data, ...otherAttachments] : [data],
              }
              dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrandWithHookVideo })
            }

            if (type === AttachmentType.Video) {
              const updatedBrandWithVideo = {
                ...updatedBrand,
                attachments: attachments ? [data, ...attachments] : [data],
              }
              dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrandWithVideo })
            }
          }

          setIsLoading(false)
          refetchBrand && typeId && refetchBrand({ brandId: typeId })
          refetchWidget && refetchWidget()
          setFiles([])
          reset()
          handleClose()
        }
      }

      catch (error) {
        setIsLoading(false)
        console.log('upload video file error in create video modal >>>>>>>>>>', error)
        Alert.error(SOMETHING_WENT_WRONG);
      }
    }
  }
  const filesLength = files.length === 0
  const { attachments: userAttachments } = currentUser || {}
  const userThumbnails = userAttachments?.filter(attachment => attachment.type === AttachmentType.Thumbnail)

  return (
    <>
      <CustomModal maxWidth="800px" isOpen={isOpen} handleClose={handleClose} title="Create Video" hideBackdrop>
        <Box paddingTop='25px'>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off">
              <Box>
                <UploadVideoDocument
                  entityType={entityType || "brands"}
                  typeId={typeId || brandId || ""}
                  attachmentType={videoType}
                  attachmentId={videoId || undefined}
                  fieldName="videoUrl"
                  files={files}
                  setFiles={setFiles}
                  isLoading={isLoading}
                />
              </Box>

              <Box display='flex' mt={1} alignItems='center'>
                {!isLandscape ?
                  <Typography variant="body1" display='flex'><Box component='img' src={images.PORTRAIT} mr={1} />Portrait</Typography>
                  :
                  <Typography variant="body1" display='flex'><Box component='img' src={images.LANDSCAPE} mr={1} />Landscape</Typography>
                }
              </Box>

              <Box>
                <CustomController
                  controllerName="title"
                  controllerLabel="Video Title"
                  fieldType='text'
                  tooltipText="This will appear on the customer end on the widget."
                />
              </Box>

              <Box sx={{ marginBottom: '1rem' }}>
                <CustomController
                  controllerName="description"
                  controllerLabel="Description"
                  fieldType='text'
                />
              </Box>

              <UploadVideoThumbnail isLandscape userThumbnails={userThumbnails || []} fieldName="thumbnailId" />

              <DialogActions sx={{ paddingRight: '0' }}>
                <Button type='submit' variant="contained" color="primary" disabled={isLoading || filesLength} endIcon={isLoading && <CircularProgress size={20} color="inherit" />}>Finish</Button>
              </DialogActions>
            </form>
          </FormProvider>
        </Box>
      </CustomModal>
    </>
  )
}