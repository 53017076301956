import { FC } from "react";
import { Box } from "@mui/material";
import { images } from "../../../../assets/images";
import { SiteWidgetPreviewProps } from "../../../interfaceTypes";
import { brandImages } from "../../../theme/styleConstants";

export const SiteWidgetBubble: FC<SiteWidgetPreviewProps> = ({ idleBackgroundColor, logoUrl }) => (
  <Box sx={brandImages}>
    <Box height='83px' width='83px' position='relative'>
      <Box sx={{ background: idleBackgroundColor }} borderRadius='40px 40px 40px 5px' height='83px' width='83px' />

      <Box position='absolute' component='img' src={logoUrl || images.DUMMY} />
    </Box>
  </Box>
)
