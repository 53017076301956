import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { WidgetType } from '../../../generated';
import { CustomWindow } from '../../interfaceTypes';
import { getWidgetPreviewName } from '../../utils';

export const WidgetPreview = () => {
  const { brandId, widgetId } = useParams();
  const [searchParams] = useSearchParams()
  let widgetType = searchParams.get('widgetType')

  const showPopupWidget = () => {
    (window as CustomWindow)?.cabaPopup?.open({ hookVideo: true, designAdvice: true, customerSupport: true });
  }

  const getWidgetType = (widgetType: WidgetType) => {
    switch (widgetType) {
      case WidgetType.Site:
        return 'site';

      case WidgetType.Block:
        return 'pdp-block';

      case WidgetType.LandingPage:
        return 'landing-page';

      case WidgetType.PopupWidget:
        return 'pop-up';

      default:
        return "";
    }
  }

  const currentWidgetType = widgetType && getWidgetType(widgetType as WidgetType) === 'pdp-block' ? 'page-block' : widgetType && getWidgetType(widgetType as WidgetType)

  useEffect(() => {
    if (!!widgetType) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${process.env.REACT_APP_AWS_BASE_URL}/${getWidgetType(widgetType as WidgetType)}-widget/build/main.css`;
      document.head.appendChild(link);

      const script = document.createElement('script');
      script.defer = true;
      script.id = `caba-${currentWidgetType}-widget-script`;
      script.setAttribute('caba-widget-id', widgetId || '');
      script.setAttribute('caba-brand-id', brandId || '');
      widgetType === WidgetType.LandingPage && script.setAttribute('caba-widget-preview', "true");
      script.setAttribute('caba-x-api-key', '');
      script.src = `${process.env.REACT_APP_AWS_BASE_URL}/${getWidgetType(widgetType as WidgetType)}-widget/build/bundle.js`;

      document.getElementById(`caba-main-widget`)?.appendChild(script);
    }

    return () => {
      const scriptElement = document.getElementById(`caba-${currentWidgetType}-widget-script`)

      // TO CLEAN UP SCRIPT ELEMENT
      document.getElementById(`caba-main-widget`)?.removeChild(scriptElement as HTMLScriptElement)
    }
  }, [brandId, widgetType, widgetId, currentWidgetType])

  return (
    <Box pt="50px">
      <Box id="caba-main-widget">
        {widgetType &&
          <>
            <Typography sx={{ marginTop: 1, pb: 2 }} variant="subtitle1">{getWidgetPreviewName(widgetType as WidgetType)}</Typography>
            <Box id={`caba-${currentWidgetType}-widget`} />
          </>
        }

        {widgetType === WidgetType.PopupWidget &&
          <Button onClick={showPopupWidget} type='button' sx={{ display: 'flex', justifyContent: 'center' }} variant='contained' color='primary'>Open Popup Widget</Button>
        }
      </Box>
    </Box>
  )
}
