import { Box, Grid, Skeleton } from '@mui/material'

export const TaskEditTemplateSkeleton = () => (
  <Box>
    <Skeleton sx={{ marginTop: "2rem" }} variant="text" width={200} height={30} />

    <Skeleton variant="text" width={100} height={20} />

    <Box marginTop={4}>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" height={20} width={100} />
          <Skeleton sx={{ marginTop: '10px', marginBottom: '20px' }} variant="text" height={20} width={200} />
        </Grid>
      </Grid>
    </Box>
  </Box>
)
