import { Breadcrumbs, Typography, Link } from '@mui/material'
import { FC } from 'react'
import { BreadCrumbProps } from '../../interfaceTypes'

export const BreadCrumb: FC<BreadCrumbProps> = ({ currentPage, previousPage }) => (
  <Breadcrumbs sx={{ marginTop: 1, marginBottom: 2 }} aria-label="breadcrumb">
    <Link href="/brand-settings?activeTab=2" fontSize={14} underline="none">
      {previousPage}
    </Link>

    <Typography color="inherit" fontSize={14}>{currentPage}</Typography>
  </Breadcrumbs>
)
