const palette = {
  primary: {
    main: "#5F40A6"
  },
  secondary: {
    main: '#FF5996',
  },
  success: {
    main: '#ECE8F4'
  },

  black: '#000000',
  blackOne: 'rgba(0, 0, 0, 0.6)',
  blackTwo: '#635C72',
  lightColor: 'rgba(0, 0, 0, 0.54)',
  lightColorTwo: 'rgba(27, 15, 56, 0.07)',
  lightColorThree: 'rgba(0, 0, 0, 0.87)',
  lightBorderColor: '#EFEEF1',
  lightBorderColorTwo: '#E0E0E0',
  lightBorderColorThree: '#eeeeee52',
  lightBlack: '#888298',
  light: 'rgba(95, 64, 166, 0.12)',
  lightOne: 'rgba(0, 0, 0, 0.04)',
  lightTwo: '#E0DEE4',
  lightThree: '#EBE8F4',
  lightFour: '#A1A1A1',
  lightFive: '#898298',
  lightSix: '#F5F5F5',
  lightSeven: 'rgba(25, 55, 101, 0.05)',
  lightEight: 'rgba(255, 255, 255, 0.003)',
  lightNine: 'rgba(255, 89, 150, 0.1)',
  lightTen: 'rgb(236, 243, 242)',
  dark: '#C4C4C4',
  danger: '#D32F2F',
  pink: '#CC4778',
  pinkTwo: '#FFDEEAB2',
  darkPurple: '#1B0F3812',
  lightPink: '#FFDEEA',
  pinkOne: '#FF5996',
  pinkThree: '#FCEEF5',
  white: '#FFFFFF',
  redDev: '#F5F7F8',
  lightGray: 'rgba(0, 0, 0, 0.12)',
  green: '#0CC72A',
  red: '#fc5e5e',
  redTwo: 'rgb(252, 94, 94)',
  darkRed: '#771117',
  tooltipBackground: 'rgba(97,97,97,0.92)',
  bodyBackground: '#F8F8F9',
  offWhite: '#F5F5F5',
  yellow: '#ed6c02',
  editorBorder: '#F1F1F1',
  lightLeftBorder: '#e2e2e2',
  lightPink2: 'rgb(255, 89, 150)',
  lightBackground: 'rgba(0, 0, 0, 0.2)',
  purple1: "#5F40A6",
}

export default palette;