import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { Box, Button, Popover, Typography } from '@mui/material'
import { FC, MouseEvent, useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { MoveArrow, TransparentCloseIcon } from '../../../assets/images'
import { VideoOrientation } from '../../../generated'
import { HOOK_VIDEO } from '../../constants'
import { AppContext } from '../../context'
import { PreviewHookVideoLayoutProps } from '../../interfaceTypes'
import palette from '../../theme/palette'
import { VideoPlayIcon } from '../../theme/styleComponents'
import { HookVideoHeadingWrapper, PopoverStyle, videoIcon } from '../../theme/styleConstants'
import { CustomLazyImage } from '../common/CustomLazyImage';
import DefaultVideoThumbnail from '../../../assets/images/video-thumbnail-3.jpg';

export const PreviewHookVideoLayoutMobile: FC<PreviewHookVideoLayoutProps> = ({ hookVideos }) => {
  const [anchorElDescriptionPopover, setAnchorElDescriptionPopover] = useState<HTMLElement | null>(null);
  const [anchorElTitlePopover, setAnchorElTitlePopover] = useState<HTMLElement | null>(null);
  const popOverOpen = Boolean(anchorElDescriptionPopover);
  const popOverTitleOpen = Boolean(anchorElTitlePopover);
  const { selectedBrand } = useContext(AppContext)
  const { attachments } = selectedBrand || {}
  const brandHookVideos = attachments?.filter(attachment => attachment.type === HOOK_VIDEO)
  const brandHookVideoPortrait = brandHookVideos?.find(video => video.videoOrientation === VideoOrientation.Portrait)
  const widgetHookVideoPortrait = hookVideos?.find(video => video.videoOrientation === VideoOrientation.Portrait)
  const { thumbnail: brandHookVideoThumbnail } = brandHookVideoPortrait || {}
  const { url: brandHookVideoThumbnailUrl, title: brandHookVideoTitle, description: brandHookVideoDescription } = brandHookVideoThumbnail || {}
  const { thumbnail, title: widgetHookVideoTitle, description: widgetHookVideoDescription } = widgetHookVideoPortrait || {}
  const { url: widgetHookVideoThumbnail } = thumbnail || {}
  const { watch } = useFormContext()
  const mobileCtaButtonTextValue = watch('mobileCtaButtonText')
  const mobileCtaButtonStyleValue = watch('mobileCtaButtonStyle')

  const handlePopoverDescriptionOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElDescriptionPopover(event.currentTarget);
  };

  const handlePopoverDescriptionClose = () => {
    setAnchorElDescriptionPopover(null);
  };

  const handlePopoverTitleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElTitlePopover(event.currentTarget);
  };

  const handlePopoverTitleClose = () => {
    setAnchorElTitlePopover(null);
  };

  return (
    <Box padding={1}>
      <Box mb={2} height={653.33} width={'100%'} margin='0 auto 20px auto' position='relative'>
        <CustomLazyImage
          imageUrl={widgetHookVideoThumbnail || brandHookVideoThumbnailUrl || DefaultVideoThumbnail}
        />

        <VideoPlayIcon sx={{ backgroundColor: palette.lightBackground }}>
          <PlayCircleIcon sx={videoIcon} />
        </VideoPlayIcon>

        <Box sx={HookVideoHeadingWrapper}>
          <Box display='flex' alignItems='flex-start' mb={1}>
            <Box flex='1 1'>
              <Box>
                <Typography
                  aria-haspopup="true"
                  aria-owns={popOverTitleOpen ? 'mouse-over-popover' : undefined}
                  variant="subtitle1"
                  component="div"
                  sx={{ color: 'white', maxWidth: '255px' }}
                  noWrap
                  onMouseEnter={handlePopoverTitleOpen}
                  onMouseLeave={handlePopoverTitleClose}
                >
                  {widgetHookVideoTitle || brandHookVideoTitle}
                </Typography>

                <Popover
                  id="mouse-over-popover"
                  sx={{ pointerEvents: 'none', zIndex: 99999 }}
                  open={popOverTitleOpen}
                  anchorEl={anchorElTitlePopover}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  onClose={handlePopoverTitleClose}
                  disableRestoreFocus
                >
                  <Typography sx={PopoverStyle} variant='h3'>{widgetHookVideoTitle || brandHookVideoTitle}</Typography>
                </Popover>
              </Box>

              <Box>
                <Typography
                  gutterBottom
                  aria-haspopup="true"
                  aria-owns={popOverOpen ? 'mouse-over-popover' : undefined}
                  variant="subtitle1"
                  component="div"
                  sx={{ color: 'white', maxWidth: '255px' }}
                  noWrap
                  onMouseEnter={handlePopoverDescriptionOpen}
                  onMouseLeave={handlePopoverDescriptionClose}
                >
                  {widgetHookVideoDescription || brandHookVideoDescription}
                </Typography>

                <Popover
                  id="mouse-over-popover"
                  sx={{ pointerEvents: 'none', zIndex: 99999 }}
                  open={popOverOpen}
                  anchorEl={anchorElDescriptionPopover}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  onClose={handlePopoverDescriptionClose}
                  disableRestoreFocus
                >
                  <Typography sx={PopoverStyle} variant='h3'>{widgetHookVideoDescription || brandHookVideoDescription}</Typography>
                </Popover>
              </Box>
            </Box>

            {TransparentCloseIcon()}
          </Box>
        </Box>

        <Box position='absolute' bottom={15} width='100%' textAlign='center'>
          <Button sx={{ borderRadius: mobileCtaButtonStyleValue, background: 'rgba(0,0,0,.4) !important', border: `1px solid ${palette.white}` }}>
            <>
              <Typography maxWidth='172px' noWrap sx={{ color: 'white', marginRight: '5px' }}>{mobileCtaButtonTextValue || 'N/A'}</Typography>
              {MoveArrow()}
            </>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
