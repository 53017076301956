import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, DialogActions, IconButton, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { TEXT_COPIED } from '../../constants';
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import palette from '../../theme/palette';
import { Alert } from './Alert';
import { CustomModal } from './CustomModal';
import { GenerateApiKeyViewInterface } from '../../interfaceTypes';

export const GenerateApiKeyView: FC<GenerateApiKeyViewInterface> = ({ locallyKey }) => {
  const { isEdit, dispatch } = useContext(AppContext)
  const handleGenerateViewApiClose = () => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: false })
  };

  const copyText = () => {
    if (locallyKey) {
      navigator.clipboard.writeText(locallyKey)
      Alert.success(TEXT_COPIED)
    }
  }

  return (
    <CustomModal
      title="Your new api key"
      maxWidth='750px'
      isOpen={isEdit}
      handleClose={handleGenerateViewApiClose}
    >
      <Box mb={1}>
        <Typography>Save and store this new key to a secure place, such as a password manager or secret store.</Typography>
        <Typography mt={1} variant='h4' sx={{ color: palette.lightPink2, fontWeight: '600' }}>You won't be able to see it again.</Typography>
      </Box>

      <Box display='flex' my={2} p={2} borderRadius={2} border={`1px solid ${palette.lightGray}`} alignItems='center'>
        <Box flex={1} sx={{ wordBreak: 'break-word' }}>
          {locallyKey}
        </Box>

        <IconButton id="copy-button" onClick={copyText} size="small"><ContentCopyIcon fontSize="small" color="primary" /></IconButton>
      </Box>

      <DialogActions sx={{ padding: '0' }}>
        <Button variant='contained' color='primary' onClick={handleGenerateViewApiClose}>Done</Button>
      </DialogActions>
    </CustomModal>
  )
}