import { Card } from "@mui/material";
import { FC } from "react";
import { VideoListingComponent } from "./VideoListingComponent";

/**
 * Component for displaying recorded Videos.
 * @returns {JSX.Element}
 */
export const Videos: FC = (): JSX.Element => (
  <Card sx={{ padding: '0px 16px 16px 16px' }}>
    <VideoListingComponent />
  </Card>
)