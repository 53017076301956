// packages
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import { FC, useContext, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
// component
import { UploadArea, UploadBox, UploadIcon } from "../../theme/styleComponents";
import { Alert } from "./Alert";
// others
import { DocumentIcon } from "../../../assets/images";
import {
  Attachment, AttachmentType, MetaType, SnappingPosition, UserStatus, VideoBox,
  VideoGridType, VideoLayout, VideoOrientation, WidgetType
} from "../../../generated";
import { AUTH_TOKEN, FILE_IS_GREATER, IMAGE_IS_LARGER, SOMETHING_WENT_WRONG } from "../../constants";
import { AppContext, AuthContext } from "../../context";
import { ActionType } from "../../context/AppContextReducer";
import { UploadDocumentProps } from "../../interfaceTypes";

export const UploadDocument: FC<UploadDocumentProps> = ({
  typeId, entityType, attachmentType, attachmentId, setActiveStep, fieldName,
  setCurrentUploadedAttachment, currentUploadedAttachment, activeStep,
  setIsDocumentLoading, widgetData, setWidgetData, description, accept,
  maxSize, refetchBrandsData, isCreateBrand, isLandscape, isKiosk, refetchWidget, metaType, setVideoForFeatureImage, videoForFeatureImage,
  recommendedSize
}) => {
  const { selectedBrand, refetchBrand, dispatch } = useContext(AppContext)
  const { currentUser, setUser } = useContext(AuthContext)
  const token = localStorage.getItem(AUTH_TOKEN);
  const [files, setFiles] = useState<File[]>([])
  const [uploading, setUploading] = useState<boolean>(false)
  const { attachments, id, createdAt: brandCreatedAt, name, updatedAt: brandUpdatedAt } = selectedBrand || {}
  const { id: currentAttachmentId } = currentUploadedAttachment || {}
  const { id: userId, status: userStatus, attachments: userAttachments = [], email, fullName, createdAt,
    updatedAt, emailVerified, attachment: userAttachment } = currentUser || {}
  const {
    brand, id: widgetId, brandId, name: widgetName, createdAt: widgetCreatedAt,
    snappingPosition, updatedAt: widgetUpdatedAt, tellingFormHeading, tellingFormDescription, type: widgetType,
    videoBoundingBox, videoGridType, videoLayout, videos, hookVideos, showBannerAlways, skipVideos, hookVideoFlyerTimer,
    showCustomerSupport, showDesignAdvice, customerSupportButtonHeading, customerSupportButtonDescription, designAdviceButtonHeading,
    designAdviceButtonDescription, mobileFlyerHeading, mobileFlyerDescription, showClientFormHookVideo } = widgetData || {}
  const {
    id: widgetBrandId, attachments: widgetBrandAttachments, name: widgetBrandName,
    createdAt: widgetBrandCreatedAt, updatedAt: widgetBrandUpdatedAt
  } = brand || {}
  const mediumViewport = useMediaQuery('(max-width:500px)');

  const onDrop = async (acceptedFiles: File[]) => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));

    const formData = new FormData();
    const orientation = isLandscape ? VideoOrientation.Landscape : VideoOrientation.Portrait
    const orientationToSend = orientation.toLowerCase()
    if (acceptedFiles.length !== 0) {
      typeId && formData.append('typeId', typeId);
      (attachmentType === 'featureImage' || attachmentType === 'bannerImage') && widgetId && formData.append('widgetId', widgetId);
      !currentAttachmentId && attachmentId && formData.append('id', attachmentId)
      attachmentId && currentAttachmentId && formData.append('id', currentAttachmentId)
      metaType && formData.append('metaType', metaType)
      formData.append("type", attachmentType);
      formData.append("file", acceptedFiles[0]);
      formData.append('videoOrientation', orientationToSend)

      try {
        setUploading(true)
        setIsDocumentLoading && setIsDocumentLoading(true)

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/${entityType}/upload-file`,
          formData,
          {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        )

        const { data, status } = response

        if (status === 201 && data) {
          const { metaType, type } = data
          Alert.success('File uploaded successfully');

          setCurrentUploadedAttachment && setCurrentUploadedAttachment(data)
          setActiveStep && setActiveStep(activeStep || 0 + 1)

          if (metaType === MetaType.Brand) {
            const updatedBrand = {
              ...selectedBrand,
              id: id || "",
              name: name || "",
              createdAt: brandCreatedAt!,
              updatedAt: brandUpdatedAt!,
            }

            if (type === AttachmentType.Logo) {
              const updatedBrandWithLogo = {
                ...updatedBrand,
                attachments: attachments || [],
                logo: data,
              }
              dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrandWithLogo })
              dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
              refetchBrand && !isCreateBrand && refetchBrand({ brandId: id })
              refetchBrandsData && refetchBrandsData()
            }

            if (type === AttachmentType.OnePager) {
              const updatedBrandWithOnePager = {
                ...updatedBrand,
                attachments: attachments || [],
                onePager: data,
              }
              dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrandWithOnePager })
            }

            if (type === AttachmentType.HookVideo) {
              const otherAttachments = attachments?.filter((attachment) => attachment.id !== data.id)

              const updatedBrandWithHookVideo = {
                ...updatedBrand,
                attachments: otherAttachments && otherAttachments.length > 0 ? [data, ...otherAttachments] : [data],
              }
              dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrandWithHookVideo })
            }

            if (type === AttachmentType.Video) {
              const updatedBrandWithVideo = {
                ...updatedBrand,
                attachments: attachments ? [data, ...attachments] : [data],
              }
              dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrandWithVideo })
            }
          }

          if (metaType === MetaType.User) {
            const updateUser = {
              ...currentUser,
              id: userId || "",
              email: email || "",
              fullName: fullName || "",
              emailVerified: emailVerified || false,
              createdAt: createdAt!,
              updatedAt: updatedAt!,
              status: userStatus || UserStatus.Active,
            }

            if (type === AttachmentType.Logo) {
              const updatedUserWithLogo = {
                ...updateUser,
                attachments: userAttachments || [],
                attachment: data as Attachment | null
              }

              setUser(updatedUserWithLogo)
            }

            if (type === AttachmentType.Thumbnail) {
              const thumbnails = userAttachments && userAttachments.length > 0 ? [...userAttachments, data] : [data]

              const updatedUserWithThumbnail = {
                ...updateUser,
                attachment: userAttachment,
                attachments: thumbnails,
              }

              setUser({ ...updatedUserWithThumbnail })
            }
            dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
          }

          if (metaType === MetaType.Widget) {
            const updatedWidget = {
              ...widgetData,
              id: widgetId || "",
              brandId: brandId || "",
              name: widgetName || "",
              showBannerAlways: showBannerAlways || false,
              snappingPosition: snappingPosition || SnappingPosition.BottomLeft,
              tellingFormHeading: tellingFormHeading || "",
              tellingFormDescription: tellingFormDescription || "",
              type: widgetType || WidgetType.Site,
              videoBoundingBox: videoBoundingBox || VideoBox.Regular,
              videoGridType: videoGridType || VideoGridType.Massonary,
              videoLayout: videoLayout || VideoLayout.ThreeVideos,
              videos: videos || [],
              hookVideos: hookVideos || [],
              createdAt: widgetCreatedAt!,
              updatedAt: widgetUpdatedAt!,
              skipVideos: skipVideos || false,
              hookVideoFlyerTimer: hookVideoFlyerTimer,
              showCustomerSupport: showCustomerSupport || false,
              showDesignAdvice: showDesignAdvice || false,
              customerSupportButtonHeading: customerSupportButtonHeading || "",
              customerSupportButtonDescription: customerSupportButtonDescription || "",
              designAdviceButtonHeading: designAdviceButtonHeading || "",
              designAdviceButtonDescription: designAdviceButtonDescription || "",
              mobileFlyerHeading: mobileFlyerHeading || "",
              mobileFlyerDescription: mobileFlyerDescription || "",

              brand: {
                id: widgetBrandId || "",
                name: widgetBrandName || "",
                attachments: widgetBrandAttachments || [],
                createdAt: widgetBrandCreatedAt!,
                updatedAt: widgetBrandUpdatedAt!,
              },
            }

            if (type === AttachmentType.Logo) {
              const updatedWidgetWithLogo = {
                ...updatedWidget,
                showCustomerSupport: showCustomerSupport || false,
                showDesignAdvice: showDesignAdvice || false,
                customerSupportButtonDescription: customerSupportButtonDescription || '',
                customerSupportButtonHeading: customerSupportButtonHeading || '',
                designAdviceButtonDescription: designAdviceButtonDescription || "",
                designAdviceButtonHeading: designAdviceButtonHeading || "",
                mobileFlyerHeading: mobileFlyerHeading || "",
                mobileFlyerDescription: mobileFlyerDescription || "",
                attachment: data as Attachment | null,
                showClientFormHookVideo: showClientFormHookVideo || false
              }

              setWidgetData && setWidgetData(updatedWidgetWithLogo)
            }
          }

          if (metaType === MetaType.Attachment) {
            if (videoForFeatureImage) {
              if (type === AttachmentType.FeatureImage) {
                setVideoForFeatureImage && setVideoForFeatureImage({
                  ...videoForFeatureImage,
                  featureImage: data
                })
              }

              if (type === AttachmentType.BannerImage) {
                setVideoForFeatureImage && setVideoForFeatureImage({
                  ...videoForFeatureImage,
                  bannerImage: data
                })
              }
            }
          }

          setFiles([])
          setUploading(false)
          setIsDocumentLoading && setIsDocumentLoading(false)
          refetchWidget && refetchWidget()
        }
      }

      catch (error) {
        Alert.error(SOMETHING_WENT_WRONG);
      }
    }
  }


  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize,
    onDrop: onDrop,
    accept,
    multiple: false
  });

  useEffect(() => {
    fileRejections.forEach(({ errors }) => (
      errors.map((messages) => {
        const { message } = messages || {}

        if (message === FILE_IS_GREATER) {
          return Alert.error(IMAGE_IS_LARGER)
        }
        else {
          return Alert.error(message)
        }
      }))
    )
  }, [fileRejections])

  return (
    <>
      {!uploading &&
        <Box width='100%'>
          <UploadArea>
            <Box {...getRootProps()}>
              <input name={fieldName} {...getInputProps()} />

              <UploadBox>
                {!isKiosk &&
                  <UploadIcon>
                    <UploadFileIcon color="primary" />
                  </UploadIcon>
                }

                <Typography sx={{ marginBottom: '.5rem' }} variant="h4"><Typography component='span' color='primary'>Click to upload </Typography>or drag and drop</Typography>

                <Typography variant="body2">{description}</Typography>

                {isKiosk &&
                  <Typography sx={{ marginBottom: '.5rem' }} variant="body2">{recommendedSize}</Typography>
                }
              </UploadBox>
            </Box>
          </UploadArea>
        </Box>
      }

      {uploading &&
        <Box mb='20px'>
          {files.map((file) => {
            const { name } = file || {}

            return (
              <Box borderRadius='4px' display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" key={file.name}>
                <DocumentIcon />

                <Box maxWidth={mediumViewport ? "70%" : "85%"} flex={1}>
                  <Typography variant="body1" fontSize="16px" letterSpacing="0.17px" noWrap>{name}</Typography>
                </Box>

                <Box marginTop="5px">
                  <CircularProgress size={35} />
                </Box>
              </Box>
            )
          })}
        </Box>
      }
    </>
  );
}