import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from '@mui/icons-material/Save';
import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { Reducer, useContext, useEffect, useReducer } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CreateAliasEmail, useCreateEmailAliasMutation, useGetAllEmailAliasLazyQuery } from "../../../generated";
import { EMAIL_ADDED, GRAPHQL_QUERY_POLICY } from "../../constants";
import { UserAction, UserActionType, UserState, userInitialState, userReducer } from "../../context/UserContextReducer";
import palette from "../../theme/palette";
import { emailInputFieldParentBox, emailInputFieldWrapper, emailInputFieldWrapperParentBox } from "../../theme/styleConstants";
import { emailAliasFormValidationSchema } from "../../validationSchema";
import { Alert } from "../common/Alert";
import { CustomController } from "../common/CustomController";
import { EmailInputField } from "./EmailInputField";
import { AuthContext } from "../../context";
import { DeleteEmailTemplate } from "./DeleteEmailTemplate";

export const EmailForm = () => {
  const { currentUser } = useContext(AuthContext)
  const [userState, userDispatch] = useReducer<Reducer<UserState, UserAction>>(userReducer, userInitialState);
  const { allEmails } = userState
  const { id: userId } = currentUser || {}

  const [getAllEmailAliases, { loading: getLoading }] = useGetAllEmailAliasLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onCompleted(data) {
      const { getAllEmailAlias } = data;
      userDispatch({ type: UserActionType.SET_ALL_EMAIL, allEmails: getAllEmailAlias })
    }
  });

  const getAllEmails = async () => {
    if (userId) {
      await getAllEmailAliases({
        variables: {
          userId
        }
      })
    }
  }

  const [createAliasEmail] = useCreateEmailAliasMutation({
    onError() { },

    onCompleted(data) {
      if (data) {
        const { createAliasEmail } = data;
        const updatedEmails = allEmails ? [createAliasEmail, ...allEmails] : [createAliasEmail]

        userDispatch({ type: UserActionType.SET_ALL_EMAIL, allEmails: updatedEmails })

        Alert.success(EMAIL_ADDED)
        reset();
      }
    }
  })

  const onSubmit = async (data: CreateAliasEmail) => {
    const { email } = data || {};

    if (userId) {
      await createAliasEmail({
        variables: {
          createAliasEmail: {
            userId,
            email
          }
        }
      })
    }
  }

  const methods = useForm<CreateAliasEmail>({
    mode: "all",
    resolver: yupResolver(emailAliasFormValidationSchema),

    defaultValues: {
      email: '',
      userId: ''
    }
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    (async () => await getAllEmails())()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Typography variant="h6">Email Aliases</Typography>

      {!getLoading ?
        <Box sx={emailInputFieldWrapperParentBox}>
          {allEmails?.map((emailData, index: number) => {
            const { id } = emailData

            return (
              <Box key={id} sx={emailInputFieldWrapper}>
                <EmailInputField userState={userState} userDispatch={userDispatch} emailData={emailData} />
              </Box>
            )
          })}
        </Box>
        :
        <Box>
          <Grid container>
            {Array(9).fill(0).map((_, index) => (

              <Grid item md={4}>
                <Box display='flex' gap='10px' key={index}>
                  <Box>
                    <Skeleton variant="text" width='150px' height='60px' />
                  </Box>
                </Box>
              </Grid>

            ))}
          </Grid>
        </Box>
      }

      <Box pt={2} marginBottom='2rem' borderBottom={`1px solid ${palette.lightBorderColor}`}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box sx={emailInputFieldParentBox}>
              <CustomController
                controllerName='email'
                controllerLabel='Email'
                fieldType='email'
              />

              <Box pt="15px">
                <IconButton type="submit">
                  <SaveIcon color="primary" />
                </IconButton>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>

      <DeleteEmailTemplate userState={userState} userDispatch={userDispatch} />
    </>
  )
}