import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { useRemoveJobMutation } from '../../../generated';
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { DeleteJobProps } from '../../interfaceTypes';
import { firstLetterUppercase } from '../../utils';
import { Alert } from '../common/Alert';
import { CustomModal } from '../common/CustomModal';

export const DeleteJobModal: FC<DeleteJobProps> = ({ refetch, job }) => {
  const { isDeleteOpen, dispatch } = useContext(AppContext)
  const { id, customerEmail } = job || {}
  const handleJobClose = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
  };
  const [removeJob, { loading }] = useRemoveJobMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ""))
    },

    onCompleted(data) {
      const { removeJob } = data || {}

      if (removeJob) {
        refetch && refetch()
        Alert.success("Job deleted successfully.");
        dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
        dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
      }
    },
  })

  const deleteJob = async () => {
    if (id) {
      removeJob({
        variables: {
          id,
        }
      })
    }
  }

  return (
    <CustomModal
      title="Delete Job"
      maxWidth='700px'
      isOpen={isDeleteOpen}
      handleClose={handleJobClose}
    >
      <Box>
        <Typography variant="h4" marginTop={2} marginBottom={3} paddingLeft={0.5}>Are you sure you want to delete this Job for customer {customerEmail}? </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button sx={{ marginRight: '10px' }} onClick={handleJobClose}>Cancel</Button>

        <Button variant="contained" onClick={deleteJob} color="error" disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}>
          Delete
        </Button>
      </Box>
    </CustomModal>
  )
}
