import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { JobStatus } from '../../generated'
import { PageHeading } from '../components/common/PageHeading'
import { JobsListing } from '../components/jobs/JobsListing'
import { JobStatusItems } from '../constants'
import { AppContext } from '../context'
import { ActionType } from '../context/AppContextReducer'
import { filterJobs, jobsAction } from '../theme/styleConstants'

export const JobsContainer = () => {
  const { dispatch } = useContext(AppContext)
  const [jobStatus, setJobStatus] = useState<string>('')

  const handleRoleChange = (event: SelectChangeEvent) => {
    const { target: { value } } = event
    setJobStatus(value as unknown as JobStatus)
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
  };

  useEffect(() => {
    setJobStatus(JobStatus.InReview)
  }, [])

  return (
    <>
      <Box sx={jobsAction}>
        <PageHeading title="Jobs" />

        <FormControl sx={{ ...filterJobs, marginTop: '2rem' }}>
          <InputLabel id="jobLabel" shrink>Job Status</InputLabel>

          <Select
            labelId="jobLabel"
            id="selectJob"
            notched
            defaultValue={JobStatus.InReview}
            value={jobStatus || ""}
            displayEmpty={true}
            sx={filterJobs}
            size="small"
            label="Job Status"
            onChange={handleRoleChange}
          >
            {JobStatusItems.map((status, index) => {
              const { name, value } = status || {}
              return (
                <MenuItem key={value} value={value}>{name}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>

      <JobsListing jobStatus={jobStatus} />
    </>
  )
}
