export const PlayerIcon = () => <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.3906 11.1562L9.14062 6.14062C8.39062 5.76562 7.5 6.28125 7.5 7.125V16.875C7.5 17.7656 8.39062 18.2812 9.14062 17.8594L17.3906 13.125C18.1875 12.7031 18.1875 11.625 17.3906 11.1562ZM23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12ZM2.625 12C2.625 6.84375 6.79688 2.625 12 2.625C17.1562 2.625 21.375 6.84375 21.375 12C21.375 17.2031 17.1562 21.375 12 21.375C6.79688 21.375 2.625 17.2031 2.625 12Z" fill="white" />
</svg>;

export const VideoPauseIconSvg = () => <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z" fill="white" /></svg>

export const VideoPlayIconSvg = () => <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" fill="white" />
</svg>

export const VolumeSolid = () => (
  <svg fill="#ffffff" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z" /></svg>
)

export const VolumeHighSolid = () => <svg fill="#ffffff" width="20" height="20" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
  <path d="M521.1 106.981C564.3 142.181 592 195.881 592 255.981C592 316.081 564.3 369.781 521.1 404.981C510.8 413.381 495.7 411.781 487.3 401.481C478.9 391.181 480.5 376.081 490.8 367.681C523.3 341.281 544 301.081 544 255.981C544 210.881 523.3 170.681 490.8 144.181C480.5 135.781 479 120.681 487.3 110.381C495.6 100.081 510.8 98.5809 521.1 106.881V106.981ZM460.6 181.481C482.1 199.081 496 225.881 496 255.981C496 286.081 482.1 312.881 460.6 330.481C450.3 338.881 435.2 337.281 426.8 326.981C418.4 316.681 420 301.581 430.3 293.181C441.1 284.381 448 270.981 448 255.981C448 240.981 441.1 227.581 430.3 218.681C420 210.281 418.5 195.181 426.8 184.881C435.1 174.581 450.3 173.081 460.6 181.381V181.481ZM349.1 34.7809C360.6 39.9809 368 51.3809 368 63.9809V447.981C368 460.581 360.6 471.981 349.1 477.181C337.6 482.381 324.1 480.281 314.7 471.881L179.8 351.981H112C76.7 351.981 48 323.281 48 287.981V223.981C48 188.681 76.7 159.981 112 159.981H179.8L314.7 40.0809C324.1 31.6809 337.6 29.6809 349.1 34.7809Z" />
</svg>;

export const VideoArrow = () => <svg width="20" height="20" viewBox="0 0 27 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M3.95515 20.7732C4.28061 21.0704 4.72202 21.2373 5.18229 21.2373C5.64255 21.2373 6.08397 21.0704 6.40942 20.7732L20.627 7.79196L20.6245 17.4119C20.6245 17.6203 20.6695 17.8266 20.7568 18.0191C20.8441 18.2116 20.9721 18.3865 21.1335 18.5338C21.2948 18.6811 21.4864 18.798 21.6972 18.8777C21.908 18.9574 22.134 18.9985 22.3622 18.9985C22.5903 18.9985 22.8163 18.9574 23.0271 18.8777C23.2379 18.798 23.4295 18.6811 23.5908 18.5338C23.7522 18.3865 23.8802 18.2116 23.9675 18.0191C24.0548 17.8266 24.0998 17.6203 24.0998 17.4119L24.0998 3.96683C24.1001 3.75841 24.0553 3.55198 23.9681 3.35938C23.8809 3.16677 23.7529 2.99177 23.5915 2.8444C23.4301 2.69702 23.2384 2.58017 23.0275 2.50053C22.8165 2.4209 22.5904 2.38004 22.3622 2.3803L7.63656 2.38031C7.17571 2.38031 6.73374 2.54746 6.40787 2.84499C6.082 3.14252 5.89893 3.54606 5.89893 3.96683C5.89893 4.3876 6.08201 4.79114 6.40787 5.08867C6.73374 5.3862 7.17571 5.55335 7.63656 5.55335L18.1727 5.55111L3.95516 18.5324C3.6297 18.8295 3.44686 19.2326 3.44686 19.6528C3.44686 20.073 3.6297 20.4761 3.95515 20.7732Z" fill="#5F40A6" />
</svg>;

export const AnimatedLoader = () => <svg xmlns="http://www.w3.org/2000/svg" width="70px" height="70px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="10" r="35"
    strokeDasharray="164.93361431346415 56.97787143782138">
    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
      values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
  </circle>
</svg>;

export const PlaySVG = () => <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.838867 0.15918V14.1592L11.8389 7.15918L0.838867 0.15918Z" fill="black" fill-opacity="0.54" />
</svg>

export const MoreIcon = () => <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.83879 12.4924C8.92212 12.4924 8.17212 13.2424 8.17212 14.1591C8.17212 15.0758 8.92212 15.8258 9.83879 15.8258C10.7555 15.8258 11.5055 15.0758 11.5055 14.1591C11.5055 13.2424 10.7555 12.4924 9.83879 12.4924ZM19.8388 12.4924C18.9221 12.4924 18.1721 13.2424 18.1721 14.1591C18.1721 15.0758 18.9221 15.8258 19.8388 15.8258C20.7555 15.8258 21.5055 15.0758 21.5055 14.1591C21.5055 13.2424 20.7555 12.4924 19.8388 12.4924ZM14.8388 12.4924C13.9221 12.4924 13.1721 13.2424 13.1721 14.1591C13.1721 15.0758 13.9221 15.8258 14.8388 15.8258C15.7555 15.8258 16.5055 15.0758 16.5055 14.1591C16.5055 13.2424 15.7555 12.4924 14.8388 12.4924Z" fill="#FF5996" />
</svg>

export const CommentsIcon = () => <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.72709 13.6119H16.9508C17.1173 13.6119 17.2568 13.5553 17.3694 13.4421C17.4819 13.3288 17.5382 13.1885 17.5382 13.0211C17.5382 12.8537 17.4819 12.7145 17.3694 12.6035C17.2568 12.4926 17.1173 12.4371 16.9508 12.4371H8.72709C8.56066 12.4371 8.42115 12.4937 8.30857 12.607C8.19598 12.7202 8.13969 12.8605 8.13969 13.0279C8.13969 13.1954 8.19598 13.3345 8.30857 13.4455C8.42115 13.5565 8.56066 13.6119 8.72709 13.6119ZM8.72709 11.0665H16.9508C17.1173 11.0665 17.2568 11.0099 17.3694 10.8966C17.4819 10.7834 17.5382 10.6431 17.5382 10.4757C17.5382 10.3082 17.4819 10.1691 17.3694 10.0581C17.2568 9.94715 17.1173 9.89167 16.9508 9.89167H8.72709C8.56066 9.89167 8.42115 9.94829 8.30857 10.0615C8.19598 10.1748 8.13969 10.3151 8.13969 10.4825C8.13969 10.6499 8.19598 10.7891 8.30857 10.9001C8.42115 11.011 8.56066 11.0665 8.72709 11.0665ZM8.72709 8.52105H16.9508C17.1173 8.52105 17.2568 8.46443 17.3694 8.35119C17.4819 8.23794 17.5382 8.09761 17.5382 7.93021C17.5382 7.7628 17.4819 7.62362 17.3694 7.51266C17.2568 7.40171 17.1173 7.34623 16.9508 7.34623H8.72709C8.56066 7.34623 8.42115 7.40285 8.30857 7.51609C8.19598 7.62934 8.13969 7.76967 8.13969 7.93706C8.13969 8.10448 8.19598 8.24366 8.30857 8.35461C8.42115 8.46557 8.56066 8.52105 8.72709 8.52105ZM6.18165 16.7448C5.86837 16.7448 5.59425 16.6273 5.35928 16.3923C5.12432 16.1574 5.00684 15.8832 5.00684 15.57V5.3882C5.00684 5.07491 5.12432 4.80079 5.35928 4.56582C5.59425 4.33086 5.86837 4.21338 6.18165 4.21338H19.4963C19.8096 4.21338 20.0837 4.33086 20.3186 4.56582C20.5536 4.80079 20.6711 5.07491 20.6711 5.3882V18.4678C20.6711 18.7248 20.5503 18.9033 20.3088 19.0033C20.0674 19.1032 19.8552 19.0618 19.6725 18.879L17.5382 16.7448H6.18165ZM6.18165 15.57H19.4963V5.3882H6.18165V15.57Z" fill="black" fill-opacity="0.54" />
</svg>

export const PlayArrowFilled = () => <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.838867 0V14L11.8389 7L0.838867 0Z" fill="black" fill-opacity="0.54" />
</svg>;

export const ViewsIcon = () => <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.505493 10.8333V3.49996H2.83883V10.8333H0.505493ZM4.67216 10.8333V0.166626H7.00549V10.8333H4.67216ZM8.83883 10.8333V6.16663H11.1722V10.8333H8.83883Z" fill="#B3B3B3" />
</svg>;

export const BackIcon = () => <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.1722 7.49259H6.05883L9.78549 3.76593L8.83883 2.82593L3.50549 8.15926L8.83883 13.4926L9.77883 12.5526L6.05883 8.82593H14.1722V7.49259Z" fill="#5F40A6" />
</svg>;

export const PlayTriangleIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
  <path d="M18.7803 12.5186V44.7998L44.1441 28.6592L18.7803 12.5186Z" fill="white" />
</svg>

export const CommentIcon = () => <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.0152 6.10107H8.06691C5.81392 6.10107 3.97559 7.93941 3.97559 10.1924C3.97559 11.5055 4.6114 12.7495 5.6757 13.5097C5.52365 13.7999 5.35779 14.0626 5.20575 14.2837C4.97077 14.6154 4.95695 15.0439 5.19193 15.3757C5.37161 15.6383 5.66187 15.7903 5.96596 15.7903C6.04889 15.7903 6.13182 15.7765 6.21476 15.7627C7.51403 15.4171 8.6889 14.7951 9.49058 14.2975H13.029C15.282 14.2975 17.1203 12.4592 17.1203 10.2062C17.1203 7.95323 15.2682 6.10107 13.0152 6.10107ZM13.0152 13.3024H9.33854C9.24178 13.3024 9.15885 13.33 9.07592 13.3715C8.48157 13.7447 7.3067 14.4081 6.03507 14.7675C6.28387 14.4219 6.51884 14.0073 6.73999 13.5373C6.85057 13.3024 6.76764 13.0259 6.53266 12.9015C5.56512 12.3625 4.95695 11.312 4.95695 10.1924C4.95695 8.47846 6.35298 7.08244 8.06691 7.08244H13.0152C14.7291 7.08244 16.1252 8.47846 16.1252 10.1924C16.1252 11.9063 14.7291 13.3024 13.0152 13.3024Z" fill="white" />
  <path d="M13.3894 8.64404H7.69471C7.41827 8.64404 7.21094 8.8652 7.21094 9.12781C7.21094 9.39043 7.43209 9.61159 7.69471 9.61159H13.4032C13.6797 9.61159 13.887 9.39043 13.887 9.12781C13.887 8.8652 13.6658 8.64404 13.3894 8.64404Z" fill="white" />
  <path d="M13.3894 10.7729H7.69471C7.41827 10.7729 7.21094 10.9941 7.21094 11.2567C7.21094 11.5193 7.43209 11.7405 7.69471 11.7405H13.4032C13.6797 11.7405 13.887 11.5193 13.887 11.2567C13.887 10.9941 13.6658 10.7729 13.3894 10.7729Z" fill="white" />
</svg>

export const EditIcon = () => <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3406 15.3863C10.7494 15.4148 11.1678 15.2686 11.479 14.9477L16.4697 9.80279C17.034 9.22106 17.034 8.28344 16.4697 7.70174L14.3309 5.49677C13.7591 4.90733 12.8261 4.90733 12.2544 5.49677L7.26372 10.6417C6.95278 10.9622 6.81318 11.3908 6.84492 11.8084L6.19429 12.4792C5.73209 12.9556 5.64848 13.6709 5.94345 14.2338L4.31686 16.0848C4.19938 16.2185 4.17103 16.4086 4.24441 16.5708C4.31779 16.733 4.4793 16.8371 4.65728 16.8371H6.62102C6.69018 16.8371 6.75842 16.8213 6.82051 16.7909L7.88716 16.268C8.45286 16.6275 9.20712 16.5549 9.69668 16.0502L10.3406 15.3863ZM12.2544 7.70175C12.8261 7.1123 13.759 7.1123 14.3309 7.70175C14.8951 8.28349 14.8951 9.22106 14.3309 9.80279L11.479 12.7427C10.9072 13.3322 9.97433 13.3322 9.40257 12.7427C8.83828 12.1611 8.83828 11.2234 9.40257 10.6417L12.2544 7.70175Z" fill="white" />
</svg>




