import { Box, Button, IconButton, Rating, Tooltip, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { TranscriptionSummaryProps } from "../../interfaceTypes";
import palette from "../../theme/palette";
import { transcriptGridStyle } from "../../theme/styleConstants";
import { NoDataFound } from "../common/NoDataFound";
import { SummaryComponent } from "./SummaryComponent";
import { useState } from "react";
import { useSingleSummarySyncMutation } from "../../../generated";
import { Alert } from "../common/Alert";
import { useParams } from "react-router-dom";
import SyncIcon from '@mui/icons-material/Sync';

/**
 * Component for displaying summary for a video.
 * @returns {JSX.Element}
 */
export const TranscriptionSummary = ({ scheduleMeetingData, setSeekingTime }: TranscriptionSummaryProps): JSX.Element => {
  const { summary } = scheduleMeetingData ?? {}
  const [hideButton, setHideButton] = useState<boolean>(false)
  const { summary: transcriptSummary, ...result } = summary || {}
  const { action_items, follow_up_email, instructions_to_cs, meeting_classification,
    customer_support_person_performance, sales_likelihood_rating, sales_likelihood,
    customer_support_person_rating } = result || {}
  const { id: meetingId } = useParams();

  const [singleSummarySync, { loading }] = useSingleSummarySyncMutation({
    onCompleted() {
      Alert.success('Summary generation in progress... wait for a while')
    }
  })

  const buttonClickHandler = () => {
    setHideButton(true)
    if (meetingId) {
      singleSummarySync({
        variables: {
          meetingId: meetingId
        }
      })
    }
  }

  return (
    <Box sx={{ ...transcriptGridStyle, maxHeight: 'calc(100vh - 600px)' }}>
      {!transcriptSummary?.length ?
        <Box>
          <NoDataFound height='calc(100vh - 665px)' description='Summary not found' />
          {(!hideButton && !loading) ?
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant='contained' color='primary' onClick={buttonClickHandler}>Generate Summary</Button>
            </Box>
            : loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )
              :
              <></>
          }
        </Box>
        :
        <Box>
          <Box component="section" sx={{ borderBottom: `1px solid ${palette.lightBorderColor}`, pb: 2.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
              <Typography component='h3' fontWeight='bold'>Summary</Typography>

              <Box height="22px" position="relative">
                {(!hideButton && !loading) ?
                  <Tooltip title='Click to regenerate summary'>
                    <IconButton onClick={buttonClickHandler} color='primary'>
                      <SyncIcon />
                    </IconButton>
                  </Tooltip>

                  : loading ?
                    <Box color={palette.lightGray}>
                      <CircularProgress size="22px" color="primary" />
                    </Box>
                    :
                    <Box />
                }
              </Box>
            </Box>

            <Box component="ul" sx={{ pb: "0", mb: 0 }}>
              {!!transcriptSummary?.length && transcriptSummary.map((summaryArray, index) => (
                <SummaryComponent key={`${index}-summaryComponent`} summaryArray={summaryArray} setSeekingTime={setSeekingTime} />
              ))}
            </Box>
          </Box>

          <Box>
            <Box>
              <Box component="section" sx={{ borderBottom: `1px solid ${palette.lightBorderColor}`, py: 2.5 }}>
                <Typography component='h3' fontWeight='bold'>
                  Action Items
                </Typography>

                <Typography>
                  {action_items}
                </Typography>
              </Box>

              <Box component="section" sx={{ borderBottom: `1px solid ${palette.lightBorderColor}`, py: 2.5 }}>
                <Typography component='h3' fontWeight='bold'>
                  Follow Up Email
                </Typography>

                <Typography>
                  {follow_up_email}
                </Typography>
              </Box>

              <Box component="section" sx={{ borderBottom: `1px solid ${palette.lightBorderColor}`, py: 2.5 }}>
                <Typography component='h3' fontWeight='bold'>
                  Instructions To CS
                </Typography>

                <Typography>
                  {instructions_to_cs}
                </Typography>
              </Box>

              <Box component="section" sx={{ borderBottom: `1px solid ${palette.lightBorderColor}`, py: 2.5 }}>
                <Typography component='h3' fontWeight='bold'>
                  Meeting Classification
                </Typography>

                <Typography>
                  {meeting_classification}
                </Typography>
              </Box>

              <Box component="section" sx={{ borderBottom: `1px solid ${palette.lightBorderColor}`, py: 2.5 }}>
                <Typography component='h3' fontWeight='bold'>
                  Customer Support Person Performance
                </Typography>

                <Typography>
                  {customer_support_person_performance}
                </Typography>
              </Box>

              <Box component="section" sx={{ borderBottom: `1px solid ${palette.lightBorderColor}`, py: 2.5 }}>
                <Typography component='h3' fontWeight='bold'>
                  Customer Support Person Rating
                </Typography>

                <Box mt='10px'>
                  <Rating name="read-only" value={parseInt(customer_support_person_rating || '0') / 2} readOnly />
                </Box>
              </Box>

              <Box component="section" sx={{ borderBottom: `1px solid ${palette.lightBorderColor}`, py: 2.5 }}>
                <Typography component='h3' fontWeight='bold'>
                  Sales Likelihood
                </Typography>

                <Typography>
                  {sales_likelihood}
                </Typography>
              </Box>

              <Box component="section" sx={{ pt: 2.5 }}>
                <Typography component='h3' fontWeight='bold'>
                  Sales Likelihood Rating
                </Typography>

                <Box mt='10px'>
                  <Rating name="read-only" value={parseInt(sales_likelihood_rating || "0") / 2} readOnly />
                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      }
    </Box>
  )
}