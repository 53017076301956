import React, { FC, useEffect, useRef, useState } from 'react'
import { TimeInterface } from '../../interfaceTypes'
import { formatTime } from '../../utils'
import { START_TIMER } from '../../constants';
import { WidgetActionType } from '../../context/WidgetContextReducer';

export const TimeData: FC<TimeInterface> = ({ seconds, kioskStatus, userStatusRef, fallBack,
  toggleKioskWidgetData, focusDispatch }) => {
  const [timerSeconds, setTimerSeconds] = useState(seconds)
  const timerRef = useRef<NodeJS.Timer>()

  useEffect(() => {
    if (userStatusRef?.current && focusDispatch) {
      timerRef.current = setInterval(() => {
        const innerTimerStart = localStorage.getItem(START_TIMER);

        setTimerSeconds((timerSeconds) => {
          if (typeof timerSeconds !== 'undefined' && timerSeconds > 0) {
            return innerTimerStart === "false" ? timerSeconds - 1 : seconds;
          }
          else {
            if (kioskStatus === 2) {
              focusDispatch({ type: WidgetActionType.SET_WAITING, waiting: false })
            } else if (timerSeconds === 0) {
              toggleKioskWidgetData && innerTimerStart === "false" && userStatusRef.current < 3 && toggleKioskWidgetData()
              toggleKioskWidgetData && fallBack && toggleKioskWidgetData()
            }
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      timerRef.current && clearInterval(timerRef.current)
    };
    // eslint-disable-next-line
  }, [seconds, kioskStatus]);

  useEffect(() => {
    setTimerSeconds(seconds)
  }, [seconds])

  return (
    <time dateTime={`P${Math.round(timerSeconds)}S`}>
      {formatTime(timerSeconds)}
    </time>
  )
}