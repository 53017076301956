import { Box } from '@mui/material'
import { FC } from 'react'
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { CarouselImageBox } from './CarousellImageBox';

export const CarouselFiveVideos: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  const fourthImage = thumbnailUrls && thumbnailUrls[3]
  const fifthImage = thumbnailUrls && thumbnailUrls[4]
  return (
    <Box padding={1} minHeight="200px" display='flex' flexDirection='column'>
      <CarouselImageBox width='100%' marginBottom='16px' height='200px' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />

      <Box width='100%' display='flex' justifyContent='space-between'>
        <CarouselImageBox width='18%' height='70px' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />

        <CarouselImageBox width='18%' height='70px' imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} />

        <CarouselImageBox width='18%' height='70px' imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} />

        <CarouselImageBox width='18%' height='70px' imageUrl={fourthImage || images.DUMMY} borderRadius={borderRadius} />

        <CarouselImageBox width='18%' height='70px' imageUrl={fifthImage || images.DUMMY} borderRadius={borderRadius} />
      </Box>
    </Box>
  )
}
