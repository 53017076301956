import { Box } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomColorControlProps } from '../../../interfaceTypes';
import { CustomColorPicker } from '../../common/ColorPickerModal';

export const CustomColorControl: FC<CustomColorControlProps> = ({ name, label }) => {
  const { control } = useFormContext();

  return (
    <Box position='relative'>
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, ...rest } }) => <CustomColorPicker label={label} {...rest} />}
      />
    </Box>
  )
}
