import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Collapse, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from 'react-hook-form';

import { images } from "../../../../assets/images";
import { LandingPageTabsProps } from '../../../interfaceTypes';
import customTheme from "../../../theme";
import palette from "../../../theme/palette";
import { CircleBox, LandingPageVideo } from "../../../theme/styleComponents";
import { getInTouch, previewDetails } from "../../../theme/styleConstants";

export const LandingPageWidget: FC<LandingPageTabsProps> = ({ showOperator }) => {
  const { watch } = useFormContext()
  const buttonTextValue = watch('buttonText')
  const buttonBackgroundColorValue = watch('buttonBackgroundColor')
  const buttonColorValue = watch('buttonColor')
  const headingColorValue = watch('headingColor')
  const descriptionColorValue = watch('descriptionColor')

  return (
    <Box padding={3} sx={{ [customTheme.breakpoints.down('sm')]: { paddingX: 1 } }}>
      <Box padding={3} border={`1px solid ${palette.dark}`} borderRadius='4px'>
        <Typography variant="h2" sx={{ color: headingColorValue }}>Heading</Typography>

        <Typography marginTop='0.5rem' variant="h4" sx={{ color: descriptionColorValue }}>Text goes here...</Typography>

        <LandingPageVideo>
          <Box component='img' src={images.VIDEO_PLAYER_ICON} />

          <Typography variant="h3" sx={{ opacity: '0.6', marginTop: '0.5rem' }}>Video from operator</Typography>
        </LandingPageVideo>

        <Collapse in={showOperator} unmountOnExit mountOnEnter>
          <Box className='landingOperatorDetail' sx={previewDetails}>
            <Box display='flex' alignItems='center' justifyContent='start'>
              <CircleBox>
                <PersonIcon sx={{ color: palette.lightFour }} />
              </CircleBox>

              <Box>
                <Typography variant="subtitle2">Operator Name</Typography>

                <Typography variant="h3" sx={{ opacity: '0.6' }}>Your personal assistant</Typography>
              </Box>
            </Box>

            <Box>
              <Button variant="contained" sx={{ ...getInTouch, backgroundColor: `${buttonBackgroundColorValue} !important`, textTransform: 'none' }}>
                <Typography sx={{ color: buttonColorValue }} maxWidth={160} noWrap>{buttonTextValue || 'N/A'}</Typography></Button>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}
