import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { DetailCardInterface } from '../../interfaceTypes'
import palette from '../../theme/palette'

export const DetailCard:FC<DetailCardInterface> = ({title, email, phone}) => {
  const {black, blackOne } = palette

  return (
    <Box>
      <Typography sx={{ marginBottom: '1rem' }} variant='subtitle1'>{title || ""}</Typography>

      <Typography sx={{ marginBottom: '10px', color: blackOne }} variant='h4'>
        Email :<Typography component='span' sx={{ color: black, fontWeight: '500', marginLeft: '10px' }}>{email || "N/A"}</Typography>
      </Typography>

      <Typography sx={{ marginBottom: '10px', color: blackOne }} variant='h4'>
        Phone :<Typography component='span' sx={{ color: black, fontWeight: '500', marginLeft: '10px' }}>{phone || "N/A"}</Typography>
      </Typography>
    </Box>
  )
}
