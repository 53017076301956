import { DndContext, KeyboardSensor, MeasuringStrategy, MouseSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Grid, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from 'react-hook-form';

import { VideoSelectionProps } from "../../../../interfaceTypes";
import customTheme from "../../../../theme";
import { BrandActionType } from "../../reducer/brandReducer";
import { RenderCheckBox } from "../RenderCheckbox";

const measuringConfig = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

export const VideoSelection: FC<VideoSelectionProps> = ({ videos, videosToShow, brandState, brandDispatch, gridMd, handleDragEnd, setItems }) => {
  const mediumViewport = useMediaQuery('(max-width:575px)');
  const { control } = useFormContext()
  const { videoIds } = brandState
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  useWatch({ control, name: 'videos' })

  const handleCheckboxes = async (
    id: string,
    index: number,
    featureImageId: string,
    bannerImageId: string
  ) => {
    const hasVideo = videoIds.filter((video) => video.id === id);

    if (hasVideo.length > 0) {
      //remove from the array and update the array
      const newVideoIds = videoIds.filter((video) => video.id !== id);
      brandDispatch({
        type: BrandActionType.SET_VIDEO_IDS,
        videoIds: newVideoIds,
      });

      if (setItems) {
        setItems((prevItems) => {
          const oldIndex = prevItems.findIndex((item) => item.id === id);
          return arrayMove(prevItems, oldIndex, videoIds.length);
        });
      }
    } else {
      brandDispatch({
        type: BrandActionType.SET_VIDEO_IDS,
        videoIds: videoIds
          ? [...videoIds, { id, sequenceNo: index + 1, featureImageId, bannerImageId }]
          : [{ id, sequenceNo: index + 1, featureImageId, bannerImageId }],
      });

      if (setItems) {
        setItems((prevItems) => {
          const oldIndex = prevItems.findIndex((item) => item.id === id);
          const newIndex = videoIds.length
          const videoAtOldIndex = prevItems[oldIndex]
          prevItems[oldIndex] = prevItems[newIndex]
          prevItems[newIndex] = videoAtOldIndex
          return [...prevItems]
        });
      }
    }
  };
  const onDragStart = () => {
    brandDispatch({ type: BrandActionType.SET_IS_DRAGGING, isDragging: true })
  }

  const onDragCancel = () => {
    brandDispatch({ type: BrandActionType.SET_IS_DRAGGING, isDragging: false })
  }

  return (
    <DndContext
      measuring={measuringConfig}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={onDragStart}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={videos} strategy={rectSortingStrategy} disabled={mediumViewport}>
        <Grid container columnSpacing={{ xs: 2, md: 3 }} rowSpacing={3} sx={{
          [customTheme.breakpoints.down('sm')]: { justifyContent: 'center' }
        }}>
          {videos?.map((video, index) => {
            const { id } = video

            return (
              <Grid md={gridMd} sm={6} xs={12} item key={id}>
                <RenderCheckBox
                  index={index}
                  video={video}
                  videoIds={videoIds}
                  videosToShow={videosToShow}
                  handleCheckboxes={() => handleCheckboxes(id, index, video.featureImage?.id || '', video.bannerImage?.id || "")}
                />
              </Grid>
            )
          })}
        </Grid>
      </SortableContext>
    </DndContext>
  )
}