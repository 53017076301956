import { Box, Button, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { FC, SyntheticEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TellingFormProps } from '../../../interfaceTypes';
import { tabsStyle, ctaButtonStyle, mockButtonStyles, radioButtonStyles } from '../../../theme/styleConstants';
import { handleTabs } from '../../../utils';
import { CustomController } from '../../common/CustomController';
import { TabPanel } from '../../common/TabPanel';
import { CustomColorControl } from './CustomColorControl';
import { CustomSwitcher } from './CustomSwitcher';

export const TellingForm: FC<TellingFormProps> = ({ headingLabel, descriptionLabel, designHelperText, supportHelperText, tellingFormBorder }) => {
  const [tabValue, setTabValue] = useState(0);
  const { control, watch } = useFormContext();
  const mediumViewport = useMediaQuery('(max-width:575px)');
  const mobileCtaButtonTextValue = watch('mobileCtaButtonText')

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const isShowCustomerSupport = watch('showCustomerSupport')
  const isShowClientFormHookVideo = watch('showClientFormHookVideo')

  return (
    <>
      <CustomController
        controllerName="tellingFormHeading"
        controllerLabel={headingLabel || "Enter Widget Heading"}
        fieldType='text'
      />

      <CustomController
        controllerName="tellingFormDescription"
        controllerLabel={descriptionLabel || "Describe this widget"}
        fieldType='text'
        isMultiLine
        rowsLength={4}
      />

      <Box pt={0.5}>
        <Typography variant="subtitle1" mb={2}>Form Fields Settings</Typography>

        <Box pt={0.5}>
          <CustomController
            controllerName="nameLabel"
            controllerLabel="Name label"
            fieldType='text'
          />
        </Box>

        <Box pt={0.5}>
          <CustomController
            controllerName="emailLabel"
            controllerLabel="Email label"
            fieldType='text'
          />
        </Box>

        <Box pt={0.5}>
          <CustomController
            controllerName="phoneLabel"
            controllerLabel="Phone label"
            fieldType='text'
          />
        </Box>
      </Box>

      <Box marginTop='1rem'>
        <CustomSwitcher label="Hook Video" defaultValue={isShowClientFormHookVideo} controllerName="showClientFormHookVideo" />
      </Box>

      <Box mt={1}>
        <Typography variant="subtitle1" mb={2}>Trigger CTA For Mobile</Typography>

        <CustomController
          controllerName="mobileCtaButtonText"
          controllerLabel="CTA text"
        />
      </Box>

      <Box my={1}>
        <Typography variant="subtitle1" mb={2}>Button Styles</Typography>

        <Controller
          control={control}
          name="mobileCtaButtonStyle"
          render={({ field }) => (
            <RadioGroup
              {...field}
              defaultValue="0px"
              name="mobileCtaButtonStyle"
              sx={{ flexDirection: 'row' }} >
              <FormControlLabel sx={ctaButtonStyle}
                value="0px"
                control={<Radio sx={radioButtonStyles} />}
                label={<Button variant='outlined' sx={{ borderRadius: '0px', ...mockButtonStyles }}><Typography noWrap maxWidth={mediumViewport ? 200 : 80}>{mobileCtaButtonTextValue || "- -"}</Typography></Button>}
              />

              <FormControlLabel sx={ctaButtonStyle} value="4px"
                control={<Radio sx={radioButtonStyles} />}
                label={<Button variant='outlined' sx={{ borderRadius: '4px', ...mockButtonStyles }}><Typography noWrap maxWidth={mediumViewport ? 200 : 80}>{mobileCtaButtonTextValue || "- -"}</Typography></Button>}
              />

              <FormControlLabel sx={ctaButtonStyle} value="60px"
                control={<Radio sx={radioButtonStyles} />}
                label={<Button variant='outlined' sx={{ borderRadius: '60px', ...mockButtonStyles }}><Typography noWrap maxWidth={mediumViewport ? 200 : 80}>{mobileCtaButtonTextValue || "- -"}</Typography></Button>}
              />
            </RadioGroup>
          )}
        />
      </Box>


      {tellingFormBorder &&
        <>
          <Box mb={1}>
            <CustomSwitcher label="Client telling form border" defaultValue={false} controllerName="tellingFormBorder" />
          </Box>

          <CustomColorControl name="tellingFormBorderColor" label='Telling form border color' />
        </>
      }

      <Box sx={tabsStyle}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab sx={{ textTransform: 'capitalize' }} label="design Advice" {...handleTabs(0)} />

          <Tab sx={{ textTransform: 'capitalize' }} label="Customer Support" {...handleTabs(1)} />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Box pt={2}>
          <CustomController
            controllerName="helperTextDesign"
            controllerLabel={designHelperText || "Design Helper Text"}
            fieldType='text'
          />

          <CustomController
            controllerName="designAdviceButtonHeading"
            controllerLabel="Design Advice Button Heading"
          />

          <CustomController
            controllerName="designAdviceButtonDescription"
            controllerLabel="Design Advice Button Description"
          />
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Box mt={2}>
          <CustomSwitcher label="Show Customer Support" defaultValue={isShowCustomerSupport} controllerName="showCustomerSupport" />
        </Box>

        <Box pt={2}>
          <CustomController
            controllerName="helperTextSupport"
            controllerLabel={supportHelperText || "Support Helper Text"}
            fieldType='text'
          />
        </Box>

        <Box pt={0.5}>
          <CustomController
            controllerName="gorgiasSupportTicketSubject"
            controllerLabel="Gorgias Support Ticket Subject"
            fieldType='text'
          />
        </Box>

        <Box pt={0.5}>
          <CustomController
            controllerName="gorgiasSupportTicketMessage"
            controllerLabel="Gorgias Support Ticket Message"
            fieldType='text'
          />
        </Box>

        <Box pt={0.5}>
          <CustomController
            controllerName="customerSupportButtonHeading"
            controllerLabel="Customer Support Button Heading"
          />
        </Box>

        <Box pt={0.5}>
          <CustomController
            controllerName="customerSupportButtonDescription"
            controllerLabel="Customer Support Button Description"
          />
        </Box>
      </TabPanel>
    </>
  )
}