import { Autocomplete, Box, Button, FormControl, InputLabel, ListItem, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Brand, useGetAllBrandsLazyQuery, UserRoles } from '../../../generated';
import { GRAPHQL_QUERY_POLICY, SOMETHING_WENT_WRONG } from '../../constants';
import { AppContext, AuthContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { UserAction, UserActionType, UserState } from '../../context/UserContextReducer';
import { DispatchAndState, ItemInterface } from '../../interfaceTypes';
import customTheme from '../../theme';
import { UserRightActions } from '../../theme/styleComponents';
import { Alert } from '../common/Alert';
import { UsersInvitationModal } from './UsersInviteModal';

export const UsersActions: FC<DispatchAndState<UserState, UserAction>> = ({ dispatch: userDispatch, state, loading }) => {
  const { dispatch } = useContext(AppContext);
  const { isAdmin } = useContext(AuthContext);
  const { userRole, selectedBrand } = state || {}
  const [brandsList, setBrandsList] = useState<Array<Brand>>([])

  const [getAllBrands] = useGetAllBrandsLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      return Alert.error(SOMETHING_WENT_WRONG)
    },

    onCompleted(data) {
      const { getAllBrands } = data || {}
      if (getAllBrands) {
        setBrandsList([{ name: 'All', id: 'all-brands', attachments: [], createdAt: '', updatedAt: '' }, ...getAllBrands])
      }
    }
  });

  const handleRoleChange = (event: SelectChangeEvent) => {
    const { target: { value } } = event

    userDispatch({ type: UserActionType.SET_USER_ROLES, userRole: value as UserRoles | string })
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
  };

  const handleBrandChange = (selectedItem: ItemInterface) => {
    userDispatch({ type: UserActionType.SET_SELECTED_BRAND, selectedBrand: selectedItem })
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
  };

  const handleClickOpen = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: true })
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
    userDispatch({ type: UserActionType.SET_KEYWORD, keyword: value as string })
  };

  useEffect(() => {
    getAllBrands()
    // eslint-disable-next-line
  }, [])

  const { formState: { errors } } = useForm();
  const customErrors: any = errors["brandLabel"]

  return (
    <Box paddingY={4} display='flex' justifyContent='space-between' sx={{ [customTheme.breakpoints.down('sm')]: { flexDirection: 'column' }, }}>
      <Box>
        <TextField
          id="Search"
          label="Search"
          type="text"
          autoComplete="off"
          size='small'
          onChange={handleChange}
          sx={{ [customTheme.breakpoints.down('sm')]: { width: '100%' }, }}
        />
      </Box>

      <UserRightActions>
        {isAdmin &&
          <FormControl variant="outlined" sx={{ minWidth: 150 }}>
            <Autocomplete
              size="small"
              defaultValue={{ label: '', id: '' }}
              fullWidth
              value={selectedBrand}
              onChange={(event, item: ItemInterface) => {
                handleBrandChange(item)
              }}
              renderOption={(props, option) => {
                const { id, label } = option || {}

                return (
                  <ListItem {...props} key={id}>
                    {label}
                  </ListItem>
                )
              }}
              disableClearable
              options={brandsList?.map(item => ({ label: item.name, id: item.id }))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!customErrors?.id?.message}
                  helperText={customErrors?.id?.message || ''}
                  label="Brand"
                />
              )}
            />
          </FormControl>
        }

        <FormControl>
          <InputLabel id="roleLabel" shrink>Role</InputLabel>

          <Select
            labelId="roleLabel"
            id="selectRole"
            notched
            defaultValue={''}
            value={userRole || ""}
            displayEmpty={true}
            disabled={loading}
            sx={{ width: 130 }}
            size="small"
            label="Role"
            onChange={handleRoleChange}
          >
            <MenuItem value="">All</MenuItem>
            {isAdmin && <MenuItem value={UserRoles.Admin}>Admin</MenuItem>}
            <MenuItem value={UserRoles.Operator}>Operator</MenuItem>
            <MenuItem value={UserRoles.BrandManager}>Brand Manager</MenuItem>
          </Select>
        </FormControl>

        <Button sx={{ padding: "8px 15px" }} variant="contained" color="primary" disabled={loading} onClick={handleClickOpen}>Invite User</Button>
      </UserRightActions>

      <UsersInvitationModal />
    </Box>
  )
}
