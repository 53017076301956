import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { Box, Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { images } from "../../../assets/images";
import { PlayTriangleIcon } from "../../../assets/svgs";
import { WidgetType, useGetSignedUrlWithKeyMutation } from "../../../generated";
import { VideoCardComponentProps } from "../../interfaceTypes";
import { CustomVideoPlayer, OverLay, VideoRecordDetails, VideoRecordElement, VideoRecordWrap } from "../../theme/styleComponents";
import { playerIconStyle, purpleLabelStyle, videoContentWrapper, videoListingImageBox } from "../../theme/styleConstants";
import { formatDateAndTimeToCustomFormat, getUrl, urlAppend } from "../../utils";

/**
 * Reusable component for displaying a video box.
 * @param {SxStyles} sxStyles - Styling properties for the box.
 * @returns {JSX.Element} - VideoCardComponent.
 */
export const VideoCardComponent = ({ scheduleMeeting }: VideoCardComponentProps): JSX.Element => {
  const [videoUrl, setVideoUrl] = useState<string | undefined>('')
  const [getSingedUrlWithKey] = useGetSignedUrlWithKeyMutation()

  const {
    user, widget, customerName, customerEmail, attachments, eventCreatedAt
  } = scheduleMeeting || {}
  const { email, fullName, phoneNumber } = user || {}
  const { name, type } = widget || {}

  useEffect(() => {
    getUrl(attachments, getSingedUrlWithKey, setVideoUrl)
    // eslint-disable-next-line
  }, [scheduleMeeting])

  const isKiosk = type === WidgetType.Kiosk

  const urlToAppend = '/upload/c_scale,h_200/e_loop/dl_200,vs_40/'

  return (
    <VideoRecordWrap>
      <VideoRecordDetails>
        <VideoRecordElement>
          {videoUrl ?
            <>
              <CustomVideoPlayer>
                <Box className="videoContentCard">
                  <Box sx={playerIconStyle} className="playerIcon">
                    <PlayTriangleIcon />
                  </Box>
                  <Box
                    className="jpgImage"
                    component='img'
                    src={urlAppend(videoUrl, urlToAppend, "jpg")}
                    sx={videoListingImageBox}
                  />
                  <OverLay />

                  <Box
                    className="gifImage"
                    component='img'
                    src={urlAppend(videoUrl, urlToAppend, "gif")}
                    sx={videoListingImageBox}
                  />
                </Box>
              </CustomVideoPlayer>
            </>
            :
            <Box component='img'
              src={images.DUMMY_VIDEO_IMAGE}
              alt="video placeholder"
              sx={{ maxHeight: '100%', width: '100%' }}
            />
          }
        </VideoRecordElement>

        <Box padding="20px 0px 0px 20px" flex="1">
          <Typography variant="h2" mb={0.5}>{name ? name : 'No record found' || ''} - {type || ''}</Typography>

          {!isKiosk &&
            <Box sx={videoContentWrapper}>
              {customerName &&
                <Chip label={customerName} sx={purpleLabelStyle} />
              }

              {customerEmail &&
                <Chip label={customerEmail} sx={purpleLabelStyle} />
              }
            </Box>
          }
          <Box display='flex' gap='5px'>
            {user && <HeadsetMicIcon fontSize="small" />}

            <Typography variant="body2" mb={2}>
              {fullName || ''} {email ? ` - ${email}` : ''} {phoneNumber ? `- (${phoneNumber})` : ''}
            </Typography>
          </Box>

          <Typography variant="subtitle2" mb={1}>
            {eventCreatedAt ? formatDateAndTimeToCustomFormat(new Date(eventCreatedAt)) : 'No record found'}
          </Typography>
        </Box>
      </VideoRecordDetails>
    </VideoRecordWrap>
  );
};
