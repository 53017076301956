import { Alert, AlertTitle, Button, Collapse, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { FC, useState } from "react";
import customTheme from "../../theme";
import { getBrandAlertDismiss, setBrandAlertDismiss } from "../../utils";

export const AlertToast: FC = () => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(false);
    setBrandAlertDismiss()
  }

  return (
    <Box>
      <Collapse in={open && !getBrandAlertDismiss()}>
        <Alert
          severity="info"
          sx={{ position: 'relative', width: '100%', marginY: '1rem' }}
          action={
            <Button color="inherit" size="small"
              onClick={handleClick}
              sx={{
                textTransform: 'capitalize', [customTheme.breakpoints.down('sm')]: {
                  position: 'absolute',
                  right: 0,
                  top: '6px',
                }
              }}>
              Dismiss
            </Button>
          }
        >
          <AlertTitle sx={{ marginTop: '-4px' }}><Typography variant="caption">Brand Setting</Typography></AlertTitle>
          <Typography variant="subtitle2"> Add anything related to your brand image which will help operators to understand your brand. Add your brand values eg. (color pallets, logos, images or anything related to tone of voice.</Typography>
        </Alert>
      </Collapse>
    </Box>
  )
}