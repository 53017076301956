import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, DialogActions } from "@mui/material";
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { FC, ReactNode, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { CreateBrandInput, useCreateBrandMutation } from "../../../generated";
import { ACCEPT_FOR_IMAGES, CREATE_BRAND_SUCCESS, DESCRIPTION_FOR_IMAGE, IMAGE_MAX_SIZE } from "../../constants";
import { AppContext } from "../../context";
import { ActionType } from "../../context/AppContextReducer";
import { BrandSelectionInterface } from "../../interfaceTypes";
import { stepperStep } from "../../theme/styleConstants";
import { firstLetterUppercase } from "../../utils";
import { createBrandValidationSchema } from "../../validationSchema";
import { BrandActionType } from "../brandSetting/reducer/brandReducer";
import { Alert } from "../common/Alert";
import { CustomController } from "../common/CustomController";
import { UploadDocument } from "../common/UploadDocument";

export const AddBrandStepper: FC<BrandSelectionInterface> = ({ brandState, brandDispatch, refetch }) => {
  const [currentBrandId, setCurrentBrandId] = useState<string>()
  const { dispatch } = useContext(AppContext)
  const steps = ['Add brand Name', 'Add Brand logo'];
  const [activeStep, setActiveStep] = useState(0);

  const { brandsList: brandsData } = brandState

  const methods = useForm<CreateBrandInput>({
    resolver: yupResolver(createBrandValidationSchema),

    defaultValues: {
      name: "",
      supportEmail: ""
    }
  });

  const { handleSubmit, reset } = methods

  const [createBrand, { loading }] = useCreateBrandMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      Alert.success(CREATE_BRAND_SUCCESS)

      const { createBrand: brandData } = data || {}

      if (brandData) {
        const { id: currentBrandId } = brandData || {}
        setCurrentBrandId(currentBrandId)
        brandDispatch({ type: BrandActionType.SET_BRAND_LIST, brandsList: [brandData, ...brandsData] })
        reset();
        setActiveStep(activeStep + 1);

        (activeStep !== steps.length - 1) && navigate('/select-brand')
      }
    },
  })

  const handleBrandClose = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
  };

  const checkBRand = () => {
    if (activeStep === steps.length - 1) {
      dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
    }
  }

  const navigate = useNavigate();

  const onSubmit = async (data: CreateBrandInput) => {
    const { name, supportEmail } = data

    await createBrand({
      variables: {
        createBrandInput: {
          name,
          supportEmail
        }
      }
    })
  }

  return (
    <Box sx={{ width: '100%', marginTop: "2rem", }}>
      <Stepper sx={stepperStep} activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: ReactNode } = {};

          return (
            <Step key={`${label}-${index}`} {...stepProps} sx={{ padding: '0 16px' }}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep !== steps.length &&
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box>
              {activeStep === 0 &&
                <Box width='100%' sx={{ padding: '40px 0px 50px 0px' }}>
                  <CustomController
                    controllerLabel='Brand Name'
                    controllerName='name'
                  />

                  <CustomController
                    fieldType="email"
                    controllerLabel='Support Email'
                    controllerName='supportEmail'
                  />
                </Box>
              }

              {activeStep === 1 &&
                <Box width='100%' sx={{ padding: '40px 0px 50px 0px' }}>
                  <UploadDocument
                    maxSize={IMAGE_MAX_SIZE}
                    isCreateBrand
                    accept={ACCEPT_FOR_IMAGES}
                    description={DESCRIPTION_FOR_IMAGE}
                    entityType="brands"
                    typeId={currentBrandId || ''}
                    attachmentType='logo'
                    refetchBrandsData={refetch}
                  />
                </Box>
              }
            </Box>

            <DialogActions sx={{ paddingRight: '0' }}>
              <Button disabled={activeStep === 0} onClick={handleBrandClose}>
                Cancel
              </Button>

              <Button type='submit' variant="contained" color="primary"
                startIcon={activeStep !== steps.length - 1 && <AddIcon />}
                endIcon={loading && <CircularProgress size={20} color="inherit" />} onClick={checkBRand} disabled={loading}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Create'}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      }
    </Box>
  )
}