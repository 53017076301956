import { Box } from '@mui/material'
import { FC } from 'react'
import { ChildrenType } from '../../interfaceTypes'
import { MobilePreviewTabs } from '../../theme/styleComponents'
import { mobileTabsWrapper } from '../../theme/styleConstants'

export const MobilePreview: FC<ChildrenType> = ({ children }) => (
  <Box sx={mobileTabsWrapper}>
    <MobilePreviewTabs>
      {children}
    </MobilePreviewTabs>
  </Box>
)
