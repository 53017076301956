import { Box, Button, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress'
import { useState } from "react"
import { useSyncSummariesScriptMutation } from "../../../generated"
import { Alert } from "../common/Alert"
import { PageHeading } from "../common/PageHeading"
import { StickyTable } from "../common/StickyTable"

/**
 * Component for displaying information of summary synchronization to Super Admins.
 * @returns {JSX.Element}
 */
export const DevOnlyComponent = (): JSX.Element => {
  const [disableButton, setDisableButton] = useState<boolean>(false)

  const [syncSummariesScript, { loading }] = useSyncSummariesScriptMutation({
    onCompleted() {
      Alert.success('Summaries Synchronization Started')
      setDisableButton(true)
    }
  })

  const buttonClickHandler = () => {
    syncSummariesScript()
  }

  return (
    <Box>
      <PageHeading title="Super Admins" subTitle="Sync Up Summaries" />

      <Box mt={5}>
        <StickyTable height="auto">
          <TableHead>
            <TableRow>
              <TableCell>Task</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant='body2' color='inherit'>Ability to generate summaries for all existing videos</Typography>
              </TableCell>

              <TableCell>
                <Button variant="contained" color="primary" disabled={loading || disableButton}
                  endIcon={loading && <CircularProgress size={20} color="inherit" />}
                  onClick={buttonClickHandler}
                >
                  Generate Summary
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </StickyTable>
      </Box>
    </Box>
  )
}