import { Box } from "@mui/material";
import { createRef, FC, useContext, useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import KioskBannerImage from '../../../assets/images/banner-bottom.jpg';
import { Attachment } from "../../../generated";
import { CustomVideoPlayer } from "../../components/common/CustomVideoPlayer";
import { START_TIMER } from "../../constants";
import { SocketContext } from "../../context/SocketContext";
import { SocketActionType } from "../../context/SocketContextReducer";
import { WidgetActionType } from "../../context/WidgetContextReducer";
import { ARROW_KEYS, KioskUserStatus, KioskWidgetIdleInterface, ReactPlayerForwardRef, WidgetKioskState } from "../../interfaceTypes";
import { KioskIdleWidget } from "../../theme/styleComponents";
import { KioskWidgetBannerImage } from "../KioskWidgetBannerImage";

export const KioskWidgetIdle: FC<KioskWidgetIdleInterface> = ({ state, dispatch }) => {
  const { mutateKioskState, socketDispatch, isConnected } = useContext(SocketContext)
  const [activeSlide, setActiveSlide] = useState<number>(0)
  const [currentVideo, setCurrentVideo] = useState<Attachment>()
  const activeSliderRef = useRef(activeSlide)
  const idleSliderRef = useRef<Slider>(null)
  const idleSliderPlayerRef = createRef<ReactPlayerForwardRef>();
  const userStatusRef = useRef(0);
  const { widgetKioskData } = state
  const { videos = [], bannerImage } = widgetKioskData || {};
  const { url: bannerImageUrl } = bannerImage || {};
  const { bannerImage: currentVideoBanner } = currentVideo || {}
  const { url: currentVideoBannerImage } = currentVideoBanner || {};

  const settings: Settings = {
    centerMode: true,
    infinite: true,
    variableWidth: true,
    centerPadding: "0px",
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    speed: 1500,

    beforeChange(currentSlide: number, nextSlide: number) {
      idleSliderPlayerRef.current?.pauseChildVideo()
      userStatusRef.current === 1 && dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_ACTIVE_SLIDE, widgetKioskActiveSlide: nextSlide })
      userStatusRef.current === 1 && showFocusWidget()
    },

    afterChange(currentSlide) {
      activeSliderRef.current = currentSlide
      setActiveSlide(activeSliderRef.current)
      setCurrentVideo(videos[currentSlide])
    },
  };

  useEffect(() => {
    videos && setCurrentVideo(videos[activeSlide])
    localStorage.removeItem(START_TIMER)
    // eslint-disable-next-line 
  }, [])

  const visitPrevSlide = () => {
    idleSliderRef.current?.slickPrev()
    idleSliderPlayerRef.current?.playChildVideo()
    dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_ACTIVE_SLIDE, widgetKioskActiveSlide: activeSliderRef.current > 0 ? activeSliderRef.current - 1 : videos && videos.length - 1 })
  }


  const visitNextSlide = () => {
    idleSliderRef.current?.slickNext()
    dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_ACTIVE_SLIDE, widgetKioskActiveSlide: activeSliderRef.current === videos.length - 1 ? 0 : activeSliderRef.current + 1 })
  }

  const showFocusWidget = (isEnterPressed: boolean = false) => {
    !isEnterPressed && mutateKioskState(false, true, false)
    dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_STATE, widgetKioskState: WidgetKioskState.Focused })
    dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_USER_STATUS, widgetKioskUserStatus: KioskUserStatus.Focused })
    userStatusRef.current = 1
  }


  const handleKeyArrowsEvent = (event: { key: string; }) => {
    if (event.key === ARROW_KEYS.ArrowLeft && isConnected) {
      showFocusWidget()
      visitPrevSlide()
    } else if (event.key === ARROW_KEYS.ArrowRight && isConnected) {
      showFocusWidget()
      visitNextSlide()
    }
  };

  const handleKeyEnterEvent = (event: { key: string; }) => {
    if (event.key === 'Enter' && isConnected) {
      dispatch({ type: WidgetActionType.SET_IDLE_PRESS_ENTER, idlePressEnter: true })
      showFocusWidget(true)
      visitNextSlide()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyEnterEvent)
    mutateKioskState(false, false, false)
    socketDispatch({ type: SocketActionType.SET_HANGUP_DATA, hangUpData: { data: { message: '' } } })
    socketDispatch({ type: SocketActionType.SET_JOINING_DATA, joiningData: { data: { message: '', meetingUrl: undefined } } })

    return (() => {
      document.removeEventListener('keyup', handleKeyEnterEvent)
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyArrowsEvent)

    return () => {
      document.removeEventListener('keydown', handleKeyArrowsEvent)
    };
    // eslint-disable-next-line
  }, [isConnected]);

  const playCurrentVideo = (isPaused: boolean) => {
    setTimeout(async () => {
      const currentSlideNode = document.getElementsByClassName('slick-current')[0]
      if (currentSlideNode) {
        const currentVideoNode = currentSlideNode.querySelector('video')
        if (currentVideoNode) {
          if (isPaused) {
            await currentVideoNode.play()
          } else {
            currentVideoNode.pause()
          }
        }
      }
    }, 100)
  }

  useEffect(() => {
    playCurrentVideo(true)
  }, [activeSlide])

  const aspectRatio = 16 / 9;
  const innerWidth = window.innerWidth;
  const height = parseFloat((innerWidth / aspectRatio).toFixed(2));
  const slideWidth = parseFloat(((height - (height * (innerWidth > 2500 ? 0.23 : 0.30))) * aspectRatio).toFixed(2));

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden', padding: '50px 0', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <KioskIdleWidget>
        <Slider {...settings} ref={idleSliderRef}>
          {videos?.map((video, index) => {
            const { id: videoId, url: videoUrl, thumbnail, title, description, videoOrientation } = video || {}
            const { url: thumbnailUrl } = thumbnail || {}

            return (
              <Box sx={{ width: `${slideWidth + 100}px !important` }} key={videoId}>
                <CustomVideoPlayer
                  isActiveSlide={index === activeSlide}
                  ref={idleSliderPlayerRef}
                  videoId={videoId}
                  url={videoUrl || ''}
                  videoTitle={title || ''}
                  videoDescription={description || ''}
                  thumbnail={thumbnailUrl || ''}
                  videoOrientation={videoOrientation}
                  state={state}
                  goToPrevSlide={visitPrevSlide}
                  goToNextSlide={visitNextSlide}
                  dispatch={dispatch}
                />
              </Box>
            )
          })}
        </Slider>
      </KioskIdleWidget>

      {currentVideo &&
        <KioskWidgetBannerImage slideWidth={slideWidth} imageUrl={currentVideoBannerImage || bannerImageUrl || KioskBannerImage} />
      }
    </Box>
  )
}
