import { QueryOptions } from "@apollo/client";
import { JobStatus, VideoBox } from "../../generated";

// Apollo messages
export const INVALID_OR_EXPIRED_TOKEN_MESSAGE = 'Sorry! Your token is expired or invalid';
export const NOT_FOUND_EXCEPTION = 'Not Found Exception';
export const USER_DEACTIVATED = 'User Deactivated';
export const BRAND_DELETE_SUCCESSFULLY = 'Brand deleted Successfully'
export const API_KEY_DELETE = 'Api key deleted Successfully'
export const CONFLICT_EXCEPTION = 'conflict exception';
export const CONFLICT = 'conflict';
export const BAD_REQUEST = 'Bad Request';
export const BAD_USER_INPUT = 'Bad User Input';
export const USER_REGISTERED_SUCCESSFULLY = 'User Registered Successfully';
export const EMAIL_ALREADY_IN_USE = 'Email is already in use';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const REQUEST_NOT_FOUND = 'Requests not found for current user';
export const TOKEN_NOT_FOUND = 'Token not found';
export const AUTH_TOKEN = 'caba_admin_token';
export const USER_EMAIL = 'caba_user_email';
export const JOB_PATH = 'caba_job_path';
export const VIDEO_DETAIL_PATH = 'caba_video_detail_path'
export const BRAND_ID = 'caba_brand_id';
export const PRECONDITION_FAILED_EXCEPTION = 'Precondition Failed Exception';
export const UNAUTHORIZED = 'Unauthorized';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const INVALID_AUTH_TOKEN = 'Invalid Authorization Token - Expired or Invalid';
export const TOKEN_INVALID = 'Token Invalid';
export const FORGET_PASSWORD = 'Forgot password?'
export const LOGIN = 'Login'
export const SIGN_UP = 'Sign Up'
export const LOG_IN = 'Login'
export const INVALID_EMAIL = "Invalid email address";
export const GET_STARTED = 'Get started for free'
export const SAVE = 'Save'
export const INVITED_USER_SUCCESSFULLY = 'Invitation sent successfully';
export const STATUS_UPDATED_SUCCESSFULLY = 'Status Updated Successfully';
export const USER_UPDATED_SUCCESSFULLY = 'User Updated Successfully';
export const REMOVE_USER_SUCCESSFULLY = 'Remove User Successfully';
export const SET_DEFAULT_USER_SUCCESSFULLY = 'Default User Set Successfully';
export const USER_ALREADY_EXIST = 'User already exists';
export const CREATE_USER_SUCCESS = "User created successfully"
export const CREATE_BRAND_SUCCESS = "Brand created successfully"
export const RESET_PASSWORD = 'Reset Password'
export const FORGET_PASSWORD_INSTRUCTION = 'Please enter your associated email to reset password.'
export const SEND_INSTRUCTION = 'Send instructions'
export const PASSWORD_UPDATED_SUCCESSFULLY = 'Password Updated Successfully'
export const PROFILE_UPDATED_SUCCESSFULLY = 'Profile Updated Successfully'
export const BACK_TO_LOGIN = 'Back to login page'
export const SET_PASSWORD = 'Set your Password'
export const SET_PASSWORD_SUBTITLE = 'Set up password to complete your signup.'
export const FORBIDDEN_EXCEPTION = "forbidden exception";
export const EMAIL_CHANGED_OR_NOT_VERIFIED_MESSAGE = "Email changed or not verified, please verify your email";
export const WRONG_EMAIL_OR_PASSWORD = "You have entered wrong email or password";
export const MESSAGE_SEND_SUCCESSFULLY = "Message sent successfully";
export const VIDEO_UPLOAD_SUCCESSFULLY = "Video sent successfully";
export const ADMIN_NOT_ACCESSIBLE = 'This user is not able to access admin panel.'
export const NOT_FOUND_EMAIL_MESSAGE = "No user found with this email";
export const FORGET_PASSWORD_SUCCESS = "An email has been sent to your registered email address";
export const ROOT_ROUTE = "/";
export const DASHBOARD_ROUTE = "/dashboard";
export const SELECT_BRAND_ROUTE = "/select-brand"
export const LOGIN_ROUTE = "/login"
export const RESET_PASSWORD_FAILURE = "Reset password failed";
export const SET_PASSWORD_FAILURE = "Set password failed";
export const RESET_PASSWORD_SUCCESS = "Password reset successfully"
export const SET_PASSWORD_SUCCESS = "Password set successfully"
export const FOLLOW_INSTRUCTIONS = 'Please follow provided link in email to reset your password'
export const PASSWORD_VALIDATION_MESSAGE = "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character";
export const INVITE_NEW_USER = 'Invite new user'
export const USERS = 'Users'
export const DATE_FORMAT = "MM/DD/YYYY"
export const TIME_FORMAT = "h:mm:ss a"
export const USER_UPDATE_SUCCESS = "User updated successfully"
export const AT_LEAST_ONE_ROLE_REQUIRED = "Please select at least one role"
export const INTERNAL_SERVER_ERROR = 'Internal server error'
export const USER_PASSWORD_SUCCESS = "User password updated successfully"
export const SEARCH_PLACEHOLDER = "Name, Joining Date, Role, etc"
export const PAGE_LIMIT = 10
export const NO_USER_FOUND = 'No user found'
export const USERS_TABLE_HEADERS = ['Staff', 'Joined on', 'Role', 'Status', 'Email verified', 'Actions']
export const UNABLE_TO_FETCH_ALL_CASE_TYPES = 'Unable to fetch all case types'
export const UNABLE_TO_FETCH_LAW_TYPE = 'Unable to fetch law type'
export const CASE_CREATED_SUCCESS = 'Case created successfully'
export const ADD_WIDGET = "Add Widget"
export const NO_WIDGET_FOUND = "No Widget Found"
export const CREATE_CUSTOMIZED_WIDGET = "Create a new customized widget for your store by clicking on ‘Add Widget’"
export const PASSWORD = 'password'
export const TEXT = 'text'
export const BRAND_GUIDELINES = 'Brand Guidelines'
export const BRAND_NAME = 'Brand Name'
export const SUPPORT_EMAIL = 'Support Email'
export const ROUTING_EMAIL = 'Routing Email'
export const CUSTOMER_LANDING_PAGE_URL = 'Customer landing page url'
export const URL = 'Url'
export const API_KEY = 'Api key'
export const USER_NAME = 'User name'
export const PRIMARY_COLOR = 'Primary Color'
export const SECONDARY_COLOR = 'Secondary Color'
export const FILE_IS_GREATER = 'File is larger than 40000000 bytes'
export const IMAGE_IS_LARGER = 'File is larger than 40MB'
export const VIDEO_IS_GREATER = 'File is larger than 4000000000 bytes'
export const ASSET_IS_LARGE = 'The asset that you have uploaded is too large, you will be notified via email once the upload has finished'
export const FILE_IS_LARGER = 'File is larger than 3MB'
export const VIDEO_IS_LARGE = 'File is larger than 4GB'
export const SITE_WIDGET = 'Landing page Widget'
export const LANDING_PAGE_WIDGET = 'Landing page widget'
export const LANDING_PAGE_WIDGET_SETTINGS = 'Landing page widget settings'
export const SITE_WIDGET_SETTING = 'Site widget settings'
export const POPUP_WIDGET_SETTING = 'Popup widget settings'
export const WIDGET_SETTING = 'Widget settings'
export const PAGE_HEADING = 'Page heading'
export const BUTTON_TEXT = 'Button text'
export const WIDGET_NAME = 'widget name'
export const VIDEO_SETTINGS = 'Video settings'
export const CABA_BRAND_ALERT = 'caba_brand_alert'
export const PAGE_BLOCK_WIDGET = 'Page block settings'
export const KIOSK_WIDGET = 'kiosk settings'
export const GENERAL_SETTINGS = 'General Settings'
export const GORGIAS_SETTINGS = 'Gorgias Settings'
export const CALENDER_OVERRIDE_SETTINGS = 'Calendar override setting'
export const CUSTOMER_SUPPORT = 'Customer Support'
export const ENABLE = 'Enable'
export const DISABLE = 'Disable'
export const FOUR_O_FOUR = '404'
export const PAGE_NOT_FOUND = 'Page Not Found'
export const LOOKS_LIKE_AN_EMPTY_SPACE = 'Looks like an empty space. You can go back to homepage by clicking the button below'
export const TEXT_COPIED = 'Copied to clipboard'
export const CALENDER = 'Calendar'
export const VIDEO_UPDATED_SUCCESSFULLY = 'Video updated successfully'
export const VIDEO_CREATED_SUCCESSFULLY = 'Video created successfully'
export const TIME_SETTINGS_HELPER_TEXT = 'How long the video should play before triggering hook video, leave at 0 for entire video'
export const TEXT_SETTINGS = 'Widget Heading'
export const THANKYOU_SETTINGS = 'Thank You Settings'
export const CLIENT_TELLING_FORM = 'Client Telling Form Settings'
export const PATH_WAYS_BANNER = 'Path ways banner'
export const VIEW = "View"
export const EDIT = "Edit"
export const REMOVE = "Remove"
export const DELETE = "Delete"
export const TEMPLATE = "Template"
export const START_TIMER = "startTimer"
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#=+%&/,><':;|_~`])\S{8,99}$/
export const SPACES_REGEX = /^\S+(?: \S+)*$/
export const ALPHABETS_REGEX = /^\b(?!.*?\s{2})[A-Za-z ]{1,}\b$/;
export const phoneReg = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const ssnReg = /^(\d{3}-?\d{2}-?\d{4})|(xxx-xx-xxxx)$/i;
export const URL_REGEX = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const CABA_SOCKET_SESSION_ID = 'cabaSocketSessionId'
export const KIOSK_BLOCK = 'Kiosk Block';
export const ADD = 'Add';
export const EMAIL_ADDED = 'Email added successfully';
export const EMAIL_DELETED = 'Email deleted successfully';
export const EMAIL_UPDATED = 'Email updated successfully'
export const HOOK_VIDEO = 'HOOK_VIDEO'
export const TIMER_CLICK = 60
export const KEY_STROKES = 20
export const devOnlyUrl = '/dev-only'

export const VariantAlert = {
  initial: {
    opacity: 0,
    x: "-50%",
    y: "-100%"
  },

  visible: {
    opacity: 1,
    x: "-50%",
    y: "0%",
    transition: {
      type: "just",
    }
  },

  exit: {
    opacity: 1,
    x: "-50%",
    y: "-100%",
    transition: {
      type: "just",
    }
  },
};

export const VIDEO_BOX = [
  {
    value: VideoBox.Sharp,
    option: "Sharp",
    borderRadius: '0px',
  },
  {
    value: VideoBox.Regular,
    option: "Regular",
    borderRadius: '4px',
  },
  {
    value: VideoBox.Soft,
    option: "Soft",
    borderRadius: '8px',
  }
]


export const sidebarLink = {
  dashboard: "Dashboard",
  selectBrand: "Brands",
  operators: "Operators",
  taskTemplate: "Tasks",
  brandSetting: "Brand Settings",
  jobs: "Jobs",
}

export const privacyPolicy = "https://www.cabadesign.co/privacy-policy"
export const terms = "https://www.cabadesign.co/terms-of-service"

export const UPLOAD_VIDEO_STEPS = [
  'Upload Video', 'Video Details'
];

export enum WidgetType {
  SITE = "site",
  LANDING_PAGE = "landing-page",
  PAGE_BLOCK = "page-block",
  KIOSK = "kiosk",
  POPUP_WIDGET = "popup widget"
}

export const JobStatusItems = [
  { name: 'All', value: '' },
  { name: 'Pending', value: JobStatus.Pending },
  { name: 'In Review', value: JobStatus.InReview },
  { name: 'Rejected', value: JobStatus.Rejected },
  { name: 'Completed', value: JobStatus.Completed },
]

export const DASHBOARD_LINK = [
  { title: sidebarLink.selectBrand, link: SELECT_BRAND_ROUTE },
  { title: sidebarLink.operators, link: '/operators' },
  { title: sidebarLink.taskTemplate, link: '/task-template' },
  { title: sidebarLink.brandSetting, link: '/brand-settings' },
  { title: sidebarLink.jobs, link: '/jobs' },
]
export const AUTH_LINKS = {
  FORGET_PASSWORD_LINK: '/forgot-password',
  LOGIN_LINK: '/login',
  SIGN_UP_LINK: '/signup',
  SET_PASSWORD: '/set-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
}

export const LOGIN_FIELDS = [
  {
    title: "Email",
    fieldType: "email",
    name: "email",
  },
  {
    title: "Password",
    fieldType: "password",
    name: "password",
  }
]

export const SET_PASSWORD_FIELDS = [
  {
    title: "New Password",
    fieldType: "password",
    name: "password",
  },
  {
    title: "Confirm Password",
    fieldType: "password",
    name: "repeatPassword",
  }
]

export const INVITE_USER_FORM_FIELDS = [
  {
    title: "Email",
    fieldType: "email",
    name: "email",
  },
  {
    title: "First Name",
    fieldType: "text",
    name: "firstName",
  },
  {
    title: "Last Name",
    fieldType: "text",
    name: "lastName",
  }
]

export const TASK_TEMPLATE_STEP_ONE_FIELDS = [
  { title: "Template Name", fieldType: "text", name: "name" },
  { title: "Description", fieldType: "text", name: "description" },
  { title: "Gorgias Ticket Subject", fieldType: "text", name: "subject" },
  // { title: "Add Script", fieldType: "text", name: "script", rowsLength: 4, isMultiLine: true },
]

export const TASK_TEMPLATE_STEP_THREE_FIELDS = [
  { title: "Job Heading (Operator)", fieldType: "text", name: "videoPageHeading" },
  { title: "Job Description (Operator)", fieldType: "text", name: "videoPageDescription" },
  { title: "Video Heading (Customer)", fieldType: "text", name: "videoHeading" },
  { title: "Video Description (Customer)", fieldType: "text", name: "videoDescription" },
  { title: "Post Greet Title (Customer)", fieldType: "text", name: "postGreetTitle" },
]

export const GRAPHQL_QUERY_POLICY = {
  fetchPolicy: "network-only",
  nextFetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
} as unknown as QueryOptions

export const ACCEPT_FOR_IMAGES = {
  'file/png': ['.png'],
  'file/jpg': ['.jpg'],
  'file/jpeg': ['.jpeg'],
}

export const ACCEPT_FOR_VIDEOS = {
  'file/video': ['.mp4', '.mov', '.avi']
}

export const IMAGE_MAX_SIZE = 40000000
export const VIDEO_MAX_SIZE = 4000000000
export const DESCRIPTION_FOR_IMAGE = 'PNG or JPG (Max. 40MB)'
export const DESCRIPTION_FOR_VIDEO = '.mp4 or .mov (max. 4GB)'

export const CREATE_TASK_STEPS = ["Configure", "Audience", "Job"];
export const TOOLTIP_POPPER_PROPS = { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] };

export const WIDGET_TYPE_ARRAY = [
  { label: 'Block', value: 'BLOCK' },
  { label: 'Kiosk', value: 'KIOSK' },
  { label: 'Landing Page', value: 'LANDING_PAGE' },
  { label: 'Site', value: 'SITE' },
  { label: 'Popup Widget', value: 'POPUP_WIDGET' },
]

