import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { images } from '../../../../assets/images'
import { AttachmentType } from '../../../../generated'
import { SampleFileContentProps } from '../../../interfaceTypes'
import palette from '../../../theme/palette'
import { sampleFileStyle } from '../../../theme/styleConstants'

export const SampleFileContent: FC<SampleFileContentProps> = ({ description, attachmentType }) => {
  const { primary: { main: primaryColor } } = palette

  const imageToDownload = (type: AttachmentType) => {
    switch (type) {
      case AttachmentType.BannerImage:
        return images.BANNER_IMAGE;

      case AttachmentType.FeatureImage:
        return images.FEATURE_IMAGE;

      case AttachmentType.WaitingImage:
        return images.WAITING_IMAGE;

      case AttachmentType.FallBackImage:
        return images.FALLBACK_IMAGE;

      default:
        break;
    }
  }

  return (
    <Box sx={sampleFileStyle}>
      <Box component='img' src={images.SAMPLE_ICON} />
      <Box>
        <Typography>Sample image</Typography>

        <Typography variant='subtitle2'>{description}
          <Typography component='a' href={imageToDownload(attachmentType)} download sx={{ color: primaryColor, marginLeft: '5px', textDecoration: 'underline' }}>Download</Typography>
        </Typography>
      </Box>
    </Box>
  )
}
