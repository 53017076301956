import { Maybe, TaskTemplate, } from "../../generated";

export interface TaskState {
  tasksData?: Array<TaskTemplate>
  currentTaskData?: Maybe<TaskTemplate> | undefined;
}

export const taskInitialState: TaskState = {
  tasksData: [],
  currentTaskData: null
}

export enum TaskActionType {
  SET_TASKS_DATA = 'setTasksData',
  SET_CURRENT_TASK = 'setCurrentTask',
}

export type TaskAction =
  { type: TaskActionType.SET_TASKS_DATA, tasksData: Array<TaskTemplate> }
  | { type: TaskActionType.SET_CURRENT_TASK, currentTaskData: Maybe<TaskTemplate> | undefined }


export const taskReducer = (state: TaskState, action: TaskAction): TaskState => {
  switch (action.type) {
    case TaskActionType.SET_TASKS_DATA:
      return {
        ...state,
        tasksData: action.tasksData
      }

    case TaskActionType.SET_CURRENT_TASK:
      return {
        ...state,
        currentTaskData: action.currentTaskData
      }
    default:
      return state
  }
}
