import { PlayCircleOutline as PlayCircleOutlineIcon } from '@mui/icons-material';
import CommentIcon from '@mui/icons-material/Comment';
import { Box, IconButton, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Comment } from '../../../generated';
import { RecordedVideoContext } from '../../context/RecordedVideoContext';
import { VideoDetailActionType } from '../../context/VideoDetailContextReducer';
import { SpeakerCommentComponentProps } from '../../interfaceTypes';
import palette from '../../theme/palette';
import { commentCountStyle, iconsBox, playCircleIcon, playCircleOutlineIconWrapper, speakerDataSentenceTypography } from '../../theme/styleConstants';
import { formatTimeToCustomFormat, timeToShow } from '../../utils';
import { CommentTextField } from './CommentTextField';
import { VideoCommentComponent } from './VideoCommentComponent';

/**
 * Component for displaying speaker's comment.
 * @returns {JSX.Element}
 */
export const SpeakerCommentComponent = ({ speakerData, handlePlay }: SpeakerCommentComponentProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isComment, setIsComment] = useState<boolean>(false)
  const { dispatch } = useContext(RecordedVideoContext)
  const [commentArray, setCommentArray] = useState<Comment[]>([])
  const { comments, sentence, startTime, speaker } = speakerData

  const commentOpenHandler = () => {
    setIsComment(true)
    dispatch({ type: VideoDetailActionType.SET_IS_SCROLLING, isScrolling: true })
  }

  useEffect(() => {
    comments && setCommentArray(comments)
  }, [comments])

  return (
    <>
      <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Typography fontSize='14px' fontWeight='600'>
          {speaker?.replace('spk_0', 'Speaker 0').replace('spk_1', 'Speaker 1').replace('spk_2', 'Speaker 2')}
        </Typography>

        <Box sx={{ ...iconsBox, opacity: isHovered ? 1 : 0, py: 0.5, pr: 1 }}>
          <Box sx={playCircleOutlineIconWrapper}>
            <PlayCircleOutlineIcon sx={playCircleIcon} onClick={() => handlePlay(formatTimeToCustomFormat(Number(startTime)))} />

            <Typography component='p' color="white" fontSize='14px' fontWeight='600'>
              {timeToShow(Number(startTime))}
            </Typography>

            <IconButton onClick={commentOpenHandler}>
              {commentArray.length !== 0 &&
                <Typography component='span' color='white' sx={{ background: palette.primary.main, ...commentCountStyle }
                }>
                  {commentArray.length}
                </Typography>}
              <CommentIcon sx={{ cursor: 'pointer', color: 'white', fontSize: 'medium' }} />
            </IconButton>
          </Box>
        </Box>

        <Typography component='p' sx={speakerDataSentenceTypography}>
          {sentence}
        </Typography>


        {!!commentArray.length &&
          <Box pt='20px' ml='25px'>
            <Typography variant='h4' fontWeight='700'>Comments</Typography>

            <Box pt="16px">
              {commentArray?.map((item) =>
                <VideoCommentComponent
                  key={item.id}
                  singleComment={item}
                  isCommentOpen={isComment}
                  setIsCommentOpen={setIsComment}
                  setCommentArray={setCommentArray}
                  speakerData={speakerData}
                  commentArray={commentArray} />
              )}
            </Box>
          </Box>
        }
      </Box>

      <CommentTextField
        isCommentOpen={isComment}
        setIsCommentOpen={setIsComment}
        setCommentArray={setCommentArray}
        speakerData={speakerData}
        commentArray={commentArray}
      />
    </>
  )
};