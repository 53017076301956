import { Box, Tab, Tabs } from "@mui/material";
import { FC, SyntheticEvent, useState } from "react";

import { LandingPageTabsProps } from '../../../interfaceTypes';
import { customIconLinkBox, tabsStyle } from "../../../theme/styleConstants";
import { handleTabs } from "../../../utils";
import { MobilePreview } from "../../common/MobilePreview";
import { TabPanel } from '../../common/TabPanel';
import { LandingPageWidget } from "./LandingPageWidget";
import { CustomIconLink } from "../../common/CustomIconLink";
import { BRAND_ID } from "../../../constants";
import { useSearchParams } from "react-router-dom";
import { WidgetType } from "../../../../generated";

export const LandingPageTabs: FC<LandingPageTabsProps> = ({ showOperator }) => {
  const [preview, setPreview] = useState(0);
  const brandId = localStorage.getItem(BRAND_ID);
  const [searchParams] = useSearchParams();
  const widgetId = searchParams.get('widgetId');
  const landingWidgetLink = `${process.env.REACT_APP_BASE_URL}/widget-preview/brand/${brandId}/widget/${widgetId}?widgetType=${WidgetType.LandingPage}`

  const handlePreviewChange = (event: SyntheticEvent, newValue: number) => {
    setPreview(newValue);
  }

  return (
    <>
      <Box sx={{ ...tabsStyle, position: 'relative' }}>
        <Tabs value={preview} onChange={handlePreviewChange} aria-label="basic tabs example">
          <Tab sx={{ textTransform: 'capitalize' }} label="Desktop" {...handleTabs(0)} />

          <Tab sx={{ textTransform: 'capitalize' }} label="Mobile" {...handleTabs(1)} />
        </Tabs>

        <Box sx={customIconLinkBox}>
          <CustomIconLink widgetLink={landingWidgetLink} />
        </Box>
      </Box>

      <TabPanel value={preview} index={0}>
        <LandingPageWidget showOperator={showOperator} />
      </TabPanel>

      <TabPanel value={preview} index={1}>
        <MobilePreview>
          <Box position='relative' top={64}>
            <LandingPageWidget showOperator={showOperator} />
          </Box>
        </MobilePreview>
      </TabPanel>
    </>
  )
}
