import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material/';
import Button from '@mui/material/Button';
import { ChangeEvent, FC } from 'react';
import { Link as ReactRouterLink } from "react-router-dom";

import { TaskActionsProp } from '../../interfaceTypes';
import { leftTaskAction, taskAction, taskActionCreateButton } from '../../theme/styleConstants';
import { Search } from '../common/Search';

export const TaskActions: FC<TaskActionsProp> = ({ setKeyword, loading }) => {

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event

    setKeyword(value);
  };

  return (
    <Box sx={taskAction}>
      <Box sx={leftTaskAction}>
        <Search onChange={handleChange} infoText="Search with name, audience key or tags" />
      </Box>

      <ReactRouterLink to='create'>
        <Box>
          <Button sx={taskActionCreateButton}
            variant="contained" disabled={loading} color="primary" startIcon={<AddIcon />}>
            Create
          </Button>
        </Box>
      </ReactRouterLink>
    </Box>
  )
}