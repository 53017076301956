import { FC, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteAttachmentModal } from "./DeleteAttachmentModal";
import { DeleteItemProps } from '../../interfaceTypes';
import { deleteButton } from '../../theme/styleConstants';

export const DeleteArea: FC<DeleteItemProps> = ({ itemId, itemType }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <Box className="deleteButton">
      <IconButton onClick={handleModal} sx={deleteButton}>
        <DeleteIcon color="error" />
      </IconButton>

      <DeleteAttachmentModal
        isOpen={open}
        attachmentId={itemId}
        handleClose={handleModal}
        attachmentType={itemType}
      />
    </Box>
  )
}
