import { Alert, Box, Button, Card, Checkbox, Chip, Menu, styled, Typography } from "@mui/material";
import { motion } from "framer-motion";
import customTheme from ".";
import { images } from "../../assets/images";
import { checkDevice } from "../utils";
import palette from "./palette";
import { flexCenter } from './styleConstants';
import { MentionProps } from "../interfaceTypes";

const { lightBlack, primary: { main: primaryMain } } = palette

export const AuthLayoutContainer = styled(Box)(() => ({
  minHeight: "100vh",
  padding: '24px',
  maxWidth: 500,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "center"
}));

export const TaskVideoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse'
  }
}));

export const UserRightActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'start',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '1rem',
  },

  '& button': {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },

  '& .MuiFormControl-root': {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },

    '& .MuiInputBase-root': {
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
    }
  }
}));

export const DrawerList = styled(Box)(() => ({
  "& .MuiListItemButton-root": {
    color: lightBlack,
    borderBottom: 'none',
    fontSize: '14px',
    fontWeight: '400',
    height: '50px',

    "&:hover": {
      backgroundColor: 'transparent',
      color: primaryMain,

      "&:after": {
        display: 'none'
      }
    },

    '&.active': {
      backgroundColor: 'transparent',
      color: primaryMain,

      "&::after": {
        display: 'none'
      },

      "&:hover": {
        backgroundColor: 'transparent',
      },
    }
  }
}));

export const RemoveButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  backgroundColor: palette.redTwo,

  "&:hover": {
    backgroundColor: palette.redTwo
  }
}));

export const SelectBrandArea = styled(Box)(() => ({
  border: `1px solid ${palette.light}`,
  borderRadius: '8px',
  flexDirection: 'column',
  padding: '30px 1rem 20px',
  transition: '0.3s',
  position: 'relative',
  zIndex: '9',
  ...flexCenter,
  height: '200px',

  "&:hover": {
    border: `1px solid ${primaryMain}`,
  }
}));

export const SelectBrandWrapper = styled(Box)(() => ({
  position: 'relative',
  transition: '0.3s',

  "& .brandsButton": {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    opacity: "0",
    transition: '0.6s',
    zIndex: '10',
  },

  "&:hover": {
    "& .brandsButton": {
      opacity: '1',
    }
  }
}));

export const SiteWidgetPreview = styled(Box)(() => ({
  border: `1px solid ${palette.lightBorderColorTwo}`,
  borderRadius: '6px',
  width: '100%',
  maxWidth: '350px',
  margin: '0 auto 2rem',
}));

export const ImageBox = styled(Box)(() => ({
  ...flexCenter,
  height: '125px'
}));

export const TitleBox = styled(Box)(() => ({
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  backgroundColor: primaryMain,
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px'
}));

export const VideoWidgetTitle = styled(Box)(() => ({
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px'
}));

export const Controls = styled(Button)(() => ({
  position: 'absolute',
  top: '10px',
  textTransform: 'capitalize',
  padding: '0',
  minWidth: 'auto'
}));

export const VideoBox = styled(Box)(() => ({
  flex: '1',
  position: 'relative',
  height: '160px',
  backgroundColor: palette.lightThree,
}));

export const MuteButton = styled(Button)(() => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  textTransform: 'capitalize',
  borderRadius: '27px',
  minWidth: 'auto',
  fontSize: '11px'
}));

export const VideoArrowIcon = styled(Box)(() => ({
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  transform: 'rotate(180deg)',
}));

export const AccordionWrapper = styled(Box)(() => ({
  marginBottom: '1rem',
  padding: '7px 16px',
  backgroundColor: palette.white
}));

export const LandingPageVideo = styled(Box)(() => ({
  marginTop: '1rem',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '174px',
  backgroundColor: palette.lightSix,
  borderRadius: '3px'
}));

export const CircleBox = styled(Box)(() => ({
  marginRight: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '33px',
  height: '33px',
  borderRadius: '16.5px',
  backgroundColor: palette.lightSix
}));

export const WidgetBox = styled(Box)(() => ({
  marginBottom: '2rem',
  border: `1px solid ${palette.lightTwo}`,
  borderRadius: '5px',
  padding: '29px',
  position: 'relative',

  "&:last-child": {
    marginBottom: '0'
  }
}));

export const EmptyDataBox = styled(Box)(() => ({
  marginTop: '2rem',
  borderRadius: '5px',
  marginBottom: "2rem",
  padding: '29px',
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}));

export const VideoThumbnail = styled(Box)(() => ({
  width: '100%',
  position: 'relative',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& video': {
    objectFit: 'contain',
  }
}));

export const VideoCardWrapper = styled(Card)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${palette.lightColorTwo}`,
  borderRadius: '4px',
  height: '100%',
  position: 'relative',

  '& .videoLibraryActions': {
    position: 'absolute',
    top: '1rem',
    right: '.5rem',
    opacity: '0',
    transition: 'opacity 0.3s',
    zIndex: '1',

    "& button": {
      '&:hover': {
        backgroundColor: palette.white
      }
    },

    [theme.breakpoints.down('md')]: {
      top: checkDevice() ? '2.5rem' : '1rem'
    }
  },

  '& .MuiCardActionArea-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  "&:hover": {
    '& .videoLibraryActions': {
      opacity: '1',
    },
  }
}));

export const ImageWrapper = styled(Box)(() => ({
  '& img': {
    objectFit: 'cover',
  }
}));

export const UploadVideoBox = styled(Box)(() => ({
  position: 'relative',
  border: `1px solid ${palette.lightColorTwo}`,
  borderRadius: '4px',
  padding: '24px',
}));

export const UploadBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const UploadArea = styled(Box)(() => ({
  border: `1px dashed ${palette.lightGray}`,
  borderRadius: '4px',
  padding: '24px 16px'
}));

export const RenderVideo = styled(Box)(() => ({
  height: 192,
  borderRadius: "6px",
  overflow: "hidden",

  '& video': {
    objectFit: "cover"
  }
}));

export const AlertWarning = styled(Alert)(() => ({
  color: palette.white,
  backgroundColor: palette.yellow,
  fontSize: '1rem',
  maxWidth: '665px',

  '& svg': {
    fill: palette.white
  }
}));

export const PreviewBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  minHeight: '225px',
  border: `1px solid ${palette.light}`,
  borderRadius: '4px'
}));

export const UploadIcon = styled(Box)(() => ({
  backgroundColor: palette.light,
  width: '40px',
  height: '40px',
  borderRadius: '100%',
  marginBottom: '.5rem',
  marginRight: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const ColorPicker = styled(Box)(() => ({
  position: 'absolute',
  right: '1rem',
  top: '30%',
  border: `2px solid ${palette.darkPurple}`,
  borderRadius: '4px',
  width: '24px',
  height: '24px',
}));

export const Logo = styled(Box)(() => ({
  display: 'flex',
  paddingRight: '30px',
  borderRight: `1px solid ${palette.lightBorderColor}`,
  marginRight: '20px'
}));

export const AuthLayoutBox = styled(Box)(({ theme }) => ({
  backgroundColor: palette.white,
  borderRadius: '4px',
  padding: '50px',
  marginTop: '40px',
  boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',

  [theme.breakpoints.down('md')]: {
    padding: '24px',
  }
}));

export const TaskInfo = styled(Chip)(({ theme }) => ({
  fontsize: '12px',
  fontWeight: '500',
  backgroundColor: palette.pinkTwo,
  color: palette.pink,
  borderRadius: '4px',
  '& svg': {
    fill: palette.pink,
  }
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  position: 'absolute',
  top: '-14px',
  left: '-14px',
  padding: '3px',
  zIndex: '1',
  backgroundColor: palette.white,
  color: palette.primary.main,

  '&.Mui-checked': {
    color: palette.primary.main,
  },

  '&.MuiButtonBase-root:hover': {
    backgroundColor: palette.white
  }
}));

export const RadioBox = styled(Box)(({ theme }) => ({
  marginBottom: '1rem',
  border: `1px solid ${palette.lightColorTwo}`,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px'
}));

export const Shape = styled(Box)(({ theme }) => ({
  height: '32px',
  width: '32px',
  border: `2px solid ${palette.blackTwo}`,

  [customTheme.breakpoints.down('sm')]: {
    width: '25px',
    height: '25px',
  }
}));

export const ThumbnailWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '86px',
  width: '86px',
  padding: '5px',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  display: "flex",

  "& img": {
    maxWidth: "100%",
    objectFit: "contain",
    borderRadius: '4px',
    margin: "0 auto"
  },

  '& .deleteButton': {
    position: 'absolute',
    top: '-16px',
    right: '-5px',
    opacity: '0',
    transition: 'opacity 0.3s',
    zIndex: '9',
    border: `1px solid ${palette.lightBorderColorTwo}`,
    borderRadius: '100%',

    "& .MuiButtonBase-root": {
      padding: '5px',

      "& svg": {
        fontSize: "1.2rem"
      }
    }
  },

  "&:hover": {
    '& .deleteButton': {
      opacity: '1',
    }
  },

  [customTheme.breakpoints.down('sm')]: {
    width: '50px',
    height: '50px'
  }
}));

export const CustomMenu = styled(Menu)(({ theme }) => ({
  '& .MuiList-root': {
    border: `1px solid ${palette.lightGray}`,
    minWidth: '250px',
    maxWidth: '290px',
    background: palette.white,
    boxShadow: `0px 0px 15px ${palette.lightSeven}`,
    borderRadius: '4px',
    paddingTop: '0',
    paddingBottom: '0',
    '& .MuiMenuItem-root': {
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  }
}));

export const TooltipComponent = styled(Box)(({ theme }) => ({
  position: 'relative',

  '& .customTooltip': {
    position: 'absolute',
    bottom: '20px',
    maxHeight: '100px',
    maxWidth: '300px',
    overflowY: 'auto',
    fontSize: '0.68rem',
    display: 'none',
    borderRadius: '4px',
    backgroundColor: palette.tooltipBackground,
    color: palette.white,
    padding: '5px',
    transition: '0.3s'
  },

  '&:hover': {
    '& .customTooltip': {
      display: 'block',
    }
  }

}));

export const CompanyNameBox = styled(Box)(({ theme }) => ({
  maxHeight: '205px',
  overflow: 'auto',
}));

export const GalleryBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  "& img": {
    objectFit: 'cover',
  }
}));

export const VideoPlayIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: "9",
  height: "100%",
  width: "100%",
  top: "0",
  ...flexCenter
}));

export const ScatterLoaderComponent = styled(Box)(({ theme }) => ({
  width: "100%",
  minWidth: "600px",
  overflow: "hidden",

  "& .scatterboxloader": {
    margin: "auto"
  }
}));

export const ImageOverlay = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0',
  backgroundColor: palette.lightColor,
}));

export const CustomAdminMenu = styled(Menu)(({ theme }) => ({
  '& .MuiList-root': {
    border: `1px solid ${palette.lightGray}`,
    width: '198px',
    background: palette.white,
    boxShadow: `0px 0px 15px ${palette.lightSeven}`,
    borderRadius: '4px',
    maxHeight: '205px',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',

    '& .MuiMenuItem-root': {
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  }
}));

export const CustomCollapseWrapper = styled(Box)(({ theme }) => ({
  marginBottom: "1rem",
  padding: '7px 16px',
  backgroundColor: palette.white
}));

export const AppLoading = styled(Box)(() => ({
  height: '100vh',
  width: '100%',
  flexDirection: 'column',
  ...flexCenter,
}));

export const MassonaryImageBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const AnimateBox = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'absolute',
  cursor: 'default',
  animation: 'marquee 10s linear infinite',

  "&:hover": {
    animationPlayState: 'paused',
  },

  '@keyframes marquee': {
    '0%': { top: '0' },
    '100%': { top: '-100%' }
  }
}));

export const ShowScript = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '-15px',
  right: '-21px',
  color: primaryMain,
  cursor: 'pointer'
}));

export const ErrorBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: '50%',
  left: '50%',
  width: '100%',
  transform: `translate(-50%, -50%)`,
  'p': {
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    }
  }
}));

export const MobilePreviewTabs = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${images.MOBILE_PREVIEW})`,
  width: '420px',
  height: '880px',
  backgroundSize: 'cover',
  margin: '0 auto',
  transform: 'scale(.5)',
  transformOrigin: 'top center',

  [theme.breakpoints.down('sm')]: {
    width: '260px',
    height: '544px',
  },

  "& .landingOperatorDetail": {
    flexDirection: 'column',
    "& .MuiButton-root": {
      marginTop: '16px',
      width: '100%',
    }
  }
}));

export const TvPreview = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${images.TV_SCREEN})`,
  width: 'calc(100% - 30px)',
  height: '300px',
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  margin: 'auto',

  [theme.breakpoints.down(1199)]: {
    width: '100%',
    maxWidth: '451px',
    height: '269px',
  },

  [theme.breakpoints.down(515)]: {
    width: '100%',
    maxWidth: '286px',
    height: '170px'
  }
}));

export const VideoRecordWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: "1px solid #E0E0E0",
  borderRadius: "5px",
  position: 'relative',
  zIndex: 9,

  [theme.breakpoints.down(675)]: {
    flexDirection: "column",
    alignItems: 'flex-start',
    width: '100%',
  },
}));

export const VideoRecordDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',

  [theme.breakpoints.down(768)]: {
    flexDirection: 'column',
    padding: '10px'
  },
}));

export const VideoRecordElement = styled(Box)(({ theme }) => ({
  position: 'relative',

  [theme.breakpoints.down(768)]: {
    width: '100%',
    height: '215px'
  },

  [theme.breakpoints.up(768)]: {
    width: '266px',
    height: '170px'
  },
}));

export const VideoSearchListWrap = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  paddingBottom: '15px',
  marginBottom: '15px',
  borderBottom: '1px solid #ccc',
  display: "flex",
  gap: '10px',

  [theme.breakpoints.down(700)]: {
    flexDirection: 'column'
  },
}));

export const VideoSearchListCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '150px',

  [theme.breakpoints.down(575)]: {
    height: '200px'
  },

  [theme.breakpoints.up(575)]: {
    height: '100px'
  },
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '30rem',
  fontWeight: '600',
  opacity: '0.1',

  [theme.breakpoints.down('lg')]: {
    fontSize: '25rem',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '18rem',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '10rem',
  }
}));

export const PageNotFountText = styled(Typography)(({ theme }) => ({
  fontSize: '68px',
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    fontSize: '48px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '28px',
  }
}));

export const KioskIdleWidget = styled(Box)(() => ({
  position: 'relative',
  maxWidth: '100%',
  margin: 'auto',
  overflow: 'hidden',

  '.slick-slide': {
    transition: 'transform 0.3s ease-in-out',
    transform: 'scale(0.90)',
  },

  '.slick-slide.slick-current': {
    position: 'relative',
    transform: 'scale(1)',
  },

  '.slick-slide.slick-current .caba-pdp-hover-controls': {
    bottom: '27px'
  }
}))

export const KioskFocusWidget = styled(Box)(() => ({
  position: 'relative',
  maxWidth: '100%',
  margin: 'auto',
  overflow: 'hidden',

  '.slick-slider': {
    height: '100%',

    '.kiosk-focus-slide': {
      width: "100%",
      height: "100%",
      position: 'relative',
      display: "block !important",
      margin: '-10px 0',
    },
  },

  '.slick-list': {
    height: '100% !important'
  },

  '.slick-slide': {
    transition: 'transform 0.3s ease-in-out',
    transform: 'scale(0.9)',
  },

  '.slick-slide.slick-current': {
    position: 'relative',
    transform: 'scale(1)',
    zIndex: 123
  },
}))

export const CustomPhoneContainer = styled(Box)(() => ({
  '.react-tel-input .form-control': {
    height: '56px',
    '-webkit-appearance': 'none',

    "&:focus": {
      outline: 'none',
    }
  },

  '.MuiFormControl-root.MuiFormControl-fullWidth': {
    marginTop: '8px'
  },

  '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: ' translate(10px, -6px) scale(0.75)',
    background: "none",
    padding: '0 6px',
    zIndex: '99',
    backgroundColor: palette.white
  },

  '.display_none': {
    display: 'none!important'
  },

  '.special-label': {
    display: 'none!important'
  },

  '.transparent': {
    color: 'transparent',
  },

  '.appearance': {
    '-webkit-appearance': 'none',
  }
}))

export const CustomPlayerVideo = styled(Box)(() => ({
  paddingTop: '56.55%',
  position: 'relative',

  '.react-player': {
    'video': {
      objectFit: 'cover',
    }
  },

  'video::-webkit-media-controls': {
    display: 'none'
  }
}));

export const CustomPlayerVideoLibrary = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down(675)]: {
    width: '100%',
    '.react-player': {
      'video': {
        borderRadius: "5px 5px 0px 0px",
      }
    },

  },

  '.react-player': {
    'video': {
      objectFit: 'contain',
      borderRadius: "5px",
      aspectRatio: "16/9"
    }
  },
}));

export const KioskWidgetWrapper = styled(Box)(() => ({
  position: 'absolute',
  opacity: 0,

  '&.with-active': {
    position: 'relative',
    opacity: 1,
  }
}))

export const KioskWidgetBanner = styled(motion.div)(() => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'flex-end',
  left: 0,
  width: '100%',
  justifyContent: 'center',
  padding: '0 20px',

  "img": {
    width: "100%",
    display: 'block',
    borderRadius: '8px 8px 0 0',
  }
}))

export const KioskWidgetAlert = styled(motion.div)(() => ({
  position: 'fixed',
  left: "50%",
  top: 0,
  transform: "translate(-50%, 0)",
  zIndex: 1000,
  color: '#ffffff',
  padding: '15px 15px',
  borderRadius: '0px 0px 20px 20px',
  minWidth: '50vw',
}))

export const KioskWidgetFeature = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const KioskWidgetFeatureInner = styled(Box)(() => ({
  height: '90vh',
  position: 'relative',
  maxWidth: '90%',
  width: "100%",
  overflow: 'hidden',
  marginLeft: "-54px"
}))

export const KioskWaitingImageText = styled(Box)(() => ({
  position: "absolute",
  bottom: "20px",
  width: "58%",
  left: "50%",
  transform: "translateX(-50%)",
  color: palette.white,
  textAlign: "center",
  zIndex: 100,

  "p": {
    fontSize: '16px'
  }
}))

export const AnimatedLoaderWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: '100'
}))

export const VideoTextWrapper = styled(Box)(() => ({
  position: 'absolute',
  color: palette.white,
}))

export const CheckBoxWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  borderBottom: '2px solid #E0E0E0'
}))

export const CustomVideoPlayer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  '.react-player': {
    'video': {
      objectFit: 'cover',
      borderRadius: '5px',
    }
  },
}));

export const OverLay = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%)',
  borderRadius: '5px'
}))

export const VideoDetailComment = styled(Box)(() => ({
  borderRadius: '4px',
  border: '1px solid #ECECEC',
  background: '#FFF',
  padding: '10px',
  boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.04)'
}))

export const VideoCommentBox = styled(Box)(() => ({
  borderRadius: '6px',
  marginBottom: '8px',
  padding: '8px 16px 8px 16px',
  position: 'relative',
  border: '1px solid transparent',
  background: 'none',

  '&:hover': {
    border: '1px solid #FF5996',
    backgroundColor: 'rgba(255, 89, 150, 0.06)'
  }
}));

export const MentionWrapper = styled(Box)(({ isEdit }: MentionProps) => ({
  '& textarea': {
    marginTop: isEdit ? '0' : '10px',
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '10px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    resize: 'none',
    borderRadius: '10px',
    display: 'block',
    width: '100%',
    maxWidth: '800px',
    maxHeight: '200px',
    height: 'auto',
    fontFamily: 'Figtree',
    overFlowY: 'auto',

    "&:focus": {
      outline: 'none',
    },

    "&:hover": {
      borderColor: primaryMain,
    },
  },

  "& .p-mention-panel": {
    left: '0 !important',
    transform: 'translateY(-101%)',
    top: '0 !important',
    minWidth: 0,
    width: '250px',
    borderRadius: '10px',
    boxShadow: 'none',
    marginLeft: '25px',

    '::-webkit-scrollbar': {
      width: '10px'
    },

    '::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
      backgroundColor: 'white'
    },

    ':: -webkit-scrollbar-thumb': {
      background: palette.purple1,
      borderRadius: '10px'
    },
  },

  "& .p-mention": {
    display: 'block'
  },
}))
