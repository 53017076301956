import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { JobStatus, useUpdateJobMutation } from "../../../generated";
import { BRAND_ID } from "../../constants";
import { RejectJobInputInterface, RejectJobInterface } from "../../interfaceTypes";
import { UpdateJobValidationSchema } from "../../validationSchema";
import { Alert } from "../common/Alert";
import { CustomController } from "../common/CustomController";

export const RejectJob: FC<RejectJobInterface> = ({ jobId, refetchJob, handleCloseReject }) => {
  const brandId = localStorage.getItem(BRAND_ID);
  const methods = useForm<RejectJobInputInterface>({
    mode: "all",
    resolver: yupResolver(UpdateJobValidationSchema),

    defaultValues: {
      statusMessage: ""
    },
  });

  const { handleSubmit, reset } = methods;

  const [updateJob, { loading }] = useUpdateJobMutation({
    onError({ message }) {
      return Alert.error(message)
    },

    onCompleted() {
      reset()
      refetchJob && refetchJob()
      handleCloseReject()
      return Alert.success("Job rejected successfully!")
    },
  });

  const onSubmit = async (data: RejectJobInputInterface) => {
    const { statusMessage } = data

    await updateJob({
      variables: {
        updateJobInput: {
          id: jobId,
          statusMessage,
          status: JobStatus.Rejected,
          brandId: brandId || ""
        }
      },
    });
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <CustomController
            controllerName="statusMessage"
            controllerLabel="Status"
            fieldType="text"
            rowsLength={4}
            isMultiLine
          />

          <Box display='flex' justifyContent='flex-end' mt={2}>
            <Button sx={{ marginLeft: '10px' }} onClick={handleCloseReject}>Cancel</Button>

            <Button type="submit" variant="contained" color="error" disabled={loading} endIcon={loading && <CircularProgress size={20} color="inherit" />}>Reject</Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}
