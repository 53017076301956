import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, CircularProgress, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useUpdateBrandMutation } from '../../../generated';
import { API_KEY, URL, USER_NAME } from '../../constants';
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { UpdateBrand } from '../../interfaceTypes';
import { blurText } from '../../theme/styleConstants';
import { firstLetterUppercase } from '../../utils';
import { gorgiasValidationSchema } from '../../validationSchema';
import { Alert } from '../common/Alert';
import { CustomController } from '../common/CustomController';
import { BrandTags } from './BrandTags';

export const Gorgias: FC = () => {
  const { selectedBrand, dispatch } = useContext(AppContext)
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const { gorgiasSettings, gorgiasEnable, id: brandId, name, createdAt, updatedAt, attachments, gorgiasTags } = selectedBrand || {}
  const { url: gorgiasUrl } = gorgiasSettings || {}
  const gorgiasTagsValue = gorgiasTags !== "" ? gorgiasTags?.split(', ') : []

  const methods = useForm<UpdateBrand>({
    mode: "all",
    resolver: yupResolver(gorgiasValidationSchema),

    defaultValues: {
      gorgiasSettings: {
        url: gorgiasUrl || '',
        apiKey: '',
        userName: '',
      },
      gorgiasTags: gorgiasTagsValue || [],
    }
  });

  const { handleSubmit, setValue, reset } = methods;

  const [updateBrand, { loading }] = useUpdateBrandMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { updateBrand } = data
        const { gorgiasSettings } = updateBrand
        const { url, brandId: gorgiasBrandId, createdAt: gorgiasCreated, updatedAt: gorgiasUpdated, id: gorgiasId } = gorgiasSettings || {}

        const updatedBrand = {
          ...selectedBrand,
          id: brandId || "",
          name: name || "",
          attachments: attachments || [],
          createdAt,
          updatedAt,

          gorgiasSettings: {
            id: gorgiasId || "",
            url: url || "",
            brandId: gorgiasBrandId || "",
            createdAt: gorgiasCreated,
            updatedAt: gorgiasUpdated
          },
        }

        if (updateBrand) {
          dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrand })
          reset()
          return Alert.success("Brand updated successfully")
        }
      }
    }
  })

  const onSubmit = async (data: UpdateBrand) => {
    const { gorgiasSettings } = data
    const { url, apiKey, userName } = gorgiasSettings || {}

    if (brandId) {
      await updateBrand({
        variables: {
          updateBrandInput: {
            brandId,
            gorgiasSettings: {
              url: url || '',
              apiKey: apiKey || "",
              userName: userName || ""
            },
          }
        }
      })
    }
  }

  useEffect(() => {
    gorgiasUrl && setValue('gorgiasSettings.url', gorgiasUrl)
    if (gorgiasTagsValue && gorgiasTagsValue.length > 0) {
      setValue("gorgiasTags", gorgiasTagsValue)
    }
    //eslint-disable-next-line
  }, [gorgiasUrl, gorgiasTags])

  return (
    <Box sx={{ mt: "2rem" }}>
      <Box display='flex' justifyContent='space-between' alignItems="center">
        <Typography variant="subtitle1" mb={2}>Gorgias</Typography>

        <IconButton color="primary" size="large" onClick={() => { setIsEditOpen(!isEditOpen) }}>
          {isEditOpen ? <ArrowBackIcon /> : <EditIcon />}
        </IconButton>
      </Box>

      <Collapse in={!isEditOpen}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item lg={4} md={6} xs={12}>
            <Box>
              <Typography variant='body2'>{URL}</Typography>
              <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{gorgiasUrl}</Typography>
            </Box>
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <Box>
              <Typography variant='body2'>{API_KEY}</Typography>

              <Box display="flex" alignItems="center">
                <Typography variant='h4' sx={{ ...blurText, marginTop: '10px', marginBottom: '20px' }}>ujilkajsd-askhfdaslncasdasd-ijansdisad</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <Box>
              <Typography variant='body2'>{USER_NAME}</Typography>
              <Typography sx={{ marginTop: '10px', marginBottom: '20px', ...blurText }} variant='h4'>Hassan Latif</Typography>
            </Box>
          </Grid>
        </Grid>
      </Collapse>

      <Collapse in={isEditOpen}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item sm={6} xs={12}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <CustomController
                  controllerName='gorgiasSettings.url'
                  controllerLabel={URL}
                  fieldType='text'
                />

                <CustomController
                  controllerName='gorgiasSettings.apiKey'
                  controllerLabel={API_KEY}
                  fieldType='text'
                />

                <CustomController
                  controllerName='gorgiasSettings.userName'
                  controllerLabel={USER_NAME}
                  fieldType='text'
                />

                <Box display="flex" justifyContent="flex-end">
                  <Button sx={{ marginTop: '1rem' }} type='submit' variant='contained' color='primary'
                    disabled={loading} endIcon={loading && <CircularProgress size={20} color="inherit" />}>
                    Update
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Collapse>

      {gorgiasEnable &&
        <BrandTags />
      }
    </Box>
  )
}
