import { Avatar, Box, Collapse, IconButton, Popover, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import palette from "../../theme/palette";
import { commentLineBox } from "../../theme/styleConstants";
import { Edit } from "@mui/icons-material";
import { CommentTextField } from "./CommentTextField";
import { VideoCommentComponentProps } from "../../interfaceTypes";
import { AuthContext } from "../../context";

/**
* Component for rendering a single comment card.
* @returns {JSX.Element}
*/
export const VideoCommentComponent = ({ singleComment, setIsCommentOpen, commentArray, setCommentArray, speakerData }: VideoCommentComponentProps): JSX.Element => {
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext)
  const { id: currentUserId } = currentUser ?? {}
  const { comment, user, createdAt } = singleComment
  const { attachment, fullName, email, id: userId } = user || {}
  const { url } = attachment || {}
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleEditClick = () => {
    setIsEdit(!isEdit)
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const formatComment = (comment: string) => {
    const pattern = /\([^)]*\)/g;
    const replacedComment = comment.replace(pattern, '')

    const parts = replacedComment.split(/(@\w+)/g);
    return parts.map((part, index) => {
      if (part.startsWith('@')) {
        return (
          <Typography component='span' key={`${index} + ${part}`} style={{ color: '#8B008B', borderRadius: '4px', marginLeft: '2px' }}>
            {part.replace(pattern, '')}
          </Typography>
        );
      } else {
        return (
          <Typography component='span' key={index} marginLeft='5px'>
            {part.replace(pattern, '')}
          </Typography>
        );
      }
    });
  };

  return (
    <Box>
      <Box display='flex' key={id} gap='6px' pb={1}>
        <Avatar
          src={url || ""}
          sx={{ "& img": { border: `1px solid ${palette.lightBlack}`, borderRadius: '50%' } }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ p: 1, borderRadius: '5px', border: `1px solid ${palette.lightGray}`, boxShadow: `0px 0px 15px ${palette.lightSeven}`, display: 'flex' }}>
            <Avatar
              src={url || ""}
              sx={{ "& img": { border: `1px solid ${palette.lightBlack}`, borderRadius: '50%' } }}
            />

            <Box pl={1}>
              <Typography>
                {fullName}
              </Typography>

              <Typography sx={{ fontSize: '12px', mt: '-3px' }}>
                {email}
              </Typography>
            </Box>
          </Box>
        </Popover>

        <Box flex={1}>
          <Collapse in={!isEdit} unmountOnExit mountOnEnter>
            <Box display="flex" alignItems="center">
              <Typography component='p' sx={commentLineBox}>
                {formatComment(comment || "")}
              </Typography>

              <IconButton disabled={userId !== currentUserId} onClick={handleEditClick} sx={{ marginLeft: '10px' }}>
                <Edit color={userId !== currentUserId ? "disabled" : "primary"} sx={{ width: '16px', height: '16px' }} />
              </IconButton>
            </Box>

            <Box mt={"-2px"}>
              <Typography component='span' fontSize='11px' color="primary">
                {moment(createdAt).startOf('seconds').fromNow()}
              </Typography>
            </Box>
          </Collapse>

          <Collapse in={isEdit} unmountOnExit mountOnEnter>
            <Box display="flex" alignItems="center">
              <CommentTextField
                isCommentOpen={true}
                setIsCommentOpen={setIsCommentOpen}
                setCommentArray={setCommentArray}
                speakerData={speakerData}
                commentArray={commentArray}
                isEdit={isEdit}
                singleComment={singleComment}
                setIsEdit={setIsEdit}
              />
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  )
}