import { AnimatePresence } from 'framer-motion'
import { FC } from 'react'
import { KioskBannerImageInterface } from '../interfaceTypes'
import { KioskWidgetBanner } from '../theme/styleComponents'
import { Box } from '@mui/material'

export const KioskWidgetBannerImage: FC<KioskBannerImageInterface> = ({ imageUrl, isFocus, slideWidth }) => {
  const currentSlideWidth = slideWidth ? `${slideWidth + 100}px` : undefined

  return (
    <AnimatePresence>
      <KioskWidgetBanner
        key={imageUrl}
        initial={{ bottom: -150 }}
        animate={{ bottom: 0, left: 0 }}
        exit={{ bottom: -150 }}
        transition={{ type: "tween", delay: 0.7 }}
      >
        {imageUrl &&
          <Box sx={{ maxWidth: isFocus ? 'calc(100% - 132px)' : currentSlideWidth }}>
            <img src={imageUrl} alt="banner" />
          </Box>
        }
      </KioskWidgetBanner>
    </AnimatePresence>
  )
}