import { Box } from '@mui/material'
import { ChangeEvent, FC, Reducer, useCallback, useContext, useEffect, useReducer, useRef } from 'react'
import { useGetAllBrandsLazyQuery } from '../../generated'
import { BrandSelection } from '../components/brand/BrandSelection'
import { BrandAction, BrandActionType, brandInitialState, brandReducer } from '../components/brandSetting/reducer/brandReducer'
import { Alert } from '../components/common/Alert'
import { PageHeading } from '../components/common/PageHeading'
import { Search } from '../components/common/Search'
import { AUTH_TOKEN, GRAPHQL_QUERY_POLICY, SOMETHING_WENT_WRONG } from '../constants'
import { AppContext, AuthContext } from '../context'
import { ActionType } from '../context/AppContextReducer'
import { BrandState } from '../interfaceTypes'
import { selectBrand } from '../theme/styleConstants'
import palette from '../theme/palette'
export const BrandContainer: FC = () => {
  const { dispatch, isBrandLoading, selectedBrand } = useContext(AppContext)
  const [brandState, brandDispatch] = useReducer<Reducer<BrandState, BrandAction>>(brandReducer, brandInitialState);
  const hasToken = localStorage.getItem(AUTH_TOKEN);
  const { isLoggedIn } = useContext(AuthContext)
  const { brandsList } = brandState

  const [getAllBrands, { loading, refetch }] = useGetAllBrandsLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      return Alert.error(SOMETHING_WENT_WRONG)
    },

    onCompleted(data) {
      const { getAllBrands: brandsData } = data || {}
      if (brandsData) {
        brandDispatch({ type: BrandActionType.SET_BRAND_LIST, brandsList: brandsData })
      }
    }
  });

  useEffect(() => {
    hasToken && isLoggedIn && getAllBrands()
    // eslint-disable-next-line
  }, [isLoggedIn])

  useEffect(() => {
    brandDispatch({ type: BrandActionType.SET_FILTERED_BRAND_LIST, filteredBrandsList: brandsList })
    // eslint-disable-next-line
  }, [brandsList])

  useEffect(() => {
    dispatch({ type: ActionType.SET_IS_BRAND_LOADING, isBrandLoading: loading })
    dispatch({ type: ActionType.SET_IS_BRAND_SETTINGS, isBrandSettings: loading })
    dispatch({ type: ActionType.SET_IS_TOKEN_VERIFIED, isTokenVerified: loading })
    // eslint-disable-next-line
  }, [loading])

  const headerRef = useRef<HTMLDivElement>()
  const onScrollHeaderBehaviour = useCallback(() => {
    if (headerRef.current) {
      if (window.scrollY > headerRef.current?.offsetHeight) {
        headerRef.current.style.backgroundColor = palette.bodyBackground;
        headerRef.current.style.zIndex = '99';
        headerRef.current.style.top = selectedBrand ? '86px' : '64px';
        headerRef.current.style.boxShadow = '0px 15px 10px -15px rgba(0,0,0,0.25)';
      }
      else if (window.scrollY === 0) {
        headerRef.current.style.backgroundColor = palette.bodyBackground;
        headerRef.current.style.zIndex = '0';
        headerRef.current.style.top = '0';
        headerRef.current.style.boxShadow = 'none';
      }
    }
  }, [selectedBrand]);

  useEffect(() => {
    window.addEventListener('scroll', onScrollHeaderBehaviour);
    return () => {
      window.removeEventListener('scroll', onScrollHeaderBehaviour);
    };
    // eslint-disable-next-line
  }, [selectedBrand]);

  const onSearchBrandName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: { value } } = event
    const oldBrandsList = brandsList.filter((item) => {
      return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });
    brandDispatch({ type: BrandActionType.SET_FILTERED_BRAND_LIST, filteredBrandsList: oldBrandsList })
  }

  return (
    <>
      <Box ref={headerRef} sx={selectBrand}>
        <PageHeading marginTop='0' title='Select brands' />

        <Box>
          <Search onChange={onSearchBrandName} loading={isBrandLoading} infoText="Search by brand name" />
        </Box>
      </Box>

      <BrandSelection brandState={brandState} brandDispatch={brandDispatch} refetch={refetch} />
    </>
  )
}