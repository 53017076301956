import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, MouseEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DELETE, TEMPLATE, VIEW } from '../../constants';
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { TaskActionType } from '../../context/TaskContextReducer';
import { CustomModalProps } from '../../interfaceTypes';
import palette from '../../theme/palette';

export const Tasks: FC<CustomModalProps> = ({ taskDispatch, tasksData, task }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { dispatch } = useContext(AppContext)
  const { id } = task || {}
  const navigate = useNavigate()
  const currentTask = tasksData?.filter((task) => task.id === id);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: false })
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
    setAnchorEl(null);
  };

  const handleEditTaskOpen = () => {
    taskDispatch && taskDispatch({ type: TaskActionType.SET_CURRENT_TASK, currentTaskData: currentTask && currentTask[0] })
    setAnchorEl(null);
    navigate(`/task-template/${id}`)
  };

  const handleRemoveClickOpen = () => {
    taskDispatch && taskDispatch({ type: TaskActionType.SET_CURRENT_TASK, currentTaskData: currentTask && currentTask[0] })
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: true })
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        id={id}
        aria-controls={open ? 'header-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="firstHeaderMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: { minWidth: "200px", }
        }}
      >
        <MenuItem onClick={handleEditTaskOpen}>{VIEW} {TEMPLATE}</MenuItem>
        <MenuItem sx={{ color: palette.redTwo }} onClick={handleRemoveClickOpen}>{DELETE} {TEMPLATE}</MenuItem>
      </Menu>
    </Box>
  )
}
