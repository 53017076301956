import { Typography, Box } from '@mui/material'
import { CustomController } from '../../common/CustomController'

export const FlyerForm = () => (
  <>
    <Box position='relative'>
      <CustomController
        controllerName="hookVideoFlyerTimer"
        controllerLabel="Time Trigger Settings"
        fieldType='number'
      />

      <Box position='absolute' top='1.8rem' right='2.1rem'>
        <Typography variant="h3" fontWeight='600'>(seconds)</Typography>
      </Box>
    </Box>
    
    <CustomController
      controllerName="mobileFlyerHeading"
      controllerLabel="Flyer Heading"
      fieldType='text'
    />

    <CustomController
      controllerName="mobileFlyerDescription"
      controllerLabel="Flyer Description"
      fieldType='text'
    />
  </>
)