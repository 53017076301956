import { Box, List, ListItem, Typography } from '@mui/material'
import "highlight.js/styles/atom-one-dark.css"
import Highlight from 'react-highlight'
import { testingApi } from '../../theme/styleConstants'

export const InfoApiKeys = () => (
  <Box mt={2}>
    <Box mb={2}>
      <Typography variant='subtitle1'>Operator Api Key</Typography>

      <Typography variant='h4'>
        API keys are useful for accessing public data anonymously, and are used to associate API requests with the consumer. API Keys provides you a programmatic interface to create and manage API keys for your project. When you use API keys in your applications, ensure that they are kept secure during both storage and transmission. Publicly exposing your credentials can result in your account being compromised.
        An API key is a simple encrypted string that you can use when calling Operator API’s services.
      </Typography>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Managing API keys</Typography>

      <Typography my={0.5} variant='h4'>
        When viewing the API keys page, you will see a list of your current API keys along with the following information:
      </Typography>

      <List disablePadding sx={{ listStyleType: 'disc', listStylePosition: 'inside', border: '0' }}>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Name- The name you defined for your API key.</ListItem>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Public Key- The secret key provided to you.</ListItem>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Created At- Date at which the key has been created.</ListItem>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Action- Actions you can perform on your API keys, such as deleting the key.</ListItem>
      </List>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Creating an API key</Typography>

      <List disablePadding sx={{ listStyleType: 'disc', listStylePosition: 'inside', border: '0', mt: 0.5 }}>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Navigate to brand settings on the navigation bar, and then select API Keys.</ListItem>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Give your API key a name.</ListItem>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Click the Generate Key button.</ListItem>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Copy your API key somewhere safe. For security reasons, do not put it directly in your code, or commit it somewhere public like GitHub.</ListItem>
      </List>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Deleting an API key</Typography>

      <Typography variant='h4' mt={0.5}>
        Once you delete a key, it can no longer be used to access Operator services. Click the delete icon in the same row as the key you want to delete. This will delete the key permanently.
      </Typography>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Replacing an old API key with a new one</Typography>

      <List disablePadding sx={{ listStyleType: 'disc', listStylePosition: 'inside', border: '0', mt: 0.5 }}>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Locate the API key you would like to replace in your list of keys and click the Delete icon to delete the API key.</ListItem>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Next, create a new API key.</ListItem>
        <ListItem disablePadding sx={{ display: "list-item", fontSize: '14px' }}>Replace the old API key with the new one in your code.</ListItem>
      </List>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Testing an Restful API key</Typography>

      <Typography variant='h4' my={0.5}>A typical use of an API key is to pass the key into a REST API call, in the body as a key value pair in form-data. Where `x-api-key` will be the name of the key and value will be the key provided at the time of creation of an API key. Upon a successful response please check Gorgias for the created ticket.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>
          {`var myHeaders = new Headers();
  myHeaders.append("x-api-key", "<YOUR_PRIVATE_KEY>");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "audienceKey": "<AUDIENCE_KEY_TASK_TEMPLATE>",
    "customerEmail": "john.doe@example.com",
    "customerName": "John Doe",
    "customerPhone": "1234567890",
    "brandId": "<YOUR_BRAND_ID>",
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch("BaseUrl", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));    
})`
          }
        </Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Testing GraphQl API</Typography>

      <Typography variant='h4' my={0.5}>You can use your site widget to invoke the above API directly. Please see the below code to invoke the method with the provided parameters. You should be able to use your browser's console on the deployed site.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>
          {`caba.sendEvent("<AUDIENCE_KEY_TASK_TEMPLATE>", "<YOUR_PRIVATE_KEY>", {
  cabaCustomerEmail: "john.doe@example.com",
  cabaCustomerName: "John Doe",
  cabaCustomerPhone: "1234567890"
})`}
        </Highlight>


      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Show site widget API</Typography>

      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to display the site widget. After executing this code, your widget will be visible.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`window.widget.show()`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Hide site widget API</Typography>

      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to hide the site widget. After performing this action, your widget will be hidden and won't reappear automatically until you trigger the 'show widget' API.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`window.widget.hide()`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Popup widget API</Typography>

      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to directly display a pop up widget that contains hook video and clienteling form.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`cabaPopup.open({hookVideo: true, designAdvice: true, customerSupport: true})`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to directly display a pop up widget that contains hook video and design advice of clienteling form.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`cabaPopup.open({hookVideo: true, designAdvice: true, customerSupport: false})`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to directly display a pop up widget that contains hook video and customer support of clienteling form.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`cabaPopup.open({hookVideo: true, designAdvice: false, customerSupport: true})`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to directly display a pop up widget that contains only clienteling form.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`cabaPopup.open({hookVideo: false, designAdvice: true, customerSupport: true})`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to directly display a pop up widget that contains only design advice of clienteling form.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`cabaPopup.open({hookVideo: false, designAdvice: true, customerSupport: false})`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to directly display a pop up widget that contains only customer support of clienteling form.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`cabaPopup.open({hookVideo: false, designAdvice: false, customerSupport: true})`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Show popup design advice form API</Typography>

      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to directly display design advice in client telling. After executing this code, your design advice form will be visible.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`cabaPopup.openDesignAdvice()`}</Highlight>
      </Box>
    </Box>

    <Box mb={2}>
      <Typography variant='caption'>Show popup customer support form API</Typography>

      <Typography variant='h4' my={0.5}>You can utilize this API within the browser's console to directly display customer support in client telling. After executing this code, your customer support form will be visible.</Typography>

      <Box sx={testingApi}>
        <Highlight className='javascript'>{`cabaPopup.openCustomerSupport()`}</Highlight>
      </Box>
    </Box>
  </Box>
)
