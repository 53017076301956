import { Box } from '@mui/material';
import { FC } from 'react'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { MassonaryImageBox, VideoPlayIcon } from '../../theme/styleComponents'
import palette from '../../theme/palette'
import { ImageBoxProps } from '../../interfaceTypes';
import { images } from '../../../assets/images';
import { flexCenter } from '../../theme/styleConstants';
import { CustomLazyImage } from '../common/CustomLazyImage';

export const PreviewImageBoxMobile: FC<ImageBoxProps> = ({ marginBottom, borderRadius, imageUrl, width, height }) => {
  const isImageUrl = imageUrl === images.DUMMY

  return (
    <MassonaryImageBox height={height} width={width} marginBottom={marginBottom} border={isImageUrl ? `1px solid ${palette.lightBorderColorTwo}` : ''}>
      <Box borderRadius={borderRadius} overflow="hidden" height='100%' width='100%' sx={flexCenter}>
        <CustomLazyImage
          isImageUrl={isImageUrl}
          imageUrl={imageUrl}
        />
      </Box>

      {!isImageUrl &&
        <VideoPlayIcon>
          <PlayCircleIcon sx={{ color: palette.white, width: '30px', height: '30px' }} />
        </VideoPlayIcon>
      }
    </MassonaryImageBox>
  )
}
