import { Box, Card } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Maybe, ScheduleMeeting, useGetAllScheduleMeetingsLazyQuery } from "../../../generated";
import { BRAND_ID, GRAPHQL_QUERY_POLICY } from "../../constants";
import { AppContext } from "../../context";
import { ActionType } from "../../context/AppContextReducer";
import { VideoListingObject } from "../../interfaceTypes";
import { overLayWrapper } from "../../theme/styleConstants";
import { CustomPagination } from "../common/CustomPagination";
import { NoDataFound } from "../common/NoDataFound";
import { VideoCardComponent } from "./VideoCardComponent";
import { VideoListSkeleton } from "./VideoListSkeleton";
import { VideoSearchFilters } from "./VideoSearchFilters";
import { VideoSearchListing } from "./VideoSearchListing";

/**
 * Component for displaying Video Cards.
 * @returns {JSX.Element}
 */
export const VideoListingComponent: FC = (): JSX.Element => {
  const [scheduleMeetings, setScheduleMeetings] = useState<Maybe<Array<Maybe<ScheduleMeeting>>>>([])
  const brandId = localStorage.getItem(BRAND_ID);
  const { pageLimit, currentPage, dispatch } = useContext(AppContext)
  const [videoSearchKeys, setVideoSearchKeys] = useState<VideoListingObject>({ searchText: '', keyword: '', operatorId: '', widgetType: null })
  const { operatorId, keyword, widgetType, searchText } = videoSearchKeys || {}

  const [getAllMeetings, { loading }] = useGetAllScheduleMeetingsLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onCompleted(data) {
      const { getAllScheduleMeetings: { scheduleMeetings, pagination } } = data

      if (pagination) {
        const { totalPages } = pagination

        totalPages && dispatch({ type: ActionType.SET_TOTAL_PAGE_COUNT, totalPageCount: totalPages })
      }
      setScheduleMeetings(scheduleMeetings || [])
    }
  })

  useEffect(() => {
    if (brandId && !searchText) {
      getAllMeetings({
        variables: {
          allScheduleMeetingInput: {
            brandId,
            ...(widgetType && { widgetType }),
            operatorId,
            customerNameOrMail: !!keyword ? keyword : undefined,
            paginationOptions: {
              page: currentPage,
              limit: pageLimit
            }
          }
        }
      })
    }
    // eslint-disable-next-line 
  }, [currentPage, operatorId, keyword, widgetType])

  useEffect(() => {
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
    // eslint-disable-next-line
  }, [])

  const isEmpty = scheduleMeetings?.length === 0 && !loading

  return (
    <>
      <VideoSearchFilters videoSearchKeys={videoSearchKeys} setVideoSearchKeys={setVideoSearchKeys} loading={loading} />

      <Box position="relative">
        {
          !!searchText?.length &&
          <VideoSearchListing videoSearchKeys={videoSearchKeys} />
        }

        {!!!searchText?.length &&
          <>
            {loading ?
              <VideoListSkeleton numberOfMap={3} />
              :
              <Card>
                {isEmpty ?
                  <NoDataFound height='calc(100vh - 300px)' description='Record not found' />
                  :
                  <Box mt='15px'>
                    <Box maxHeight="calc(100vh - 400px)" overflow="auto">
                      {scheduleMeetings?.map((scheduleMeeting, index) => {
                        const { attachments, id } = scheduleMeeting || {}

                        return (
                          <Box display="flex" alignItems="center" position='relative' mb='20px' key={`scheduleMeetings-${index}`}>
                            {attachments?.length === 0 &&
                              <Box sx={overLayWrapper} />
                            }

                            <Link style={{ color: 'inherit', flex: '1' }} to={`/brand/${brandId}/recorded-videos/${id}`}>
                              <VideoCardComponent scheduleMeeting={scheduleMeeting} />
                            </Link>
                          </Box>
                        )
                      })}
                    </Box>

                    <CustomPagination loading={loading} />
                  </Box>
                }
              </Card>
            }
          </>
        }
      </Box>
    </>
  )
}