// component import
import { AuthLayout } from "../../../components/auth/Layout";
import { LoginForm } from "../../../components/auth/LoginForm";
// other imports
import { LOG_IN } from "../../../constants";

export const Login = (): JSX.Element => (
  <AuthLayout title={LOG_IN}>
    <LoginForm />
  </AuthLayout>
)