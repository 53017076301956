import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Collapse, DialogActions, Grid, IconButton, Typography } from '@mui/material';
import { FC, Reducer, useContext, useEffect, useReducer, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player';
import { PlayerIcon } from '../../../../assets/images';
import { AttachmentType, VideoOrientation } from '../../../../generated';
import { ACCEPT_FOR_IMAGES, DESCRIPTION_FOR_IMAGE, IMAGE_MAX_SIZE } from '../../../constants';
import { AppContext } from '../../../context';
import { ActionType } from '../../../context/AppContextReducer';
import { BrandState, EditVideoForKioskProps, VideoSettingProps } from '../../../interfaceTypes';
import palette from '../../../theme/palette';
import { PreviewBox } from '../../../theme/styleComponents';
import { CustomModal } from '../../common/CustomModal';
import { UploadDocument } from '../../common/UploadDocument';
import { BrandAction, BrandActionType, brandInitialState, brandReducer } from '../reducer/brandReducer';
import { SampleFileContent } from './SampleFileContent';

export const EditVideoForKiosk: FC<EditVideoForKioskProps> = ({ videoForFeatureImage, widgetData, refetch, setVideoForFeatureImage, }) => {
  const [videoSettings, setVideoSettings] = useState<VideoSettingProps>({ url: "", played: 0, loaded: 0, pip: false, playing: false, playbackRate: 1 });
  const { dispatch, isEdit } = useContext(AppContext)
  const [brandState, brandDispatch] = useReducer<Reducer<BrandState, BrandAction>>(brandReducer, brandInitialState);
  const { featureImageEdit, bannerVideoImageEdit } = brandState

  const handleFeatureImageEdit = () => { brandDispatch({ type: BrandActionType.SET_FEATURE_IMAGE_EDIT, featureImageEdit: !featureImageEdit }) }
  const handleVideoBannerImageEdit = () => { brandDispatch({ type: BrandActionType.SET_BANNER_VIDEO_IMAGE_EDIT, bannerVideoImageEdit: !bannerVideoImageEdit }) }
  const { id: videoId, thumbnailId, url: videoDataUrl, featureImage, bannerImage } = videoForFeatureImage || {}
  const { url: featureImageURL, id: featureImageId } = featureImage || {}
  const featureImageURLArray = featureImageURL?.split('/upload')
  const updatedFeatureImageURLArray = featureImageURLArray && [...featureImageURLArray.slice(0, 1), '/upload/q_auto', ...featureImageURLArray.slice(1)]
  const updatedFeatureImageURL = updatedFeatureImageURLArray?.join('')
  const { url: BannerImageURL, id: BannerImageId } = bannerImage || {}
  const BannerImageURLArray = BannerImageURL?.split('/upload')
  const updatedBannerImageURLArray = BannerImageURLArray && [...BannerImageURLArray.slice(0, 1), '/upload/q_auto', ...BannerImageURLArray.slice(1)]
  const updatedBannerImageURL = updatedBannerImageURLArray?.join('')

  const { url, pip, playing } = videoSettings

  const handlePlay = () => {
    setVideoSettings({ ...videoSettings, playing: true })
  }

  const onProgressVideo = (event: any) => {
    if (event.playedSeconds > 6) {
      setVideoSettings({ ...videoSettings })
    }
  }

  const loadUrl = (url: string) => {
    setVideoSettings({
      url, played: 0, loaded: 0, pip: false, playing: false
    })
  }

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: false })
  };

  const { thumbnail, videoOrientation } = videoForFeatureImage || {}
  const { url: thumbnailUrl } = thumbnail || {}
  const thumbnailUrlArray = thumbnailUrl?.split('/upload')
  const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
  const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')

  useEffect(() => {
    videoDataUrl && loadUrl(videoDataUrl)
    // eslint-disable-next-line
  }, [videoDataUrl, thumbnailId])

  const onFinishClick = () => {
    refetch && refetch()
    handleClose()
  }

  const isLandscape = videoOrientation === VideoOrientation.Landscape

  return (
    <CustomModal title="Edit Video" maxWidth="1100px" isOpen={isEdit} handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Box paddingTop={updatedFeatureImageURL ? '56px' : '0'}>
            <Box sx={{ backgroundColor: isLandscape ? 'transparent' : palette.offWhite }} paddingTop={isLandscape ? '56.55%' : '268.11px'} position='relative' mb={2}>
              <Box position='absolute' top='0' width='100%' height='100%'>
                <Box marginX='auto' width={isLandscape ? '100%' : 148} height='100%'>
                  <ReactPlayer
                    className='react-player'
                    width='100%'
                    height='100%'
                    url={url}
                    pip={pip}
                    controls={true}
                    style={{ objectFit: 'cover' }}
                    playing={playing}
                    muted={true}
                    onClickPreview={handlePlay}
                    playIcon={<PlayerIcon />}
                    light={updatedThumbnailUrl || ""}
                    onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
                    onProgress={onProgressVideo}
                    playsinline
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <Box>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant="subtitle1">Feature Image</Typography>

              {updatedFeatureImageURL &&
                <IconButton color="primary" size="large" onClick={handleFeatureImageEdit}>
                  {featureImageEdit ? <ArrowBackIcon /> : <EditIcon />}
                </IconButton>
              }
            </Box>

            <Collapse in={featureImageEdit}>
              <Box pt={1}>
                <UploadDocument
                  maxSize={IMAGE_MAX_SIZE}
                  isKiosk
                  recommendedSize='Recommended size (300px * 600px)'
                  accept={ACCEPT_FOR_IMAGES}
                  description={DESCRIPTION_FOR_IMAGE}
                  entityType="widgets"
                  typeId={videoId || ''}
                  widgetData={widgetData}
                  attachmentType='featureImage'
                  isLandscape
                  attachmentId={featureImageId || undefined}
                  metaType="attachment"
                  setVideoForFeatureImage={setVideoForFeatureImage}
                  videoForFeatureImage={videoForFeatureImage}
                />
              </Box>

              <Box pt={1}>
                <PreviewBox sx={{ minHeight: '100px' }}>
                  <LazyLoadImage
                    src={updatedFeatureImageURL || ""}
                    style={{ maxHeight: "295px", maxWidth: '295px' }}
                  />
                  <Box component='img' />
                </PreviewBox>
              </Box>
            </Collapse>

            <Collapse in={!featureImageEdit}>
              {!updatedFeatureImageURL ?
                <Box>
                  <UploadDocument
                    maxSize={IMAGE_MAX_SIZE}
                    isKiosk
                    recommendedSize='Recommended size (300px * 600px)'
                    accept={ACCEPT_FOR_IMAGES}
                    description={DESCRIPTION_FOR_IMAGE}
                    entityType="widgets"
                    typeId={videoId || ''}
                    attachmentType='featureImage'
                    attachmentId={featureImageId || undefined}
                    isLandscape
                    metaType="attachment"
                    widgetData={widgetData}
                    setVideoForFeatureImage={setVideoForFeatureImage}
                    videoForFeatureImage={videoForFeatureImage}
                  />
                </Box>
                :
                <Box pt={1}>
                  <PreviewBox sx={{ minHeight: '100px' }}>
                    <LazyLoadImage
                      src={updatedFeatureImageURL || ""}
                      style={{ maxHeight: "295px", maxWidth: '295px' }}
                    />
                    <Box component='img' />
                  </PreviewBox>
                </Box>
              }
            </Collapse>

            <SampleFileContent attachmentType={AttachmentType.FeatureImage} description='Here’s the sample file for feature image.' />
          </Box>

          <Box>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant="subtitle1">Banner Image</Typography>

              {updatedBannerImageURL &&
                <IconButton color="primary" size="large" onClick={handleVideoBannerImageEdit}>
                  {bannerVideoImageEdit ? <ArrowBackIcon /> : <EditIcon />}
                </IconButton>
              }
            </Box>

            <Collapse in={bannerVideoImageEdit}>
              <Box pt={1}>
                <UploadDocument
                  maxSize={IMAGE_MAX_SIZE}
                  isKiosk
                  recommendedSize='Recommended size (640px * 72px)'
                  accept={ACCEPT_FOR_IMAGES}
                  description={DESCRIPTION_FOR_IMAGE}
                  entityType="widgets"
                  typeId={videoId || ''}
                  widgetData={widgetData}
                  attachmentType='bannerImage'
                  isLandscape
                  attachmentId={BannerImageId || undefined}
                  metaType="attachment"
                  setVideoForFeatureImage={setVideoForFeatureImage}
                  videoForFeatureImage={videoForFeatureImage}
                />
              </Box>

              <Box pt={1}>
                <PreviewBox sx={{ minHeight: '100px' }}>
                  <LazyLoadImage
                    src={updatedBannerImageURL || ""}
                    style={{ maxHeight: "295px", maxWidth: '295px' }}
                  />
                  <Box component='img' />
                </PreviewBox>
              </Box>
            </Collapse>

            <Collapse in={!bannerVideoImageEdit}>
              {!updatedBannerImageURL ?
                <Box>
                  <UploadDocument
                    maxSize={IMAGE_MAX_SIZE}
                    isKiosk
                    recommendedSize='Recommended size (640px * 72px)'
                    accept={ACCEPT_FOR_IMAGES}
                    description={DESCRIPTION_FOR_IMAGE}
                    entityType="widgets"
                    typeId={videoId || ''}
                    attachmentType='bannerImage'
                    attachmentId={BannerImageId || undefined}
                    isLandscape
                    metaType="attachment"
                    widgetData={widgetData}
                    setVideoForFeatureImage={setVideoForFeatureImage}
                    videoForFeatureImage={videoForFeatureImage}
                  />
                </Box>
                :
                <Box pt={1}>
                  <PreviewBox sx={{ minHeight: '100px' }}>
                    <LazyLoadImage
                      src={updatedBannerImageURL || ""}
                      style={{ maxHeight: "295px", maxWidth: '295px' }}
                    />
                    <Box component='img' />
                  </PreviewBox>
                </Box>
              }
            </Collapse>

            <SampleFileContent attachmentType={AttachmentType.BannerImage} description='Here’s the sample file for banner image.' />
          </Box>
        </Grid>
      </Grid>
      <DialogActions sx={{ paddingRight: '0' }}>
        <Button type='button' variant="contained" color="primary" onClick={onFinishClick}>Finish</Button>
      </DialogActions>
    </CustomModal>
  )
}
