import React, { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { AppContext } from '../../context';
import { Box, Button, Chip, CircularProgress, Collapse, Grid, IconButton, Typography } from '@mui/material';
import Tags from '../taskTemplate/Tags';
import { firstLetterUppercase } from '../../utils';
import { Alert } from '../common/Alert';
import { useUpdateBrandTagsMutation } from '../../../generated';
import { ActionType } from '../../context/AppContextReducer';

export const BrandTags = () => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const { selectedBrand, dispatch } = useContext(AppContext)
  const { id: brandId, name, createdAt, updatedAt, attachments, gorgiasTags } = selectedBrand || {}
  const gorgiasTagsValue = gorgiasTags !== "" ? gorgiasTags?.split(', ') : []

  const methods = useForm({
    mode: "all",

    defaultValues: {
      gorgiasTags: gorgiasTagsValue || [],
    }
  });

  const { handleSubmit } = methods;

  const [updateBrandTags, { loading }] = useUpdateBrandTagsMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { updateBrandTags } = data
        const { gorgiasTags: updatedGorgiasTags } = updateBrandTags

        const updatedBrand = {
          ...selectedBrand,
          id: brandId || "",
          name: name || "",
          attachments: attachments || [],
          createdAt,
          updatedAt,

          gorgiasTags: updatedGorgiasTags
        }
        if (updateBrandTags) {
          dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrand })
          return Alert.success("Tags updated successfully")
        }
      }
    }
  })

  const onSubmit = async (data: any) => {
    const { gorgiasTags } = data
    const tagsValue = gorgiasTags.length > 0 ? gorgiasTags.join(', ') : ""

    if (brandId) {
      await updateBrandTags({
        variables: {
          updateBrandTags: {
            brandId,
            gorgiasTags: tagsValue,
          }
        }
      })
    }
  }


  return (
    <Box sx={{ mt: "2rem" }}>
      <Box display='flex' justifyContent='flex-end' alignItems="center">
        <IconButton color="primary" size="large" onClick={() => { setIsEditOpen(!isEditOpen) }}>
          {isEditOpen ? <ArrowBackIcon /> : <EditIcon />}
        </IconButton>
      </Box>

      <Collapse in={!isEditOpen}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item sm={6} xs={12}>
            <Box>
              <Typography variant='body2'>Tags</Typography>
              {gorgiasTags ?
                <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>
                  {gorgiasTags.split(', ').map(gorgiasTags => (
                    <Chip key={gorgiasTags} color='primary' sx={{ marginRight: '5px', marginBottom: '10px' }} label={gorgiasTags} />
                  ))}
                </Typography>
                :
                <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>N/A</Typography>
              }
            </Box>
          </Grid>
        </Grid>

      </Collapse>

      <Collapse in={isEditOpen}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item sm={6} xs={12}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Tags controllerName='gorgiasTags' />

                <Box display="flex" justifyContent="flex-end">
                  <Button sx={{ marginTop: '1rem' }} type='submit' variant='contained' color='primary'
                    disabled={loading} endIcon={loading && <CircularProgress size={20} color="inherit" />}>
                    Update
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}
