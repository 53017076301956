import { Box, Button, CircularProgress, Typography } from '@mui/material'
import axios from 'axios'
import { FC, useState } from 'react'
import { SOMETHING_WENT_WRONG } from '../../constants'
import { ConfirmationLoaderProps } from '../../interfaceTypes'
import { getToken } from '../../utils'
import { Alert } from '../common/Alert'
import { CustomModal } from '../common/CustomModal'

export const ConfirmationToSendVideo: FC<ConfirmationLoaderProps> = ({ isOpen, handleClose, videoData, refetchJob, attachmentId, entity, resetForm }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const token = getToken()

  const sendVideo = async () => {
    const formData = new FormData();
    const { title, description, thumbnailId, type, file, attachmentType, typeId, metaType, videoOrientation } = videoData || {}
    const orientationToSend = videoOrientation?.toLowerCase()

    if (file) {
      attachmentId && formData.append('id', attachmentId)
      typeId && formData.append('typeId', typeId);
      type && formData.append("type", type);
      title && formData.append('title', title);
      description && formData.append('description', description);
      thumbnailId && formData.append('thumbnailId', thumbnailId);
      attachmentType && formData.append('attachmentType', attachmentType);
      metaType && formData.append('metaType', metaType);
      formData.append("file", file);
      formData.append('videoOrientation', orientationToSend || "")

      try {
        setIsLoading(true)
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/${entity}/upload-file`,
          formData,
          {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        )

        const { data, status } = response

        if (status === 201 && data) {
          const { status: statusInData, message } = data

          if (statusInData === 200) {
            Alert.success(message);
          } else {
            Alert.success('File uploaded successfully');
          }

          setIsLoading(false)
          refetchJob && refetchJob()
          resetForm()
          handleClose()
        }
      }

      catch (error) {
        setIsLoading(false)
        console.log('>>>>>>>>>>>>> error >>>>>>>>>>>>>', error)
        Alert.error(SOMETHING_WENT_WRONG);
      }
    }
  }

  return (
    <CustomModal maxWidth="700px" isOpen={isOpen} handleClose={handleClose} title="Send Video">
      <Box>
        <Typography variant="h4" marginTop={2} marginBottom={3} paddingLeft={0.5}>Are you sure you want to send this video to customer?</Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button sx={{ marginLeft: '10px' }} onClick={handleClose}>Cancel</Button>

        <Button onClick={sendVideo} variant="contained" color="primary" disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} color="inherit" />}>
          Send
        </Button>
      </Box>
    </CustomModal>
  )
}
