import { Box, Typography, Switch } from "@mui/material"
import { FC } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { DISABLE, ENABLE } from "../../../constants";
import { CustomSwitcherInterface } from "../../../interfaceTypes";

export const CustomSwitcher: FC<CustomSwitcherInterface> = ({ label, defaultValue, controllerName }) => {
  const { control } = useFormContext()

  return (
    <Box display='flex' alignItems="center">
      <Box>
        <Typography>{label}</Typography>
      </Box>

      <Box display='flex' alignItems='center'>
        <Controller
          control={control}
          name={controllerName}
          defaultValue={!!defaultValue}
          render={({ field }) => (
            <>
              <Switch
                sx={{ marginRight: '1rem' }}
                color="secondary"
                value={field.value}
                checked={!!field.value}
                onChange={(_event, value) => field.onChange(value)}
              />

              <Typography variant="body1">{field.value ? ENABLE : DISABLE}</Typography>
            </>
          )}
        />
      </Box>
    </Box>
  )
}
