import { Box, Button, CircularProgress, Drawer, Grid, Typography, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { useGetWidgetLazyQuery, useUpdateWidgetMutation, Widget } from "../../../../generated";
import { BRAND_ID, GENERAL_SETTINGS, GRAPHQL_QUERY_POLICY, LANDING_PAGE_WIDGET, THANKYOU_SETTINGS, WIDGET_SETTING } from "../../../constants";
import { UpdateWidget } from "../../../interfaceTypes";
import palette from "../../../theme/palette";
import { AccordionWrapper } from '../../../theme/styleComponents';
import { drawerButton, previewDrawer, stickyBottom } from "../../../theme/styleConstants";
import { firstLetterUppercase } from "../../../utils";
import { Alert } from "../../common/Alert";
import { BreadCrumb } from "../../common/BreadCrumb";
import { CustomAccordion } from "../../common/CustomAccordion";
import { ThreeDotsLoader } from "../../common/ThreeDotsLoader";
import { ButtonFields } from "../widgetCommon/ButtonFields";
import { CalendlyOverride } from "../widgetCommon/CalendlyOverride";
import { CustomColorControl } from "../widgetCommon/CustomColorControl";
import { CustomSwitcher } from "../widgetCommon/CustomSwitcher";
import { PostForm } from "../widgetCommon/PostForm";
import { LandingPageTabs } from './LandingTabs';

export const LandingPageWidget: FC = () => {
  const [widgetData, setWidgetData] = useState<Widget>();
  const { attachment: widgetLogo } = widgetData || {};
  const [searchParams] = useSearchParams();
  const widgetId = searchParams.get('widgetId');
  const [toggleDrawerState, setToggleDrawerState] = useState(false);
  const mediumViewport = useMediaQuery('(max-width:500px)');
  const brandId = localStorage.getItem(BRAND_ID);

  const [getWidget, { loading: widgetDataLoading }] = useGetWidgetLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() { },

    onCompleted(data) {
      const { getWidget } = data || {}

      if (getWidget) {
        setWidgetData(getWidget)
      }
    }
  });

  const [updateWidget, { loading }] = useUpdateWidgetMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { updateWidget } = data

        if (updateWidget) {
          const updatedWidget = {
            ...updateWidget,
            attachment: widgetLogo
          }

          reset({}, { keepValues: true, keepIsValid: true });
          setWidgetData(updatedWidget)

          return Alert.success("Widget updated successfully")
        }
      }
    }
  })

  useEffect(() => {
    if (!widgetData && widgetId && brandId) {
      getWidget({
        variables: {
          getWidgetInput: {
            id: widgetId,
            brandId
          },
          widgetId
        }
      })
    }
    //eslint-disable-next-line
  }, [widgetData, widgetId])

  const {
    name, customPlayerSettings, buttonText,
    playVideoAfterSeconds, heading, headingColor, description, descriptionColor,
    backgroundColor, buttonBackgroundColor, buttonColor, showOperator, postGreetMessage, nameLabel, emailLabel, phoneLabel, helperTextSupport, gorgiasSupportTicketMessage, gorgiasSupportTicketSubject,
    calenderUrl, gorgiasTags
  } = widgetData || {}

  const methods = useForm<UpdateWidget>({
    shouldUnregister: false,

    defaultValues: {
      heading: heading || "",
      calenderUrl: calenderUrl || "",
      headingColor: headingColor || palette.black,
      descriptionColor: descriptionColor || palette.black,
      buttonText: buttonText || "",
      buttonBackgroundColor: buttonBackgroundColor || palette.black,
      buttonColor: buttonColor || palette.white,
      playVideoAfterSeconds: playVideoAfterSeconds || 0,
      name: name || "",
      showOperator,
      gorgiasTags: gorgiasTags?.split(', ') || [],
      postGreetMessage
    }
  });

  const { setValue, handleSubmit, control, formState: { isDirty }, reset } = methods;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    heading && setValue('heading', heading);
    headingColor && setValue('headingColor', headingColor);
    descriptionColor && setValue('descriptionColor', descriptionColor);
    buttonText && setValue('buttonText', buttonText);
    buttonColor && setValue('buttonColor', buttonColor);
    buttonBackgroundColor && setValue('buttonBackgroundColor', buttonBackgroundColor);
    name && setValue('name', name);
    setValue('showOperator', showOperator);
    postGreetMessage && setValue('postGreetMessage', postGreetMessage)
    nameLabel && setValue('nameLabel', nameLabel)
    emailLabel && setValue('emailLabel', emailLabel)
    phoneLabel && setValue('phoneLabel', phoneLabel)
    helperTextSupport && setValue('helperTextSupport', helperTextSupport)
    gorgiasSupportTicketMessage && setValue('gorgiasSupportTicketMessage', gorgiasSupportTicketMessage)
    gorgiasSupportTicketSubject && setValue('gorgiasSupportTicketSubject', gorgiasSupportTicketSubject)
    calenderUrl && setValue('calenderUrl', calenderUrl)
    if (gorgiasTags) {
      setValue("gorgiasTags", gorgiasTags.split(', '))
    }
    //eslint-disable-next-line
  }, [
    name, customPlayerSettings, playVideoAfterSeconds,
    headingColor, description, descriptionColor,
    backgroundColor, buttonColor, showOperator, postGreetMessage, buttonColor, showOperator, calenderUrl, gorgiasTags
  ])

  type Anchor = 'bottom';

  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setToggleDrawerState(!toggleDrawerState);
  };

  const onSubmit = async (data: UpdateWidget) => {
    if (widgetId && brandId) {
      const { playVideoAfterSeconds: videoSeconds, gorgiasTags } = data

      await updateWidget({
        variables: {
          updateWidgetInput: {
            ...data,
            playVideoAfterSeconds: videoSeconds ? Number.parseFloat(videoSeconds?.toString()) : 0,
            gorgiasTags: gorgiasTags.join(', '),
            id: widgetId,
            brandId
          },
          widgetId,
        }
      })
    }
  }

  const showOperatorValue = useWatch({ control, name: 'showOperator' });

  return (
    <>
      {widgetDataLoading ?
        <ThreeDotsLoader height="calc(100vh - 300px)" />
        :
        <Box>
          <Typography sx={{ marginTop: 1 }} variant="subtitle1">{LANDING_PAGE_WIDGET}</Typography>

          <Box display='flex' justifyContent='space-between' position="relative" alignItems='center'>
            <BreadCrumb currentPage={LANDING_PAGE_WIDGET} previousPage={WIDGET_SETTING} />
          </Box>

          <FormProvider  {...methods}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1.2} rowSpacing={0.5}>
                <Grid item md={6} sm={12} xs={12}>
                  <CustomAccordion expanded={true} label={GENERAL_SETTINGS} id="landingPageWidgetSetting">
                    <CustomColorControl name="headingColor" label="Heading Color" />

                    <CustomColorControl name="descriptionColor" label="Description color" />

                    <Box marginTop='2rem'>
                      <CustomSwitcher label="Operator" controllerName="showOperator" defaultValue={!!showOperator} />
                    </Box>
                  </CustomAccordion>

                  <CalendlyOverride />

                  <CustomAccordion expanded={true} label="Button" id="text">
                    <ButtonFields />
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label={THANKYOU_SETTINGS} id="postFormSettings">
                    <PostForm />
                  </CustomAccordion>

                  <Box sx={stickyBottom}>
                    <Button color="primary" variant='contained' type="submit" fullWidth
                      endIcon={loading && <CircularProgress size={20} color="inherit" />}
                      disabled={loading || !isDirty}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <AccordionWrapper position='sticky' top="145px">
                    <LandingPageTabs showOperator={!!showOperatorValue} />
                  </AccordionWrapper>

                  {mediumViewport && (
                    <Box>
                      <Button sx={previewDrawer} onClick={() => toggleDrawer('bottom', true)}>
                        <Box sx={drawerButton}>
                          <Typography variant="subtitle2" sx={{ color: palette.white, textTransform: 'capitalize' }}>Preview</Typography>
                        </Box>
                      </Button>

                      <Drawer
                        anchor='bottom'
                        open={toggleDrawerState}
                        onClose={() => toggleDrawer('bottom', false)}
                      >
                        <Box paddingTop={1}>
                          <LandingPageTabs showOperator={!!showOperatorValue} />
                        </Box>
                      </Drawer>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      }
    </>
  )
}
