//packages block
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
// component block
import { CustomController } from "../common/CustomController"
// others
import { AUTH_LINKS, RESET_PASSWORD_SUCCESS, SAVE } from "../../constants";
import { resetPasswordValidationSchema } from "../../validationSchema";
import { useVerifyResetPasswordTokenAndSetPasswordMutation, useVerifyResetPasswordTokenMutation } from "../../../generated";
import { Alert } from "../common/Alert";
import { firstLetterUppercase } from "../../utils";
import { ResetPasswordProps } from "../../interfaceTypes";

export const SetPasswordForm: FC = (): JSX.Element => {

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')

  const methods = useForm<ResetPasswordProps>({
    mode: "all",
    resolver: yupResolver(resetPasswordValidationSchema),
    defaultValues: {
      password: "",
      repeatPassword: ""
    }
  });

  const { handleSubmit } = methods;

  const [verifyToken, { loading: vLoading }] = useVerifyResetPasswordTokenMutation({
    onError({ message }) {
      Alert.error(firstLetterUppercase(message || ''))
      navigate('/login')
    }
  })

  const [resetPassword, { loading }] = useVerifyResetPasswordTokenAndSetPasswordMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data.verifyResetPasswordTokenAndSetPassword) {
        Alert.success(RESET_PASSWORD_SUCCESS)
        navigate('/login')
      }
    }
  })

  const onSubmit = async (data: any) => {
    await resetPassword({
      variables: {
        resetPasswordInput: {
          password: data.password,
          token: token || ""
        }
      }
    })
  }

  useEffect(() => {
    verifyToken({
      variables: {
        token: token || ""
      }
    })
  }, [token, verifyToken])

  if (vLoading) {
    return <Box marginBottom='20px' marginTop='1rem' ><CircularProgress /></Box>
  }

  return (
    <Box marginTop='1rem' width='100%'>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box>
            <CustomController
              controllerName='password'
              controllerLabel='Password'
              fieldType='password'
              isPassword
            />
          </Box>
          <Box>
            <CustomController
              controllerName='repeatPassword'
              controllerLabel='Re enter your password'
              fieldType='password'
              isPassword
            />
          </Box>

          <Box marginBottom='20px' marginTop='1rem' >
            <Typography component={Link} to='/login' color="primary" variant="h4">
              Back to Login
            </Typography>
          </Box>

          <Button variant="contained" type="submit" fullWidth color="primary" disabled={loading}
            endIcon={loading && <CircularProgress size={20} color="inherit" />}>
            {SAVE}
          </Button>

          <Box marginTop={3} display='flex' justifyContent='center' alignItems='center'>
            <Typography variant='h4' sx={{ marginRight: '5px' }}>Already have an account?</Typography>

            <Typography component={Link} to={AUTH_LINKS.LOGIN_LINK} color="primary" variant="h4">
              Login
            </Typography>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}