import * as yup from "yup";
import { ALPHABETS_REGEX, BUTTON_TEXT, INVALID_EMAIL, PAGE_HEADING, PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE, phoneReg, SPACES_REGEX } from "../constants";
import { maxLength, minLength, requiredMessage, validMessage, validMessageForName } from "../utils";

const passwordValidationSchema = { password: yup.string().required(requiredMessage("Password")), }

const emailValidationSchema = {
  email: yup.string().email(INVALID_EMAIL).required(requiredMessage("Email")),
}

const emailSchema = (fieldName: string) => yup.string().email(INVALID_EMAIL).required(requiredMessage(fieldName))

export const roleTypeSchema = {
  roleType: yup.string().required().nullable()
}

const passwordAndRepeatPasswordSchema = {
  password: yup.string().required(requiredMessage("Password")).matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
  repeatPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match.").required("Confirm your Password."),
}

const passwordAndSetPasswordSchema = {
  oldPassword: yup.string().required(requiredMessage("Current Password")).matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
  newPassword: yup.string().required(requiredMessage("New Password")).matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
  repeatPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Passwords must match.").required("Confirm your Password."),
}

export const newPasswordAndRepeatPasswordSchema = yup.object({
  newPassword: yup.string().required(requiredMessage("Password")).matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
  repeatPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Passwords must match.").required("Confirm your Password."),
})

export const loginValidationSchema = yup.object({
  ...emailValidationSchema,
  ...passwordValidationSchema
});

export const forgotPasswordValidationSchema = yup.object({ ...emailValidationSchema })

export const resetPasswordValidationSchema = yup.object({
  ...passwordAndRepeatPasswordSchema,
})

export const setPasswordValidationSchema = yup.object({
  ...passwordAndSetPasswordSchema,
})

const fullNameSchema = { fullName: yup.string().min(2, minLength("Full name", 2)).max(100, maxLength("Full name", 100)).matches(ALPHABETS_REGEX, validMessageForName("special characters, digits and spaces at the end are not allowed")).required(requiredMessage("Full name")) }
const roleValidationSchema = { roleType: yup.string().required().nullable() }
const tagLineSchema = { tagLine: yup.string().min(2, minLength("Tagline", 2)).max(100, maxLength("Tagline", 100)).matches(SPACES_REGEX, validMessage("Tagline")) }

export const inviteNewStaffSchema = (isAdmin: boolean) => yup.object({
  ...emailValidationSchema,
  ...roleValidationSchema,

  customBrandId: !isAdmin ? yup.object().shape({
    id: yup.string().required(requiredMessage("Brand")),
    label: yup.string()
  }) : yup.object().notRequired(),
})

export const registerUserValidationSchema = yup.object({
  ...fullNameSchema,
  ...emailValidationSchema,
  ...roleValidationSchema,
  ...passwordAndRepeatPasswordSchema,
  ...tagLineSchema,
})

export const calendlySchema = yup.object({
  calenderUrl: yup.string().required(requiredMessage('Please enter url'))
})

export const updateFullNameSchema = yup.object({
  phoneNumber: yup.string().matches(phoneReg, 'Phone number is not valid'),
  ...fullNameSchema,
  ...tagLineSchema
})

const brandSchema = () => yup.string().min(2, minLength("Brand Name", 2)).max(100, maxLength("Brand Name", 100)).matches(SPACES_REGEX, validMessage("Brand Name")).required(requiredMessage("Brand Name"))

export const createBrandValidationSchema = yup.object({
  name: brandSchema(),
  supportEmail: yup.string().email(INVALID_EMAIL).required(requiredMessage("Support Email"))
})

const templateInfoSchema = {
  name: yup.string().min(2, minLength("Name", 2)).max(100, maxLength("Name", 100)).required(requiredMessage("Name")),
  description: yup.string().min(2, minLength("Description", 2)).max(256, maxLength("Description", 256)).required(requiredMessage("Description")),
  subject: yup.string().min(2, minLength("Subject", 2)).max(256, maxLength("Subject", 256)).required(requiredMessage("Subject")),
}

const templateSegmentSchema = {
  segmentAudienceKey: yup.string().min(2, minLength("Audience key", 2)).max(100, maxLength("Audience key", 100)).required(requiredMessage("Audience key")),
  smsText: yup.string().required(requiredMessage('Sms text'))
}

const templateJobSchema = {
  postGreetMessage: yup.string().min(2, minLength("Post greet message", 2)).max(500, maxLength("postGreetMessage", 500)).required(requiredMessage("postGreetMessage")),
  postGreetTitle: yup.string().min(2, minLength("Post greet title", 2)).max(256, maxLength("postGreetTitle", 256)).required(requiredMessage("postGreetTitle")),
  videoPageHeading: yup.string().min(2, minLength("Job Heading", 2)).max(256, maxLength("Job Heading", 256)).required(requiredMessage("Job Heading")),
  videoPageDescription: yup.string().min(2, minLength("Job Description", 2)).max(256, maxLength("Job Description", 256)).required(requiredMessage("Job Description")),
}

export const createTaskTemplateValidationSchema = [
  yup.object({
    ...templateInfoSchema
  }),

  yup.object({
    ...templateSegmentSchema
  }),

  yup.object({
    ...templateJobSchema
  })
]

export const editTaskTemplateValidationSchema = yup.object({
  ...templateInfoSchema,
  ...templateSegmentSchema,
})

export const attachmentRequiredSchema = yup.object({
  videoUrl: yup.mixed().nullable().required('Upload video to proceed')
})

export const updateVideoSchema = yup.object({
  title: yup.string().min(2, minLength("Video title", 2)).max(100, maxLength("Video title", 100)).matches(SPACES_REGEX, validMessage("Video title")).required(requiredMessage("Video title")),
  description: yup.string().min(2, minLength("Video description", 2)).matches(SPACES_REGEX, validMessage("Video description")).required(requiredMessage("Video description")),
  thumbnailId: yup.string().required(requiredMessage("Thumbnail"))
})

export const createVideoSchema = yup.object({
  title: yup.string().min(2, minLength("Video title", 2)).max(100, maxLength("Video title", 100)).matches(SPACES_REGEX, validMessage("Video title")).required(requiredMessage("Video title")),
  description: yup.string().min(2, minLength("Video description", 2)).matches(SPACES_REGEX, validMessage("Video description")).required(requiredMessage("Video description")),
  thumbnailId: yup.string().required(requiredMessage("Thumbnail"))
})

export const taskDetailUpdateVideoSchema = yup.object({
  thumbnailId: yup.string().required(requiredMessage("Thumbnail"))
})

export const brandGuideLinesSchema = (showRoutingEmail: boolean) => yup.object({
  name: brandSchema(),
  supportEmail: emailSchema('Support Email'),
  routingEmail: !showRoutingEmail ? yup.string().email(INVALID_EMAIL).required(requiredMessage("Routing Email")) : yup.string().notRequired(),
  customerLandingPageUrl: yup.string().required(requiredMessage('Please enter url')),
})

const pageHadingSchema = {
  heading: yup.string().min(2, minLength(PAGE_HEADING, 2)).max(100, maxLength(PAGE_HEADING, 50)).matches(SPACES_REGEX, validMessage(PAGE_HEADING)).required(requiredMessage(PAGE_HEADING)),
}

const buttonTextSchema = {
  buttonText: yup.string().min(2, minLength(BUTTON_TEXT, 2)).max(100, maxLength(BUTTON_TEXT, 50)).matches(SPACES_REGEX, validMessage(BUTTON_TEXT)).required(requiredMessage(BUTTON_TEXT)),
}

export const landingPageValidationSchema = yup.object({
  name: brandSchema(),
  ...pageHadingSchema,
  ...buttonTextSchema
})

export const UpdateJobValidationSchema = yup.object({
  statusMessage: yup.string().required(requiredMessage('Status is required'))
});

export const updateWidgetSchema = yup.object({
  videoGridType: yup.string().required(requiredMessage('Video grid type is required')),
  videoLayout: yup.string().required(requiredMessage('Video layout is required'))
})

export const gorgiasValidationSchema = yup.object().shape({
  gorgiasSettings: yup.object().shape({
    url: yup.string().required(requiredMessage('URL')),
    apiKey: yup.string().required(requiredMessage('Api key')),
    userName: yup.string().required(requiredMessage('User name'))
  }),
})

export const Apikeyresolver = yup.object({
  keyName: yup.string().required(requiredMessage('Key Name'))
})

export const emailAliasFormValidationSchema = yup.object({
  ...emailValidationSchema
})

export const commentValidationSchema = yup.object({
  value: yup.string().required(requiredMessage('Text'))
})