import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../../assets/images';
import { Brand } from '../../../generated';
import { BRAND_ID } from '../../constants';
import { AppContext, AuthContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { BrandSelectionInterface } from '../../interfaceTypes';
import palette from '../../theme/palette';
import { SelectBrandArea, SelectBrandWrapper } from '../../theme/styleComponents';
import { brandWrapper, addBrand, brandDummyImage, brandImage } from '../../theme/styleConstants';
import { BrandSelectionSkeleton } from '../skeletons/BrandSelectionSkeleton';
import { AddBrandModal } from './AddBrandModal';
import { DeleteBrand } from './DeleteBrand';

export const BrandSelection: FC<BrandSelectionInterface> = ({ brandState, brandDispatch, refetch }) => {
  const { isOpen, isBrandLoading, dispatch, isDeleteOpen } = useContext(AppContext)
  const { isAdmin } = useContext(AuthContext)
  const [activeBrand, setActiveBrand] = useState<Brand | null>(null)

  const handleDeleteOpen = (brand: Brand) => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: !isDeleteOpen })
    setActiveBrand(brand)
  }

  const { filteredBrandsList: brandsData } = brandState

  const handleSelectedBrand = (brand: Brand) => {
    const { id } = brand

    dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: brand })
    localStorage.setItem(BRAND_ID, id)
  }

  const handleBrandOpen = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: !isOpen })
  };

  return (
    <Box marginY='2rem'>
      {isBrandLoading ?
        <Box marginY='2rem'>
          <BrandSelectionSkeleton noOfItems={12} />
        </Box>
        :

        <Box>
          <Grid container spacing={2} rowSpacing={2}>
            {isAdmin &&
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <Button sx={addBrand} onClick={handleBrandOpen}>
                  <SelectBrandArea width="100%" sx={{ border: `1px dashed ${palette.primary.main}`, minHeight: '200px' }}>
                    <Box>
                      <AddIcon sx={{ height: '3rem', width: '3rem' }} />
                    </Box>

                    <Typography
                      variant='h6'
                      maxWidth="100%"
                      color='primary'
                      sx={{ textAlign: 'center' }}
                      noWrap
                    >
                      Add Brand
                    </Typography>
                  </SelectBrandArea>
                </Button>
              </Grid>
            }
            {brandsData.map((brand) => {
              const { id, name, logo } = brand
              const { url } = logo || {}
              const urlArray = url?.split('/upload')
              const updatedUrlArray = urlArray && [...urlArray.slice(0, 1), '/upload/q_auto', ...urlArray.slice(1)]
              const updatedUrl = updatedUrlArray?.join('')

              return (
                <Grid item lg={3} md={4} sm={12} xs={12} key={id}>
                  <Box sx={brandWrapper}>
                    <SelectBrandWrapper>
                      <Box onClick={() => handleSelectedBrand(brand)}>
                        <Box component={Link} to="/brand-settings">
                          <SelectBrandArea>
                            <Box
                              component='img'
                              src={updatedUrl || images.DUMMY_IMAGE}
                              alt='Brand Logo'
                              sx={updatedUrl ? brandImage : brandDummyImage}
                            />

                            <Tooltip title={name}>
                              <Typography
                                variant='h6'
                                maxWidth="100%"
                                sx={{ color: palette.black, textAlign: 'center' }}
                                noWrap
                              >
                                {name}
                              </Typography>
                            </Tooltip>
                          </SelectBrandArea>
                        </Box>
                      </Box>

                      {isAdmin &&
                        <Box className='brandsButton'>
                          <IconButton onClick={() => handleDeleteOpen(brand)}>
                            <DeleteIcon color='error' sx={{ width: '16px', height: '16px' }} />
                          </IconButton>
                        </Box>
                      }
                    </SelectBrandWrapper>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      }

      <AddBrandModal brandState={brandState} brandDispatch={brandDispatch} refetch={refetch} />

      <DeleteBrand
        brand={activeBrand}
        brandsData={brandsData}
        refetch={refetch}
      />
    </Box>
  )
}
