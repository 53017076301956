// packages
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import { FC, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
// component
import CloseIcon from '@mui/icons-material/Close';
import { UploadArea, UploadBox, UploadIcon } from "../../theme/styleComponents";
import { Alert } from "./Alert";
// others
import { DocumentIcon } from "../../../assets/images";
import { ACCEPT_FOR_VIDEOS, DESCRIPTION_FOR_VIDEO, VIDEO_IS_GREATER, VIDEO_IS_LARGE, VIDEO_MAX_SIZE } from "../../constants";
import { CreateVideoDocumentProps } from "../../interfaceTypes";

export const UploadVideoDocument: FC<CreateVideoDocumentProps> = ({ fieldName, setFiles, files, isLoading }) => {
  const mediumViewport = useMediaQuery('(max-width:500px)');

  const onDrop = async (acceptedFiles: File[]) => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize: VIDEO_MAX_SIZE,
    onDrop: onDrop,
    accept: ACCEPT_FOR_VIDEOS,
    multiple: false
  });

  useEffect(() => {
    fileRejections.forEach(({ errors }) => (
      errors.map((messages) => {
        const { message } = messages || {}

        if (message === VIDEO_IS_GREATER) {
          return Alert.error(VIDEO_IS_LARGE)
        }
        else {
          return Alert.error(message)
        }
      }))
    )
  }, [fileRejections])

  const RemoveFile = () => {
    setFiles([])
  }

  return (
    <>
      {files.length === 0 ?
        <Box mb={1} width='100%'>
          <UploadArea>
            <Box {...getRootProps()}>
              <input name={fieldName} {...getInputProps()} />

              <UploadBox>
                <UploadIcon>
                  <UploadFileIcon color="primary" />
                </UploadIcon>

                <Typography sx={{ marginBottom: '.5rem' }} variant="h4"><Typography component='span' color='primary'>Click to upload </Typography>or drag and drop</Typography>

                <Typography variant="body2">{DESCRIPTION_FOR_VIDEO}</Typography>
              </UploadBox>
            </Box>
          </UploadArea>
        </Box>
        :
        <Box mb={1}>
          {files.map((file, index) => {
            const { name } = file || {}

            return (
              <Box borderRadius='4px' display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" key={`${file.name}-${index}`}>
                <DocumentIcon />

                <Box maxWidth={mediumViewport ? "70%" : "85%"} flex={1}>
                  <Typography variant="body1" fontSize="16px" letterSpacing="0.17px" noWrap>{name}</Typography>
                </Box>

                <Box marginTop="5px">
                  <IconButton onClick={RemoveFile} disabled={isLoading}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            )
          })}
        </Box>
      }
    </>
  );
}