import { Box, Grid, Typography } from "@mui/material";
import { AnimatePresence, motion } from 'framer-motion';
import { FC, Reducer, createRef, useContext, useEffect, useReducer, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import KioskBannerImage from '../../../assets/images/banner-bottom.jpg';
import KioskFallbackImage from '../../../assets/images/fallback-default.jpg';
import KioskFeatureImage from '../../../assets/images/feature-image.png';
import KioskWaitingImage from '../../../assets/images/waiting-image.jpg';
import { Attachment } from "../../../generated";
import { CustomVideoPlayer } from "../../components/common/CustomVideoPlayer";
import { TimeData } from "../../components/common/TimeData";
import { KEY_STROKES, START_TIMER, TIMER_CLICK } from "../../constants";
import { SocketContext } from "../../context/SocketContext";
import { WidgetAction, WidgetActionType, WidgetState, widgetInitialState, widgetReducer } from "../../context/WidgetContextReducer";
import { ARROW_KEYS, KioskUserStatus, KioskWidgetFocusInterface, ReactPlayerForwardRef } from "../../interfaceTypes";
import { KioskFocusWidget, KioskWaitingImageText, KioskWidgetFeature, KioskWidgetFeatureInner } from "../../theme/styleComponents";
import { KioskImageWrapper } from "../KioskImageWrapper";
import { KioskWidgetBannerImage } from "../KioskWidgetBannerImage";
import { WhereBy } from "../Whereby";

const KioskWidgetFocus: FC<KioskWidgetFocusInterface> = ({ state, dispatch, toggleKioskWidget }) => {
  const { mutateKioskState, joiningData, hangUpData, isConnected, inCall } = useContext(SocketContext)
  const [focusState, focusDispatch] = useReducer<Reducer<WidgetState, WidgetAction>>(widgetReducer, widgetInitialState)
  const { kioskStatus, fallBack, waiting } = focusState
  const enterCount = useRef(0)
  const [activeSlide, setActiveSlide] = useState<number>(0)
  const idleSliderRef = useRef<Slider>(null)
  const arrowKeyPressed = useRef<boolean>(false)
  const currentVideoIndexRef = useRef<Attachment>()
  const idleSliderPlayerRef = createRef<ReactPlayerForwardRef>();
  const secondsValue = parseInt(process.env.REACT_APP_KIOSK_SECONDS || '1')
  const userStatusRef = useRef(kioskStatus);
  const { widgetKioskActiveSlide, widgetKioskData, idlePressEnter } = state
  const { bannerImage, videos = [], focusStateTimer, waitingStateTimer, waitingImage, fallbackStateTimer, fallBackImage } = widgetKioskData || {}
  const { url: bannerImageUrl } = bannerImage || {}
  const { url: fallBackImageUrl } = fallBackImage || {}
  const { url: waitingImageUrl } = waitingImage || {}
  const { featureImage, bannerImage: currentVideoBanner } = currentVideoIndexRef.current || {}
  const { url: currentFeatureImage } = featureImage || {}
  const { url: currentVideoBannerImage } = currentVideoBanner || {};
  const { data: { message: joiningDataMessage, meetingUrl: joiningDataMeetingUrl } } = joiningData || {}
  const { data: { message: hangUpDataMessage } } = hangUpData || {}
  const inCallRef = useRef(inCall)
  const [counter, setCounter] = useState<number>(0)
  const counterRefData = useRef(counter)
  const secondsRef = useRef<number>(secondsValue)
  const slidePadding = window.innerHeight;
  const centerPadding = (slidePadding * (slidePadding > 2500 ? 0.125 : 0.156)).toFixed(0);
  const [timeLeft, setTimeLeft] = useState(TIMER_CLICK);
  const isKeyPressed = useRef(false)

  useEffect(() => {
    if (isKeyPressed.current) {
      const timer = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(prevTime => prevTime - 1);
        } else {
          isKeyPressed.current = false
          setTimeLeft(TIMER_CLICK);
          enterCount.current = 0
        }
      }, 1000);

      return () => clearInterval(timer);
    }
    // eslint-disable-next-line
  }, [timeLeft, isKeyPressed.current]);

  const settings: Settings = {
    vertical: true,
    centerMode: true,
    infinite: true,
    centerPadding: `${centerPadding}px`,
    slidesToShow: 1,
    slidesToScroll: 1,

    afterChange(currentSlide) {
      setTimeout(() => {
        setActiveSlide(currentSlide)
      }, 100)

      currentVideoIndexRef.current = videos[currentSlide]
    },
  };

  const keyStrokesCount = () => {
    if (enterCount.current < KEY_STROKES) {
      enterCount.current = enterCount.current + 1
    }
    else {
      dispatch({ type: WidgetActionType.SET_TOO_MANY_KEY_STROKES, tooManyKeyStrokes: true })
      dispatch({ type: WidgetActionType.SET_SLIDE_ANIMATION, slideAnimation: true })
    }
  }

  const playCurrentVideo = (isPaused: boolean) => {
    setTimeout(async () => {
      const currentSlideNode = document.getElementsByClassName('slick-current')[0]
      if (currentSlideNode) {
        const currentVideoNode = currentSlideNode.querySelector('video')
        if (currentVideoNode) {
          if (isPaused) {
            await currentVideoNode.play()
          } else {
            currentVideoNode.pause()
          }
        }
      }
    }, 100)
  }

  const visitPrevSlide = () => {
    if (!inCall) {
      idleSliderRef.current?.slickPrev()
      idleSliderPlayerRef.current?.playChildVideo()

      if (userStatusRef.current === 1 && !idlePressEnter) {
        arrowKeyPressed.current = true
        secondsRef.current = Number(focusStateTimer)

        setTimeout(() => {
          arrowKeyPressed.current = false
        }, 1)
      }
    }
  }

  const showKioskWidgetUserState = (status: KioskUserStatus) => {
    dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_USER_STATUS, widgetKioskUserStatus: status })
  }

  const visitNextSlide = () => {
    if (!inCall) {
      idleSliderRef.current?.slickNext()

      if (userStatusRef.current === 1 && !idlePressEnter) {
        arrowKeyPressed.current = true
        secondsRef.current = Number(focusStateTimer)

        setTimeout(() => {
          arrowKeyPressed.current = false
        }, 1)
      }
    }
  }

  const toggleKioskWidgetData = () => {
    toggleKioskWidget()
    userStatusRef.current = 0
    focusDispatch({ type: WidgetActionType.SET_KIOSK_STATUS, kioskStatus: userStatusRef.current })
    dispatch({ type: WidgetActionType.SET_IDLE_PRESS_ENTER, idlePressEnter: false })
  }

  useEffect(() => {
    secondsRef.current = Number(focusStateTimer)
    idleSliderRef.current?.slickGoTo(widgetKioskActiveSlide)
    setActiveSlide(widgetKioskActiveSlide)
    // eslint-disable-next-line
  }, [])

  const handleKeyArrowEvent = (event: { key: string; }) => {
    if (event.key === ARROW_KEYS.ArrowLeft && isConnected && !inCallRef.current) {
      isKeyPressed.current = true
      isKeyPressed && keyStrokesCount()
      visitPrevSlide()
    } else if (event.key === ARROW_KEYS.ArrowRight && isConnected && !inCallRef.current) {
      isKeyPressed.current = true
      isKeyPressed && keyStrokesCount()
      counterRefData.current = counterRefData.current + 1
      setCounter(counterRefData.current)
      visitNextSlide()
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyArrowEvent)

    return () => {
      document.removeEventListener('keyup', handleKeyArrowEvent)
    };
    // eslint-disable-next-line
  }, [isConnected]);

  const onKeyEnterPress = () => {
    userStatusRef.current === 1 && mutateKioskState(true, true, false)

    if (userStatusRef.current < 2) {
      userStatusRef.current = userStatusRef.current + 1

      focusDispatch({ type: WidgetActionType.SET_KIOSK_STATUS, kioskStatus: userStatusRef.current })

      if (userStatusRef.current === 2) {
        localStorage.setItem(START_TIMER, "false")
        showKioskWidgetUserState(KioskUserStatus.Waiting)
        secondsRef.current = Number(waitingStateTimer)
        focusDispatch({ type: WidgetActionType.SET_WAITING, waiting: true })
      }
    }
  }

  const handleKeyEnterEvent = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      isKeyPressed.current = true
      isKeyPressed && keyStrokesCount()
      onKeyEnterPress()
    }
  };

  useEffect(() => {
    const timerStart = localStorage.getItem(START_TIMER);

    if (timerStart === null) {
      localStorage.setItem(START_TIMER, "true");
    }

    document.addEventListener('keyup', handleKeyEnterEvent)

    return () => {
      secondsRef.current = Number(secondsValue)
      document.removeEventListener('keyup', handleKeyEnterEvent)
    };
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // videos && setCurrentVideo(videos[widgetKioskActiveSlide])
    currentVideoIndexRef.current = videos[widgetKioskActiveSlide]
    idlePressEnter && onKeyEnterPress()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!!joiningDataMessage) {
      userStatusRef.current = userStatusRef.current + 1
      focusDispatch({ type: WidgetActionType.SET_KIOSK_STATUS, kioskStatus: userStatusRef.current })
      playCurrentVideo(false)
      inCallRef.current = inCall
      idleSliderPlayerRef.current?.pauseChildVideo()
      document.removeEventListener('keyup', handleKeyEnterEvent)
    }
    // eslint-disable-next-line
  }, [joiningDataMessage])

  useEffect(() => {
    if (!!hangUpDataMessage) {
      toggleKioskWidgetData()
    }
    // eslint-disable-next-line
  }, [hangUpDataMessage])

  useEffect(() => {
    if (!waiting && kioskStatus === 2) {
      userStatusRef.current = 4
      focusDispatch({ type: WidgetActionType.SET_KIOSK_STATUS, kioskStatus: userStatusRef.current })
      focusDispatch({ type: WidgetActionType.SET_FALL_BACK, fallBack: true })
      secondsRef.current = Number(fallbackStateTimer)
    }
    // eslint-disable-next-line
  }, [waiting, fallBack])

  useEffect(() => {
    if (fallBack) {
      mutateKioskState(false, false, false)
      document.removeEventListener('keypress', handleKeyEnterEvent, false)
    }
    // eslint-disable-next-line
  }, [fallBack])

  useEffect(() => {
    playCurrentVideo(true)
  }, [activeSlide])

  const imageTextForTimer = "This screen will go away in"
  const displayImageText = kioskStatus === 1 ? 'none' : 'block'

  const renderRightSide = (imageUrl: string, seconds: number, isFeatured?: boolean, imageText?: string, fallBack?: boolean, fallbackStateTimer?: string) => (
    <KioskWidgetFeature>
      <KioskWidgetFeatureInner>
        <AnimatePresence>
          <motion.div
            style={{
              height: "100%", width: "100%", backgroundColor: isFeatured ? 'transparent' : "#FFFFFF",
              position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'
            }}
            key={imageUrl}
            initial={{ right: -150, zIndex: 0, opacity: 0 }}
            animate={{ right: 0, zIndex: 1, opacity: 1 }}
            exit={{ right: -150, zIndex: 0, opacity: 0 }}
            transition={{ type: "tween", delay: 0.7 }}
          >
            <KioskImageWrapper imageUrl={imageUrl} isFeature={isFeatured} />

            {!arrowKeyPressed.current &&
              <KioskWaitingImageText sx={{ display: displayImageText }}>
                <Typography variant="subtitle2" component='p' sx={{ color: "#898298" }}>
                  {imageText ? `${imageText} ` : ""}

                  {seconds &&
                    <TimeData
                      seconds={seconds}
                      userStatusRef={userStatusRef}
                      toggleKioskWidgetData={toggleKioskWidgetData}
                      focusDispatch={focusDispatch}
                      kioskStatus={kioskStatus}
                      fallBack={fallBack}
                      fallbackStateTimer={fallbackStateTimer}
                    />
                  }
                </Typography>
              </KioskWaitingImageText>
            }
          </motion.div>
        </AnimatePresence>
      </KioskWidgetFeatureInner>
    </KioskWidgetFeature>
  )

  return (
    <Box sx={{ height: '100vh', overflow: 'hidden' }}>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item md={8}>
          <AnimatePresence>
            <motion.div
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "just", delay: 1 }}
            >
              <Box sx={{ height: '100vh', position: 'relative', padding: '0 2vw' }}>
                <KioskFocusWidget>
                  <Slider {...settings} ref={idleSliderRef}>
                    {videos.map((video, index) => {
                      const { id: videoId, url: videoUrl, thumbnail, title, description, videoOrientation } = video || {}
                      const { url: thumbnailUrl } = thumbnail || {}

                      return (
                        <Box key={videoId} className="kiosk-focus-slide">
                          <CustomVideoPlayer
                            isActiveSlide={index === activeSlide}
                            ref={idleSliderPlayerRef}
                            videoId={videoId}
                            url={videoUrl || ''}
                            videoTitle={title || ''}
                            videoDescription={description || ''}
                            thumbnail={thumbnailUrl || ''}
                            state={focusState}
                            videoOrientation={videoOrientation}
                            isFocus={true}
                            goToPrevSlide={visitPrevSlide}
                            goToNextSlide={visitNextSlide}
                            dispatch={focusDispatch}
                          />
                        </Box>
                      )
                    })}
                  </Slider>
                </KioskFocusWidget>

                {currentVideoIndexRef.current &&
                  <KioskWidgetBannerImage isFocus={true} imageUrl={currentVideoBannerImage || bannerImageUrl || KioskBannerImage} />
                }
              </Box>
            </motion.div>
          </AnimatePresence>
        </Grid>

        <Grid item md={4}>
          <>
            <Box sx={{ display: 'none' }}>
              {videos.map((video) => {
                const { featureImage, id } = video
                const { url: featureImageUrl } = featureImage || {}

                return (
                  <img key={id} src={featureImageUrl || ''} alt="" />
                )
              })}
            </Box>

            {kioskStatus === 1 &&
              renderRightSide(currentFeatureImage || KioskFeatureImage, secondsRef.current, true)
            }

            {kioskStatus === 2 && !joiningDataMeetingUrl && !fallBack &&
              renderRightSide(waitingImageUrl || KioskWaitingImage, secondsRef.current, undefined, imageTextForTimer)
            }

            {joiningDataMeetingUrl && !fallBack &&
              <WhereBy roomUrl={joiningDataMeetingUrl || ''} />
            }

            {fallBack && !joiningDataMeetingUrl &&
              renderRightSide(fallBackImageUrl || KioskFallbackImage, secondsRef.current, undefined, imageTextForTimer, fallBack, fallbackStateTimer || "")
            }
          </>
        </Grid>
      </Grid>
    </Box>
  )
}

export default KioskWidgetFocus