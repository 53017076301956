//packages block
import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, CircularProgress, Box, Typography, Link as MuiLink } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// component block
import { CustomController } from "../common/CustomController";
import { Alert } from "../common/Alert";
// others
import { AUTH_TOKEN, EMAIL_CHANGED_OR_NOT_VERIFIED_MESSAGE, FORBIDDEN_EXCEPTION, JOB_PATH, LOGIN, LOGIN_FIELDS, privacyPolicy, SELECT_BRAND_ROUTE, terms, VIDEO_DETAIL_PATH } from "../../constants";
import { loginValidationSchema } from "../../validationSchema";
import { LoginUserInput, useLoginMutation, UserRoles } from "../../../generated";
import { AuthContext } from "../../context";
import { firstLetterUppercase } from "../../utils";

export const LoginForm = (): JSX.Element => {
  const { setIsLoggedIn, setIsAdmin, setIsOperator, isAdmin, isOperator, setUserLoader } = useContext(AuthContext);
  const navigate = useNavigate();
  const methods = useForm<LoginUserInput>({
    mode: "all",
    resolver: yupResolver(loginValidationSchema),

    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { handleSubmit } = methods;
  const jobUrl = localStorage.getItem(JOB_PATH)
  const videoDetailUrl = localStorage.getItem(VIDEO_DETAIL_PATH)

  const getUrlToNavigate = (isAdmin: boolean) => {
    if (videoDetailUrl) {
      return videoDetailUrl
    } else if (isAdmin) {
      return SELECT_BRAND_ROUTE
    } else if (jobUrl) {
      return jobUrl
    } else {
      return '/profile'
    }
  }

  const [login, { loading }] = useLoginMutation({
    onError({ message }) {
      if (message.toLowerCase() === FORBIDDEN_EXCEPTION) {
        return Alert.error(EMAIL_CHANGED_OR_NOT_VERIFIED_MESSAGE);
      }

      return Alert.error(firstLetterUppercase(message || ""));
    },

    onCompleted(data) {
      if (data) {
        const { login: { accessToken, roles } } = data;

        if (accessToken && roles) {
          localStorage.setItem(AUTH_TOKEN, accessToken)
          const isAdmin = roles.find((role) => role.role === UserRoles.Admin || role.role === UserRoles.BrandManager)
          const isOperator = roles.find((role) => role.role === UserRoles.Operator)

          setUserLoader(true)
          setIsAdmin(!!isAdmin)
          setIsOperator(!!isOperator)
          setIsLoggedIn(true)
          navigate(getUrlToNavigate(!!isAdmin))
        }
      }
    },
  });

  useEffect(() => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      isAdmin && navigate(SELECT_BRAND_ROUTE)
      isOperator && navigate(jobUrl || '/profile')
    }
  }, [navigate, isAdmin, isOperator, jobUrl]);

  const onSubmit = async (data: LoginUserInput) => {
    await login({
      variables: {
        loginUser: data,
      },
    });
  };

  return (
    <Box sx={{ marginTop: "1rem" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {LOGIN_FIELDS.map((field) => {
            const { fieldType, name, title } = field;

            return (
              <CustomController
                key={`${name}-${title}`}
                controllerName={name}
                controllerLabel={title}
                fieldType={fieldType}
                isPassword={fieldType === 'password'}
              />
            );
          })}

          <Box marginTop="1rem">
            <Typography
              variant="h4"
              component={Link}
              to="/forgot-password"
              color="primary"
            >
              Forgot Password?
            </Typography>
          </Box>

          <Box>
            <Typography variant='h3' sx={{ opacity: '0.6', marginY: '10px' }}>
              By continuing, you agree that we create an account for you (unless already created), and accept our
              <MuiLink href={terms} target='_blank' sx={{ cursor: 'pointer', marginX: '5px', }}>
                Terms and Conditions
              </MuiLink>
              and
              <MuiLink href={privacyPolicy} target='_blank' sx={{ cursor: 'pointer', marginLeft: '5px', }}>
                Privacy Policy
              </MuiLink>.
            </Typography>
          </Box>

          <Box marginTop={3}>
            <Button variant="contained" type="submit" fullWidth color="primary" disabled={loading}
              endIcon={loading && <CircularProgress size={20} color="inherit" />}
            >
              {LOGIN}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
