import { Box } from "@mui/material"
import { PageHeading } from "../../../components/common/PageHeading"
import { CreateTaskTemplateStepper } from "../../../components/taskTemplate/CreateTaskTemplateStepper"

export const CreateTaskTemplate = (): JSX.Element => (
  <Box sx={{ width: '80%', margin: 'auto' }}>
    <PageHeading title="Create a Task" />

    <CreateTaskTemplateStepper />
  </Box>
)