import { KioskMutateSocketResponse, SocketResponse } from "../interfaceTypes";

export interface SocketState {
  isConnected: boolean;
  isError: boolean;
  socketBrandId: string;
  socketWidgetId: string;
  joiningData: SocketResponse
  hangUpData: SocketResponse
  kioskMutatedData: KioskMutateSocketResponse
  inCall: boolean
}

export const socketInitialState: SocketState = {
  isConnected: false,
  isError: false,
  socketBrandId: "",
  socketWidgetId: "",
  joiningData: { data: { message: "", meetingUrl: undefined } },
  hangUpData: { data: { message: "" } },
  kioskMutatedData: { message: "", state: { kioskWidgetId: "", brandId: "", state: undefined } },
  inCall: false
}

export enum SocketActionType {
  SET_IS_CONNECTED = 'setIsConnected',
  SET_IS_ERROR = 'setIsError',
  SET_SOCKET_BRAND_ID = 'setSocketBrandId',
  SET_SOCKET_WIDGET_ID = 'setSocketWidgetId',
  SET_JOINING_DATA = 'setJoiningData',
  SET_HANGUP_DATA = 'setHangupData',
  SET_KIOSK_MUTATE_DATA = 'setKioskMutateData',
  SET_IN_CALL = 'setInCall'
}

export type SocketAction =
  | { type: SocketActionType.SET_IS_CONNECTED, isConnected: boolean }
  | { type: SocketActionType.SET_IS_ERROR, isError: boolean }
  | { type: SocketActionType.SET_SOCKET_BRAND_ID, socketBrandId: string }
  | { type: SocketActionType.SET_SOCKET_WIDGET_ID, socketWidgetId: string }
  | { type: SocketActionType.SET_JOINING_DATA, joiningData: SocketResponse }
  | { type: SocketActionType.SET_HANGUP_DATA, hangUpData: SocketResponse }
  | { type: SocketActionType.SET_KIOSK_MUTATE_DATA, kioskMutatedData: KioskMutateSocketResponse }
  | { type: SocketActionType.SET_IN_CALL, inCall: boolean }

export const socketReducer = (state: SocketState, action: SocketAction): SocketState => {
  switch (action.type) {
    case SocketActionType.SET_IS_CONNECTED:
      return {
        ...state,
        isConnected: action.isConnected
      }

    case SocketActionType.SET_IS_ERROR:
      return {
        ...state,
        isError: action.isError
      }

    case SocketActionType.SET_SOCKET_BRAND_ID:
      return {
        ...state,
        socketBrandId: action.socketBrandId
      }

    case SocketActionType.SET_SOCKET_WIDGET_ID:
      return {
        ...state,
        socketWidgetId: action.socketWidgetId
      }

    case SocketActionType.SET_JOINING_DATA:
      return {
        ...state,
        joiningData: action.joiningData
      }

    case SocketActionType.SET_HANGUP_DATA:
      return {
        ...state,
        hangUpData: action.hangUpData
      }

    case SocketActionType.SET_KIOSK_MUTATE_DATA:
      return {
        ...state,
        kioskMutatedData: action.kioskMutatedData
      }

    case SocketActionType.SET_IN_CALL:
      return {
        ...state,
        inCall: action.inCall
      }
  }
}
