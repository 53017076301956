import { FC } from 'react'
import { VideoGridType, VideoLayout } from '../../../generated'
import { PreviewVideoProps } from "../../interfaceTypes";
import { CarouselFiveVideos } from './carousel/CarousellFiveVideo'
import { CarouselThreeVideos } from './carousel/CarousellThreeVideos'
import { GalleryThreeVideo } from './gallery/GalleryThreeVideo'
import { MassonaryThreeVideo } from './massonary/MassonaryThreeVideo'
import { MassonayFiveVideo } from './massonary/MassonayFiveVideo'
import { SingleVideoLayout } from './SingleVideoLayout'
import { GalleryFiveVideo } from './gallery/GalleryFiveVideos'
import { getBorderRadiusValue } from '../../utils';
import { PortraitGalleryVideos } from './portraitGallery/PortraitGalleryVideos';
import { useFormContext } from 'react-hook-form';

export const PreviewVideoLayout: FC<PreviewVideoProps> = ({ videoGridType, selectedVideos, attachmentsToShow, landscapeThumbnailUrls }) => {
  const previewImage = attachmentsToShow?.filter(video => selectedVideos?.some(item => item.id === video.id))?.map((item) => item?.thumbnail?.url || "")
  const { watch } = useFormContext();
  const borderRadiusValue = getBorderRadiusValue(watch('videoBoundingBox'));
  const videoLayout = watch('videoLayout');
  const isPortrait = watch('isPortrait')

  switch (videoLayout) {
    case VideoLayout.SingleVideo:
      return <SingleVideoLayout thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

    case VideoLayout.ThreeVideos:
      switch (videoGridType) {
        case VideoGridType.Massonary:
          return <MassonaryThreeVideo thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        case VideoGridType.Carousell:
          return <CarouselThreeVideos thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        case VideoGridType.Gallery:
          return <GalleryThreeVideo thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        default:
          return <></>
      }

    case VideoLayout.FiveVideos:
      switch (videoGridType) {
        case VideoGridType.Massonary:
          return <MassonayFiveVideo thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        case VideoGridType.Carousell:
          return <CarouselFiveVideos thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        case VideoGridType.Gallery:
          return <GalleryFiveVideo thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        default:
          return <></>
      }

    case VideoLayout.InfiniteVideos:
      return isPortrait && <PortraitGalleryVideos thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

    default:
      return <></>
  }
}
