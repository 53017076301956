
import { FC } from "react";
import { Box } from "@mui/material";

import { UploadDocument } from "../common/UploadDocument";
import { UploadImageModalProps } from "../../interfaceTypes";
import { CustomModal } from "../common/CustomModal";
import { ACCEPT_FOR_IMAGES, DESCRIPTION_FOR_IMAGE, IMAGE_MAX_SIZE } from "../../constants";

export const UploadImageModal: FC<UploadImageModalProps> = ({ isOpen, handleClose, entityType, typeId, attachmentType, attachmentId, isLandscape }) => (
  <CustomModal
    title="Upload Image"
    isOpen={isOpen}
    handleClose={handleClose}
    maxWidth="700px"
  >
    <Box padding="0 0 20px">
      <UploadDocument
        maxSize={IMAGE_MAX_SIZE}
        accept={ACCEPT_FOR_IMAGES}
        description={DESCRIPTION_FOR_IMAGE}
        entityType={entityType}
        isLandscape={isLandscape}
        typeId={typeId || ""}
        attachmentType={attachmentType}
        attachmentId={attachmentId || undefined}
      />
    </Box>
  </CustomModal>
)

