import { Box } from '@mui/material'
import { FC, useContext } from 'react'
import { VideoOrientation } from '../../../generated'
import { HOOK_VIDEO } from '../../constants'
import { AppContext } from '../../context'
import { PreviewHookVideoLayoutProps } from '../../interfaceTypes'
import { PreviewDesktopHookVideo } from './PreviewDesktopHookVideo'
import { PreviewHookVideoForm } from './PreviewHookVideoForm'

export const PreviewHookVideoLayout: FC<PreviewHookVideoLayoutProps> = ({ hookVideos }) => {
  const { selectedBrand } = useContext(AppContext)
  const { attachments } = selectedBrand || {}
  const brandHookVideos = attachments?.filter(attachment => attachment.type === HOOK_VIDEO)
  const brandHookVideoLandscape = brandHookVideos?.find(video => video.videoOrientation === VideoOrientation.Landscape)
  const widgetHookVideoLandscape = hookVideos?.find(video => video.videoOrientation === VideoOrientation.Landscape)
  const { thumbnail: widgetThumbnail } = widgetHookVideoLandscape || {}
  const { url: widgetHookVideoThumbnail } = widgetThumbnail || {}
  const { thumbnail } = brandHookVideoLandscape || {}
  const { url: brandHookVideoThumbnail } = thumbnail || {}


  return (
    <Box sx={{ transform: 'scale(0.50)', transformOrigin: 'left top', width: 'calc(100% + 537px)' }}>
      <Box padding={1} display='flex' gap={1}>
        <Box width='62%' sx={{ backgroundColor: 'black' }}>
          <PreviewDesktopHookVideo hookVideoThumbnail={widgetHookVideoThumbnail || brandHookVideoThumbnail || ''} />
        </Box>

        <Box width='38%'>
          <PreviewHookVideoForm />
        </Box>
      </Box>
    </Box>
  )
}
