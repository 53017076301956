import { Box, List, ListItem, Typography } from '@mui/material'
import Highlight from 'react-highlight'
import { stickyHeadingEvents, testingApi } from '../../theme/styleConstants'

export const PopupWidgetEvents = () => (
  <Box sx={testingApi}>
    <Typography variant='caption' component='div' mb={2} sx={stickyHeadingEvents}>Popup Widget</Typography>

    <Typography variant='h4'>Subscribe Event</Typography>

    <Highlight className='javascript'>
      {`cabaPopup.subscribeEvent('eventName', (event) => console.log(event))`}
    </Highlight>

    <Typography variant='h4'>Un-subscribe Event</Typography>

    <Highlight className='javascript'>{`cabaPopup.unSubscribeEvent('eventName', (event) => console.log(event))`}</Highlight>

    <Typography variant='h4'>Fetch Popup up widget Events</Typography>

    <Highlight>{`const allEventNames = cabaPopup.fetchPopupEvents()`}</Highlight>

    <Typography variant='h4'>Response Payload:</Typography>

    <Highlight>
      {`{
  "popupClickedLiveShowroom": "popupClickedLiveShowroom",
  "popupClickedCustomerSupport": "popupClickedCustomerSupport",
  "popupFormSubmitted": "popupFormSubmitted",
  "popupBookedCalendar": "popupBookedCalendar",
  "popupWidgetOpened": "popupWidgetOpened",
}`}
    </Highlight>

    <Typography variant='h4'>For example to get single event name</Typography>

    <Typography variant='h4'>Now you can use this as</Typography>

    <Typography variant='h4' component='div' mb={2}>Popup Widget</Typography>

    <List disablePadding sx={{ listStyleType: 'disc', listStylePosition: 'inside', border: '0', mb: 2 }}>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>popupClickedLiveShowroom</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>popupClickedCustomerSupport</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>popupFormSubmitted</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>popupBookedCalendar</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>popupWidgetOpened</ListItem>
    </List>

    <Typography component='div' variant='caption' mb={2}>Event: openPopupWidget</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>{`details: {
  "widgetType": "Popup Widget",
  "action": "openPopupWidget"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: popupClickedLiveShowroom</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Popup Widget",
  "action": "popupClickedLiveShowroom"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: popupClickedCustomerSupport</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Popup Widget",
  "action": "popupClickedCustomerSupport"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: popupBookedCalendar</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Popup Widget",
  "action": "popupBookedCalendar",
  "calendarUrl": "https://cal.com/john-doe/15min"
  
  "customerDetail": {
    "name": "John Doe",
    "email": "john.doe@example.com",
    "phone": "(1234) 567-9010"
  },
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: popupFormSubmitted</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Popup Widget",
  "action": "popupFormSubmitted",

  "customerDetail": {
    "name": "John Doe",
    "email": "john.doe@example.com",
    "phone": "(1234) 567-9010"
  }
}`}
    </Highlight>
  </Box>
)