// packages import
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { RotatingSquare } from 'react-loader-spinner'
// others
import { CustomLoaderProps } from "../../interfaceTypes";
import { CustomModal } from "./CustomModal";

export const CustomLoaderModal: FC<CustomLoaderProps> = ({ isOpen, handleClose }) => (

  <CustomModal isOpen={isOpen} handleClose={handleClose} maxWidth="500px">
    <Box sx={{padding: '40px 25px'}}>
      <Box display="flex" justifyContent="center">
        <RotatingSquare
          height="100"
          width="100"
          color={`#4fa94d`}
          ariaLabel="rotating-square-loading"
          strokeWidth="4"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Box>

      <Box textAlign="center" pt={3}>
        <Typography variant="h6">Widget created successfully!</Typography>
      </Box>
    </Box>
  </CustomModal>
)