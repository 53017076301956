import { Box, Chip, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { BRAND_ID } from "../../constants";
import { TranscriptionProps } from "../../interfaceTypes";
import { purpleLabelStyle, sentenceTypographyStyle, transcriptionParentBox, videoSearchMapperBox } from "../../theme/styleConstants";
import { formatTimeToCustomFormat, sortByStartTime, timeToShow, truncateSentence } from "../../utils";

/**
 * Component for all the functionality for rendering transcriptions.
 * @returns {JSX.Element}
 */
export const Transcription: FC<TranscriptionProps> = ({ transcriptions, searchText, id }): JSX.Element => {

  const sortedTranscriptions = transcriptions
    ? [...transcriptions].sort(sortByStartTime)
    : [];

  const brandId = localStorage.getItem(BRAND_ID);

  return (
    <Box sx={videoSearchMapperBox}>
      {sortedTranscriptions.map((search) => {
        const { transcription } = search;
        const { startTime, sentence } = transcription || {};
        const transcript = sentence || "";
        const searchTerms = searchText?.toLowerCase().trim().split(" "); // Trim whitespace from the search query

        const truncatedTranscript = truncateSentence(transcript, 110, searchTerms);

        return (
          <Link to={`/brand/${brandId}/recorded-videos/${id}?seekTo=${formatTimeToCustomFormat(Number(startTime))}`} key={startTime}>
            <Box sx={transcriptionParentBox}>
              <Chip label={timeToShow(Number(startTime))} sx={{ ...purpleLabelStyle, minWidth: '86px', cursor: 'pointer' }} />

              <Typography sx={sentenceTypographyStyle} noWrap>
                {truncatedTranscript.split(/\s+/).map((word, index) => {
                  const isHighlighted = searchTerms?.some(term => word.toLowerCase().includes(term));
                  return (
                    isHighlighted ? (
                      <strong key={`${word}-${index}`}>
                        <u>
                          <Typography component="span" sx={{ backgroundColor: "#FFEB80" }}>
                            {word}
                          </Typography>
                        </u>
                        {" "}
                      </strong>
                    ) :
                      `${word} `
                  );
                })}
              </Typography>
            </Box>
          </Link>
        );
      })}
    </Box>
  );
};