import { Maybe, TranscriptBody, UserPickType } from "../../generated";

export interface VideoDetailState {
  videoUrl: string | undefined;
  showAlert: boolean;
  isVideoLoading: boolean;
  transcription: TranscriptBody[];
  activeSentenceIndex: number | null;
  isScrolling: boolean;
  allUsers: Maybe<Array<Maybe<UserPickType>>>
  keyword: string;
  isCommentOpened: boolean;
}

export const videoDetailInitialState: VideoDetailState = {
  videoUrl: '',
  showAlert: true,
  isVideoLoading: true,
  transcription: [],
  activeSentenceIndex: null,
  isScrolling: false,
  allUsers: [],
  keyword: "",
  isCommentOpened: false
};

export enum VideoDetailActionType {
  SET_VIDEO_URL = 'setVideoUrl',
  SET_SHOW_ALERT = 'setShowAlert',
  SET_VIDEO_LOADING = 'setVideoLoading',
  SET_TRANSCRIPTION = 'setTranscription',
  SET_ACTIVE_SENTENCE_INDEX = 'setActiveSentenceIndex',
  SET_IS_SCROLLING = 'setIsScrolling',
  SET_ALL_USERS = 'setAllUsers',
  SET_KEYWORD = 'setKeyword',
  SET_IS_COMMENT_OPENED = 'setIsCommentOpen'
}

export type VideoDetailAction =
  { type: VideoDetailActionType.SET_ACTIVE_SENTENCE_INDEX, activeSentenceIndex: number | null }
  | { type: VideoDetailActionType.SET_IS_SCROLLING, isScrolling: boolean }
  | { type: VideoDetailActionType.SET_SHOW_ALERT, showAlert: boolean }
  | { type: VideoDetailActionType.SET_TRANSCRIPTION, transcription: TranscriptBody[] }
  | { type: VideoDetailActionType.SET_VIDEO_LOADING, isVideoLoading: boolean }
  | { type: VideoDetailActionType.SET_VIDEO_URL, videoUrl: string }
  | { type: VideoDetailActionType.SET_ALL_USERS, allUsers: Maybe<Array<Maybe<UserPickType>>> }
  | { type: VideoDetailActionType.SET_KEYWORD, keyword: string }
  | { type: VideoDetailActionType.SET_IS_COMMENT_OPENED, isCommentOpened: boolean }

export const videoDetailReducer = (state: VideoDetailState, action: VideoDetailAction): VideoDetailState => {
  switch (action.type) {
    case VideoDetailActionType.SET_ACTIVE_SENTENCE_INDEX:
      return {
        ...state,
        activeSentenceIndex: action.activeSentenceIndex
      }

    case VideoDetailActionType.SET_IS_SCROLLING:
      return {
        ...state,
        isScrolling: action.isScrolling
      }

    case VideoDetailActionType.SET_SHOW_ALERT:
      return {
        ...state,
        showAlert: action.showAlert
      }

    case VideoDetailActionType.SET_TRANSCRIPTION:
      return {
        ...state,
        transcription: action.transcription
      }

    case VideoDetailActionType.SET_VIDEO_LOADING:
      return {
        ...state,
        isVideoLoading: action.isVideoLoading
      }

    case VideoDetailActionType.SET_VIDEO_URL:
      return {
        ...state,
        videoUrl: action.videoUrl
      }

    case VideoDetailActionType.SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.allUsers
      }

    case VideoDetailActionType.SET_KEYWORD:
      return {
        ...state,
        keyword: action.keyword
      }

    case VideoDetailActionType.SET_IS_COMMENT_OPENED:
      return {
        ...state,
        isCommentOpened: action.isCommentOpened
      }

    default:
      return state
  }
}
