import { PageHeading } from "../../components/common/PageHeading"
import { AlertToast } from '../../components/common/AlertToast'
import { BrandTabs } from '../../components/brandSetting/BrandTabs'

export const BrandSettings = (): JSX.Element => (
  <>
    <PageHeading title="Brand Settings" subTitle="Create custom settings for brands" />
    <AlertToast />
    <BrandTabs />
  </>
)