import { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ImageBoxProps } from '../../interfaceTypes';

export const CustomLazyImage: FC<ImageBoxProps> = ({ imageUrl, isImageUrl }) => (
  <LazyLoadImage
    style={{ display: 'block', objectFit: 'cover' }}
    width={isImageUrl ? 'auto' : '100%'}
    height={isImageUrl ? 'auto' : '100%'}
    alt={"Image"}
    src={imageUrl}
  />
)
