// packages import
import { FC, Suspense, useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// pages
import { LandingPageWidget } from "../components/brandSetting/landingPageWidget";
import { Layout } from "../components/layout";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import { SetPassword } from "../pages/auth/SetPassword";
import { SignUp } from "../pages/auth/SignUp";
import { VerifyEmail } from "../pages/auth/VerifyEmail";
import { Login } from "../pages/auth/login";
import { BrandSettings } from "../pages/main/BrandSettings";
import { Jobs } from "../pages/main/Jobs";
import { JsApis } from "../pages/main/JsApis";
import { Operators } from "../pages/main/Operators";
import { Profile } from '../pages/main/Profile';
import { SelectBrand } from "../pages/main/SelectBrand";
import { TaskDetail } from '../pages/main/TaskDetail';
import { PrivateRoute } from "./PrivateRoute";
// other packages
import { CircularProgress } from "@mui/material";
import { TaskEditTemplate } from "../components/taskTemplate/TaskEditTemplate";
import { AUTH_LINKS, ROOT_ROUTE } from "../constants";
import { AppContext, AuthContext } from "../context";
import { RecordedVideoContextProvider } from "../context/RecordedVideoContext";
import { SocketContextProvider } from "../context/SocketContext";
import { KioskWidget } from "../kiosk";
import PageNotFound from "../pages/main/PageNotFound";
import { RecordedVideos } from "../pages/main/RecordedVideos";
import { VideoDetailPage } from "../pages/main/VideoDetailPage";
import { Tasks } from "../pages/main/tasks";
import { CreateTaskTemplate } from "../pages/main/tasks/CreateTaskTemplate";
import { getToken } from "../utils";
import { WidgetPreview } from "../pages/main/WidgetPreview";
import { DevOnlyComponent } from "../components/recordedVideos/DevOnlyComponent";
// import { VideoSearchSummary } from "../components/recordedVideos/VideoSearchSummary";

export const MainRoutes: FC = (): JSX.Element => {
  const { isAdmin, isOperator, isLoggedIn, userLoader, isBrandManager } = useContext(AuthContext)
  const { selectedBrand } = useContext(AppContext)

  return (
    <BrowserRouter>
      <Routes>
        {!getToken() && !isLoggedIn && <Route path={ROOT_ROUTE} element={<Navigate replace to={AUTH_LINKS.LOGIN_LINK} />} />}
        <Route path={AUTH_LINKS.LOGIN_LINK} element={<Login />} />
        <Route path={AUTH_LINKS.SIGN_UP_LINK} element={<SignUp />} />
        <Route path={AUTH_LINKS.SET_PASSWORD} element={<SetPassword />} />
        <Route path={AUTH_LINKS.FORGET_PASSWORD_LINK} element={<ForgotPassword />} />
        <Route path={AUTH_LINKS.VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route path={'/brand/:id/kiosk/:id'} element={
          <SocketContextProvider>
            <KioskWidget />
          </SocketContextProvider>
        } />

        <Route path='/' element={<Layout />}>
          {isOperator &&
            <Route path={ROOT_ROUTE} element={<PrivateRoute><Profile /></PrivateRoute>} />
          }

          {(isAdmin || isBrandManager) && !selectedBrand &&
            <Route path={ROOT_ROUTE} element={<PrivateRoute><SelectBrand /></PrivateRoute>} />
          }

          {(isAdmin || isBrandManager) &&
            <Route path={ROOT_ROUTE} element={<PrivateRoute><Operators /></PrivateRoute>} />
          }

          {(isAdmin || isBrandManager) &&
            <>
              <Route path='select-brand' element={
                <PrivateRoute>
                  <SelectBrand />
                </PrivateRoute>
              } />

              <Route path='operators' element={
                <PrivateRoute>
                  <Operators />
                </PrivateRoute>
              } />

              <Route path='task-template' element={
                <PrivateRoute>
                  <Tasks />
                </PrivateRoute>
              } />

              <Route path='brand-settings' element={
                <PrivateRoute>
                  <BrandSettings />
                </PrivateRoute>
              } />

              <Route path='jobs' element={
                <PrivateRoute>
                  <Jobs />
                </PrivateRoute>
              } />

              <Route path='brand/:brandId/recorded-videos' element={
                <PrivateRoute>
                  <RecordedVideoContextProvider>
                    <RecordedVideos />
                  </RecordedVideoContextProvider>
                </PrivateRoute>
              } />

              <Route path='/dev-only' element={
                <PrivateRoute>
                  <RecordedVideoContextProvider>
                    <DevOnlyComponent />
                  </RecordedVideoContextProvider>
                </PrivateRoute>
              } />

              <Route path='js-apis' element={
                <PrivateRoute>
                  <JsApis />
                </PrivateRoute>
              } />

              <Route path='brand-settings/lading-page' element={
                <PrivateRoute>
                  <LandingPageWidget />
                </PrivateRoute>
              } />

              <Route path='task-template/create' element={
                <PrivateRoute>
                  <CreateTaskTemplate />
                </PrivateRoute>
              } />

              <Route path='task-template/:id' element={
                <PrivateRoute>
                  <TaskEditTemplate />
                </PrivateRoute>
              } />

              <Route path='widget-preview/brand/:brandId/widget/:widgetId' element={
                <PrivateRoute>
                  <WidgetPreview />
                </PrivateRoute>
              } />
            </>
          }

          <Route path='job/:id' element={
            <PrivateRoute>
              <TaskDetail />
            </PrivateRoute>
          } />

          <Route path='brand/:brandId/recorded-videos/:id' element={
            <PrivateRoute>
              <RecordedVideoContextProvider>
                <VideoDetailPage />
              </RecordedVideoContextProvider>
            </PrivateRoute>
          } />

          <Route path='profile' element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />

          {(!isLoggedIn || (!userLoader && (isAdmin || isBrandManager || isOperator))) &&
            <Route path="*" element={
              <Suspense fallback={<CircularProgress />}>
                <PageNotFound />
              </Suspense>
            } />
          }
        </Route>
      </Routes>
    </BrowserRouter>
  )
}