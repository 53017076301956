import { FC } from "react";
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { Controller, useFormContext } from 'react-hook-form';
import { VIDEO_BOX } from "../../../../constants";
import { RadioBox, Shape } from "../../../../theme/styleComponents";
import palette from "../../../../theme/palette";

export const VideoBoundingBox: FC = () => {
  const { control } = useFormContext()

  return (
    <Box marginTop='2rem'>
      <Typography variant="caption">Video Bounding Box</Typography>

      <Controller
        control={control}
        name="videoBoundingBox"
        render={({ field }) => (
          <RadioGroup
            {...field}
            sx={{ marginTop: '1rem' }}
            value={field.value}
            onChange={(_event, value) => field.onChange(value)}
          >
            {VIDEO_BOX.map((box, index) => {
              const { option, value, borderRadius } = box
              const isChecked = field.value === value

              return (
                <RadioBox
                  key={`${value}-${index}`}
                  sx={isChecked ? { border: `1px solid ${palette.primary.main}` } : undefined}
                >
                  <FormControlLabel
                    value={value}
                    control={<Radio checked={isChecked} />}
                    label={option}
                  />

                  <Shape borderRadius={borderRadius} />
                </RadioBox>
              )
            })}
          </RadioGroup>
        )}
      />
    </Box>
  )
}