import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { WidgetHeadingTextInterface } from '../../../interfaceTypes'

export const WidgetHeadingText: FC<WidgetHeadingTextInterface> = ({ headingName, headingValue }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant='body2' textTransform="capitalize">{headingName}</Typography>

    <Typography sx={{ marginY: '10px' }} variant='h4'>{headingValue}</Typography>
  </Box>
)
