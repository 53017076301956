import { Box, useMediaQuery } from '@mui/material';
import { FC } from 'react'
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { PreviewImageBoxMobile } from '../PreviewImageBoxMobile';
import { childVideoBox } from '../../../theme/styleConstants';
import { images } from '../../../../assets/images';

export const MassonayFiveVideoMobile: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  const fourthImage = thumbnailUrls && thumbnailUrls[3]
  const fifthImage = thumbnailUrls && thumbnailUrls[4]
  const mediumViewport = useMediaQuery('(max-width:500px)');

  return (
    <Box p={1} minHeight='200px' display='flex' flexDirection='column'>
      <PreviewImageBoxMobile height={mediumViewport ? '100px' : '240px'} width='100%' marginBottom='10px' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />

      <Box sx={childVideoBox}>
        <PreviewImageBoxMobile height={mediumViewport ? '55px' : '95px'} width='48%' marginBottom='10px' imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} />

        <PreviewImageBoxMobile height={mediumViewport ? '55px' : '95px'} width='48%' marginBottom='10px' imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} />
      </Box>

      <Box sx={childVideoBox}>
        <PreviewImageBoxMobile height={mediumViewport ? '55px' : '95px'} width='48%' imageUrl={fourthImage || images.DUMMY} borderRadius={borderRadius} />

        <PreviewImageBoxMobile height={mediumViewport ? '55px' : '95px'} width='48%' imageUrl={fifthImage || images.DUMMY} borderRadius={borderRadius} />
      </Box>
    </Box>
  )
}
