import { Chip, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment"
import { Job, JobStatus, Maybe, useGetAllJobsLazyQuery } from "../../../generated";
import { BRAND_ID, DATE_FORMAT, DELETE, GRAPHQL_QUERY_POLICY, TIME_FORMAT, VIEW } from "../../constants";
import { AppContext } from "../../context";
import { ActionType } from "../../context/AppContextReducer";
import { JobListingProps } from "../../interfaceTypes";
import palette from "../../theme/palette";
import { formatEnumMember } from "../../utils";
import { CustomPagination } from "../common/CustomPagination";
import { NoDataFound } from "../common/NoDataFound";
import { StickyTable } from "../common/StickyTable";
import { TableActionButton } from "../common/TableActionButton";
import { DataListSkeleton } from "../skeletons/DataListSkeleton";
import { DeleteJobModal } from "./DeleteJob";

export const JobsListing: FC<JobListingProps> = ({ jobStatus }) => {
  const { isDeleteOpen, pageLimit, currentPage, dispatch } = useContext(AppContext)
  const navigate = useNavigate()
  const brandId = localStorage.getItem(BRAND_ID);
  const [allJobs, setAllJobs] = useState<Maybe<Array<Maybe<Job>>>>([])
  const [job, setJob] = useState<Maybe<Job>>()

  const [getAllJobs, { loading, refetch }] = useGetAllJobsLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      setAllJobs([])
    },

    onCompleted(data) {
      const { getAllJobs: { jobs, pagination } } = data || {}

      if (jobs && jobs?.length > 0) {
        setAllJobs(jobs)
      }
      if (pagination) {
        const { totalPages } = pagination

        dispatch({ type: ActionType.SET_TOTAL_PAGE_COUNT, totalPageCount: totalPages || 0 })
      }
    }
  })

  const isEmpty = !loading && allJobs?.length === 0

  const handleDeleteModel = (job?: Maybe<Job>) => {
    if (job) setJob(job)
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: !isDeleteOpen })
  };

  useEffect(() => {
    const jobStatusUpdate = jobStatus === "" ? undefined : jobStatus

    if (brandId) {
      getAllJobs({
        variables: {
          getJobsInput: {
            brandId,
            status: jobStatusUpdate as JobStatus,

            paginationOptions: {
              page: currentPage,
              limit: pageLimit
            }
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [currentPage, jobStatus])

  useEffect(() => {
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
    // eslint-disable-next-line
  }, [])

  return (
    <TableContainer sx={{ marginTop: '2rem' }}>
      {isEmpty ?
        <NoDataFound height='calc(100vh - 285px)' description='Record not found' />
        :
        <StickyTable height="calc(100vh - 280px)" pagination={<CustomPagination loading={loading} />}>
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>

              <TableCell>Customer Email</TableCell>

              <TableCell>Video Heading</TableCell>

              <TableCell>Video Description</TableCell>

              <TableCell>Created At</TableCell>

              <TableCell>Status</TableCell>

              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          {loading ?
            <DataListSkeleton noOfRows={15} noOfCols={7} />
            :
            <TableBody>
              {allJobs?.map(job => {
                const { id, customerEmail, status, videoPageHeading, videoPageDescription, user, createdAt } = job || {}
                const { fullName } = user || {}
                const createdDate = moment(createdAt).format(DATE_FORMAT)
                const createdTime = moment(createdAt).format(TIME_FORMAT)

                return (
                  <TableRow key={id}>
                    <TableCell align="left">
                      {fullName || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {customerEmail || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {videoPageDescription || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      {videoPageHeading || 'N/A'}
                    </TableCell>

                    <TableCell align="left">
                      <Typography variant='body2' color='inherit'>{createdDate}</Typography>
                      <Typography variant='h3' sx={{ fontWeight: '500', color: palette.blackTwo }}>{createdTime}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <Chip sx={{ fontWeight: '600', border: `1px solid ${palette.primary.main}` }} label={formatEnumMember(status || '')} variant="outlined" color="default" />
                    </TableCell>

                    <TableCell align="center">
                      <TableActionButton
                        editBtnText={`${VIEW} Job `}
                        removeBtnText={`${DELETE} Job `}
                        editBtnHandler={() => navigate(`/job/${id}`)}
                        removeBtnHandler={() => handleDeleteModel(job)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          }
        </StickyTable>
      }

      <DeleteJobModal
        job={job}
        refetch={refetch}
      />
    </TableContainer>
  )
}
