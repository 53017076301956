import { Box, Grid, Tab, Tabs } from '@mui/material';
import { FC, SyntheticEvent, useContext, useState } from 'react'
import { AttachmentType, VideoOrientation } from '../../../../generated';
import { AppContext } from '../../../context';
import { VideoLibraryInnerTabsProps } from '../../../interfaceTypes';
import { VideoCardWrapper } from '../../../theme/styleComponents';
import { libraryVideoStylePortrait, tabsSticky } from '../../../theme/styleConstants';
import { handleTabs } from '../../../utils';
import { TabPanel } from '../../common/TabPanel';
import { brandInitialState } from '../reducer/brandReducer';
import { AddVideo } from './AddVideo';
import { VideoCard } from './VideoCard';
import { VideoLibraryActions } from './VideoLibraryActions';

export const VideoLibraryInnerTabs: FC<VideoLibraryInnerTabsProps> = ({ handleModal, handleVideoData, brandDispatch, brandState }) => {
  const [preview, setPreview] = useState(0);

  const handlePreviewChange = (event: SyntheticEvent, newValue: number) => {
    setPreview(newValue);
  };

  const { selectedBrand } = useContext(AppContext)
  const { attachments } = selectedBrand || {}

  const hookVideo = attachments?.filter(video => video.type !== AttachmentType.HookVideo)
  const brandVideoLandscape = hookVideo?.filter(video => video.videoOrientation === VideoOrientation.Landscape)
  const brandVideoPortrait = hookVideo?.filter(video => video.videoOrientation === VideoOrientation.Portrait)

  return (
    <>
      <Box sx={{ ...tabsSticky, top: '145px', mb: '1rem' }}>
        <Tabs value={preview} onChange={handlePreviewChange} aria-label="basic tabs example">
          <Tab sx={{ textTransform: 'capitalize' }} label="Landscape" {...handleTabs(0)} />

          <Tab sx={{ textTransform: 'capitalize' }} label="Portrait" {...handleTabs(1)} />
        </Tabs>
      </Box>

      <TabPanel value={preview} index={0}>
        {brandVideoLandscape && brandVideoLandscape.length >= 0 &&
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <AddVideo height="372px" handleModalOpen={() => handleModal('video', VideoOrientation.Landscape)} />
            </Grid>

            {brandVideoLandscape.map(video => {
              const { id } = video || {}

              return (
                <Grid item lg={4} md={4} sm={6} xs={12} key={id}>
                  <VideoCardWrapper>
                    <Box onClick={() => handleVideoData(video)}>
                      <VideoLibraryActions isDeleteVideo />
                    </Box>

                    <VideoCard dispatch={brandDispatch} state={brandInitialState} videoData={video} />
                  </VideoCardWrapper>
                </Grid>
              )
            })}
          </Grid>
        }
      </TabPanel>

      <TabPanel value={preview} index={1}>
        {brandVideoPortrait && brandVideoPortrait.length >= 0 &&
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <AddVideo height="427px" handleModalOpen={() => handleModal('video', VideoOrientation.Portrait)} />
            </Grid>

            {brandVideoPortrait.map(video => {
              const { id } = video || {}

              return (
                <Grid item lg={4} md={4} sm={6} xs={12} key={id}>
                  <VideoCardWrapper>
                    <Box onClick={() => handleVideoData(video)}>
                      <VideoLibraryActions isDeleteVideo />
                    </Box>

                    <VideoCard dispatch={brandDispatch} state={brandState} videoData={video} videoStyle={libraryVideoStylePortrait} />
                  </VideoCardWrapper>
                </Grid>
              )
            })}
          </Grid>
        }
      </TabPanel>
    </>
  )
}
