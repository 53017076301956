import { FC } from "react";
import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import { TableSkeletonProps } from "../../interfaceTypes";

/**
 * It would render Skelton on Table
 *
 *
 * @returns JSX Element DimensionTableSkeleton
 */

export const TaskTemplateSkeleton: FC<TableSkeletonProps> = ({ noOfRows }) => (
  <TableBody>
    {new Array(noOfRows).fill(0).map((_, index) => (
      <TableRow key={`${index}-skeleton`}>

        <TableCell>
          <Skeleton variant="text" height={30} width={278} />
          <Skeleton variant="text" height={30} width={278} />
        </TableCell>

        <TableCell><Skeleton variant="text" height={30} width={225} /></TableCell>

        <TableCell><Skeleton variant="text" height={30} width={213} /></TableCell>

        <TableCell align="center"><Skeleton variant="text" height={30} width={100} /></TableCell>

        <TableCell align="center"><Skeleton variant="text" height={30} /></TableCell>

        <TableCell align="center"><Skeleton variant="text" height={30} /></TableCell>
      </TableRow>
    ))}
  </TableBody>
);