// packages block
import { createContext, FC, useLayoutEffect, useState } from "react";
import { Maybe, useLoggedInUserLazyQuery, User, UserRoles } from "../../generated";
import { AppLoader } from "../components/common/AppLoader";
import { AUTH_TOKEN, GRAPHQL_QUERY_POLICY, USER_EMAIL } from "../constants";
// graphql, interfaces/types and constants block
import { AuthContextProps, ChildrenType } from "../interfaceTypes";

export const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  isLoggedIn: false,
  isAdmin: false,
  isBrandManager: false,
  isOperator: false,
  userLoader: false,
  setIsLoggedIn: () => { /* 'setIsLoggedIn' is empty */ },
  setIsAdmin: () => { /* 'setIsAdmin' is empty */ },
  setIsBrandManager: () => { /* 'setIsAdmin' is empty */ },
  setIsOperator: () => { /* 'setIsAttorney' is empty */ },
  setUser: (currentUser: User | null) => { /* 'setUser' is empty */ },
  setUserLoader: () => { /* 'setIsAttorney' is empty */ },
})

export const AuthContextProvider: FC<ChildrenType> = ({ children }) => {
  const [currentUser, setUser] = useState<Maybe<User>>(null);
  const [isLoggedIn, _setIsLoggedIn] = useState<boolean>(false);
  const [isAdmin, _setIsAdmin] = useState<boolean>(false);
  const [isBrandManager, _setIsBrandManager] = useState<boolean>(false);
  const [isOperator, _setIsOperator] = useState<boolean>(false);
  const [userLoader, _setUserLoader] = useState<boolean>(false);

  const [fetchUser] = useLoggedInUserLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      setUser(null);
      _setUserLoader(false);
    },

    onCompleted(data) {
      if (data) {
        const { me } = data;

        if (me) {
          setUser(me);
          const { email } = me;
          localStorage.setItem(USER_EMAIL, email || "");
        }
      }
    }
  });

  const setIsAdmin = (isAdmin: boolean) => _setIsAdmin(isAdmin);
  const setIsBrandManager = (isBrandManager: boolean) => _setIsBrandManager(isBrandManager);
  const setIsOperator = (isOperator: boolean) => _setIsOperator(isOperator);
  const setIsLoggedIn = (isLoggedIn: boolean) => _setIsLoggedIn(isLoggedIn);
  const setUserLoader = (userLoader: boolean) => _setUserLoader(userLoader);
  const hasToken = localStorage.getItem(AUTH_TOKEN);

  useLayoutEffect(() => {
    setIsLoggedIn(!!hasToken);
    if (isLoggedIn && hasToken && !currentUser) {
      _setUserLoader(true)
      fetchUser()
    };

    if (currentUser) {
      const { roles } = currentUser || {}
      const admin = roles?.filter(item => item?.role === UserRoles.Admin || item?.role === UserRoles.SuperAdmin)
      const brandManager = roles?.filter(item => item?.role === UserRoles.BrandManager)
      const operator = roles?.filter(item => item?.role === UserRoles.Operator)

      setIsOperator(operator?.length !== 0)
      setIsAdmin(admin?.length !== 0)
      setIsBrandManager(brandManager?.length !== 0)
      _setUserLoader(false)
    }
  }, [isLoggedIn, hasToken, currentUser, fetchUser]);

  if (userLoader) return <AppLoader />

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, setIsAdmin, isAdmin, currentUser, setUser, setIsOperator, setUserLoader, isOperator, userLoader, isBrandManager, setIsBrandManager }}>
      {children}
    </AuthContext.Provider>
  )
}