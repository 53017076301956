import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Chip, TextField } from "@mui/material";
import { ChangeEvent, FC, KeyboardEvent, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TagsProps } from '../../interfaceTypes';
import palette from '../../theme/palette';

const Tags: FC<TagsProps> = ({ controllerName }) => {
  const [inputValue, setInputValue] = useState("");
  const { control, setValue, watch } = useFormContext()
  const gorgiasValue = watch(controllerName)
  const { white } = palette || {};

  const getGorgiasValue = (event: KeyboardEvent<HTMLDivElement>) => {
    const { target } = event as unknown as ChangeEvent<{ value: string, name?: string } & (HTMLInputElement)>
    event.preventDefault();
    if (target.value.length > 0) {
      if (gorgiasValue) {
        setValue(controllerName, [...gorgiasValue, target.value], { shouldDirty: true })
        setInputValue("")
      } else {
        setValue(controllerName, [target.value], { shouldDirty: true })
        setInputValue("")
      }
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    switch (event.code) {
      case "Tab": {
        getGorgiasValue(event)
        break;
      }
      case "Space": {
        getGorgiasValue(event)
        break;
      }
      case "Enter": {
        getGorgiasValue(event)
        break;
      }
      default:
    }
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          sx={{ marginTop: '8px', marginBottom: '4px' }}
          multiple
          disableClearable={true}
          options={[]}
          defaultValue={gorgiasValue || []}
          freeSolo
          renderTags={(tags, getTagProps) => (
            tags.length > 0
              ?
              tags.map((tags, index) => (
                <Chip
                  deleteIcon={<CloseIcon />}
                  variant="filled"
                  color='primary'
                  sx={{
                    "& .MuiChip-label": {
                      color: white
                    }
                  }}
                  label={tags}
                  {...getTagProps({ index })}
                />
              ))
              :
              <></>
          )}
          inputValue={inputValue}
          onInputChange={(_, newValue) => {
            setInputValue(newValue)
          }}
          value={field.value}
          onChange={(e, newValue) => {
            field.onChange(newValue);
          }}
          onKeyDown={(e) => handleKeyDown}
          renderInput={params => {
            params.inputProps.onKeyDown = handleKeyDown;

            return (
              <TextField
                {...params}
                label='Enter Tags'
                placeholder="Tags"
                variant="outlined"
              />
            );
          }}
        />
      )}
    />
  );
};

export default Tags;
