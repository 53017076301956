import { Box, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes';
import { GalleryBox } from '../../../theme/styleComponents';
import { PortraitGalleryImageBox } from '../portraitGallery/PortraitGalleryImageBox';

export const GalleryThreeVideoMobile: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  const mediumViewport = useMediaQuery('(max-width:500px)');

  return (
    <Box paddingY={1} minHeight="200px" display='flex'>
      <GalleryBox overflow='hidden'>
        <PortraitGalleryImageBox width='250.92px' height='140.63px' marginLeft={mediumViewport ? '-150px' : '-190px'} scale='0.9' imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />

        <PortraitGalleryImageBox width='271.92px' height='152.44px' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} zIndex={9} videoIcon={true} />

        <PortraitGalleryImageBox width='250.92px' height='140.63px' marginRight={mediumViewport ? '-150px' : '-190px'} scale='0.9' imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />
      </GalleryBox>
    </Box>
  )
}
