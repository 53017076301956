import { FC } from "react";
import { Box, FormHelperText, RadioGroup, Typography } from "@mui/material";
import { Controller, useFormContext } from 'react-hook-form';
import { RenderRadio } from "./RenderRadio";
import { VideoGridType } from "../../../../../generated";
import { images } from "../../../../../assets/images";

export const VideoGridField: FC = () => {
  const { control } = useFormContext()

  return (
    <Box marginTop='1rem'>
      <Typography variant="caption">Video Grid</Typography>

      <Controller
        control={control}
        name="videoGridType"
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup
              {...field}
              sx={{ marginTop: '1rem' }}
              value={field.value}
              onChange={(_event, value) => field.onChange(value)}
            >
              <Box>
                <RenderRadio
                  value={VideoGridType.Massonary}
                  imageUrl={images.MASSONARY}
                  isChecked={field.value === VideoGridType.Massonary}
                  label="Masonry"
                />
              </Box>

              <RenderRadio
                value={VideoGridType.Gallery}
                imageUrl={images.GALLERY}
                isChecked={field.value === VideoGridType.Gallery}
                label="Gallery"
              />

              <Box>
                <RenderRadio
                  value={VideoGridType.Carousell}
                  imageUrl={images.CAROUSELL_THREE_LAYOUT}
                  isChecked={field.value === VideoGridType.Carousell}
                  label="Carousel"
                />
              </Box>
            </RadioGroup>

            {error &&
              <FormHelperText error>{error.message}</FormHelperText>
            }
          </>

        )}
      />
    </Box>
  )
}