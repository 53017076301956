import { Box, Typography } from '@mui/material';
import { FC } from 'react'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ImageBoxProps } from '../../../interfaceTypes';
import { CustomLazyImage } from '../../common/CustomLazyImage';
import { VideoPlayIcon } from '../../../theme/styleComponents';
import { flexCenter } from '../../../theme/styleConstants';
import { images } from '../../../../assets/images';
import palette from '../../../theme/palette';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export const GalleryImageBox: FC<ImageBoxProps> = ({ borderRadius, marginLeft, marginRight, imageUrl, width, scale, height, zIndex, videoIcon, backgroundColor, margin, paddingLeft, paddingRight, isLandscape, featureImage, border, marginTop, transformOrigin }) => {
  const isImageUrl = imageUrl === images.DUMMY

  return (
    <Box width={width} margin={margin} marginTop={marginTop} marginLeft={marginLeft} borderRadius={borderRadius}
      marginRight={marginRight} paddingLeft={paddingLeft} paddingRight={paddingRight} height={height} position='relative'
      zIndex={zIndex} border={border ? `1px solid ${palette.lightBorderColorThree}` : ''}
      sx={{ transform: `scale(${scale})`, backgroundColor: isImageUrl ? palette.white : palette.black, transformOrigin: transformOrigin }}>
      <Box display='flex' flexDirection='column' overflow="hidden" height='100%' marginX={isLandscape ? 'none' : "auto"} width={isLandscape ? '100%' : "111.25px"} sx={flexCenter}>
        <CustomLazyImage
          isImageUrl={isImageUrl}
          imageUrl={imageUrl}
        />

        {featureImage && isImageUrl &&
          <Box display='flex' justifyContent='center' alignItems='start' mt={2} px={3}>
            <WarningAmberIcon sx={{ height: '16px', width: '16px', mr: 1 }} color='warning' />

            <Typography variant='h3' sx={{ opacity: '0.7' }}>Please add feature Image</Typography>
          </Box>
        }
      </Box>

      {!isImageUrl && videoIcon &&
        <VideoPlayIcon>
          <PlayCircleIcon sx={{ color: palette.white, width: '30px', height: '30px' }} />
        </VideoPlayIcon>
      }
    </Box>
  )
}
