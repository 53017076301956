import { ApiKey, Attachment, WidgetVideoInput } from "../../../../generated"
import { BrandFE, BrandState } from "../../../interfaceTypes"

export const brandInitialState: BrandState = {
  timeSetting: '',
  videoLayoutType: 'THREE_VIDEO_LAYOUT',
  videoGridType: 'massonary',
  videoBorderType: 'sharp',
  tabsIndex: 0,
  mobileDrawerTabs: 0,
  openDialogue: false,
  openDrawer: false,
  previewTabs: false,
  currentVideo: null,
  logoEdit: false,
  onePagerEdit: false,
  featureImageEdit: false,
  bannerImageEdit: false,
  waitingImageEdit: false,
  fallbackImageEdit: false,
  brandsList: [],
  filteredBrandsList: [],
  videoIds: [],
  isDragging: false,
  isEditButtonText: false,
  isEditPostForm: false,
  isEditTellingForm: false,
  showColorPicker: false,
  brandApiKeys: [],
  currentApiKey: null,
  bannerVideoImageEdit: false
}

export enum BrandActionType {
  SET_TIME_SETTING = 'setTimeSettings',
  SET_VIDEO_LAYOUT_TYPE = 'setVideoLayoutType',
  SET_VIDEO_GRID_TYPE = 'setVideoGridType',
  VIDEO_BORDER_TYPE = 'setVideoBorderType',
  TABS_INDEX = 'setTabsIndex',
  MOBILE_DRAWER_TABS = 'setMobileDrawerTabs',
  OPEN_DIALOGUE = 'setOpenDialogue',
  OPEN_DRAWER = 'setOpenDrawer',
  PREVIEW_TABS = 'setPreviewTabs',
  SET_CURRENT_VIDEO = 'setCurrentVideo',
  SET_LOGO_EDIT = 'setLogoEdit',
  SET_ONE_PAGER_EDIT = 'setOnePagerEdit',
  SET_FEATURE_IMAGE_EDIT = 'setFeatureImageEdit',
  SET_BANNER_IMAGE_EDIT = 'setBannerImageEdit',
  SET_FALLBACK_IMAGE_EDIT = 'setFallbackImageEdit',
  SET_WAITING_IMAGE_EDIT = 'setWaitingImageEdit',
  SET_BRAND_LIST = 'setBrandsList',
  SET_FILTERED_BRAND_LIST = 'setFilteredBrandsList',
  SET_VIDEO_IDS = 'setVideoIds',
  SET_IS_DRAGGING = 'setIsDragging',
  SET_IS_BUTTON_TEXT = 'setIsButtonText',
  SET_IS_EDIT_POST_FORM = 'setIsPostForm',
  SET_IS_EDIT_TELLING_FORM = 'setIsTellingForm',
  SET_IS_COLOR_PICKER = 'setIsColorPicker',
  SET_BRAND_API_KEYS = 'setBrandApiKeys',
  SET_CURRENT_BRAND_API_KEY = 'setCurrentBrandApiKeys',
  SET_BANNER_VIDEO_IMAGE_EDIT = 'setBannerVideoImageEdit'
}

export type BrandAction =
  | { type: BrandActionType.SET_TIME_SETTING, timeSetting: string }
  | { type: BrandActionType.SET_VIDEO_LAYOUT_TYPE, videoLayoutType: string }
  | { type: BrandActionType.SET_VIDEO_GRID_TYPE, videoGridType: string }
  | { type: BrandActionType.VIDEO_BORDER_TYPE, videoBorderType: string }
  | { type: BrandActionType.TABS_INDEX, tabsIndex: number }
  | { type: BrandActionType.MOBILE_DRAWER_TABS, mobileDrawerTabs: number }
  | { type: BrandActionType.OPEN_DIALOGUE, openDialogue: boolean }
  | { type: BrandActionType.OPEN_DRAWER, openDrawer: boolean }
  | { type: BrandActionType.PREVIEW_TABS, previewTabs: boolean }
  | { type: BrandActionType.SET_CURRENT_VIDEO, currentVideo: Attachment | null }
  | { type: BrandActionType.SET_LOGO_EDIT, logoEdit: boolean }
  | { type: BrandActionType.SET_ONE_PAGER_EDIT, onePagerEdit: boolean }
  | { type: BrandActionType.SET_FEATURE_IMAGE_EDIT, featureImageEdit: boolean }
  | { type: BrandActionType.SET_BANNER_IMAGE_EDIT, bannerImageEdit: boolean }
  | { type: BrandActionType.SET_FALLBACK_IMAGE_EDIT, fallbackImageEdit: boolean }
  | { type: BrandActionType.SET_WAITING_IMAGE_EDIT, waitingImageEdit: boolean }
  | { type: BrandActionType.SET_BRAND_LIST, brandsList: Array<BrandFE> }
  | { type: BrandActionType.SET_FILTERED_BRAND_LIST, filteredBrandsList: Array<BrandFE> }
  | { type: BrandActionType.SET_VIDEO_IDS, videoIds: WidgetVideoInput[] }
  | { type: BrandActionType.SET_IS_DRAGGING, isDragging: boolean }
  | { type: BrandActionType.SET_IS_BUTTON_TEXT, isEditButtonText: boolean }
  | { type: BrandActionType.SET_IS_EDIT_POST_FORM, isEditPostForm: boolean }
  | { type: BrandActionType.SET_IS_EDIT_TELLING_FORM, isEditTellingForm: boolean }
  | { type: BrandActionType.SET_IS_COLOR_PICKER, showColorPicker: boolean }
  | { type: BrandActionType.SET_BRAND_API_KEYS, brandApiKeys: (ApiKey | { keyName: string; brandId: string; })[] }
  | { type: BrandActionType.SET_CURRENT_BRAND_API_KEY, currentApiKey: (ApiKey | null) }
  | { type: BrandActionType.SET_BANNER_VIDEO_IMAGE_EDIT, bannerVideoImageEdit: boolean }

export const brandReducer = (state: BrandState, action: BrandAction): BrandState => {
  switch (action.type) {
    case BrandActionType.SET_TIME_SETTING:
      return {
        ...state,
        timeSetting: action.timeSetting
      }

    case BrandActionType.SET_CURRENT_VIDEO:
      return {
        ...state,
        currentVideo: action.currentVideo
      }

    case BrandActionType.SET_VIDEO_LAYOUT_TYPE:
      return {
        ...state,
        videoLayoutType: action.videoLayoutType
      }

    case BrandActionType.SET_VIDEO_GRID_TYPE:
      return {
        ...state,
        videoGridType: action.videoGridType
      }

    case BrandActionType.VIDEO_BORDER_TYPE:
      return {
        ...state,
        videoBorderType: action.videoBorderType
      }

    case BrandActionType.TABS_INDEX:
      return {
        ...state,
        tabsIndex: action.tabsIndex
      }

    case BrandActionType.MOBILE_DRAWER_TABS:
      return {
        ...state,
        mobileDrawerTabs: action.mobileDrawerTabs
      }

    case BrandActionType.OPEN_DIALOGUE:
      return {
        ...state,
        openDialogue: action.openDialogue
      }

    case BrandActionType.OPEN_DRAWER:
      return {
        ...state,
        openDrawer: action.openDrawer
      }

    case BrandActionType.PREVIEW_TABS:
      return {
        ...state,
        previewTabs: action.previewTabs
      }

    case BrandActionType.SET_LOGO_EDIT:
      return {
        ...state,
        logoEdit: action.logoEdit
      }

    case BrandActionType.SET_ONE_PAGER_EDIT:
      return {
        ...state,
        onePagerEdit: action.onePagerEdit
      }

    case BrandActionType.SET_FEATURE_IMAGE_EDIT:
      return {
        ...state,
        featureImageEdit: action.featureImageEdit
      }

    case BrandActionType.SET_BANNER_IMAGE_EDIT:
      return {
        ...state,
        bannerImageEdit: action.bannerImageEdit
      }

    case BrandActionType.SET_BANNER_VIDEO_IMAGE_EDIT:
      return {
        ...state,
        bannerVideoImageEdit: action.bannerVideoImageEdit
      }

    case BrandActionType.SET_FALLBACK_IMAGE_EDIT:
      return {
        ...state,
        fallbackImageEdit: action.fallbackImageEdit
      }

    case BrandActionType.SET_WAITING_IMAGE_EDIT:
      return {
        ...state,
        waitingImageEdit: action.waitingImageEdit
      }

    case BrandActionType.SET_BRAND_LIST:
      return {
        ...state,
        brandsList: action.brandsList
      }

    case BrandActionType.SET_FILTERED_BRAND_LIST:
      return {
        ...state,
        filteredBrandsList: action.filteredBrandsList
      }

    case BrandActionType.SET_VIDEO_IDS:
      return {
        ...state,
        videoIds: action.videoIds
      }

    case BrandActionType.SET_IS_DRAGGING:
      return {
        ...state,
        isDragging: action.isDragging
      }

    case BrandActionType.SET_IS_BUTTON_TEXT:
      return {
        ...state,
        isEditButtonText: action.isEditButtonText
      }

    case BrandActionType.SET_IS_EDIT_POST_FORM:
      return {
        ...state,
        isEditPostForm: action.isEditPostForm
      }

    case BrandActionType.SET_IS_EDIT_TELLING_FORM:
      return {
        ...state,
        isEditTellingForm: action.isEditTellingForm
      }

    case BrandActionType.SET_IS_COLOR_PICKER:
      return {
        ...state,
        showColorPicker: action.showColorPicker
      }

    case BrandActionType.SET_BRAND_API_KEYS:
      return {
        ...state,
        brandApiKeys: action.brandApiKeys
      }

    case BrandActionType.SET_CURRENT_BRAND_API_KEY:
      return {
        ...state,
        currentApiKey: action.currentApiKey
      }

    default:
      return state
  }
}