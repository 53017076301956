import { FC } from 'react'
import { CustomController } from '../../common/CustomController'
import { CustomColorControl } from './CustomColorControl';

export const ButtonFields: FC = () => (
  <>
    <CustomController
      controllerName="buttonText"
      controllerLabel="Button Text"
      fieldType='text'
    />

    <CustomColorControl name="buttonColor" label='Button text color' />

    <CustomColorControl name="buttonBackgroundColor" label='Button background color' />
  </>
)
