import { FC } from 'react'
import { Box } from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { images } from '../../../assets/images'
import { ThumbnailUrlProps } from '../../interfaceTypes'
import { VideoPlayIcon } from '../../theme/styleComponents';
import palette from '../../theme/palette';
import { flexCenter } from '../../theme/styleConstants';

export const SingleVideoLayout: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0];
  
  return (
    <Box padding={1}>
      <Box position='relative' height='240px' border={firstImage ? `1px solid transparent` : `1px solid ${palette.lightBorderColorTwo}`} sx={flexCenter}>
        <Box component='img' width='100%' height='100%' src={firstImage || images.DUMMY} borderRadius={borderRadius} sx={{ objectFit: 'cover' }} />

        <VideoPlayIcon>
          <PlayCircleIcon sx={{ color: palette.white, width: '30px', height: '30px' }} />
        </VideoPlayIcon>
      </Box>
    </Box>
  )
}
