import { Button, CircularProgress, DialogActions, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { ApiKey, useDeleteApiKeyMutation } from '../../../generated';
import { API_KEY_DELETE, BRAND_ID } from '../../constants';
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { DeleteApiKeyInterface } from '../../interfaceTypes';
import { BrandActionType } from '../brandSetting/reducer/brandReducer';
import { Alert } from './Alert';
import { CustomModal } from './CustomModal';

export const DeleteApiKey: FC<DeleteApiKeyInterface> = ({ brandApiKeys, currentApiKey, brandDispatch }) => {
  const { isDeleteOpen, dispatch } = useContext(AppContext)
  const { id, keyName } = currentApiKey || {}
  const cabaBrandId = localStorage.getItem(BRAND_ID);

  const handleDeleteApiClose = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
  };

  const [deleteApiKey, { loading }] = useDeleteApiKeyMutation({
    onError({ message }) {
      handleDeleteApiClose()
      return Alert.error(message)
    },

    onCompleted(data) {
      if (data) {
        const { deleteApiKey } = data
        const apiKeys = brandApiKeys as unknown as ApiKey[]

        if (deleteApiKey) {
          const updatedBrandApiKeys = apiKeys?.filter((brandApiKey) => brandApiKey.id !== id)

          brandDispatch({ type: BrandActionType.SET_BRAND_API_KEYS, brandApiKeys: updatedBrandApiKeys })
          handleDeleteApiClose()
          return Alert.success(API_KEY_DELETE)
        }
      }
    }
  })

  const deleteCurrentApiKey = async () => {
    if (id && cabaBrandId) {
      await deleteApiKey({
        variables: {
          deleteApiKeyInput: {
            keyId: id,
            brandId: cabaBrandId
          }
        }
      })
    }
  }

  return (
    <CustomModal
      title="Delete Api Key"
      maxWidth='700px'
      isOpen={isDeleteOpen}
      handleClose={handleDeleteApiClose}
      infoIcon
    >
      <Typography variant="h4" marginTop={2} marginBottom={3} paddingLeft={0.5}>Are you sure you want to delete the key with name: <b>{keyName}</b>?
        <Typography component='div' mt={1}>
          The API key will be deleted permanently and you may lose integrations related to this API.
        </Typography>
      </Typography>

      <DialogActions sx={{ padding: '0' }}>
        <Button sx={{ marginLeft: '10px' }} onClick={handleDeleteApiClose}>Cancel</Button>

        <Button variant="contained" onClick={deleteCurrentApiKey} color="error" disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}>
          Delete
        </Button>
      </DialogActions>
    </CustomModal>
  )
}
