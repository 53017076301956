import { Button, CircularProgress, DialogActions, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { useDeleteEmailAliasMutation } from "../../../generated"
import { EMAIL_DELETED } from "../../constants"
import { AppContext } from "../../context"
import { ActionType } from '../../context/AppContextReducer'
import { UserActionType } from "../../context/UserContextReducer"
import palette from "../../theme/palette"
import { RemoveButton } from "../../theme/styleComponents"
import { Alert } from "../common/Alert"
import { CustomModal } from "../common/CustomModal"
import { EmailAliasProps } from "../../interfaceTypes"

export const DeleteEmailTemplate: FC<EmailAliasProps> = ({ userState, userDispatch }) => {
  const { isDeleteOpen, dispatch } = useContext(AppContext)
  const { allEmails, currentEmail } = userState
  const { id: emailId } = currentEmail || {}

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
  }

  const [deleteEmailAlias, { loading }] = useDeleteEmailAliasMutation({
    onCompleted(data) {
      const { deleteEmailAlias } = data || {}
      dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })

      if (deleteEmailAlias) {
        Alert.success(EMAIL_DELETED);
      }

      const newEmails = allEmails?.filter((email) => email?.id !== emailId)
      userDispatch({ type: UserActionType.SET_ALL_EMAIL, allEmails: newEmails || [] })
    },
  })

  const handleDelete = async () => {
    if (emailId) {
      await deleteEmailAlias({
        variables: {
          emailId,
        },
      });
    }
  }

  return (
    <CustomModal title='Remove Email Alias' isOpen={isDeleteOpen} handleClose={handleClose} infoIcon maxWidth='700px'>
      <Typography variant="h4" marginBottom={3} paddingLeft={0.5}>Are you sure you want to delete this email alias?</Typography>
      <DialogActions sx={{ paddingY: '0' }}>
        <Button sx={{ border: `1px solid ${palette.light}` }} color="inherit" onClick={handleClose}>Close</Button>

        <RemoveButton onClick={handleDelete} variant="contained" color="error" disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          Remove Email
        </RemoveButton>
      </DialogActions>
    </CustomModal>
  )
}