import { Alert, AlertTitle, Typography } from "@mui/material";
import { FC } from "react";
import { RejectJobInputInterface } from "../../interfaceTypes";

export const AlertRejection: FC<RejectJobInputInterface> = ({ statusMessage }) => (
  <Alert
    severity="error"
    sx={{ position: 'relative', width: '100%', marginY: '1rem' }}
  >
    <AlertTitle sx={{ marginTop: '-4px' }}><Typography variant="caption">Status Message</Typography></AlertTitle>
    <Typography variant="subtitle2">{statusMessage}</Typography>
  </Alert>
)