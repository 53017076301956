import React, { FC } from 'react'
import palette from '../../theme/palette'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { VideoPlayIcon } from '../../theme/styleComponents'
import { LandscapeHookVideoWrapper, videoIcon } from '../../theme/styleConstants'
import { CustomLazyImage } from '../common/CustomLazyImage'
import { PreviewDesktopHookVideoInterface } from '../../interfaceTypes'
import { Box } from '@mui/material'

export const PreviewDesktopHookVideo: FC<PreviewDesktopHookVideoInterface> = ({ hookVideoThumbnail }) => (
  <Box sx={LandscapeHookVideoWrapper}>
    <CustomLazyImage
      imageUrl={hookVideoThumbnail}
    />

    <VideoPlayIcon sx={{ backgroundColor: palette.lightBackground }}>
      <PlayCircleIcon sx={videoIcon} />
    </VideoPlayIcon>
  </Box>
)