import { FC, useContext } from 'react'
import { CALENDER_OVERRIDE_SETTINGS } from '../../../constants'
import { CustomAccordion } from '../../common/CustomAccordion'
import { CustomController } from '../../common/CustomController'
import Tags from '../../taskTemplate/Tags'
import { AppContext } from '../../../context'

export const CalendlyOverride: FC = () => {
  const { selectedBrand } = useContext(AppContext);
  const { gorgiasEnable } = selectedBrand || {}

  return (
    <CustomAccordion expanded={true} label={CALENDER_OVERRIDE_SETTINGS} id="calendlyOverrideSetting">
      <CustomController
        controllerName='calenderUrl'
        controllerLabel='Calendar URL'
      />

      {gorgiasEnable &&
        <Tags controllerName='gorgiasTags' />
      }
    </CustomAccordion>
  )
}
