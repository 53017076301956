// component import
import { SetPasswordForm } from "../../../components/auth/SetPasswordForm"
import { AuthLayout } from "../../../components/auth/Layout"
// others
import { SET_PASSWORD } from "../../../constants"

export const SetPassword = (): JSX.Element => (
  <AuthLayout title={SET_PASSWORD}>
    <SetPasswordForm />
  </AuthLayout>
)