import { FC } from "react";
import { Box, FormControlLabel, Radio } from "@mui/material";

import { RenderRadioProps } from "../../../../interfaceTypes";
import { RadioBox } from "../../../../theme/styleComponents";
import palette from "../../../../theme/palette";
import { widgetImage } from "../../../../theme/styleConstants";

export const RenderRadio: FC<RenderRadioProps> = ({ value, label, imageUrl, isChecked }) => (
  <RadioBox sx={isChecked ? { border: `1px solid ${palette.primary.main}` } : undefined}>
    <FormControlLabel
      value={value}
      control={<Radio checked={isChecked} />}
      label={label}
    />

    <Box component='img' src={imageUrl} sx={widgetImage} />
  </RadioBox>
)