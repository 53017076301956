import { Button, CircularProgress, DialogActions, Typography } from '@mui/material';
import { FC, useContext } from 'react';

import { WidgetType, useRemoveWidgetMutation } from '../../../../generated';
import { BRAND_ID } from '../../../constants';
import { AppContext } from '../../../context';
import { ActionType } from '../../../context/AppContextReducer';
import { WidgetActionType } from '../../../context/WidgetContextReducer';
import palette from '../../../theme/palette';
import { RemoveButton } from '../../../theme/styleComponents';
import { firstLetterUppercase, getWidgetName } from '../../../utils';
import { Alert } from '../../common/Alert';
import { CustomModal } from '../../common/CustomModal';
import { DeleteWidgetProps } from '.././../../interfaceTypes/index';

export const DeleteWidgetModal: FC<DeleteWidgetProps> = ({ widget, widgetsData, widgetDispatch, refetchAllWidgets }) => {
  const { name, id: widgetId, type } = widget || {}
  const typeToLowerCase = getWidgetName(type || WidgetType.Block)
  const { dispatch, isDeleteOpen } = useContext(AppContext)
  const brandId = localStorage.getItem(BRAND_ID);

  const [removeWidget, { loading }] = useRemoveWidgetMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ""))
    },

    onCompleted(data) {
      const { removeWidget } = data || {}

      if (removeWidget) {
        const updatedWidgets = widgetsData?.filter((widget) => widget.id !== widgetId)
        widgetDispatch && widgetDispatch({ type: WidgetActionType.SET_WIDGETS_DATA, widgetsData: updatedWidgets || [] });
        Alert.success("Widget deleted successfully.");
        dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
        refetchAllWidgets && refetchAllWidgets()
      }
    },
  })

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
  }

  const onSubmit = async () => {
    if (widgetId && brandId) {
      await removeWidget({
        variables: {
          removeWidgetInput: {
            id: widgetId,
            brandId
          }
        },
      });
    }
  }

  return (
    <CustomModal title='Remove Widget' isOpen={isDeleteOpen} handleClose={handleClose} infoIcon maxWidth='700px'>
      <Typography variant="h4" marginBottom={3} paddingLeft={0.5}>Are you sure you want to delete this {typeToLowerCase} widget having name: {name}?</Typography>
      <DialogActions sx={{ paddingY: '0' }}>
        <Button sx={{ border: `1px solid ${palette.light}` }} color="inherit" onClick={handleClose}>Close</Button>

        <RemoveButton onClick={onSubmit} variant="contained" color="error" disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          Remove Widget
        </RemoveButton>
      </DialogActions>
    </CustomModal>
  )
}
