import { FC } from 'react'
import { CustomController } from '../../common/CustomController'

export const WidgetName: FC = () => (
  <CustomController
    controllerName="name"
    controllerLabel="Enter widget name"
    fieldType='text'
  />
)
