import { createContext, FC, Reducer, useContext, useEffect, useMemo, useReducer } from "react";
import { Outlet } from "react-router-dom";
import { useGetBrandLazyQuery } from "../../generated";
import { Alert } from "../components/common/Alert";
import { BRAND_ID, GRAPHQL_QUERY_POLICY, INVALID_AUTH_TOKEN, SOMETHING_WENT_WRONG } from "../constants";
import { AppContextReducerType, ChildrenType } from "../interfaceTypes";
import { Action, ActionType, initialState, reducer, State } from "./AppContextReducer";
import { AuthContext } from "./AuthContext";

export const AppContext = createContext<AppContextReducerType>({
  ...initialState,

  dispatch: () => {
    return;
  },
});

export const AppContextProvider: FC<ChildrenType> = ({ children }): JSX.Element => {
  const { isLoggedIn } = useContext(AuthContext)
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);
  const store = useMemo(() => ({ ...state, dispatch }), [state]);
  const { selectedBrand } = state
  const cabaBrandId = localStorage.getItem(BRAND_ID)

  const [getBrand, { loading, refetch }] = useGetBrandLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: {
      brandId: cabaBrandId || ""
    },

    onError({ message }) {
      if (INVALID_AUTH_TOKEN) {
        return null
      } else {
        Alert.error(SOMETHING_WENT_WRONG)
      }

      dispatch({ type: ActionType.SET_IS_BRAND_LOADING, isBrandLoading: false })
    },

    onCompleted(data) {
      const { getBrand } = data

      if (getBrand) {
        dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: getBrand })
      }
    }
  })

  useEffect(() => {
    isLoggedIn && !selectedBrand && cabaBrandId && getBrand({
      variables: {
        brandId: cabaBrandId
      }
    })
    // eslint-disable-next-line 
  }, [isLoggedIn])

  useEffect(() => {
    dispatch({ type: ActionType.SET_IS_BRAND_LOADING, isBrandLoading: loading })
  }, [loading])

  return (
    <AppContext.Provider value={{ ...store, refetchBrand: refetch }}>
      {children}

      <Outlet />
    </AppContext.Provider>
  );
}