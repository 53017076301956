import EditIcon from '@mui/icons-material/Edit'
import { Avatar, Box } from '@mui/material'
import { FC, useContext } from 'react'
import { PageHeading } from '../components/common/PageHeading'
import { ProfileForm } from '../components/profile/ProfileForm'
import { AppContext, AuthContext } from '../context'
import { ActionType } from '../context/AppContextReducer'
import palette from '../theme/palette'
import { logoBox } from '../theme/styleConstants'

export const ProfileContainer: FC = () => {
  const { currentUser } = useContext(AuthContext)
  const { attachment } = currentUser || {}
  const { url: profileUrl } = attachment || {}
  const { isOpen, dispatch } = useContext(AppContext)

  const handleProfileDialog = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: !isOpen })
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <PageHeading title='Profile' subTitle='Here is your profile summary' />

        <Box sx={logoBox} onClick={handleProfileDialog}>
          <Avatar sx={{ height: '100%', width: '100%' }} src={profileUrl || ''} />

          <Box className='logoOverlay'>
            <EditIcon sx={{ width: '16px', height: '16px', color: palette.white }} />
          </Box>
        </Box>
      </Box>

      <ProfileForm />
    </>
  )
}
