import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, DialogActions } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateApiKeyInputDto, useCreateApiKeyMutation } from '../../../generated';
import { BRAND_ID } from '../../constants';
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { GenerateApikeyInterface } from '../../interfaceTypes';
import { Apikeyresolver } from '../../validationSchema';
import { BrandActionType } from '../brandSetting/reducer/brandReducer';
import { Alert } from './Alert';
import { CustomController } from './CustomController';
import { CustomModal } from './CustomModal';
import { GenerateApiKeyView } from './GenerateApiKeyView';

export const GenerateApiKey: FC<GenerateApikeyInterface> = ({ brandDispatch, brandState }) => {
  const { isOpen, dispatch } = useContext(AppContext)
  const [locallyKey, setLocallyKey] = useState('')
  const { brandApiKeys } = brandState

  const handleGenerateApiClose = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
  };

  const brandId = localStorage.getItem(BRAND_ID)

  const [createApikey, { loading }] = useCreateApiKeyMutation({
    onError({ message }) {
      Alert.error(message)
    },

    onCompleted(data) {
      if (data) {
        const { createApiKey } = data

        if (createApiKey) {
          const { publicKey } = createApiKey
          const updatedApiKey = { ...createApiKey, publicKey: publicKey.slice(publicKey.length - 4) }

          reset()
          setLocallyKey(publicKey)
          brandDispatch({ type: BrandActionType.SET_BRAND_API_KEYS, brandApiKeys: brandApiKeys ? [updatedApiKey, ...brandApiKeys] : [updatedApiKey] })
          dispatch({ type: ActionType.SET_IS_EDIT, isEdit: true })
          handleGenerateApiClose()
        }
      }
    }
  })

  const methods = useForm<CreateApiKeyInputDto>({
    mode: "all",
    resolver: yupResolver(Apikeyresolver),
    defaultValues: {
      keyName: ''
    }
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: CreateApiKeyInputDto) => {
    const { keyName } = data

    if (brandId) {
      await createApikey({
        variables: {
          createApiInput: {
            brandId: brandId,
            keyName
          }
        }
      })
    }
  }

  return (
    <>
      <CustomModal
        title="Generate Api Key"
        maxWidth='700px'
        isOpen={isOpen}
        handleClose={handleGenerateApiClose}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box my={2}>
              <CustomController
                controllerLabel='Key Name'
                controllerName='keyName'
              />
            </Box>

            <DialogActions sx={{ padding: 0 }}>
              <Button onClick={handleGenerateApiClose}>Close</Button>
              <Button type='submit' variant='contained' color='primary' disabled={loading} endIcon={loading && <CircularProgress size={20} color="inherit" />}>
                Generate
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </CustomModal>

      <GenerateApiKeyView
        locallyKey={locallyKey}
      />
    </>
  )
}
