import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress } from '@mui/material'
import { phone } from 'phone'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { UpdateUserInput, useUpdateUserMutation } from '../../../generated'
import { USER_UPDATED_SUCCESSFULLY } from '../../constants'
import { UserActionType } from '../../context/UserContextReducer'
import { UserGeneralInformationInterface } from '../../interfaceTypes'
import { formatPhoneNumber } from '../../utils'
import { updateFullNameSchema } from '../../validationSchema'
import { Alert } from '../common/Alert'
import { CustomController } from '../common/CustomController'
import { CustomPhoneController } from '../common/CustomPhoneController'

const UserGeneralInformation: FC<UserGeneralInformationInterface> = ({ isEditOpen, setIsEditOpen, currentUserData, usersData, dispatch }) => {
  const { id: userId, fullName = undefined, phoneNumber = undefined, email } = currentUserData || {}

  const methods = useForm<UpdateUserInput>({
    mode: "all",

    resolver: yupResolver(updateFullNameSchema),
    defaultValues: {
      fullName: fullName || "",
      phoneNumber: phoneNumber || "",
      email: email || ""
    }
  });

  useEffect(() => {
    reset({ fullName, phoneNumber, email })
    // eslint-disable-next-line
  }, [isEditOpen])

  const { handleSubmit, setValue, reset } = methods

  const [updateUserProfile, { loading }] = useUpdateUserMutation({
    onError({ message }) {
      Alert.error(message)
    },

    onCompleted(data) {
      const { updateUser } = data

      if (updateUser) {
        const updatedUsers = usersData?.map((user) => {
          if (user?.id === updateUser.id) {
            return updateUser
          }
          return user
        })

        if (updatedUsers && updatedUsers?.length > 0) {
          dispatch({ type: UserActionType.SET_USERS_DATA, usersData: updatedUsers })
          setIsEditOpen && setIsEditOpen(false)
          return Alert.success(USER_UPDATED_SUCCESSFULLY);
        }
      }
    }
  })


  const onSubmitUser = async (data: UpdateUserInput) => {
    const { fullName, phoneNumber, email } = data
    const validNumber = phoneNumber && formatPhoneNumber(phoneNumber)
    const validatePhoneNumber = phone(validNumber!)
    const { isValid } = validatePhoneNumber
    isValid ?
      await updateUserProfile({
        variables: {
          updateUserInput: {
            userId: userId,
            fullName: fullName || "",
            phoneNumber: phoneNumber || "",
            email: email || ""
          }
        }
      })
      :
      Alert.error('Phone number not valid')
  }

  useEffect(() => {
    fullName && setValue("fullName", fullName)
    phoneNumber && setValue("phoneNumber", phoneNumber)
    email && setValue("email", email)
  }, [fullName, phoneNumber, email, setValue])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitUser)} autoComplete="off">
        <Box mb={2}>
          <CustomController
            controllerName='fullName'
            controllerLabel='Full Name'
            fieldType='text'
          />

          <CustomController
            controllerName='email'
            controllerLabel='Email'
            fieldType='text'
          />

          <CustomPhoneController
            controllerName="phoneNumber"
            controllerLabel="Phone Number"
          />


          <Box sx={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" variant="contained" disabled={loading} color='primary' endIcon={loading && <CircularProgress size={15} color="inherit" />}>
              Update
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}

export default UserGeneralInformation

