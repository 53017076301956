//packages block
import { Box, Button, CircularProgress, Grow, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// component block
import { CustomController } from "../common/CustomController";
// others
import { registerUserValidationSchema } from "../../validationSchema";
import { useRegisterMutation, useVerifyInvitationTokenMutation } from "../../../generated";
import { CustomPhoneController } from "../common/CustomPhoneController";
import { useEffect, useState } from "react";
import { BAD_REQUEST, BAD_USER_INPUT, LOGIN_ROUTE, SIGN_UP, USER_REGISTERED_SUCCESSFULLY } from "../../constants";
import { Alert } from "../common/Alert";
import { firstLetterUppercase } from "../../utils";
import { SignupInterface } from "../../interfaceTypes";
import palette from "../../theme/palette";
import { AuthLayout } from "./Layout";
import { flexCenter } from "../../theme/styleConstants";

export const SignUpForm = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get("token")
  const [tokenValid, setTokenValid] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(true);
  const [userValue, setUserValue] = useState<{ email: string, roleType: string }>({ email: '', roleType: '' })
  const navigate = useNavigate();

  const [verifyUser, { loading: verifyUserLoading }] = useVerifyInvitationTokenMutation({
    onError({ message }) {
      setTokenValid(false)
      return Alert.error(firstLetterUppercase(message || ""));
    },

    onCompleted({ verifyInvitationToken }) {
      const { roles, email, existing } = verifyInvitationToken
      roles && roles.length > 0 && setUserValue({ email, roleType: firstLetterUppercase(roles[0]?.role) })
      setTokenValid(true)
      existing && navigate('/login')
    },
  })

  const verifiedEmail = async () => {
    await verifyUser({
      variables: {
        verifyEmailInput: {
          token: userToken as string
        }
      }
    })
  }

  useEffect(() => {
    verifiedEmail();

    if (isVerified) {
      setTimeout(() => {
        setIsVerified(false)
      }, 2000);
    }
    // eslint-disable-next-line
  }, [])

  const methods = useForm<SignupInterface>({
    mode: "all",
    resolver: yupResolver(registerUserValidationSchema),
    defaultValues: {
      email: "",
      fullName: "",
      phoneNumber: "",
      token: "",
      password: "",
      roleType: "",
      tagLine: "",
    },
  });

  const { handleSubmit, setValue } = methods;

  const [signUp, { loading }] = useRegisterMutation({
    onError({ message }) {
      if (message.toLowerCase() === BAD_REQUEST) {
        return Alert.error(BAD_USER_INPUT);
      }
      return Alert.error(firstLetterUppercase(message || ""));
    },

    onCompleted({ register: { emailVerified } }) {
      if (emailVerified) {
        navigate(LOGIN_ROUTE)
        return Alert.success(USER_REGISTERED_SUCCESSFULLY);
      }
    },
  });

  const onSubmit = async (data: SignupInterface) => {
    const { email, fullName, password, phoneNumber, tagLine } = data
    await signUp({
      variables: {
        registerUserInput: {
          email: email,
          fullName: fullName,
          password: password,
          phoneNumber: phoneNumber,
          token: userToken as string,
          tagLine: tagLine
        }
      }
    })
  };

  const { email: userEmail, roleType: userRoleType } = userValue

  useEffect(() => {
    userRoleType && setValue('roleType', userRoleType)
    userEmail && setValue('email', userEmail)
    // eslint-disable-next-line
  }, [userRoleType, userEmail])


  return (
    <Box sx={{ marginTop: "1rem" }}>
      {verifyUserLoading ?
        <Box height='100vh' sx={flexCenter} flexDirection="column">
          <CircularProgress color="primary" />
          <Typography variant="h5" sx={{ marginTop: '2rem', fontWeight: '500' }}>Verifying your token</Typography>
        </Box>
        :
        <AuthLayout title={tokenValid && !isVerified ? SIGN_UP : ""}>
          <Grow in={tokenValid && isVerified} mountOnEnter unmountOnExit>
            <Box textAlign='center'>
              {tokenValid && isVerified &&
                <>
                  <Box marginBottom='1rem'>
                    <CheckCircleIcon sx={{ width: '64px', height: '64px', fill: palette.green }} />
                  </Box>

                  <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>Email Verified!</Typography>
                </>
              }
            </Box>
          </Grow>

          <Grow in={tokenValid && !isVerified} mountOnEnter unmountOnExit>
            <Box marginTop='2rem'>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <CustomController
                    controllerName="fullName"
                    controllerLabel="Full Name"
                    fieldType="text"
                  />

                  <Box marginTop="0.5rem">
                    <CustomController
                      controllerName="tagLine"
                      controllerLabel="Tagline"
                      fieldType="text"
                      tooltipText="This will show beneath your name when customers see your videos"
                    />
                  </Box>
                  <Box marginTop="0.5rem">
                    <CustomController
                      controllerName="email"
                      controllerLabel="Email"
                      fieldType="email"
                      readOnly
                    />
                  </Box>
                  <Box marginTop="0.5rem">
                    <CustomController
                      controllerName="roleType"
                      controllerLabel="Role"
                      fieldType="role"
                      readOnly
                    />
                  </Box>
                  <Box marginTop="0.5rem">
                    <CustomPhoneController
                      controllerName="phoneNumber"
                      controllerLabel="Phone Number"
                    />
                  </Box>
                  <Box marginTop="0.5rem">
                    <CustomController
                      controllerName="password"
                      controllerLabel="Password"
                      fieldType="password"
                      isPassword
                    />
                  </Box>
                  <Box>
                    <CustomController
                      controllerName="repeatPassword"
                      controllerLabel="Re enter your password"
                      fieldType="password"
                      isPassword
                    />
                  </Box>
                  <Box marginTop={3} marginBottom={3}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      color="primary"
                      disabled={loading}
                      endIcon={loading && <CircularProgress size={20} color="inherit" />}
                    >
                      {SIGN_UP}
                    </Button>
                  </Box>
                  <Box
                    marginTop={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h4" sx={{ marginRight: "5px" }}>
                      Already have an account?
                    </Typography>
                    <Typography
                      color="primary"
                      component={Link}
                      to="/login"
                      variant="h4"
                    >
                      Login
                    </Typography>
                  </Box>
                </form>
              </FormProvider>
            </Box>
          </Grow>

          <Grow in={!tokenValid && !isVerified} mountOnEnter unmountOnExit>
            <Box textAlign='center'>
              {!tokenValid && !isVerified &&
                <Box textAlign='center'>
                  <Box marginBottom='1rem'>
                    <ErrorIcon sx={{ width: '64px', height: '64px', fill: palette.red }} />
                  </Box>

                  <Typography variant="h5" sx={{ marginBottom: '.5rem', fontWeight: '500' }}>Token not verified!</Typography>
                </Box>
              }
            </Box>
          </Grow>
        </AuthLayout>
      }
    </Box>
  );
};
