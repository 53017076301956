import { Box } from "@mui/material";
import { forwardRef, Reducer, useContext, useEffect, useImperativeHandle, useReducer, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { AnimatedLoader, PlayerIcon } from "../../../../assets/svgs";
import { VideoOrientation } from "../../../../generated";
import { START_TIMER } from "../../../constants";
import { SocketContext } from "../../../context/SocketContext";
import { WidgetAction, WidgetActionType, widgetInitialState, widgetReducer, WidgetState } from "../../../context/WidgetContextReducer";
import { CustomVideoPlayerInterface, ReactPlayerForwardRef } from "../../../interfaceTypes";
import { AnimatedLoaderWrapper, CustomPlayerVideo } from "../../../theme/styleComponents";
import { videoBoxStyle, VideoLoaderImage, VideoLoaderOverlay } from "../../../theme/styleConstants";

export const CustomVideoPlayer = forwardRef<ReactPlayerForwardRef, CustomVideoPlayerInterface>(({ videoId, videoTitle, videoDescription, thumbnail, isActiveSlide,
  url, isFocus, videoOrientation, goToNextSlide, state: parentState, dispatch: parentDispatcher }, ref) => {
  const [videoState, videoDispatch] = useReducer<Reducer<WidgetState, WidgetAction>>(widgetReducer, widgetInitialState)
  const [videoLoader, setVideoLoader] = useState<boolean>(false);
  const { widgetCounter } = parentState
  const { videoSettings } = videoState
  const { joiningData, hangUpData } = useContext(SocketContext)
  const { url: videoUrl, pip, muted } = videoSettings
  const reactPlayer = useRef<ReactPlayer>(null);
  const videoLoaderRef = useRef<boolean>(videoLoader);

  const loadUrl = (url: string) => {
    videoDispatch({ type: WidgetActionType.SET_VIDEO_SETTINGS, videoSettings: { ...videoSettings, url, playing: true, muted: false } })
    setVideoLoader(true)
    videoLoaderRef.current = true
  }

  const setVideoState = (isPlaying: boolean) => {
    videoDispatch({ type: WidgetActionType.SET_VIDEO_SETTINGS, videoSettings: { ...videoSettings, playing: isPlaying } })
  }

  const onPlayVideo = () => {
    parentDispatcher({ type: WidgetActionType.SET_WIDGET_COUNTER, widgetCounter: widgetCounter + 1 })
  }

  const onPauseVideo = () => {
    setVideoState(false)
  }

  const onEndedVideo = () => {
    goToNextSlide && goToNextSlide()
    reactPlayer.current?.seekTo(0)
    setVideoState(true)

    const startTimerValue = localStorage.getItem(START_TIMER)
    if (startTimerValue !== null && isFocus && startTimerValue === 'true') {
      localStorage.setItem(START_TIMER, "false")
    }
  }

  useImperativeHandle(ref, () => ({
    playChildVideo() {
      onPlayVideo()
    },

    pauseChildVideo() {
      onPauseVideo()
    },
  }));

  useEffect(() => {
    loadUrl(url)
    // eslint-disable-next-line
  }, [url])

  useEffect(() => {
    reactPlayer.current?.seekTo(0)
    isFocus && videoDispatch({ type: WidgetActionType.SET_VIDEO_SETTINGS, videoSettings: { ...videoSettings, url, playing: false, muted: false } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!!joiningData.data.message) {
      onPauseVideo()
    }
    // eslint-disable-next-line
  }, [joiningData?.data?.message])

  useEffect(() => {
    if (!!hangUpData.data.message) {
      onPlayVideo()
    }
    // eslint-disable-next-line
  }, [hangUpData?.data?.message])

  const onReadyVideo = () => {
    setVideoLoader(false)
    setVideoState(true)
  }

  const KioskVideoLoader = () => (
    <>
      <Box sx={{ ...VideoLoaderImage }} component="img" src={thumbnail} />

      <Box sx={{ ...VideoLoaderOverlay }} />

      <AnimatedLoaderWrapper>
        <AnimatedLoader />
      </AnimatedLoaderWrapper>
    </>
  )

  return (
    <CustomPlayerVideo sx={{ backgroundColor: videoOrientation === VideoOrientation.Portrait ? "#1E1E1E" : 'transparent' }}>
      {videoLoader && isActiveSlide && <KioskVideoLoader />}

      <Box sx={{ ...videoBoxStyle(isActiveSlide, videoOrientation) }}>
        <ReactPlayer
          id={videoId}
          className='react-player'
          width='100%'
          height='100%'
          url={videoUrl}
          pip={pip}
          controls={false}
          playing={false}
          muted={muted}
          playIcon={<PlayerIcon />}
          light={!isActiveSlide ? thumbnail : ''}
          onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
          onPlay={onPlayVideo}
          onPause={onPauseVideo}
          onEnded={onEndedVideo}
          playsinline
          onReady={onReadyVideo}
        />
      </Box>
    </CustomPlayerVideo>
  )
})