import { Box, Button, DialogActions } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { BRAND_ID } from '../../../constants';
import { AppContext } from '../../../context';
import { ActionType } from '../../../context/AppContextReducer';
import { AddVideoForKioskProps } from '../../../interfaceTypes';
import { CustomModal } from '../../common/CustomModal';
import { NoDataFound } from '../../common/NoDataFound';
import { VideoSelection } from '../videoLibrary/VideoSettings/VideoSelection';
import { WidgetVideoInput } from '../../../../generated';

export const AddVideoForKiosk: FC<AddVideoForKioskProps> = ({
  widgetId, brandAttachments, videoIds, brandState, brandDispatch, handleDragEnd,
  videosToShow, formValues, updateWidget, setItems
}) => {
  const { isOpen, dispatch } = useContext(AppContext)
  const [idsForVideos, setIdsForVideos] = useState<WidgetVideoInput[]>([])
  const brandId = localStorage.getItem(BRAND_ID)

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
  };

  useEffect(() => {
    if (videoIds.length > 0) {
      const updatedVideoIds = videoIds.map((video, index) => ({ id: video.id, sequenceNo: index + 1, featureImageId: video.featureImageId, bannerImageId: video.bannerImageId }))

      setIdsForVideos(updatedVideoIds)
    }
  }, [videoIds])

  const onFinishClick = async () => {
    if (widgetId && brandId) {
      await updateWidget({
        variables: {
          updateWidgetInput: {
            ...formValues,
            gorgiasTags: undefined,
            videos: idsForVideos,
            id: widgetId,
            brandId
          },
          widgetId
        },
      });

      handleClose()
    }
  }

  return (
    <CustomModal title="Manage Video" maxWidth="1100px" isOpen={isOpen} handleClose={handleClose}>
      <Box>
        {brandAttachments && brandAttachments?.length === 0 ?
          <NoDataFound height='calc(100vh - 440px)' description="Please select videos" button={true} />
          :
          <Box pt={2}>
            <VideoSelection
              brandState={brandState}
              videos={brandAttachments || []}
              gridMd={3}
              brandDispatch={brandDispatch}
              videosToShow={videosToShow}
              handleDragEnd={handleDragEnd}
              setItems={setItems}
            />
          </Box>
        }
      </Box>

      <DialogActions sx={{ paddingRight: '0' }}>
        <Button onClick={handleClose}>
          Cancel
        </Button>

        <Button type='button' variant="contained" color="primary" onClick={onFinishClick} disabled={brandAttachments?.length === 0}>Finish</Button>
      </DialogActions>
    </CustomModal>
  )
}
