import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';
import { FC } from 'react';
import { CustomIconLinkInterface } from '../../interfaceTypes';

export const CustomIconLink: FC<CustomIconLinkInterface> = ({ widgetLink }) => (
  <Button
    sx={{ mb: 2 }}
    endIcon={<OpenInNewIcon />} variant='contained' color='primary'
    target="_blank"
    href={widgetLink}
  >
    Live Preview
  </Button>
);


