import { Box, Skeleton } from "@mui/material";
import { NumberOfMapsProp } from "../../interfaceTypes";
import { skeletonStyleVideoList } from "../../theme/styleConstants";

/**
 * Skeleton component to represent a list of video placeholders.
 * @prop {NumberOfMapsProp} numberOfMap - Number of video placeholders to display.
 * @returns {JSX.Element} - VideoListSkeleton component.
 */
export const VideoListSkeleton = ({ numberOfMap }: NumberOfMapsProp): JSX.Element => (
  <>
    {new Array(numberOfMap).fill(0).map((_, index) => (
      <Box display="block" marginTop={3} border="1px solid lightgrey" borderRadius="5px" key={index}>
        <Box sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' }, p: { xs: '10px' } }}>
          <Skeleton variant="rectangular"
            sx={{ width: { sm: '266px', xs: '100%' }, px: { xs: '20px' }, height: '170px' }}
          />

          <Box marginTop='25px' marginLeft='40px'>
            <Skeleton variant="text" width={100} />

            <Box marginTop={1}>
              <Skeleton variant="text" width={150} />
            </Box>

            <Box marginTop={1}>
              <Skeleton variant="text" width={120} />
            </Box>

            <Box sx={skeletonStyleVideoList}>
              <Skeleton variant="text" width={200} />
            </Box>
          </Box>
        </Box>
      </Box>
    ))}
  </>
);
