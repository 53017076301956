import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, Button, Collapse, Typography } from "@mui/material";
import { Mention, MentionSearchEvent, MentionSelectEvent } from "primereact/mention";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AttachmentType, Maybe, User, UserPickType, useCreateCommentMutation, useUpdateCommentMutation } from "../../../generated";
import { RecordedVideoContext } from "../../context/RecordedVideoContext";
import { CommentObject, CommentTextFieldProps, CreateComment } from "../../interfaceTypes";
import { MentionWrapper } from "../../theme/styleComponents";
import { itemMentionTypography, itemTemplateBox } from "../../theme/styleConstants";
import { commentValidationSchema } from "../../validationSchema";
import { VideoDetailActionType } from "../../context/VideoDetailContextReducer";
import { useParams } from "react-router-dom";
import { formatTimeToCustomFormat } from "../../utils";
import { Alert } from "../common/Alert";

/**
* Component for rendering a single comment field.
* @returns {JSX.Element}
*/
export const CommentTextField = ({ isCommentOpen, setIsCommentOpen, speakerData, setCommentArray, commentArray, isEdit, singleComment, setIsEdit }: CommentTextFieldProps): JSX.Element => {
  const { allUsers, dispatch } = useContext(RecordedVideoContext)
  const [suggestions, setSuggestions] = useState<Maybe<Array<Maybe<UserPickType>>> | undefined>([]);
  const [commentObject, setCommentObject] = useState<Array<CommentObject>>([])
  const [atHere, setAtHere] = useState<boolean>(false)
  const [everyUser, setEveryUser] = useState<any>([])
  const { id } = useParams();
  const { startTime, id: sentenceId } = speakerData
  const { comment, id: commentId } = singleComment ?? {}

  const methods = useForm<CreateComment>({
    mode: "all",
    resolver: yupResolver(commentValidationSchema),

    defaultValues: {
      value: comment || ''
    }
  });

  const { handleSubmit, reset, control, setValue } = methods;

  const [createComment] = useCreateCommentMutation({
    onError() {
      Alert.error("Comment not added")
    },

    onCompleted(data) {
      if (data) {
        const { createComment } = data

        if (createComment) {
          Alert.success("Comment added successfully")
          setCommentArray([...commentArray, createComment])
        }
        reset();
      }
    }
  })

  const [updateComment] = useUpdateCommentMutation({
    onError() {
      Alert.error("Comment not updated")
    },

    onCompleted(data) {
      if (data) {
        const { updateComment } = data
        if (updateComment) {
          Alert.success("Comment updated successfully")

          // setting updated comment in local comment array 
          setCommentArray(commentArray.map(comment => {
            return comment.id === updateComment.id ? updateComment : comment
          }))
          setIsEdit && setIsEdit(false)
        }
      }
    }
  })

  const onSubmit = (data: CreateComment) => {
    const { value } = data;
    const userIds = commentObject.map(user => {
      const { email, assignedUserId } = user

      if (email) {
        if (value.includes(email)) {
          return assignedUserId
        }
      }

      return ""
    })

    const updatedUserIds = userIds.filter(userId => userId !== "");
    reset();
    setCommentObject([])
    setIsCommentOpen(false)
    setAtHere(false)
    const seekTo = formatTimeToCustomFormat(Number(startTime))
    if (id && sentenceId) {
      if (!isEdit) {
        createComment({
          variables: {
            createCommentPayload: {
              transcriptionId: sentenceId,
              scheduleEventId: id,
              comment: value,
              assignTo: !atHere ? updatedUserIds : everyUser,
              seekTo,
            }
          }
        })
      } else if (commentId) {
        updateComment({
          variables: {
            updateCommentInput: {
              commentId: commentId,
              transcriptionId: sentenceId,
              scheduleEventId: id,
              comment: value,
              assignTo: !atHere ? updatedUserIds : everyUser,
              seekTo,
            }
          }
        })
      }
    }
  };

  const onSearch = (event: MentionSearchEvent) => {
    setTimeout(() => {
      const query = event.query;
      let suggestions;

      if (!query.trim().length) {
        suggestions = allUsers?.filter((user) => user);
      } else {
        suggestions = allUsers?.filter((user) => {
          const { fullName } = user || {}
          if (fullName) { return fullName.toLowerCase().startsWith(query.toLowerCase()) }
          else return ""
        });
      }

      setSuggestions(suggestions);
    }, 250);
  };

  const onSelect = (event: MentionSelectEvent) => {
    const selectedSuggestion = event.suggestion as User;

    if (selectedSuggestion) {
      const { fullName, email, id } = selectedSuggestion || {};
      setCommentObject([...commentObject, { assignedUserId: id, email }]);

      if (fullName && email) {
        const updatedValue = `${methods.getValues('value')}(${email})`;
        setValue('value', updatedValue);
      }
    }
  };

  const commentDismissHandler = () => {
    setIsCommentOpen(false)
    dispatch({ type: VideoDetailActionType.SET_IS_SCROLLING, isScrolling: false })
    setIsEdit && setIsEdit(false)
  }

  const truncateEmail = (email: string | undefined) => {
    if (email) {
      const truncatedEmail = email.slice(0, 22)
      return truncatedEmail
    }
  }

  useEffect(() => {
    setEveryUser(allUsers?.map(user => user?.id || ""))
    // eslint-disable-next-line
  }, [atHere])

  const handleChange = (value: string) => {
    if (value.includes("@here")) {
      setAtHere(true)
    }
  }

  const itemTemplate = (suggestion: Maybe<Maybe<User>>) => {
    const { fullName, email, attachments } = suggestion || {}
    const { url } = attachments?.find(item => item.type === AttachmentType.Logo) || {}

    return (
      <Box sx={itemTemplateBox}>
        <Avatar src={url ?? ''} style={{ marginLeft: '10px' }} />
        <Typography component='span' sx={itemMentionTypography}>
          @{fullName}
          <Typography fontSize='12px'>
            {`${truncateEmail(email)}...`}
          </Typography>
        </Typography>
      </Box>
    )
  };

  const buttonText = isEdit ? "Update" : "Add";

  return (
    <Collapse in={isCommentOpen || isEdit} unmountOnExit mountOnEnter sx={{ flex: 1 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="field">
            <Controller
              name="value"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MentionWrapper isEdit={isEdit}>
                  <>
                    <Mention
                      {...field}
                      id={field.name}
                      autoHighlight
                      field='fullName'
                      spellCheck="false"
                      rows={1}
                      autoResize
                      suggestions={suggestions || []}
                      onSearch={onSearch}
                      placeholder="Please enter @ to mention people"
                      itemTemplate={itemTemplate}
                      onSelect={onSelect}
                      onChange={(event) => {
                        const { target: { value } } = event as unknown as ChangeEvent<{ value: string, name?: string } & (HTMLInputElement)>

                        field.onChange(value)
                        handleChange(value)
                      }}
                    />

                    {error && <Box color='#d50000'>{error.message}</Box>}
                  </>
                </MentionWrapper>
              )}
            />
          </Box>

          <Collapse in={isCommentOpen || isEdit} unmountOnExit mountOnEnter>
            <Box display='flex' justifyContent='flex-end' marginTop='10px' marginBottom='10px'>
              <Button onClick={commentDismissHandler} sx={{ color: 'grey' }}>
                Dismiss
              </Button>

              <Button sx={{ background: 'rgba(95, 64, 166, 1)', color: 'white', "&:hover": { background: 'rgba(95, 64, 166, 1)' } }} type='submit'>
                {buttonText}
              </Button>
            </Box>
          </Collapse>
        </form>
      </FormProvider>
    </Collapse>
  )
}