import { blueGrey } from "@mui/material/colors";
import palette from "../palette";

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        WebkitFontSmoothing: 'auto',
      },

      "*": {
        boxSizing: 'border-box',
      },

      "a:active": {
        textDecoration: 'none'
      },

      'a': {
        textDecoration: 'none',
      },

      body: {
        backgroundColor: '#F8F8F9',

        "& .MuiMenu-paper": {
          maxHeight: '180px'
        },

        "& .SnackbarContainer-root": {
          zIndex: '99999999 !important',
        },

        "& .SnackbarItem-wrappedRoot": {
          top: '80px !important'
        },

        "& .SnackbarItem-message": {
          letterSpacing: 0
        },

        "& .videoContentCard": {
          "& img": {
            transition: 'all .3s ease',
            position: 'absolute',
            top: 0,
            left: 0
          },

          "& .gifImage": {
            opacity: 0
          },

          "&:hover": {
            "& .jpgImage": {
              opacity: 0
            },

            "& .gifImage": {
              opacity: 1
            },

            "& .playerIcon": {
              opacity: 0
            }
          },
        },
      },
    },
  },

  MuiPopover: {
    styleOverrides: {
      root: {
        zIndex: '99999'
      }
    }
  },

  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        zIndex: '999999',

        "& ul": {
          maxHeight: "10rem",
          paddingTop: '8px',
          paddingBottom: '8px',
          border: `1px solid ${palette.lightGray}`
        }
      }
    }
  },

  MuiChip: {
    styleOverrides: {
      colorSuccess: {
        color: palette.green,
        borderColor: palette.green
      },
    }
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.87)',

        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.primary.main,
          },

        },

        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1px',
            borderColor: palette.primary.main,
          }
        },

        '&.Mui-disabled': {
          opacity: 0.5,
        }
      },

      notchedOutline: {
        // borderColor: steelFog,
      }
    }
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        "&:before": {
          borderBottom: '0 !important',
        },
      }
    }
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.54)'
      }
    },
  },

  MuiTableCell: {
    styleOverrides: {
      head: {
        backgroundColor: blueGrey[50],
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        padding: '10px 24px',
        borderRadius: '4px',
        textTransform: "capitalize" as const
      },

      containedSecondary: {
        color: palette.white
      }
    }
  },

  MuiStepper: {
    styleOverrides: {
      root: {
        '& .MuiStepLabel-label.Mui-active': {
          color: palette.secondary.main,
        },
        '& .Mui-active svg': {
          color: palette.secondary.main,
        },
        '& .MuiStepIcon-text': {
          fontSize: '12px'
        },
        '& .MuiStepIcon-root.Mui-completed': {
          color: palette.secondary.main,
        },
        '& .MuiStepLabel-label.Mui-completed': {
          color: palette.secondary.main,
        },
        "& .Mui-disabled": {
          color: '#000',
          fontWeight: '500',
          fontSize: '14px',
        },

        "& .Mui-disabled svg": {
          color: 'rgba(255, 89, 150, 0.16)',
          border: '1px solid #FF5996',
          borderRadius: '77px',
        },
        "& .Mui-disabled svg text": {
          fill: '#FF5996',
        },
      }
    }
  },

  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiTab-root': {
          fontSize: '14px',
          fontWeight: '500',
          color: '#635C72'
        },
        '& .Mui-selected': {
          color: palette.primary.main
        }
      }
    }
  },

  MuiPagination: {
    styleOverrides: {
      root: {
        '& .MuiPaginationItem-page': {
          backgroundColor: palette.white,
          color: palette.black,
          border: `1px solid ${palette.light}`,
          transition: '0.3s',
          "&:hover": {
            border: `1px solid ${palette.primary.main}`,
            backgroundColor: palette.lightTen,
            color: palette.primary.main,
          }
        },
        '& .MuiPaginationItem-page.Mui-selected': {
          border: `1px solid ${palette.primary.main}`,
          backgroundColor: palette.lightTen,
          color: palette.primary.main,
          "&:hover": {
            backgroundColor: 'transparent',
            border: `1px solid ${palette.primary.main}`,
          }
        }
      }
    }
  },

  MuiListItemButton: {
    styleOverrides: {
      root: {
        color: palette.lightBlack,
        borderBottom: '2px solid transparent',
        fontSize: '14px',
        fontWeight: '500',
        "&:hover": {
          backgroundColor: 'transparent',
          "&:after": {
            content: '""',
            borderBottom: `2px solid ${palette.primary.main}`,
            position: 'absolute',
            height: '2px',
            width: '100%',
            bottom: '-10px',
            left: '0',
          }
        },
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          fontWeight: '600',
          color: palette.primary.main,
          "&::after": {
            content: '""',
            borderBottom: `2px solid ${palette.primary.main}`,
            position: 'absolute',
            height: '2px',
            width: '100%',
            bottom: '-10px',
            left: '0',
          },
          "&:hover": {
            backgroundColor: 'transparent',
          },
        }
      }
    }
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      }
    }
  },

  MuiTable: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        boxShadow: 'none',

        th: {
          fontWeight: '600',
          borderBottom: '0.5px solid rgba(0, 0, 0, 0.12)'
        },

        td: {
          fontWeight: '400',
        }
      }
    }
  },

  MuiListItem: {
    styleOverrides: {
      root: {
        fontSize: '16px',
      }
    }
  },

  MuiList: {
    styleOverrides: {
      root: {
        border: '1px solid rgba(0, 0, 0, 0.12)'
      }
    }
  },


  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.white,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
        borderBottom: 'transparent',
        borderWidth: '1px',
        "&.Mui-focused": {
          borderBottom: palette.primary.main,
        }
      }
    }
  },

  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '& .MuiFormControlLabel-label': {
          width: '100%',
        }
      }
    }
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '12px',
        marginLeft: '0',
      }
    }
  },

  MuiAccordion: {
    styleOverrides: {
      root: {
        padding: '0',
        borderRadius: '4px',

        '& .MuiAccordionSummary-content': {
          marginTop: '0px',
          marginBottom: '0px',
          padding: '0',

          '&.Mui-expanded': {
            margin: "0 0",
          },
        },

        "& .MuiAccordionSummary-root": {
          padding: '0',

          '&.Mui-expanded': {
            minHeight: '48px',
            margin: "0 0",
          },
        },

        '& .MuiAccordionDetails-root': {
          padding: "10px 0 0 0",
          borderTop: '0.5px solid rgba(0, 0, 0, 0.12)',
        },
      }
    }
  },
}

export default components;