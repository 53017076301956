import { Box, List, ListItem, Typography } from '@mui/material'
import Highlight from 'react-highlight'
import { stickyHeadingEvents, testingApi } from '../../theme/styleConstants'

export const PageBlockEvents = () => (
  <Box sx={testingApi}>
    <Typography sx={stickyHeadingEvents} variant='caption' component='div' mb={2}>Page Block Widget</Typography>

    <Typography variant='h4'>Subscribe Event</Typography>

    <Highlight className='javascript'>{`cabaPageBlock.subscribeEvent('eventName', (event) => console.log(event))`}</Highlight>

    <Typography variant='h4'>Un-subscribe Event</Typography>

    <Highlight className='javascript'>{`cabaPageBlock.unSubscribeEvent('eventName', (event) => console.log(event))`}</Highlight>

    <Typography variant='h4'>Fetch page block Widget Events</Typography>

    <Highlight>{`const allEventNames = cabaPageBlock.fetchBlockEvents()`}</Highlight>

    <Typography variant='h4'>Response Payload:</Typography>

    <Highlight>
      {`{
  "blockClickedLiveShowroom": "blockClickedLiveShowroom",
  "blockClickedCustomerSupport": "blockClickedCustomerSupport",
  "blockFormSubmitted": "blockFormSubmitted",
  "blockBookedCalendar": "blockBookedCalendar",
  "blockHookVideoTriggered": "blockHookVideoTriggered",
  "blockVideoPlay": "blockVideoPlay",
  "openBlockClientTellingFormMobile": "openBlockClientTellingFormMobile"
}`}
    </Highlight>

    <Typography variant='h4'>For example to get single event name</Typography>

    <Highlight>{`allEventNames.blockClickedLiveShowroom`}</Highlight>

    <Typography variant='h4'>Now you can use this as</Typography>

    <Highlight>{`cabaPageBlock.subscribeEvent(allEventNames.blockClickedLiveShowroom, (event) => console.log(event))`}</Highlight>

    <Typography variant='h4' component='div' mb={2}>Page Block Widget Events</Typography>

    <List disablePadding sx={{ listStyleType: 'disc', listStylePosition: 'inside', border: '0', mb: 2 }}>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>blockClickedLiveShowroom.</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>blockClickedCustomerSupport.</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>blockBookedCalendar</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>blockFormSubmitted</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>blockHookVideoTriggered</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>blockVideoPlay</ListItem>
    </List>

    <Typography component='div' variant='caption' mb={2}>Event: blockClickedLiveShowroom</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Page Block Widget",
  "action": "blockClickedLiveShowroom"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: blockClickedCustomerSupport</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Page Block Widget",
  "action": "blockClickedCustomerSupport"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: blockBookedCalendar</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Page Block Widget",
  "action": "blockBookedCalendar",
  "calendarUrl": "https://cal.com/john-doe/15min",
  
  "customerDetail": {
    "name": "John Doe",
    "email": "john.doe@example.com",
    "phone": "(1234) 567-9010"
  },
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: blockFormSubmitted</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Page Block Widget",
  "action": "blockFormSubmitted",

  "customerDetail": {
     "name": "John Doe",
     "email": "john.doe@example.com",
     "phone": "(1234) 567-9010"
  }
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: blockHookVideoTriggered</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Page Block Widget",
  "action": "blockHookVideoTriggered"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: blockVideoPlay</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`{
  "widgetType": "Page block Widget",
  "action": "blockVideoPlay",

  "video": {
    "url": "https://example.com/video.mp4",
    "videoTitle": "test new video",
    "videoDescription": "landscape"
  }
}`}
    </Highlight>
  </Box>
)
