import { yupResolver } from "@hookform/resolvers/yup";
import { Edit } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton } from "@mui/material";
import { FC, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateEmailAliasMutation } from "../../../generated";
import { EMAIL_UPDATED } from "../../constants";
import { emailEditSaveParentBox } from "../../theme/styleConstants";
import { emailAliasFormValidationSchema } from "../../validationSchema";
import { Alert } from "../common/Alert";
import { CustomController } from "../common/CustomController";
import { ActionType } from "../../context/AppContextReducer";
import { AppContext } from "../../context";
import { UserActionType } from "../../context/UserContextReducer";
import { EmailAliasProps } from "../../interfaceTypes";

export const EmailInputField: FC<EmailAliasProps> = ({ userDispatch, emailData }) => {
  const { dispatch } = useContext(AppContext)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const [updateEmailAlias] = useUpdateEmailAliasMutation({
    onCompleted(data) {
      if (data) {
        Alert.success(EMAIL_UPDATED)
        setIsEdit(false);
      }
    }
  })

  const onDeleteClick = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: true })
    emailData && userDispatch({ type: UserActionType.SET_CURRENT_EMAIL, currentEmail: emailData })
  }

  const handleEditClick = () => {
    setIsEdit(!isEdit)
  }

  const { id: emailId, email } = emailData || {}

  const onSubmit = async (data: any) => {
    const { email: formEmail } = data || {};

    if (emailId) {
      await updateEmailAlias({
        variables: {
          email: formEmail,
          emailId
        }
      })
    }
  }

  const methods = useForm<{ email: string }>({
    mode: "all",
    resolver: yupResolver(emailAliasFormValidationSchema),

    defaultValues: {
      email,
    }
  });

  const { handleSubmit } = methods;

  return (
    <Box width="100%">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box sx={emailEditSaveParentBox}>
            <CustomController
              controllerName="email" isDisabled={!isEdit}
              controllerLabel={isEdit ? "Email" : ""}
              fieldType="email"
            />

            <Box display="flex">
              {!isEdit ? (
                <>
                  <IconButton onClick={handleEditClick}>
                    <Edit color="primary" sx={{ width: '16px', height: '16px' }} />
                  </IconButton>

                  <IconButton onClick={onDeleteClick}>
                    <DeleteIcon color="error" sx={{ width: '16px', height: '16px' }} />
                  </IconButton>
                </>
              )
                :
                <>
                  <IconButton onClick={handleEditClick}>
                    <ArrowBackIcon sx={{ width: '16px', height: '16px' }} />
                  </IconButton>

                  <IconButton type="submit">
                    <SaveIcon color="primary" sx={{ width: '16px', height: '16px' }} />
                  </IconButton>
                </>
              }
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}