import { Box, Skeleton } from "@mui/material";
import { FC } from "react";
import { WidgetSkeletonProps } from "../../../interfaceTypes";
import { WidgetBox } from "../../../theme/styleComponents";
import { widgetBoxSibling } from "../../../theme/styleConstants";

export const WidgetsSkeleton: FC<WidgetSkeletonProps> = ({ noOfRows }) => (
  <Box>
    {new Array(noOfRows).fill(0).map((_, index) => (
      <WidgetBox sx={widgetBoxSibling} key={`${index}-skeleton`}>
        <Box flex='1' position='relative'>
          <Skeleton variant="text" width={80} height={30} />
          <Skeleton variant="text" width={30} height={30} />
          <Skeleton variant="text" sx={{ marginTop: '2rem' }} width={50} height={30} />
        </Box>

        <Skeleton variant="text" width={83} height={83} />
      </WidgetBox>
    ))}
  </Box>
)
