import { Box, List, ListItem, Typography } from '@mui/material'
import Highlight from 'react-highlight'
import { stickyHeadingEvents, testingApi } from '../../theme/styleConstants'

export const SiteWidgetEvents = () => (
  <Box sx={testingApi}>
    <Typography variant='caption' component='div' mb={2} sx={stickyHeadingEvents}>Site Widget</Typography>

    <Typography variant='h4'>Subscribe Event</Typography>

    <Highlight className='javascript'>
      {`caba.subscribeEvent('eventName', (event) => console.log(event))`}
    </Highlight>

    <Typography variant='h4'>Un-subscribe Event</Typography>

    <Highlight className='javascript'>{`caba.unSubscribeEvent('eventName', (event) => console.log(event))`}</Highlight>

    <Typography variant='h4'>Fetch Site Wdget Events</Typography>

    <Highlight>{`const allEventNames = caba.fetchSiteEvents()`}</Highlight>

    <Typography variant='h4'>Response Payload:</Typography>

    <Highlight>
      {`{
  "openSiteWidget": "openSiteWidget",
  "maximizeSiteWidget": "maximizeSiteWidget",
  "minimizeSiteWidget": "minimizeSiteWidget",
  "siteClickedLiveShowroom": "siteClickedLiveShowroom",
  "siteClickedCustomerSupport": "siteClickedCustomerSupport",
  "siteFormSubmitted": "siteFormSubmitted",
  "siteBookedCalendar": "siteBookedCalendar",
  "openSiteClientTellingFormMobile": "openSiteClientTellingFormMobile"
}`}
    </Highlight>

    <Typography variant='h4'>For example to get single event name</Typography>

    <Highlight>{`allEventNames.maximizeSiteWidget`}</Highlight>

    <Typography variant='h4'>Now you can use this as</Typography>

    <Highlight>{`caba.subscribeEvent(allEventNames.maximizeSiteWidget, (event) => console.log(event))`}</Highlight>

    <Typography variant='h4' component='div' mb={2}>Site Widget</Typography>

    <List disablePadding sx={{ listStyleType: 'disc', listStylePosition: 'inside', border: '0', mb: 2 }}>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>openSiteWidget.</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>maximizeSiteWidget.</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>minimizeSiteWidget</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>siteClickedLiveShowroom</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>siteClickedCustomerSupport</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>siteFormSubmitted</ListItem>
      <ListItem disablePadding sx={{ display: "list-item", fontSize: '16px' }}>siteBookedCalendar</ListItem>
    </List>

    <Typography component='div' variant='caption' mb={2}>Event: openSiteWidget</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>{`details: {
  "widgetType": "Site Widget",
  "action": "openSiteWidget"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: maximizeSiteWidget</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Site Widget",
  "action": "maximizeSiteWidget"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: minimizeSiteWidget</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Site Widget",
  "action": "minimizeSiteWidget"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: siteClickedLiveShowroom</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Site Widget",
  "action": "siteClickedLiveShowroom"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: siteClickedCustomerSupport</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Site Widget",
  "action": "siteClickedCustomerSupport"
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: siteBookedCalendar</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Site Widget",
  "action": "siteBookedCalendar",
  "calendarUrl": "https://cal.com/john-doe/15min"
  
  "customerDetail": {
    "name": "John Doe",
    "email": "john.doe@example.com",
    "phone": "(1234) 567-9010"
  },
}`}
    </Highlight>

    <Typography component='div' variant='caption' mb={2}>Event: siteFormSubmitted</Typography>

    <Typography variant='h4' sx={{ fontWeight: '600' }}>Payload Response:</Typography>

    <Highlight>
      {`details: {
  "widgetType": "Site Widget",
  "action": "siteFormSubmitted",

  "customerDetail": {
    "name": "John Doe",
    "email": "john.doe@example.com",
    "phone": "(1234) 567-9010"
  }
}`}
    </Highlight>
  </Box>
)
