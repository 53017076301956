import { Box } from '@mui/material';
import { FC } from 'react'
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { GalleryBox } from '../../../theme/styleComponents'
import { portraitGalleryICons } from '../../../theme/styleConstants';
import { PortraitGalleryImageBox } from './PortraitGalleryImageBox';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const PortraitGalleryVideos: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  const fourthImage = thumbnailUrls && thumbnailUrls[3]
  const fifthImage = thumbnailUrls && thumbnailUrls[4]
  const sixthImage = thumbnailUrls && thumbnailUrls[5]
  return (
    <>
      <Box position='relative' overflow='hidden'>
        <Box position='absolute' top='40%' left='0' zIndex={10}>
          <Box sx={{ ...portraitGalleryICons, borderTopRightRadius: '20px', borderBottomRightRadius: '20px' }}><ChevronLeftIcon fontSize='medium' sx={{ marginLeft: '-10px' }} /></Box>
        </Box>

        <Box paddingY={3} minHeight="200px" display='flex' sx={{ transform: `scale(1)` }}>
          <GalleryBox>
            <PortraitGalleryImageBox width='30%' marginRight='16px' marginLeft="-32px" height='147.56px;' imageUrl={sixthImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />

            <PortraitGalleryImageBox width='30%' marginRight='16px' height='147.56px;' imageUrl={fourthImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />

            <PortraitGalleryImageBox width='30%' marginRight='16px' height='147.56px;' imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />

            <PortraitGalleryImageBox width='30%' marginRight='16px' height='147.56px;' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />

            <PortraitGalleryImageBox width='30%' marginRight='16px' height='147.56px;' imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />

            <PortraitGalleryImageBox width='30%' marginRight='-32px' height='147.56px;' imageUrl={fifthImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />
          </GalleryBox>
        </Box>

        <Box position='absolute' top='40%' right='0' zIndex={10}>
          <Box sx={{ ...portraitGalleryICons, borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}><ChevronRightIcon fontSize='medium' sx={{ marginLeft: '10px' }} /></Box>
        </Box>
      </Box>
    </>
  )
}
