
import { FC } from "react";
import AddIcon from '@mui/icons-material/Add';
import { UploadVideoBox } from "../../../theme/styleComponents";
import { AddVideoProps } from "../../../interfaceTypes";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { flexCenter } from "../../../theme/styleConstants";

export const AddVideo: FC<AddVideoProps> = ({ handleModalOpen, height }) => (
  <UploadVideoBox height={height} sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
    <Box height="100%" sx={flexCenter} flexDirection="column">
      <AddIcon color='primary' sx={{ width: '3rem', height: '3rem' }} />

      <Typography variant="h6" color='primary'>Upload Video</Typography>
    </Box>
  </UploadVideoBox>
)