// packages block
import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RenderCheckBoxProps, animateLayoutChangesType } from '../../../interfaceTypes';
import palette from '../../../theme/palette';
import { StyledCheckbox } from '../../../theme/styleComponents';
import { VideoLibrary } from './VideoLibrary';
import { VideoOrientation } from '../../../../generated';

const animateLayoutChanges = (args: animateLayoutChangesType) =>
  args.wasDragging ? defaultAnimateLayoutChanges(args) : true;

export const RenderCheckBox: FC<RenderCheckBoxProps> = ({ video, index, videoIds, videosToShow, handleCheckboxes }): JSX.Element => {
  const { control, watch } = useFormContext();
  const isPortraitValue = watch('isPortrait')
  const { id, thumbnail, title, videoOrientation } = video
  const { url } = thumbnail || {}
  const { white } = palette

  const sortable = useSortable({
    id,
    animateLayoutChanges,
    transition: {
      duration: 350,
      easing: 'ease-in-out'
    }
  });

  const { attributes, listeners, setNodeRef, transform, transition } = sortable || {};

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isDisabled = (videoIds.length === videosToShow && videoIds?.filter(video => video.id === id).length === 0)
  const isChecked = videoIds?.filter(video => video.id === id).length > 0

  return (
    <Box position='relative' ref={setNodeRef} {...listeners} style={style} {...attributes} >
      <FormControlLabel
        sx={{ width: '100%', margin: '0' }}
        control={
          <Controller
            name="videos"
            control={control}
            render={({ field }) => (
              <StyledCheckbox
                {...field}
                value={id}
                disabled={!isPortraitValue ? isDisabled : false}
                checked={isChecked}
                onChange={() => {
                  handleCheckboxes(id || "", index)
                  field.onChange(id || "")
                }}
              />
            )}
          />
        }

        label={
          <Box sx={{ backgroundColor: white, opacity: isDisabled ? '0.4' : '1', cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
            <VideoLibrary
              index={index}
              title={title || ""}
              imageUrl={url || ""}
              isChecked={isChecked}
              isPortrait={videoOrientation === VideoOrientation.Portrait}
            />
          </Box>
        }
      />
    </Box>
  );
};