import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, CircularProgress, DialogActions, FormControl, InputLabel, ListItem, MenuItem, Select, TextField, Collapse } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Brand, useGetAllBrandsLazyQuery, useInviteUserMutation, UserRoles } from '../../../generated';
import { CONFLICT, EMAIL_ALREADY_IN_USE, GRAPHQL_QUERY_POLICY, INVITED_USER_SUCCESSFULLY, SOMETHING_WENT_WRONG } from '../../constants';
import { AppContext, AuthContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { InviteTest } from '../../interfaceTypes';
import { firstLetterUppercase } from '../../utils';
import { inviteNewStaffSchema } from '../../validationSchema';
import { Alert } from '../common/Alert';
import { CustomController } from '../common/CustomController';
import { CustomModal } from '../common/CustomModal';

export const UsersInvitationModal: FC = () => {
  const { setIsLoggedIn, isBrandManager } = useContext(AuthContext);
  const { isOpen, dispatch, selectedBrand } = useContext(AppContext);
  const { isAdmin } = useContext(AuthContext);
  const [brandsList, setBrandsList] = useState<Array<Brand>>([])
  const { id: selectedBrandId, name: selectedBrandName } = selectedBrand || {}

  const [getAllBrands] = useGetAllBrandsLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      return Alert.error(SOMETHING_WENT_WRONG)
    },

    onCompleted(data) {
      const { getAllBrands } = data || {}
      if (getAllBrands) {
        setBrandsList([...getAllBrands])
      }
    }
  });

  const methods = useForm<InviteTest>({
    mode: 'all',
    resolver: yupResolver(inviteNewStaffSchema(isAdmin)),

    defaultValues: {
      email: "",
      roleType: UserRoles.Operator,
      brandId: "Select Brand",
      customBrandId: isBrandManager ? { id: selectedBrandId || '', label: selectedBrandName || '' } : { id: '', label: '' }
    }
  })

  const { control, formState: { errors } } = methods;

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
    reset();
  };

  const { handleSubmit, reset, watch } = methods;

  const [inviteUser, { loading }] = useInviteUserMutation({
    onError({ message }) {
      if (message.toLowerCase() === CONFLICT) {
        return Alert.error(EMAIL_ALREADY_IN_USE);
      }

      return Alert.error(firstLetterUppercase(message || ""));
    },

    onCompleted({ inviteUser }) {
      setIsLoggedIn(true);
      reset();
      dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
      if (inviteUser) {
        return Alert.success(INVITED_USER_SUCCESSFULLY);
      }
    }
  })

  const roleValue = watch("roleType");

  const onSubmit = async (data: InviteTest) => {
    const { customBrandId: { id }, email, roleType } = data
    const dataBrandId = id || localStorage.getItem('caba_brand_id') || ''

    await inviteUser({
      variables: {
        inviteUserInput: {
          brandId: dataBrandId,
          email,
          roleType
        }
      }
    })
  }

  useEffect(() => {
    getAllBrands()
    // eslint-disable-next-line
  }, [])

  const customErrors: any = errors["customBrandId"]

  return (
    <CustomModal maxWidth='600px' isOpen={isOpen} handleClose={handleClose}
      title="Invite new staff"
      subTitle='Add new staff member by inviting via email.'
    >
      <Box marginTop='1rem'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box>
              <CustomController
                controllerName='email'
                controllerLabel='Email'
                fieldType='email'
              />
            </Box>

            <Box>
              <FormControl sx={{ marginTop: '.5rem' }} fullWidth>
                <InputLabel id="userRolesLabel">Role</InputLabel>
                <Controller
                  name="roleType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      labelId="userRolesLabel"
                      id="userRoles"
                      defaultValue={UserRoles.Operator}
                      {...field}
                      fullWidth
                      label="Role"
                    >
                      {isAdmin && <MenuItem value={UserRoles.Admin}>Admin</MenuItem>}
                      <MenuItem value={UserRoles.Operator}>Operator</MenuItem>
                      <MenuItem value={UserRoles.BrandManager}>Brand Manager</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <Collapse in={isAdmin && roleValue !== UserRoles.Admin}>
                <FormControl variant="outlined" sx={{ marginTop: 1.5, marginBottom: 1, }} fullWidth>
                  <Controller
                    name='customBrandId'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        fullWidth
                        value={value}
                        onChange={(event, item) => {
                          onChange(item)
                        }}
                        renderOption={(props, option) => {
                          const { id, label } = option || {}

                          return (
                            <ListItem {...props} key={id}>
                              {label}
                            </ListItem>
                          )
                        }}
                        disableClearable
                        options={brandsList?.map(item => ({ label: item?.name, id: item?.id }))}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!customErrors?.id?.message}
                            helperText={customErrors?.id?.message || ''}
                            label="Brand"
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Collapse>

              <DialogActions sx={{ paddingX: '0px', paddingTop: '1rem', paddingBottom: '1rem' }}>
                <Button fullWidth variant="contained" color="primary" type='submit' disabled={loading}
                  endIcon={loading && <CircularProgress size={20} color="inherit" />}
                >
                  Invite
                </Button>
              </DialogActions>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </CustomModal>
  )
}
