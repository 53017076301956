import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import { Reducer, useContext, useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { ApiKey, ApiKey as ApiKeyType, useGetAllBrandKeysLazyQuery } from '../../../generated';
import { BRAND_ID, DATE_FORMAT, GRAPHQL_QUERY_POLICY, NO_USER_FOUND } from '../../constants';
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { BrandState } from '../../interfaceTypes';
import { apiKeyHeader, apiKeyHeading } from '../../theme/styleConstants';
import { Alert } from '../common/Alert';
import { DeleteApiKey } from '../common/DeleteApiKey';
import { GenerateApiKey } from '../common/GenerateApiKey';
import { NoDataFound } from '../common/NoDataFound';
import { PageHeading } from '../common/PageHeading';
import { StickyTable } from '../common/StickyTable';
import { ApikeySkeleton } from '../skeletons/ApikeySkeleton';
import { BrandAction, BrandActionType, brandInitialState, brandReducer } from './reducer/brandReducer';

export const ApiIntegration = () => {
  const { isOpen, isDeleteOpen, dispatch } = useContext(AppContext)
  const [brandState, brandDispatch] = useReducer<Reducer<BrandState, BrandAction>>(brandReducer, brandInitialState);
  const { brandApiKeys, currentApiKey } = brandState
  const currentBrandId = localStorage.getItem(BRAND_ID)

  const handleGenerateApiKey = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: !isOpen })
  };

  const handleDeleteApiKey = (apiKey: (ApiKey | null)) => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: !isDeleteOpen })
    brandDispatch({ type: BrandActionType.SET_CURRENT_BRAND_API_KEY, currentApiKey: apiKey })
  };

  const [getAllBrandKeys, { loading }] = useGetAllBrandKeysLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      Alert.error(NO_USER_FOUND)
    },

    onCompleted(data) {
      const { getAllBrandKeys } = data
      if (getAllBrandKeys) {
        brandDispatch({ type: BrandActionType.SET_BRAND_API_KEYS, brandApiKeys: getAllBrandKeys })
      }
    }
  })

  const isEmpty = brandApiKeys?.length === 0 && !loading

  useEffect(() => {
    if (currentBrandId) {
      getAllBrandKeys({
        variables: {
          brandId: currentBrandId
        }
      })
    }
    // eslint-disable-next-line
  }, [currentBrandId])

  return (
    <>
      <Box sx={apiKeyHeader}>
        <Box sx={apiKeyHeading}>
          <PageHeading marginTop='0' title='Api keys' subTitle='Manage the keys that operator uses to authenticate your API calls.' />

          <Link to='/js-apis'><Typography variant='body2' color='primary' sx={{ cursor: 'pointer', ml: 1 }}>Learn more</Typography></Link>
        </Box>

        <Button startIcon={<AddIcon />} variant='contained' color='primary' onClick={handleGenerateApiKey}>Api key</Button>
      </Box>

      {isEmpty ?
        <NoDataFound height='calc(100vh - 496px)' description='Record not found' />
        :
        <Box mb={2}>
          <StickyTable>
            <TableHead>
              <TableRow>
                <TableCell>Key Name</TableCell>
                <TableCell>Public Key</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell align='center'>Delete</TableCell>
              </TableRow>
            </TableHead>

            {loading ?
              <ApikeySkeleton noOfRows={10} />
              :
              <TableBody>
                {brandApiKeys?.map((apiKey) => {
                  const { id, keyName, publicKey, createdAt } = apiKey as unknown as ApiKeyType
                  const createdDate = moment(createdAt).format(DATE_FORMAT)

                  return (
                    <TableRow key={id} hover>
                      <TableCell sx={{ minWidth: 200 }}>{keyName}</TableCell>
                      <TableCell >*******************{publicKey}</TableCell>
                      <TableCell >{createdDate}</TableCell>
                      <TableCell align='center'><IconButton onClick={() => handleDeleteApiKey(apiKey as unknown as ApiKeyType)}><DeleteIcon color='error' /></IconButton></TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            }
          </StickyTable>
        </Box>
      }

      <GenerateApiKey
        brandState={brandState}
        brandApiKeys={brandApiKeys}
        brandDispatch={brandDispatch}
      />

      <DeleteApiKey
        brandApiKeys={brandApiKeys}
        currentApiKey={currentApiKey}
        brandDispatch={brandDispatch}
      />
    </>
  )
}