import { Button, CircularProgress, DialogActions, Typography } from '@mui/material'
import { FC, useContext } from 'react'
import { useRemoveBrandAttachmentMutation, useRemoveUserAttachmentMutation, UserStatus } from '../../../generated'
import { AppContext, AuthContext } from '../../context'
import { FullAttachmentDescription } from '../../interfaceTypes'
import { firstLetterUppercase, getCapitalize } from '../../utils'
import { Alert } from './Alert'
import { ActionType } from "../../context/AppContextReducer";
import { CustomModal } from './CustomModal'
import { Box } from '@mui/material'

export const DeleteAttachmentModal: FC<FullAttachmentDescription> = ({ isOpen, handleClose, attachmentId, attachmentType }) => {
  const { selectedBrand, dispatch } = useContext(AppContext);
  const { currentUser, setUser } = useContext(AuthContext)
  const { id: brandId, attachments, createdAt: brandCreatedAt, updatedAt: brandUpdatedAt, name } = selectedBrand || {}

  const { id: userId, fullName = undefined, tagLine = undefined, email = undefined,
    phoneNumber = undefined, roles = [], attachment: userAttachment, attachments: userAttachments = [],
    emailVerified, status: userStatus, createdAt, updatedAt } = currentUser || {}

  const [removeBrandAttachment, { loading: videoLoading }] = useRemoveBrandAttachmentMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { removeBrandAttachment } = data

        if (removeBrandAttachment) {
          const updatedAttachments = attachments?.filter((attachment) => attachment.id !== attachmentId)

          const updatedBrand = {
            ...selectedBrand,
            id: brandId || "",
            name: name || "",
            attachments: updatedAttachments || [],
            createdAt: brandCreatedAt!,
            updatedAt: brandUpdatedAt!,
          }

          dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrand })
          handleClose()
          return Alert.success("Attachment deleted successfully")
        }
      }
    }
  })

  const [removeUserAttachment, { loading: thumbnailLoading }] = useRemoveUserAttachmentMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { removeUserAttachment } = data

        if (removeUserAttachment) {
          const updatedAttachments = userAttachments?.filter((attachment) => attachment.id !== attachmentId)

          const updatedUser = {
            ...currentUser,
            id: userId || "",
            email: email || "",
            fullName: fullName || "",
            emailVerified: emailVerified || false,
            createdAt: createdAt!,
            updatedAt: updatedAt!,
            status: userStatus || UserStatus.Active,
            tagLine: tagLine,
            roles: roles,
            phoneNumber: phoneNumber,
            attachment: userAttachment,
            attachments: updatedAttachments || [],
          }

          setUser(updatedUser)
          handleClose()
          return Alert.success("Attachment deleted successfully")
        }
      }
    }
  })

  const DeleteAttachment = async () => {
    if (attachmentType === 'thumbnail') {
      await removeUserAttachment({
        variables: {
          removeAttachmentDto: {
            typeId: userId || '', //user id
            attachmentId: attachmentId || ''
          }
        }
      })
    } else if (attachmentType === 'video') {
      await removeBrandAttachment({
        variables: {
          removeAttachmentDto: {
            typeId: brandId || '', //brand id
            attachmentId: attachmentId || ''
          }
        }
      })
    }
  }

  return (
    <CustomModal
      maxWidth='700px' isOpen={isOpen} handleClose={handleClose}
      title={getCapitalize(attachmentType || '')}
      infoIcon
    >
      <Box>
        <Typography variant="h4" marginTop={2} marginBottom={3} paddingLeft={0.5}>Are you sure you want to delete this {attachmentType || ''}?</Typography>
      </Box>
      <DialogActions sx={{ paddingRight: '0' }}>
        <Button sx={{ marginLeft: '10px' }} onClick={handleClose}>Cancel</Button>

        <Button variant="contained" onClick={DeleteAttachment} color="error"
          disabled={thumbnailLoading || videoLoading}
          endIcon={(thumbnailLoading || videoLoading) && <CircularProgress size={20} color="inherit" />}
        >
          Delete
        </Button>
      </DialogActions>
    </CustomModal>
  )
}
