
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { AttachmentType, VideoOrientation } from '../../../../generated';
import { AppContext } from '../../../context';
import { UploadVideoSectionProps } from "../../../interfaceTypes";
import { UploadVideoBox, VideoCardWrapper } from "../../../theme/styleComponents";
import { hookVideoButtonWrapper, hookVideoStylePortrait, uploadHookVideoStyle } from "../../../theme/styleConstants";
import { VideoCard } from './VideoCard';
import { VideoLibraryActions } from './VideoLibraryActions';

export const UploadVideoSection: FC<UploadVideoSectionProps> = ({ title, handleModalOpen, height, isLandscape, handleVideoData, brandDispatch, brandState }) => {
  const { selectedBrand } = useContext(AppContext)
  const { attachments } = selectedBrand || {}

  const hookVideo = attachments?.filter(video => video.type === AttachmentType.HookVideo)

  const landscapeHookVideo = hookVideo?.filter(video => video.videoOrientation === VideoOrientation.Landscape)
  const portraitHookVideo = hookVideo?.filter(video => video.videoOrientation === VideoOrientation.Portrait)

  return (
    <UploadVideoBox height={height}>
      <Box sx={uploadHookVideoStyle}>
        <Typography variant="subtitle1">{title}</Typography>

        <Button
          color="secondary"
          disabled={false}
          startIcon={<AddIcon />}
          sx={{ padding: '10px 6px' }}
          onClick={handleModalOpen}
        >
          Upload Video
        </Button>
      </Box>

      {isLandscape ?
        <Box>
          {landscapeHookVideo && landscapeHookVideo.length > 0 ?
            <Box sx={hookVideoButtonWrapper} width={400} maxWidth="100%">
              {landscapeHookVideo && landscapeHookVideo.length > 0 &&
                <VideoCardWrapper>
                  <Box onClick={() => handleVideoData(landscapeHookVideo[0])}>
                    <VideoLibraryActions />
                  </Box>

                  <VideoCard dispatch={brandDispatch} state={brandState} videoData={landscapeHookVideo[0]} />
                </VideoCardWrapper>
              }
            </Box>
            :
            <Box sx={hookVideoButtonWrapper} width={318} maxWidth="100%" height={179}>
              <Typography variant='subtitle2' color='secondary'>Landscape video</Typography>
            </Box>
          }
        </Box>
        :
        <Box>
          {portraitHookVideo && portraitHookVideo.length > 0 ?
            <Box sx={hookVideoButtonWrapper} width={318} maxWidth="100%">
              {portraitHookVideo && portraitHookVideo.length > 0 &&
                <VideoCardWrapper>
                  <Box onClick={() => handleVideoData(portraitHookVideo[0])}>
                    <VideoLibraryActions />
                  </Box>

                  <VideoCard dispatch={brandDispatch} state={brandState} videoData={portraitHookVideo[0]} videoStyle={hookVideoStylePortrait} />
                </VideoCardWrapper>
              }
            </Box>
            :
            <Box sx={hookVideoButtonWrapper} width={148} maxWidth="100%" height={263}>
              <Typography variant='subtitle2' color='secondary'>Portrait video</Typography>
            </Box>
          }
        </Box>
      }
    </UploadVideoBox>
  )
}