import { Autocomplete, FormControl, ListItem, TextField } from "@mui/material"
import { FC, useContext } from "react"
import { Maybe } from "../../../generated"
import { AppContext } from "../../context"
import { ActionType } from "../../context/AppContextReducer"
import { RecordedVideoContext } from "../../context/RecordedVideoContext"
import { OperatorNameProps } from "../../interfaceTypes"

/**
* Component for all the functionality for Operator name field.
* @returns {JSX.Element}
*/
export const OperatorSearchField: FC<OperatorNameProps> = ({ loading, videoSearchKeys, setVideoSearchKeys }): JSX.Element => {

  const { dispatch } = useContext(AppContext)
  const { allUsers } = useContext(RecordedVideoContext)

  const handleOperatorChange = (value: { label: Maybe<string> | undefined; id: string | undefined; }) => {
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
    videoSearchKeys && setVideoSearchKeys({ ...videoSearchKeys, operatorId: value.id || undefined })
  };

  return (
    <FormControl variant="outlined" sx={{ width: '100%' }}>
      <Autocomplete
        size="small"
        defaultValue={{ label: 'All', id: '' }}
        fullWidth
        disabled={loading}
        onChange={(event, value) => handleOperatorChange(value)}
        renderOption={(props, option) => {
          const { id, label } = option || {}

          return (
            <ListItem {...props} key={id} value={id}>
              {label}
            </ListItem>
          )
        }}
        disableClearable
        options={[
          { label: 'All', id: '' },
          ...(allUsers || []).map(item => ({ label: item?.fullName, id: item?.id }))
        ]}

        renderInput={(params) => (
          <TextField
            {...params}
            label="Operator Name"
          />
        )}
      />
    </FormControl>
  )
}

