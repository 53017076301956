import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, CircularProgress, DialogActions, Switch, Typography } from "@mui/material";
import axios from "axios";
import { FC, useContext, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { images } from "../../../../assets/images";

import { AttachmentType, VideoOrientation } from "../../../../generated";
import { ASSET_IS_LARGE, SOMETHING_WENT_WRONG } from "../../../constants";
import { AuthContext } from "../../../context";
import { CreateVideoModalProps, UploadVideoModalProps } from "../../../interfaceTypes";
import { backdropDialog } from "../../../theme/styleConstants";
import { getToken } from "../../../utils";
import { createVideoSchema } from "../../../validationSchema";
import { Alert } from "../../common/Alert";
import { CustomController } from "../../common/CustomController";
import { CustomModal } from "../../common/CustomModal";
import { UploadVideoDocument } from "../../common/UploadVideoDocument";
import UploadVideoThumbnail from "../../common/UploadVideoThumbnail";

export const CreateVideoModalForTasks: FC<UploadVideoModalProps> = ({ isOpen, handleClose, currentVideo, videoType, entityType, typeId, refetchJob, attachmentId, videoOrientation, setCurrentVideo, videoIsLong, setVideoIsLong }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPortrait, setIsPortrait] = useState<boolean>(false)
  const { currentUser } = useContext(AuthContext);
  const token = getToken()

  const methods = useForm<CreateVideoModalProps>({
    resolver: yupResolver(createVideoSchema),

    defaultValues: {
      title: "",
      description: "",
      attachmentType: 'video',
      metaType: undefined,
      thumbnailId: "",
      videoOrientationBoolean: videoOrientation === VideoOrientation.Portrait,
    },
  });

  const { handleSubmit, control, reset } = methods
  const { id: videoId } = currentVideo || {}

  const handleOrientation = () => setIsPortrait(!isPortrait)

  const onSubmit = async (data: CreateVideoModalProps) => {
    const formData = new FormData();
    const { title, description, thumbnailId, attachmentType, metaType, videoOrientationBoolean } = data || {}
    const orientationToSend = videoOrientationBoolean ? VideoOrientation.Portrait : VideoOrientation.Landscape

    if (files.length !== 0) {
      attachmentId && formData.append('id', attachmentId)
      typeId && formData.append('typeId', typeId);
      videoType && formData.append("type", videoType);
      title && formData.append('title', title);
      description && formData.append('description', description);
      thumbnailId && formData.append('thumbnailId', thumbnailId);
      attachmentType && formData.append('attachmentType', attachmentType);
      metaType && formData.append('metaType', metaType);
      formData.append("file", files[0]);
      orientationToSend && formData.append('videoOrientation', orientationToSend.toLowerCase() || "")

      try {
        setIsLoading(true)
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/task-templates/upload-file`,
          formData,
          {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        )

        const { data, status } = response

        if (status === 201 && data) {
          const { status: statusInData, message } = data

          if (statusInData === 200) {
            Alert.success(message);
            if (message === ASSET_IS_LARGE) {
              setVideoIsLong && setVideoIsLong(!videoIsLong)
            }
          } else {
            Alert.success('File uploaded successfully');
          }

          setCurrentVideo && setCurrentVideo(data)
          setIsLoading(false)
          refetchJob && refetchJob()
          reset()
          setFiles([])
          handleClose()
        }
      }

      catch (error) {
        setIsLoading(false)
        setFiles([])
        console.log('>>>>>>>>>>>>> error in create video modal for tasks>>>>>>>>>>>>>', error)
        Alert.error(SOMETHING_WENT_WRONG);
      }
    }
  }

  const { attachments: userAttachments } = currentUser || {}
  const userThumbnails = userAttachments?.filter(attachment => attachment.type === AttachmentType.Thumbnail)

  return (
    <>
      <CustomModal maxWidth="800px" isOpen={isOpen}
        handleClose={handleClose} title="Create Video" hideBackdrop>
        <Box paddingTop='25px'>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off">
              <Box>
                <UploadVideoDocument
                  entityType={entityType || "brands"}
                  typeId={typeId || ""}
                  attachmentType={videoType}
                  attachmentId={videoId || undefined}
                  fieldName="videoUrl"
                  files={files}
                  setFiles={setFiles}
                  isLoading={isLoading}
                />
              </Box>

              <Box display='flex' mt={1} alignItems='center'>
                <Typography variant="body1" display='flex'><Box component='img' src={images.LANDSCAPE} mr={1} />Landscape</Typography>

                <Controller
                  control={control}
                  name='videoOrientationBoolean'
                  defaultValue={isPortrait}
                  render={({ field }) => (
                    <Switch
                      sx={{ marginRight: '1rem' }}
                      color="secondary"
                      value={field.value}
                      checked={isPortrait}
                      onChange={(_event, value) => {
                        field.onChange(value)
                        handleOrientation()
                      }}
                    />
                  )}
                />

                <Typography variant="body1" display='flex'><Box component='img' src={images.PORTRAIT} mr={1} />Portrait</Typography>
              </Box>

              <Box>
                <CustomController
                  controllerName="title"
                  controllerLabel="Video Title"
                  fieldType='text'
                  tooltipText="This will appear on the customer end on the widget."
                />
              </Box>

              <Box sx={{ marginBottom: '1rem' }}>
                <CustomController
                  controllerName="description"
                  controllerLabel="Description"
                  fieldType='text'
                />
              </Box>

              <UploadVideoThumbnail isLandscape={true} userThumbnails={userThumbnails || []} fieldName="thumbnailId" />

              <DialogActions sx={{ paddingRight: '0', paddingTop: '1rem' }}>
                <Button type="submit" variant="contained" color="primary" disabled={isLoading} endIcon={isLoading && <CircularProgress size={20} color="inherit" />}>
                  Send
                </Button>
              </DialogActions>
            </form>
          </FormProvider>
        </Box>
      </CustomModal>

      {isOpen &&
        <Box sx={backdropDialog} />
      }
    </>
  )
}