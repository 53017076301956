import { createContext, FC, Reducer, useEffect, useMemo, useReducer, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ChildrenType, RecordedVideoContextReducerType } from "../interfaceTypes";
import { VideoDetailAction, VideoDetailActionType, videoDetailInitialState, videoDetailReducer, VideoDetailState } from "./VideoDetailContextReducer";
import { Maybe, UserPickType, UserRoles, useUserInfoLazyQuery } from "../../generated";
import { BRAND_ID, GRAPHQL_QUERY_POLICY } from "../constants";

export const RecordedVideoContext = createContext<RecordedVideoContextReducerType>({
  ...videoDetailInitialState,

  dispatch: () => {
    return;
  },
});

export const RecordedVideoContextProvider: FC<ChildrenType> = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer<VideoDetailState, VideoDetailAction>>(videoDetailReducer, videoDetailInitialState);
  const store = useMemo(() => ({ ...state, dispatch }), [state]);
  const [page, setPage] = useState<number>(1)
  const { allUsers } = state
  const userRoles = [UserRoles.Admin, UserRoles.Operator, UserRoles.BrandManager]
  const localStorageBrandId = localStorage.getItem(BRAND_ID)
  const { brandId } = useParams();

  const [getUserInfo] = useUserInfoLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onCompleted(data) {
      const { getUserGeneralInfo } = data
      if (getUserGeneralInfo) {
        const { users, pagination } = getUserGeneralInfo
        if (users) {
          const updatedUsers: Maybe<Array<Maybe<UserPickType>>> = [...(allUsers || []), ...users]
          dispatch({ type: VideoDetailActionType.SET_ALL_USERS, allUsers: updatedUsers })
        }

        if (pagination) {
          const { totalPages } = pagination;

          if (!!totalPages && (totalPages !== page)) {
            setPage(page + 1)
          }
        }
      }
    }
  })

  const fetchAllUsers = async (page: number) => {
    await getUserInfo({
      variables: {
        userInput: {
          roles: userRoles,
          brandId: localStorageBrandId || brandId,

          paginationOptions: {
            page,
            limit: 50
          }
        }
      }
    })
  }

  useEffect(() => {
    fetchAllUsers(page)
    // eslint-disable-next-line
  }, [brandId, page])

  return (
    <RecordedVideoContext.Provider value={{ ...store }}>
      {children}

      <Outlet />
    </RecordedVideoContext.Provider>
  );
}