// packages import
import { FC, MouseEvent, useContext, useEffect, useState } from "react";
import { AppBar, Avatar, Box, Button, ListItemButton, Toolbar, Typography, useMediaQuery, MenuItem, Tooltip } from "@mui/material";
import { Link, Link as ReactRouterLink, NavLink, useLocation, useNavigate } from "react-router-dom";
// component imports
import { MobileDrawer } from "./Drawer";
// others
import { images } from "../../../assets/images"
import { AUTH_TOKEN, BRAND_ID, CABA_BRAND_ALERT, DASHBOARD_LINK, JOB_PATH, SELECT_BRAND_ROUTE, TOOLTIP_POPPER_PROPS, USER_EMAIL, VIDEO_DETAIL_PATH, devOnlyUrl } from "../../constants";
import { companyData, flexCenter } from "../../theme/styleConstants";
import { CompanyNameBox, CustomMenu, Logo } from "../../theme/styleComponents"
import palette from "../../theme/palette";
import customTheme from "../../theme";
import { AuthContext } from "../../context";
import { AppContext } from '../../context/AppContext';
import { getToken, isSuperAdmin } from "../../utils";

export const Header: FC = () => {
  const { currentUser, isAdmin, isBrandManager, isOperator, setUser, setIsLoggedIn } = useContext(AuthContext)
  const { roles } = currentUser || {}
  const { selectedBrand } = useContext(AppContext)
  const { attachment } = currentUser || {}
  const { url: profileUrl } = attachment || {}
  const selectedBrandId = localStorage.getItem(BRAND_ID)
  const navigate = useNavigate()

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const location = useLocation()
  const mediumViewport = useMediaQuery('(min-width:768px)');
  const { email, fullName } = currentUser || {}

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(VIDEO_DETAIL_PATH);
    localStorage.removeItem(USER_EMAIL);
    localStorage.removeItem(BRAND_ID);
    localStorage.removeItem(CABA_BRAND_ALERT);
    localStorage.removeItem(JOB_PATH);
    setUser(null);
    setIsLoggedIn(false);
    navigate('/login');
  };

  // const jobUrl = localStorage.getItem(JOB_PATH)

  useEffect(() => {
    !getToken() && logout()
    //eslint-disable-next-line
  }, [getToken()])

  useEffect(() => {
    if (currentUser) {
      !selectedBrandId && (isAdmin || isBrandManager) && navigate(SELECT_BRAND_ROUTE)
      // isOperator && navigate(jobUrl || '/profile')
    }
    //eslint-disable-next-line
  }, [])

  const { name, id, logo, taskTemplates } = selectedBrand || {}
  const { url } = logo || {}
  const brandId = localStorage.getItem(BRAND_ID)
  const recordedVideoUrl = `/brand/${brandId}/recorded-videos`
  const superAdmin = isSuperAdmin(roles)

  return (
    <AppBar position="sticky">
      {mediumViewport ?
        <>
          <Toolbar disableGutters sx={{ padding: '0px 18px' }}>
            <Box display="flex" flex='1'>
              <Box display='flex' alignItems='center'>
                <ReactRouterLink to='/'>
                  <Logo>
                    <Box component='img' src={images.LOGO} alt='Operator logo' />
                  </Logo>
                </ReactRouterLink>
              </Box>

              {((isAdmin || isBrandManager) && !!selectedBrandId) &&
                <Box sx={{
                  ...flexCenter, gap: '15px', [customTheme.breakpoints.down('lg')]: {
                    gap: '10px'
                  }
                }}>
                  {DASHBOARD_LINK.map((item) => {
                    const { link, title } = item

                    return (
                      <ListItemButton component={NavLink} selected={location.pathname === link} to={link} key={title} sx={{
                        textTransform: 'capitalize', [customTheme.breakpoints.down('lg')]: {
                          paddingX: '4px',
                          fontSize: '13px'
                        }
                      }}>
                        {title}
                      </ListItemButton>
                    )
                  })}

                  <ListItemButton component={NavLink} selected={location.pathname === recordedVideoUrl} to={recordedVideoUrl}
                    sx={{
                      textTransform: 'capitalize', [customTheme.breakpoints.down('lg')]: {
                        paddingX: '4px',
                        fontSize: '13px'
                      }
                    }}>
                    Recorded Videos
                  </ListItemButton>

                  {superAdmin && <ListItemButton selected={location.pathname === devOnlyUrl} component={NavLink} to={devOnlyUrl}
                    sx={{
                      textTransform: 'capitalize', [customTheme.breakpoints.down('lg')]: {
                        paddingX: '4px',
                        fontSize: '13px'
                      }
                    }}>
                    Dev Only
                  </ListItemButton>}
                </Box>
              }
            </Box>

            <Box sx={{ ...flexCenter, gap: '30px' }}>
              <Box>
                <Box display="flex" justifyContent='space-between'>
                  <Box sx={{ flexGrow: 0 }}>
                    <Button onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Box display='flex' justifyContent='start' alignItems="center">
                        <Avatar sx={{ marginRight: '10px' }} src={profileUrl || ''} />

                        <Box textAlign='left'>
                          <Tooltip title={fullName} placement="top" PopperProps={TOOLTIP_POPPER_PROPS}>
                            <Typography sx={{ maxWidth: '150px' }} variant="body1" textTransform="capitalize" fontWeight='600' noWrap>
                              {fullName || ""}
                            </Typography>
                          </Tooltip>

                          <Tooltip title={email} placement="bottom-start" PopperProps={TOOLTIP_POPPER_PROPS}>
                            <Typography sx={{ maxWidth: '150px' }} variant="body2" textTransform="none" noWrap>{email || ""}</Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Button>

                    <CustomMenu
                      sx={{ mt: '45px' }}
                      PaperProps={{
                        style: { maxHeight: '215px' }
                      }}
                      id="headerCompaniesSelect"
                      anchorEl={anchorElUser}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      keepMounted
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {((isAdmin || isBrandManager) && !!selectedBrandId) &&
                        <CompanyNameBox>
                          <MenuItem key={id}>
                            <Avatar src={url || ''} sx={companyData} />

                            <Box flex={1} maxWidth="65%" paddingRight="10px">
                              <Typography variant="body1" noWrap>{name}</Typography>

                              <Typography variant="subtitle2" sx={{ color: palette.blackOne }}>{taskTemplates?.length || 0} Task(s)</Typography>
                            </Box>

                            <Box>
                              <Button
                                sx={{ minWidth: 'auto', padding: 0, fontSize: '12px' }}
                                variant="text"
                                color="primary"
                                component={Link}
                                to='/select-brand'
                                onClick={handleCloseUserMenu}
                              >
                                Switch
                              </Button>
                            </Box>
                          </MenuItem>
                        </CompanyNameBox>
                      }

                      <Box>
                        {(!!selectedBrandId || isOperator) &&
                          <MenuItem>
                            <Box width='100%'>
                              <ReactRouterLink to='/profile'>
                                <Typography variant="h6" color={palette.black} onClick={handleCloseUserMenu}>Profile</Typography>
                              </ReactRouterLink>
                            </Box>
                          </MenuItem>
                        }

                        {(!!selectedBrand && (isAdmin || isBrandManager)) &&
                          <MenuItem>
                            <Box width='100%'>
                              <ReactRouterLink to='/js-apis'>
                                <Typography variant="h6" color={palette.black} onClick={handleCloseUserMenu}>Docs</Typography>
                              </ReactRouterLink>
                            </Box>
                          </MenuItem>
                        }

                        <MenuItem>
                          <Box width='100%'>
                            <ReactRouterLink to='/login'>
                              <Typography variant="h6" color={palette.black} onClick={logout}>Logout</Typography>
                            </ReactRouterLink>
                          </Box>
                        </MenuItem>
                      </Box>
                    </CustomMenu>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Toolbar>

          {((isAdmin || isBrandManager) && !!selectedBrandId) &&
            <Box sx={{ backgroundColor: palette.primary.main }} textAlign="center">
              <Typography variant="body1" textTransform="none" fontWeight='600' color={palette.white} py={.5}>{name} <Typography component='span' variant="subtitle2" textTransform='none'>({taskTemplates?.length || 0} Task(s))</Typography></Typography>
            </Box>
          }
        </>
        :
        <MobileDrawer logout={logout} />
      }
    </AppBar>
  )
}