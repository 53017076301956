import { ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions } from "@apollo/client";
import { Attachment, AttachmentType, ElasticSearchBody, Exact, GetSignedUrlWithKeyMutation, JobStatus, Maybe, Role, UserRoles, VideoBox, WidgetType } from "../../generated";
import client from "../apollo";
import { AUTH_TOKEN, BRAND_ID, CABA_BRAND_ALERT, CABA_SOCKET_SESSION_ID, JOB_PATH, USER_EMAIL, VIDEO_DETAIL_PATH } from "../constants";
import { Dispatch, SetStateAction } from "react";

export const handleLogout = async () => {
  localStorage.removeItem(VIDEO_DETAIL_PATH);
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(BRAND_ID);
  localStorage.removeItem(CABA_BRAND_ALERT);
  localStorage.removeItem(JOB_PATH);
  client.clearStore();
};

export const createSession = () => {
  const localStorageSessionId = localStorage.getItem(CABA_SOCKET_SESSION_ID);

  if (localStorageSessionId) {
    return localStorageSessionId;
  } else {
    const sessionId = new Date().valueOf();
    localStorage.setItem(CABA_SOCKET_SESSION_ID, sessionId.toString());
    return sessionId.toString();
  }
}

export const getBrandAlertDismiss = () => {
  return localStorage.getItem(CABA_BRAND_ALERT);
};

export const setBrandAlertDismiss = () => {
  return localStorage.setItem(CABA_BRAND_ALERT, "true");
};

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const requiredMessage = (fieldName: string) => `${fieldName} is required.`;

export const firstLetterUppercase = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const formatPhoneNumber = (phoneNumberString: string | null) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}

export const exampleMessage = (e: string) => `i.e ${e}`;
export const maxLength = (fieldName: string, length: number) => `${fieldName} can be up to ${length} characters long.`;
export const minLength = (fieldName: string, length: number) => `${fieldName} should be at least ${length} characters long.`;
export const validMessage = (fieldName: string, example?: string) => `Please enter valid ${fieldName.toLowerCase()}. ${example ? exampleMessage(example) : ""}`;
export const validMessageForName = (fieldName: string, example?: string) => `Invalid name: ${fieldName.toLowerCase()}. ${example ? exampleMessage(example) : ""}`;

export const formatEnumMember = (value: string) => {
  let formatted = ''

  value?.split("_").map(term =>
    formatted = `${formatted} ${term.charAt(0).toUpperCase()}${term.slice(1).toLowerCase()}`)

  return formatted?.trim();
};

export const getBorderRadiusValue = (videoBox: VideoBox) => {
  switch (videoBox) {
    case VideoBox.Sharp:
      return '0px'
    case VideoBox.Regular:
      return '4px'
    case VideoBox.Soft:
      return '8px'
    default:
      return '0px'
  }
}

export const getAttachmentType = (type: AttachmentType) => {
  switch (type) {
    case AttachmentType.Thumbnail:
      return "thumbnail"

    case AttachmentType.Video:
      return "video"

    case AttachmentType.Logo:
      return "logo"
    default:
      return
  }
}

export const getCapitalize = (attachment: string) => {
  return attachment.charAt(0).toUpperCase() + attachment.slice(1).toLowerCase();
}

export const handleTabs = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const getJobStatus = (jobStatus: JobStatus) => {
  switch (jobStatus) {
    case JobStatus.Pending:
      return "warning"

    case JobStatus.InReview:
      return "primary"

    case JobStatus.Completed:
      return "success"

    case JobStatus.Rejected:
      return "error"
  }
}

export const checkDevice = () => {
  if ((navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./)) && (navigator.userAgent.match(/iPhone/i) || (navigator.userAgent.match(/iPod/i)))) {
    return true
  }

  else if (navigator.vendor !== null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(/iPad/i)) {
    return true
  }

  else if (navigator.vendor !== null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.indexOf('Safari') !== -1) {
    return true
  }

  return false
}

export const getWidgetName = (type: WidgetType) => {
  switch (type) {
    case WidgetType.Block:
      return "block"

    case WidgetType.Kiosk:
      return "kiosk"

    case WidgetType.PopupWidget:
      return "popup"

    case WidgetType.Site:
      return "site"

    case WidgetType.LandingPage:
      return "landing page"
  }
}

export const formatTime = (seconds: number) => {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${hh}:${mm}:${ss}`
}

function pad(string: any) {
  return ('0' + string).slice(-2)
}

export const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const getInitials = (name: string) => {
  const cleanedName = name.replace(/\([^)]*\)/, '');

  const words = cleanedName.split(" ");
  if (words.length > 1) {
    const firstNameInitial = words[0].charAt(0).toUpperCase();
    const lastNameInitial = words[1].charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial}`;
  }
  const initials = words.map(word => word.charAt(0).toUpperCase());
  return initials.join("");
}

export const getWidgetLinkBase = (widgetType: WidgetType) => `/brand-settings/?widgetType=${widgetType}&activeTab=2`

export const formatDateAndTimeToCustomFormat = (timeStamp: Date) => {
  const date = new Date(timeStamp);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const monthName = months[monthIndex];

  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = (hours % 12).toString().padStart(2, '0'); // Ensure two digits for hours
  const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure two digits for minutes
  const formattedSeconds = seconds.toString().padStart(2, '0'); // Ensure two digits for seconds

  const formattedDateAndTime = `${day} ${monthName} ${year} - ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${amOrPm}`;

  return formattedDateAndTime;
}

export const formatTimeToCustomFormat = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const remainingSeconds = Math.floor(time % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  return formattedTime;
}

export const timeToShow = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const remainingSeconds = Math.floor(time % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  if (formattedHours === "00") {
    return formattedTime.slice(3)
  }

  return formattedTime;
}

type GetSignedUrlWithKeyType = (options?: MutationFunctionOptions<GetSignedUrlWithKeyMutation, Exact<{
  fileKey: string;
}>, DefaultContext, ApolloCache<any>> | undefined) => Promise<FetchResult<GetSignedUrlWithKeyMutation>>

type SetVideoUrlType = Dispatch<SetStateAction<string | undefined>>
const getVideoSingedUrl = async (videoFileKey: string, getSingedUrlWithKey: GetSignedUrlWithKeyType, setVideoUrl: SetVideoUrlType) => {
  const result = await getSingedUrlWithKey({ variables: { fileKey: videoFileKey } })
  const { data } = result
  const { getSignedUrlWithKey } = data || {}
  setVideoUrl(getSignedUrlWithKey)
}

export const getUrl = async (attachments: Maybe<Attachment[]> | undefined, getSingedUrlWithKey: GetSignedUrlWithKeyType, setVideoUrl: SetVideoUrlType) => {
  if (attachments?.length) {
    const videoFile = attachments?.find((meeting: any) => meeting.key?.includes('.mp4'))
    const { key, url } = videoFile || {}
    if (key && !url) {
      await getVideoSingedUrl(key, getSingedUrlWithKey, setVideoUrl);
    }
    if (url) {
      setVideoUrl(url);
    }
  }
}

export const urlAppend = (url: string | undefined, urlToAppend: string, type: string) => {
  if (!url) {
    return undefined;
  }

  const splitUrl = url.split('/upload');
  const appendUrl = splitUrl.join(urlToAppend);
  const updatedUrl = appendUrl.replace(/mp4$/, type);

  return updatedUrl;
}

export const truncateSentence = (sentence: string, maxLength: number, searchTerms: string[] | undefined) => {
  if (sentence.length <= maxLength) {
    return sentence;
  }

  const sentenceArray = sentence.toLowerCase().split(/\s+/)

  const updatedSentences = sentenceArray.map((word) => {
    const lowerWord = word.toLowerCase()
    const isHighlighted = searchTerms?.some(term => lowerWord.includes(term.toLowerCase()));

    if (isHighlighted) {
      if (sentenceArray.indexOf(lowerWord) !== -1) {
        const slicedArray = sentenceArray.slice(sentenceArray.indexOf(lowerWord))
        const finalSentence = slicedArray.join(' ')

        return `${finalSentence.toLowerCase().trim().slice(0, 80)} ...`
      }
    }

    return ""
  })

  const newArray = updatedSentences.filter(item => item !== undefined).filter(newItem => newItem !== "")
  const uniqueArray = newArray ? [...new Set(newArray)] : []

  return `... ${uniqueArray.join(" ")}`;
}

export const sortByStartTime = (a: ElasticSearchBody, b: ElasticSearchBody) => {
  const timeA = a.transcription?.startTime;
  const timeB = b.transcription?.startTime;

  const [hoursA, minutesA, secondsA] = timeA?.split(':').map(Number) || [];
  const [hoursB, minutesB, secondsB] = timeB?.split(':').map(Number) || [];

  if (hoursA !== hoursB) {
    return hoursA - hoursB;
  }
  if (minutesA !== minutesB) {
    return minutesA - minutesB;
  }
  if (secondsA !== secondsB) {
    return secondsA - secondsB;
  }

  return 0;
};

export const isSuperAdmin = (roles: Maybe<Maybe<Role>[]> | undefined) => {
  const superAdmin = roles?.some((role => role?.role === UserRoles.SuperAdmin))
  return superAdmin
}

export const hasNativeHLS = () => {
  const isSafariRegExp = new RegExp(/^((?!chrome|android).)*safari/i);
  const isIphoneRegExp = new RegExp(/^((?!chrome|android).)*iphone/i);

  return isSafariRegExp.test(navigator.userAgent) || isIphoneRegExp.test(navigator.userAgent);
};

export const getWidgetPreviewName = (type: WidgetType) => {
  switch (type) {
    case WidgetType.Block:
      return "Page block preview"

    case WidgetType.PopupWidget:
      return "Popup widget preview"

    case WidgetType.Site:
      return "Site widget preview"

    case WidgetType.LandingPage:
      return "Landing page preview"
  }
}