import { FC } from "react";
import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import { TableSkeletonProps } from "../../interfaceTypes";

/**
 * It would render Skelton on Table
 *
 *
 * @returns JSX Element DimensionTableSkeleton
 */

export const DataListSkeleton: FC<TableSkeletonProps> = ({ noOfRows, noOfCols }) => (
  <TableBody>
    {new Array(noOfRows).fill(0).map((_, index) => (
      <TableRow key={`${index}-skeleton`}>
        {noOfCols &&
          new Array(noOfCols).fill(0).map((_, index) =>
            <TableCell key={`${index}-cell-skeleton`}>
              <Skeleton variant="text" height={30} width="100%" />
            </TableCell>
          )
        }
      </TableRow>
    ))}
  </TableBody>
);