import { Box } from '@mui/material'
import { PageBlockEvents } from './PageBlockEvents'
import { SiteWidgetEvents } from './SiteWidgetEvents'
import { PopupWidgetEvents } from './PopupWidgetEvents'

export const Events = () => (
  <Box mt={2}>
    <SiteWidgetEvents />

    <PageBlockEvents />

    <PopupWidgetEvents />
  </Box>
)