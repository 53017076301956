import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { images } from '../../../assets/images'
import { flexCenter } from '../../theme/styleConstants'

export const LandscapeOrientation: FC = () => (
  <Box height='100vh' flexDirection='column' sx={flexCenter}>
    <Box component='img' src={images.ROTATE} mb={2} />

    <Typography variant='h6' textAlign='center'>For best viewing experience, please use this application in portrait mode.</Typography>
  </Box>
)
