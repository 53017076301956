import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchResultPayload, useGetTranscribeSearchResultLazyQuery } from "../../../generated";
import { BRAND_ID, GRAPHQL_QUERY_POLICY } from "../../constants";
import { VideoSearchSummaryProps } from "../../interfaceTypes";
import { Alert } from "../common/Alert";
import { NoDataFound } from "../common/NoDataFound";
import { VideoListSkeleton } from "./VideoListSkeleton";
import { VideoSearchCard } from "./VideoSearchCard";
/**
 * Component for displaying a summary of video search results.
 * @returns {JSX.Element}
 */
export const VideoSearchListing: React.FC<VideoSearchSummaryProps> = ({ videoSearchKeys }): JSX.Element => {
  const [searchResponse, setSearchResponse] = useState<Array<SearchResultPayload>>([]);
  const { operatorId, keyword, widgetType, searchText } = videoSearchKeys || {};
  const brandId = localStorage.getItem(BRAND_ID);

  const [getTranscribeSearchResult, { loading: searchLoading }] = useGetTranscribeSearchResultLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      Alert.error('Nothing Found');
    },

    onCompleted(data) {
      const { getTranscribeSearchResult } = data;
      if (getTranscribeSearchResult) {
        const sortedResponse = getTranscribeSearchResult.sort((a, b) => {
          const { scheduleEvent: eventA } = a || {};
          const { scheduleEvent: eventB } = b || {};
          const { eventCreatedAt: eventCreatedAt_A } = eventA || {}
          const { eventCreatedAt: eventCreatedAt_B } = eventB || {}

          return (eventCreatedAt_B && eventCreatedAt_A) ? eventCreatedAt_B.localeCompare(eventCreatedAt_A) : 0;
        });

        setSearchResponse(sortedResponse);
      }
    }
  });

  useEffect(() => {
    if (searchText && brandId) {
      getTranscribeSearchResult({
        variables: {
          transcribeSearchInput: {
            widgetType: widgetType || undefined,
            operatorId,
            customerNameOrMail: !!keyword ? keyword : undefined,
            searchText: searchText || "",
            brandId
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [searchText, operatorId, keyword, widgetType, brandId]);

  const isEmpty = searchResponse?.length === 0 && !searchLoading;

  return (
    searchLoading ? (
      <VideoListSkeleton numberOfMap={3} />
    ) : isEmpty ? (
      <NoDataFound height='calc(100vh - 300px)' description='Record not found' />
    ) : (
      <Box sx={{ maxHeight: "calc(100vh - 320px)", overflow: "auto" }}>
        {!!searchResponse?.length && searchResponse?.map((item) => {
          const { scheduleEvent, transcriptions } = item ?? {};

          return (
            <VideoSearchCard scheduleEvent={scheduleEvent} transcriptions={transcriptions} searchText={searchText} />
          );
        })}
      </Box>

    )
  );
};