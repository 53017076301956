import {
  ApiKey, AttachmentType, Comment, CreateTaskTemplateInput, ElasticSearchBody, GetAllBrandsQuery, GetAllJobsQuery, GetAllWidgetsQuery, GetBrandQuery, GetJobQuery, GetJobsInput, GetWidgetInput, GetWidgetQuery,
  InputMaybe, InviteUserInput, Job, Maybe, ScheduleMeeting,
  SummaryPointType,
  TranscriptBody,
  UpdateAttachmentInput, UpdateBrandInput, UpdateTaskTemplateInput, UpdateWidgetInput, UserEmail, UserInput,
  VideoOrientation, Widget, WidgetType, WidgetVideoInput
} from './../../generated/index';
// packages import
import { ApolloQueryResult } from '@apollo/client';
import { TypographyProps } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { GraphQLErrorExtensions } from "graphql";
import { ChangeEventHandler, CSSProperties, Dispatch, MouseEvent, MutableRefObject, ReactNode, RefObject, SetStateAction } from "react";
import { UseFormReset, ValidationRule } from 'react-hook-form';

// packages import
import { Active, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import { SortableTransition } from '@dnd-kit/sortable/dist/hooks/types';
import { Accept } from 'react-dropzone';
import {
  Attachment, Brand, Exact, GetAllUsersQuery, RegisterUserInput, ResetPasswordInput, TaskTemplate, UpdateUserPasswordInput, User,
  UserRoles, VideoBox, VideoGridType, VideoLayout
} from "../../generated";
import { BrandAction } from '../components/brandSetting/reducer/brandReducer';
import { Action, State } from "../context/AppContextReducer";
import { SocketAction, SocketState } from '../context/SocketContextReducer';
import { TaskAction } from '../context/TaskContextReducer';
import { WidgetAction, WidgetState } from '../context/WidgetContextReducer';
import { UserAction, UserState } from './../context/UserContextReducer';
import { VideoDetailAction, VideoDetailState } from '../context/VideoDetailContextReducer';

type Key = string | number | undefined;

export interface CloseSnackbarProps {
  id: Key;
}
export interface BrandSkeletonProps {
  noOfItems: number
}
export interface TableSkeletonProps {
  noOfRows: number
  noOfCols?: number
}

export interface WidgetSkeletonProps {
  noOfRows: number
}
export interface TabPanelProps extends ChildrenType {
  index: number;
  value: number;
}

export interface PaginationProps {
  loading: boolean
}
export interface ChildrenType {
  children?: ReactNode;
}

export interface PageHeadingProps {
  title: string,
  subTitle?: string,
  variant?: Variant,
  marginTop?: string,
  apiKey?: boolean
}

export interface InfoTooltipProps {
  title: string,
}

export interface ErrorResponseType {
  error: string;
  message: string;
  status: number;
}

export interface CustomModalProps extends ChildrenType {
  isOpen: boolean;
  isDelete?: boolean;
  handleClose?: () => void;
  title?: string;
  subTitle?: string;
  infoIcon?: boolean;
  task?: TaskTemplate | null;
  maxWidth?: string
  taskDispatch?: Dispatch<TaskAction>
  tasksData?: Array<TaskTemplate>
  hideBackdrop?: boolean
}

export interface DeleteWidgetProps {
  widget?: Widget | null;
  widgetDispatch?: Dispatch<WidgetAction>
  widgetsData?: Array<Widget>
  refetchAllWidgets?: (variables?: Partial<Exact<{ brandId: string; }>> | undefined) => Promise<ApolloQueryResult<GetAllWidgetsQuery>>
}

export interface EditTaskProps {
  isOpen?: boolean;
  isDelete?: boolean;
  handleClose?: () => void;
  title?: string;
  subTitle?: string;
  infoIcon?: boolean;
  task?: TaskTemplate | null;
  maxWidth?: string
  taskDispatch?: Dispatch<TaskAction>
  tasksData?: Array<TaskTemplate>
  editTask?: boolean,
  setEditTask?: Dispatch<SetStateAction<boolean>>
}

export interface RemoveTaskProps {
  isOpen: boolean;
  isDelete?: boolean;
  handleClose?: () => void;
  title?: string;
  subTitle?: string;
  infoIcon?: boolean;
  task?: TaskTemplate | null
  taskDispatch?: Dispatch<TaskAction>
  tasksData?: Array<TaskTemplate>
  maxWidth?: string
  removeTask?: boolean,
  setRemoveTask?: Dispatch<SetStateAction<boolean>>
}

export interface CustomLoaderProps {
  isOpen: boolean;
  handleClose: () => void;
}

export interface ConfirmationLoaderProps extends CustomLoaderProps {
  videoData?: CreateVideoModalProps
  refetchJob?: Function
  entity: string | undefined
  resetForm: UseFormReset<CreateVideoModalProps>
  attachmentId?: string
  isPortrait?: boolean
}

export interface DeleteJobProps {
  refetch?: (variables?: Partial<Exact<{
    getJobsInput: GetJobsInput;
  }>> | undefined) => Promise<ApolloQueryResult<GetAllJobsQuery>>
  job?: Maybe<Job>
}

export interface JobListingProps {
  jobStatus: string
}
export interface ErrorException extends GraphQLErrorExtensions {
  message: string;
  name: string;

  response: {
    error: string;
    message: string;
    status: number;
    response: ErrorResponseType;
  };
}

export interface AuthLayoutProps extends ChildrenType {
  title?: string,
  subTitle?: string,
}

interface ControlLabelProps {
  controllerLabel: string;
  fieldType?: string;
  pattern?: ValidationRule<RegExp>;
  error?: string;
  isPassword?: boolean;
  isDisabled?: boolean;
}

export interface BrandSelectionInterface {
  brandState: BrandState
  brandDispatch: Dispatch<BrandAction>;
  refetch?: (variables?: Partial<Exact<{
    [key: string]: never;
  }>> | undefined) => Promise<ApolloQueryResult<GetAllBrandsQuery>>
}

export interface CustomControlProps extends ControlLabelProps {
  controllerName: string;
  isMultiLine?: boolean;
  maxLength?: number
  rowsLength?: number
  readOnly?: boolean;
  tooltipText?: string
  defaultValue?: string | number;
  isPassword?: boolean;
  inputRef?: RefObject<any>;
  min?: string
}

export interface TableHeaderProps extends ChildrenType {
  pagination?: ReactNode
  height?: string
}

export interface FullDescriptionModalProps {
  script: Maybe<string> | undefined
}

export interface FullAttachmentDescription extends CustomLoaderProps {
  videoData?: Attachment | null;
  attachmentId?: string;
  attachmentType?: AttachmentTypeFE;
  dispatch?: Dispatch<BrandAction>;
  state?: BrandState
  refetchWidget?: (variables?: Partial<Exact<{ getWidgetInput: GetWidgetInput; }>> | undefined) => Promise<ApolloQueryResult<GetWidgetQuery>>
}

export interface EditVideoForKioskProps {
  videoForFeatureImage: Attachment | undefined
  widgetData?: Widget
  setVideoForFeatureImage: Dispatch<SetStateAction<Attachment | undefined>>
  widgetId: string | null
  refetch: (variables?: Partial<Exact<{ getWidgetInput: GetWidgetInput; widgetId: string; }>> | undefined) => Promise<ApolloQueryResult<GetWidgetQuery>>
}

export interface EditVideoProps {
  videoData: Attachment;
  attachmentId: string;
}

export interface AuthContextProps {
  isLoggedIn: boolean;
  isAdmin: boolean;
  isBrandManager: boolean;
  isOperator: boolean;
  userLoader: boolean;
  currentUser: User | null;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setIsAdmin: (isAdmin: boolean) => void;
  setIsBrandManager: (isAdmin: boolean) => void;
  setUserLoader: (userLoader: boolean) => void;
  setIsOperator: (isOperator: boolean) => void;
  setUser: (currentUser: User | null) => void;
}

export interface TokenProps {
  token?: string
}

export interface ContentHeadProps {
  title: string,
  buttonText: string
  link?: string
}

export type SetPasswordInput = {
  password: string;
  repeatPassword: string;
};
export interface RefetchProp {
  refetch?: () => void;
}

export interface DispatchAndState<S, D> extends RefetchProp {
  dispatch: Dispatch<D>;
  state?: S;
  loading?: boolean;
}
export interface ResetPasswordProps extends ResetPasswordInput {
  repeatPassword: string;
}

export interface AppContextReducerType extends State {
  dispatch: Dispatch<Action>;
  refetchBrand?: (variables?: Partial<Exact<{ brandId: string; }>> | undefined) => Promise<ApolloQueryResult<GetBrandQuery>>
}

export interface RecordedVideoContextReducerType extends VideoDetailState {
  dispatch: Dispatch<VideoDetailAction>;
}

export interface SocketContextReducerType extends SocketState {
  socketDispatch: Dispatch<SocketAction>;
  mutateKioskState: (isRequested: boolean, isFocused: boolean, meeting: boolean, inPause?: boolean) => void
}

export interface UserContextReducerType extends UserState {
  dispatch: Dispatch<UserAction>;
}

export type StatusBoxProps = TypographyProps & {
  borderColor?: string;
}

export interface VerifyEmailAndUpdateUserProps extends RegisterUserInput {
  repeatPassword: string;
}

export interface UsersDataRefetch {
  refetch?: (variables?: Partial<Exact<{ userInput: UserInput; }>> | undefined) => Promise<ApolloQueryResult<GetAllUsersQuery>>
}

export interface UserProps extends DispatchAndState<UserState, UserAction> {
  currentUserData: Maybe<User>
  usersData: Maybe<Array<Maybe<User>>>
  refetch?: (variables?: Partial<Exact<{ userInput: UserInput; }>> | undefined) => Promise<ApolloQueryResult<GetAllUsersQuery>>
}

export interface UserUpdateProps extends UserProps {
  editUser: boolean,
  setEditUser: Dispatch<SetStateAction<boolean>>
}

export interface UserRemoveProps extends UserProps {
  removeUser: boolean,
  setRemoveUser: Dispatch<SetStateAction<boolean>>
  refetch?: (variables?: Partial<Exact<{ userInput: UserInput; }>> | undefined) => Promise<ApolloQueryResult<GetAllUsersQuery>>
}

export interface BrandState {
  timeSetting: string
  videoLayoutType: string
  videoGridType: string
  videoBorderType: string
  tabsIndex: number
  mobileDrawerTabs: number
  openDialogue: boolean
  openDrawer: boolean
  previewTabs: boolean
  currentVideo: Attachment | null
  logoEdit: boolean
  onePagerEdit: boolean
  featureImageEdit: boolean
  bannerVideoImageEdit: boolean
  bannerImageEdit: boolean
  waitingImageEdit: boolean
  fallbackImageEdit: boolean
  brandsList: Array<BrandFE>
  filteredBrandsList: Array<BrandFE>
  videoIds: WidgetVideoInput[];
  isDragging: boolean;
  isEditButtonText: boolean
  isEditPostForm: boolean
  isEditTellingForm: boolean
  showColorPicker: boolean
  brandApiKeys: (ApiKey | { keyName: string; brandId: string; })[]
  currentApiKey: ApiKey | null;
}

export interface VideoSettingProps {
  url: string;
  played?: number;
  loaded?: number;
  pip?: boolean;
  playing?: boolean;
  playbackRate?: number
  volume?: number;
  muted?: boolean;
  play?: boolean;
  duration?: number
}

export type AttachmentTypeFE = "logo" | "onePager" | 'video' | 'hookVideo' | 'thumbnail' | 'fallbackImage' | 'featureImage' | 'bannerImage' | 'waitingImage'
export type MetaTypeFE = "brand" | "user" | "job" | "taskTemplate" | "attachment" | "widget"

export interface UploadDocumentProps {
  typeId: string;
  entityType: string;
  isCreateBrand?: boolean;
  attachmentType: AttachmentTypeFE;
  attachmentId?: string;
  widgetData?: Widget;
  setWidgetData?: Dispatch<SetStateAction<Widget | undefined>>;
  setCurrentUploadedAttachment?: Dispatch<SetStateAction<Attachment | null | undefined>>;
  currentUploadedAttachment?: Attachment | null
  setActiveStep?: Dispatch<SetStateAction<number>>;
  activeStep?: number;
  setIsDocumentLoading?: Dispatch<SetStateAction<boolean>>;
  description: string
  fieldName?: string
  accept: Accept | undefined
  maxSize: number;
  brandState?: BrandState
  brandDispatch?: Dispatch<BrandAction>;
  refetchBrandsData?: (variables?: Partial<Exact<{
    [key: string]: never;
  }>> | undefined) => Promise<ApolloQueryResult<GetAllBrandsQuery>>
  refetchWidget?: (variables?: Partial<Exact<{ getWidgetInput: GetWidgetInput; widgetId: string; }>> | undefined) => Promise<ApolloQueryResult<GetWidgetQuery>>
  isLandscape?: boolean
  isKiosk?: boolean
  metaType?: MetaTypeFE
  setVideoForFeatureImage?: Dispatch<SetStateAction<Attachment | undefined>>
  videoForFeatureImage?: Attachment | undefined
  recommendedSize?: string
}

export interface CreateVideoDocumentProps {
  fieldName?: string
  files: File[]
  setFiles: Dispatch<SetStateAction<File[]>>
  entityType: string
  typeId: string;
  attachmentType: AttachmentTypeFE;
  attachmentId?: string;
  isLoading?: boolean

  refetchBrandsData?: (variables?: Partial<Exact<{
    [key: string]: never;
  }>> | undefined) => Promise<ApolloQueryResult<GetAllBrandsQuery>>
}

export interface BrandRemoveProps {
  brandId: string;
}

export interface DeleteItemProps {
  itemId: string;
  itemType?: AttachmentTypeFE
}

export interface VideoSettingProps {
  url: string;
  played?: number;
  loaded?: number;
  pip?: boolean;
  playing?: boolean;
  playbackRate?: number
  volume?: number;
  muted?: boolean;
  play?: boolean
}

export interface UserUpdateInterface {
  roleType: UserRoles | undefined | null;
}
export interface EmptyDataInterface {
  title: string,
  description: string,
  btnText?: string
}

export interface SignupInterface extends RegisterUserInput {
  roleType?: string
}

export interface BrandDataProp {
  brandData: Brand | null,
  handleVideoData?: (video: Attachment) => void
  refetchWidget?: (variables?: Partial<Exact<{ id: string; }>> | undefined) => Promise<ApolloQueryResult<GetWidgetQuery>>
}

export interface CalendlyOverrideProps {
  tagsValue: string[],
  setTagsValue: Dispatch<SetStateAction<string[]>>
}

export interface VideoLibraryProps {
  imageUrl: string
  title: string
  index: number
  isChecked: boolean;
  isPortrait: boolean;
}

export interface UpdateFullNameInterface {
  fullName: string
}

export interface UploadVideoSectionProps {
  title?: string;
  description?: string;
  handleModalOpen?: () => void;
  height: string;
  isLandscape?: boolean;
  handleVideoData: (video: Attachment) => void
  brandDispatch?: Dispatch<BrandAction>
  brandState?: BrandState
}

export interface AddVideoProps {
  title?: string;
  description?: string;
  handleModalOpen?: () => void;
  height: string;
  isLandscape?: boolean;
  handleVideoData?: (video: Attachment) => void
  brandDispatch?: Dispatch<BrandAction>
  brandState?: BrandState
}

export interface SetNewPasswordInput extends UpdateUserPasswordInput {
  newPassword: string;
  currentPassword: string;
  repeatPassword?: string;
}

export interface OrientationThumbnails {
  portraitThumbnailUrls?: string[]
  landscapeThumbnailUrls?: string[]
}

export interface ThumbnailUrlProps extends OrientationThumbnails {
  thumbnailUrls: string[] | undefined
  borderRadius?: string
  bannerImageURL?: string | undefined
  videosToShow?: Attachment[] | undefined
  widgetData?: Widget | undefined
  checkVideoOrientation?: VideoOrientation[] | undefined
  videoForFeatureImage?: Attachment | undefined
}

export interface KioskSettingPreviewProps {
  thumbnailUrls: string[] | undefined
  bannerImageURL: string | undefined
  videosToShow: Attachment[] | undefined
  widgetData: Widget | undefined
  videoForFeatureImage?: Attachment | undefined
}

export interface ImageBoxProps {
  imageUrl: string;
  marginBottom?: string;
  borderRadius?: string;
  width?: string
  isImageUrl?: boolean
  scale?: string
  height?: string
  zIndex?: number
  videoIcon?: boolean,
  marginRight?: string,
  marginLeft?: string,
  backgroundColor?: string
  margin?: string
  paddingLeft?: string
  paddingRight?: string
  isLandscape?: Boolean
  featureImage?: Boolean
  border?: Boolean
  marginTop?: string
  transformOrigin?: string
}

export interface PreviewVideoProps extends ThumbnailUrlProps {
  videoLayout: VideoLayout,
  videoGridType: VideoGridType,
  videoBoundingBox: VideoBox,
  selectedVideos?: WidgetVideoInput[],
  attachmentsToShow: Attachment[] | undefined,
}

export type PasswordType = "password" | "text";

export interface VideoData {
  videoData: Attachment
  dispatch?: Dispatch<BrandAction>;
  state?: BrandState;
  videoStyle?: CSSProperties
}

export interface VideoLibraryInnerTabsProps {
  brandVideos: Attachment[] | undefined
  handleModal: (videoType: AttachmentTypeFE, modalVideoOrientation: VideoOrientation, videoData?: Attachment) => void
  handleVideoData: (video: Attachment) => void
  brandDispatch: Dispatch<BrandAction>
  brandState: BrandState
}

export interface IShowPasswordProps {
  passwordType: string;
  isPassword: boolean | undefined;
  handleShowPassword: () => void;
}

export interface TaskActionsProp {
  setKeyword: Dispatch<SetStateAction<string>>
  loading: boolean
}

export interface TaskTableProps {
  keyword: string,
}

export interface CreateVideoModalProps {
  file: File,
  typeId: string,
  thumbnailId: string,
  type: string,
  attachmentType: AttachmentTypeFE,
  title: string,
  description: string,
  metaType: MetaTypeFE,
  videoOrientation?: VideoOrientation | undefined
  videoOrientationBoolean?: boolean
}

export interface UploadVideoModalProps extends CustomLoaderProps {
  currentVideo?: Attachment | null;
  videoType: AttachmentTypeFE;
  entityType?: string;
  typeId?: string;
  refetchJob?: (variables?: Partial<Exact<{ id: string; }>> | undefined) => Promise<ApolloQueryResult<GetJobQuery>>
  refetchWidget?: (variables?: Partial<Exact<{ getWidgetInput: GetWidgetInput; }>> | undefined) => Promise<ApolloQueryResult<GetWidgetQuery>>
  attachmentId?: string
  isLandscape?: boolean
  videoOrientation?: VideoOrientation | undefined
  setCurrentVideo?: Dispatch<SetStateAction<AttachmentThumbnailUrl | undefined>>
  setVideoIsLong?: Dispatch<SetStateAction<boolean>>
  videoIsLong?: boolean
}

export interface AttachmentThumbnailUrl extends Attachment {
  thumbnailUrl: string
}

export interface UploadImageModalProps extends CustomLoaderProps {
  entityType: string
  typeId: string
  attachmentType: AttachmentTypeFE
  attachmentId?: string
  isLandscape?: boolean
}
export interface BrandFE extends Omit<Brand, 'attachment'> {
  logo?: Attachment | null
  onePager?: Attachment | null
}

export interface UpdateWidget extends Omit<UpdateWidgetInput, 'gorgiasTags'> {
  gorgiasTags: string[]
}

export interface UpdateBrand extends Omit<UpdateBrandInput, 'gorgiasTags'> {
  gorgiasTags: string[]
}

export interface CreateTaskTemplate extends Omit<CreateTaskTemplateInput, 'tags'> {
  tags: string[]
}


export interface UpdateTaskTemplate extends Omit<UpdateTaskTemplateInput, 'tags'> {
  tags: string[]
}

export interface ThumbnailInterface {
  thumbnail: Attachment
}

export interface SiteWidgetPreviewProps {
  idleBackgroundColor: string;
  logoUrl: string;
}

export interface EmptyDataProps {
  description: string;
  button?: boolean,
  height: string;
}

export interface RenderCheckBoxProps {
  video: Attachment;
  index: number;
  handleCheckboxes: (id: string, index: number) => void;
  videoIds: WidgetVideoInput[];
  videosToShow?: number;
}

export interface UploadVideoThumbnailInterface {
  userThumbnails: Attachment[],
  fieldName: string
  isLandscape: boolean
}

export interface UpdateInputAttachment extends UpdateAttachmentInput {
  videoUrl: string | null
}

export interface CustomColorControlProps {
  name: string;
  label: string;
}

export interface VideoControlProps {
  defaultValue: boolean;
}

export interface WidgetPropsInterface {
  dispatch: Dispatch<WidgetAction>;
  state: WidgetState;
}

export interface CustomControlInterface extends WidgetPropsInterface {
  widgetVideoPlayed: number
  volume: boolean
  toggleControllerVolume: () => void
  seekToCurrent: (played: number) => void
}

export interface TimeInterface {
  seconds: number,
  kioskStatus?: number,
  userStatusRef?: MutableRefObject<number>,
  setKioskStatus?: Dispatch<SetStateAction<number>>
  setFallBack?: Dispatch<SetStateAction<boolean>>
  fallbackStateTimer?: Maybe<string> | undefined
  toggleKioskWidgetData?: () => void
  fallBack?: boolean
  showKioskWidgetUserState?: (status: KioskUserStatus) => void
  focusDispatch?: Dispatch<WidgetAction>
}

export interface CustomAccordionProps extends ChildrenType {
  expanded: boolean;
  id: string;
  label: string;
  subHeading?: string;
}

export interface VideoSelectionProps {
  videos: Attachment[];
  videosToShow?: number;
  gridMd: number;
  brandState: BrandState
  brandDispatch: Dispatch<BrandAction>;
  handleDragEnd: (event: DragEndEvent) => void
  setItems?: Dispatch<SetStateAction<Attachment[]>>;
}

export interface AddVideoForKioskProps {
  widgetId?: string
  brandAttachments: Attachment[] | undefined
  videoIds: WidgetVideoInput[];
  handleCheckboxes?: (id: string, index: number) => Promise<void>
  formValues: UpdateWidget
  updateWidget: Function
  videos: Attachment[] | undefined;
  videosToShow?: number;
  gridMd: number;
  brandState: BrandState
  brandDispatch: Dispatch<BrandAction>;
  handleDragEnd: (event: DragEndEvent) => void
  setItems?: Dispatch<SetStateAction<Attachment[]>>;
}

export interface MultipleKeyPressProps {
  pauseTimer: Maybe<number> | undefined
  state: WidgetState;
}

export interface WidgetColorTextInterface {
  color: string,
  heading: string,
}

export interface CustomSwitcherInterface {
  label?: string,
  defaultValue: boolean,
  controllerName: string,
}

export interface WidgetHeadingTextInterface {
  headingName: string,
  headingValue: string | boolean
}

export interface RenderRadioProps {
  value: VideoGridType | VideoLayout | VideoBox;
  isChecked: boolean;
  label: string;
  imageUrl: string;
}
export interface BreadCrumbProps {
  currentPage: string,
  previousPage: string,
  href?: string
}

export interface PostMacroProps {
  postMacros: Array<string>,
  setPostMacros?: Dispatch<SetStateAction<string>>
  anchorElTask?: HTMLElement | null
  handleChangePost: (macro: string) => void
  handleOpenUserMenuTask?: (event: MouseEvent<HTMLElement>) => void
  handleCloseUserMenuTask?: () => void
  top?: string
}
export interface ThreeDostLoaderProps {
  height: string
}
export interface UpdateProfileDialogueInterface {
  isEditOpen?: boolean,
  isAdditionalEditOpen?: boolean,
  label?: string
  setIsEditOpen?: Dispatch<SetStateAction<boolean>>
  setIsAdditionalEditOpen?: Dispatch<SetStateAction<boolean>>
}

export interface UserGeneralInformationInterface extends UpdateProfileDialogueInterface {
  currentUserData: Maybe<User>
  usersData?: Maybe<Array<Maybe<User>>>
  dispatch: Dispatch<UserAction>
}

export interface UserAdditionalInformationInterface extends UpdateProfileDialogueInterface {
  currentUserData: Maybe<User>
  usersData?: Maybe<Array<Maybe<User>>>
  dispatch: Dispatch<UserAction>
}

export interface CustomIconLinkInterface{
   widgetLink:string
}

export interface CustomMacroProps {
  macros: Array<string>,
  setMacros?: Dispatch<SetStateAction<string>>
  anchorElUser?: HTMLElement | null
  handleChange: (macro: string) => void
  handleOpenUserMenu?: (event: MouseEvent<HTMLElement>) => void
  handleCloseUserMenu?: () => void
  top?: string
  listMacros?: boolean
}

export interface DeleteBrandProps {
  brand: Brand | null
  brandsData: BrandFE[]
  refetch: ((variables?: Partial<Exact<{
    [key: string]: never;
  }>> | undefined) => Promise<ApolloQueryResult<GetAllBrandsQuery>>) | undefined
}
export interface PostMacroProps {
  postMacros: Array<string>,
  anchorElUser?: HTMLElement | null
  handleChangePost: (macro: string) => void
  handleOpenUserMenu?: (event: MouseEvent<HTMLElement>) => void
  handleCloseUserMenu?: () => void
}
export interface WidgetCardProps {
  name: string
  description: string
  mediumViewport: boolean
  linkBaseUrl: string
  id: string
  ImageComponent: ReactNode
  widgetType: string
  widgetScriptType: string
  widget?: Widget | null;
  widgetDispatch?: Dispatch<WidgetAction>
  widgetsData?: Array<Widget>
  right: string
  paddingRight?: string
  paddingLeft?: string
  isKiosk?: boolean
}

export interface LandingPageTabsProps {
  showOperator: boolean
}

export interface VideoSettingPreviewProps extends OrientationThumbnails {
  thumbnailUrls: string[],
  videoIds: WidgetVideoInput[],
  thumbnailsToShow: Attachment[]
  headingValue: InputMaybe<string> | undefined
  descriptionValue: InputMaybe<string> | undefined
  headingColorValue: InputMaybe<string> | undefined
  descriptionColorValue: InputMaybe<string> | undefined
  hookVideos: Attachment[] | undefined
}

export interface PreviewHookVideoLayoutProps {
  hookVideos: Attachment[] | undefined
}

export interface PreviewDesktopHookVideoInterface {
  hookVideoThumbnail: string
}

export interface TellingFormProps {
  headingLabel?: string,
  descriptionLabel?: string,
  designHelperText?: string,
  supportHelperText?: string,
  tellingFormBorder?: boolean
}

export interface TagsProps {
  controllerName: string
}
export interface SearchComponentProps {
  infoText?: string;
  placeHolder?: string;
  loading?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disable?: boolean
}

export interface TableActionButtonInterface {
  editBtnText: string
  removeBtnText: string
  editBtnHandler: () => void
  removeBtnHandler: () => void
}

export interface RejectJobInterface {
  jobId: string
  handleCloseReject: () => void
  refetchJob?: (variables?: Partial<Exact<{ id: string; }>> | undefined) => Promise<ApolloQueryResult<GetJobQuery>>
}

export interface SendJobVideoInterface {
  sendVideoLoading: boolean
  videoSend: () => void
  handleClose: () => void
}
export interface RejectJobInputInterface {
  statusMessage: string
}

export interface DetailCardInterface {
  title: string,
  email: string,
  phone?: string
}

export interface WidgetPropsInterface {
  dispatch: Dispatch<WidgetAction>;
  state: WidgetState;
}

export interface CustomVideoPlayerInterface extends WidgetPropsInterface {
  videoId?: string,
  isActiveSlide: boolean,
  url: string,
  thumbnail: string,
  muted?: boolean,
  controls?: boolean,
  className?: string,
  customControls?: boolean,
  videoTitle: string;
  videoDescription: string,
  videoOrientation: string,
  goToPrevSlide?: () => void,
  goToNextSlide?: () => void,
  setTimer?: () => void,
  isFocus?: boolean
}

export interface ReactPlayerForwardRef {
  playChildVideo(): void
  pauseChildVideo(): void
}

export enum WidgetKioskState {
  Idle = 'IDLE',
  Focused = 'FOCUSED'
}

export enum KioskUserStatus {
  Initial = 'INITIAL',
  Focused = 'FOCUSED',
  Waiting = 'WAITING',
  Fallback = 'FALLBACK',
  Calling = 'CALLING',
  Attended = 'ATTENDED'
}

export enum ARROW_KEYS {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight'
}

export interface KioskWidgetIdleInterface extends WidgetPropsInterface { }

export interface KioskWidgetFocusInterface extends WidgetPropsInterface {
  toggleKioskWidget: () => void
}

export interface SampleFileContentProps {
  description: string
  attachmentType: AttachmentType
}

export interface KioskImageWrapperInterface {
  imageUrl: string,
  isFeature?: boolean
}

export interface KioskBannerImageInterface {
  isFocus?: boolean,
  id?: string,
  imageUrl?: Maybe<string> | undefined,
  slideWidth?: number
}

interface OptionsInterface {
  id: string,
  label: string
}

export interface InviteTest extends InviteUserInput {
  customBrandId: OptionsInterface
}

export interface ItemInterface {
  id: string,
  label: string
}

export interface SetDefaultUserProps extends UserProps {
  brandId: string;
  defaultUser: boolean;
  setDefaultUser: Dispatch<SetStateAction<boolean>>
  refetch?: (variables?: Partial<Exact<{ userInput: UserInput; }>> | undefined) => Promise<ApolloQueryResult<GetAllUsersQuery>>
}

export interface SocketResponse {
  data: {
    message: string;
    meetingUrl?: string
  }
}

export class KioskState {
  inRequest?: boolean;
  inFocus?: boolean;
  inMeeting?: boolean;
}

export interface KioskMutateSocketResponse {
  message: string;

  state: {
    kioskWidgetId: string;
    brandId: string;
    state?: KioskState;
  }
}
export interface GenerateApikeyInterface {
  brandDispatch: Dispatch<BrandAction>
  brandState: BrandState
  brandApiKeys: (ApiKey | { keyName: string; brandId: string; })[]
}

export interface GenerateApiKeyViewInterface {
  locallyKey: string
}

export interface DeleteApiKeyInterface {
  brandApiKeys: (ApiKey | { keyName: string; brandId: string; })[]
  currentApiKey: ApiKey | null
  brandDispatch: Dispatch<BrandAction>
}

export interface animateLayoutChangesType {
  id: UniqueIdentifier;
  index: number;
  items: UniqueIdentifier[];
  active: Active | null;
  newIndex: number;
  isSorting: boolean;
  isDragging: boolean;
  transition: SortableTransition | null;
  containerId: UniqueIdentifier;
  wasDragging: boolean;
  previousItems: UniqueIdentifier[];
  previousContainerId: UniqueIdentifier;
}

export interface VideoInterface {
  videoUrl: string;
  postedBy: string;
  postedAt: Date;
  comments: number;
  videoViews: number;
  title: string;
  thumbnail: string;
}

export interface VideoSearchInterface {
  title: string;
  date: Date;
  transcript: string;
  thumbnail: string;
  time: string;
}

export interface AvatarIconInterface {
  color: string,
  bgColor: string,
  text: string
}

export interface SpeakerCommentComponentProps {
  speakerData: TranscriptBody;
  handlePlay: (time: string | undefined) => void;
}

export interface NumberOfMapsProp {
  numberOfMap: number
}

export interface VideoCardComponentProps {
  scheduleMeeting: Maybe<ScheduleMeeting>
}

export interface EmailAliasProps {
  userState: UserState
  userDispatch: Dispatch<UserAction>
  emailData?: UserEmail
}

export interface EditOrDelete {
  email: string;
  id: string;
}

export interface VideoListingObject {
  keyword?: string | undefined;
  widgetType?: WidgetType | null | undefined;
  operatorId?: string;
  searchText?: string;
}

export interface VideoSearchFiltersProps {
  videoSearchKeys: VideoListingObject;
  setVideoSearchKeys: Dispatch<SetStateAction<VideoListingObject>>
  loading: boolean
}

export interface VideoSearchSummaryProps {
  videoSearchKeys: VideoListingObject;
}

export interface OperatorNameProps {
  loading: boolean
  videoSearchKeys: VideoListingObject
  setVideoSearchKeys: Dispatch<SetStateAction<VideoListingObject>>
}

export interface CommentTextFieldProps {
  isCommentOpen: boolean;
  setIsCommentOpen: Dispatch<SetStateAction<boolean>>
  speakerData: TranscriptBody
  commentArray: Comment[]
  setCommentArray: Dispatch<SetStateAction<Comment[]>>
  isEdit?: boolean
  setIsEdit?: Dispatch<SetStateAction<boolean>>
  singleComment?: Comment
}

export interface VideoSearchCardProps {
  scheduleEvent?: Maybe<ScheduleMeeting>;
  transcriptions?: Maybe<Array<ElasticSearchBody>>;
  searchText: string | undefined
}

export interface TranscriptionProps {
  transcriptions: Maybe<ElasticSearchBody[]> | undefined;
  searchText: string | undefined
  id: string | undefined
}

export interface CreateComment {
  value: string;
}

export interface CommentObject {
  email: string;
  assignedUserId: string;
}

export interface VideoCommentComponentProps {
  singleComment: Comment
  isCommentOpen: boolean;
  setIsCommentOpen: Dispatch<SetStateAction<boolean>>
  speakerData: TranscriptBody
  commentArray: Comment[]
  setCommentArray: Dispatch<SetStateAction<Comment[]>>
}

export interface TranscriptionSummaryProps {
  scheduleMeetingData: ScheduleMeeting | undefined;
  setSeekingTime: Dispatch<SetStateAction<string | null | undefined>>
}

export interface SummaryComponentProps {
  summaryArray: SummaryPointType
  setSeekingTime: Dispatch<SetStateAction<string | null | undefined>>
}

export interface CustomWindow extends Window {
  cabaPopup?: {
      open: (options: {
          hookVideo: boolean,
          designAdvice: boolean,
          customerSupport: boolean
      }) => void;
      // Other potential properties and their types
  };
}
export interface MentionProps {
  isEdit: boolean | undefined
}