import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { FC, useContext } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { UpdateUserInput, UserRoles, useUpdateUserMutation } from '../../../generated'
import { USER_UPDATED_SUCCESSFULLY } from '../../constants'
import { AuthContext } from '../../context'
import { UserActionType } from '../../context/UserContextReducer'
import { UserAdditionalInformationInterface } from '../../interfaceTypes'
import { firstLetterUppercase } from '../../utils'
import { CustomSwitcher } from '../brandSetting/widgetCommon/CustomSwitcher'
import { Alert } from '../common/Alert'

const UserAdditionalInformation: FC<UserAdditionalInformationInterface> = ({ usersData, dispatch, isAdditionalEditOpen, setIsAdditionalEditOpen, currentUserData }) => {
  const { isAdmin } = useContext(AuthContext);
  const { id, roles, isModerated, } = currentUserData || {}

  const methods = useForm<UpdateUserInput>({
    defaultValues: {
      roleType: roles && roles[0]?.role
    }
  })

  const { handleSubmit, control, watch } = methods

  const ModerateStatusValue = watch('isModerated')

  const [updateUser, { loading: userLoading }] = useUpdateUserMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ""));
    },

    onCompleted(data) {
      const { updateUser } = data

      if (updateUser) {
        const updatedUsers = usersData?.map((user) => {
          if (user?.id === updateUser.id) {
            return updateUser
          }
          return user
        })

        dispatch({ type: UserActionType.SET_USERS_DATA, usersData: updatedUsers || [] })
        setIsAdditionalEditOpen && setIsAdditionalEditOpen(false)
        return Alert.success(USER_UPDATED_SUCCESSFULLY);
      }
    }
  })

  const onSubmit = async (data: UpdateUserInput) => {
    const { roleType } = data

    await updateUser({
      variables: {
        updateUserInput: {
          roleType,
          userId: id,
          isModerated: ModerateStatusValue
        }
      }
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}
        autoComplete="off">

        <Box>
          <Typography variant='body2'>Moderated</Typography>

          <Box sx={{ marginTop: '10px', marginBottom: '20px' }}>
            <CustomSwitcher defaultValue={!!isModerated} controllerName='isModerated' />
          </Box>
        </Box>

        <FormControl sx={{ marginTop: '.5rem' }} fullWidth>
          <InputLabel id="snappingPositionTitle">Role</InputLabel>
          <Controller
            name="roleType"
            control={control}
            render={({ field }) => (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="Role"
                {...field}
              >
                {isAdmin && <MenuItem value={UserRoles.Admin}>Admin</MenuItem>}
                <MenuItem value={UserRoles.Operator}>Operator</MenuItem>
                <MenuItem value={UserRoles.BrandManager}>Brand Manager</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        <Box sx={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button size='small' variant='contained' color="primary" aria-label="add" type='submit'
            endIcon={userLoading && <CircularProgress size={20} color="inherit" />}
            disabled={userLoading}
          >
            Update
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

export default UserAdditionalInformation

