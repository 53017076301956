import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { TableSkeletonProps } from "../../interfaceTypes";

/**
 * It would render Skelton on Table
 *
 *
 * @returns JSX Element DimensionTableSkeleton
 */

export const ApikeySkeleton: FC<TableSkeletonProps> = ({ noOfRows }) => (
  <>
    <TableBody>
      {new Array(noOfRows).fill(0).map((_, index) => (
        <TableRow key={`${index}-skeleton`}>
          <TableCell>
            <Skeleton variant="text" width={200} height={30} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={210} height={30} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={100} height={30} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" height={30} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </>
);