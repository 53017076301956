import { Box } from '@mui/material';
import { FC } from 'react'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ImageBoxProps } from '../../../interfaceTypes';
import { CustomLazyImage } from '../../common/CustomLazyImage';
import { VideoPlayIcon } from '../../../theme/styleComponents';
import { flexCenter } from '../../../theme/styleConstants';
import { images } from '../../../../assets/images';
import palette from '../../../theme/palette';

export const PortraitGalleryImageBox: FC<ImageBoxProps> = ({ borderRadius, imageUrl, width, scale, height, zIndex, videoIcon, marginRight, marginLeft }) => {
  const isImageUrl = imageUrl === images.DUMMY

  return (
    <Box width={width} height={height} marginLeft={marginLeft} marginRight={marginRight} position='relative' zIndex={zIndex} border={isImageUrl ? `1px solid ${palette.lightBorderColorTwo}` : ''} sx={{ transform: `scale(${scale})` }}>
      <Box borderRadius={borderRadius} overflow="hidden" height='100%' width='100%' sx={flexCenter}>
        <CustomLazyImage
          isImageUrl={isImageUrl}
          imageUrl={imageUrl}
        />
      </Box>

      {!isImageUrl && videoIcon &&
        <VideoPlayIcon>
          <PlayCircleIcon sx={{ color: palette.white, width: '30px', height: '30px' }} />
        </VideoPlayIcon>
      }
    </Box>
  )
}
