import { Box } from '@mui/material';
import { FC } from 'react'
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { PreviewImageBox } from '../PreviewImageBox';

export const MassonayFiveVideo: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  const fourthImage = thumbnailUrls && thumbnailUrls[3]
  const fifthImage = thumbnailUrls && thumbnailUrls[4]
  return (
    <Box p={1} minHeight='200px' display='flex' justifyContent='space-between'>
      <Box width='55%' position='relative'>
        <PreviewImageBox height='200px' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />
      </Box>

      <Box width='42%' display='flex' justifyContent='space-between'>
        <Box width='45%'>
          <PreviewImageBox height='95px' marginBottom='10px' imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} />

          <PreviewImageBox height='95px' imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} />
        </Box>

        <Box width='45%'>
          <Box>
            <PreviewImageBox height='95px' marginBottom='10px' imageUrl={fourthImage || images.DUMMY} borderRadius={borderRadius} />

            <PreviewImageBox height='95px' imageUrl={fifthImage || images.DUMMY} borderRadius={borderRadius} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
