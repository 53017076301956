import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, CircularProgress, Collapse, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FC, Reducer, useContext, useEffect, useReducer, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { UpdateBrandInput, useUpdateBrandMutation } from "../../../generated";
import {
  ACCEPT_FOR_IMAGES, BRAND_NAME, CUSTOMER_LANDING_PAGE_URL, DESCRIPTION_FOR_IMAGE, DISABLE, ENABLE, GORGIAS_SETTINGS, IMAGE_MAX_SIZE, PRIMARY_COLOR,
  ROUTING_EMAIL,
  SECONDARY_COLOR, SUPPORT_EMAIL
} from "../../constants";
import { AppContext } from "../../context";
import { ActionType } from "../../context/AppContextReducer";
import { BrandState } from "../../interfaceTypes";
import palette from "../../theme/palette";
import { PreviewBox } from "../../theme/styleComponents";
import { firstLetterUppercase } from "../../utils";
import { brandGuideLinesSchema } from "../../validationSchema";
import { Alert } from "../common/Alert";
import { CustomController } from "../common/CustomController";
import { ThreeDotsLoader } from "../common/ThreeDotsLoader";
import { UploadDocument } from "../common/UploadDocument";
import { BrandAction, BrandActionType, brandInitialState, brandReducer } from "./reducer/brandReducer";
import { CustomColorControl } from "./widgetCommon/CustomColorControl";
import { CustomSwitcher } from "./widgetCommon/CustomSwitcher";
import { WidgetColorText } from "./widgetCommon/WidgetColorText";
import { WidgetHeadingText } from "./widgetCommon/WidgetHeadingText";
import { NotVerifiedIcon, VerifiedIcon } from '../../../assets/images'

export const BrandGuidelines: FC = () => {
  const { selectedBrand, dispatch, isBrandLoading, refetchBrand, showGorgiasSettings } = useContext(AppContext)
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [brandState, brandDispatch] = useReducer<Reducer<BrandState, BrandAction>>(brandReducer, brandInitialState);
  const { name, settings, id: brandId, logo, onePager, customerLandingPageUrl, supportEmail, createdAt, updatedAt, attachments, routingEmail, gorgiasEnable } = selectedBrand || {}
  const { primaryColor, secondaryColor } = settings || {}
  const { id: logoId, url: logoUrl } = logo || {}
  const logoUrlArray = logoUrl?.split('/upload')
  const updatedLogoUrlArray = logoUrlArray && [...logoUrlArray.slice(0, 1), '/upload/q_auto', ...logoUrlArray.slice(1)]
  const updatedLogoUrl = updatedLogoUrlArray?.join('')

  const { id: onePagerId, url: onePagerUrl } = onePager || {}
  const onePagerUrlArray = onePagerUrl?.split('/upload')
  const updatedOnePagerUrlArray = onePagerUrlArray && [...onePagerUrlArray.slice(0, 1), '/upload/q_auto', ...onePagerUrlArray.slice(1)]
  const updatedOnePagerUrl = updatedOnePagerUrlArray?.join('')

  const { logoEdit, onePagerEdit } = brandState

  const methods = useForm<UpdateBrandInput>({
    mode: 'all',
    resolver: yupResolver(brandGuideLinesSchema(showGorgiasSettings || false)),

    defaultValues: {
      name: name || "",
      customerLandingPageUrl: customerLandingPageUrl || '',
      supportEmail: supportEmail || "",
      gorgiasEnable: gorgiasEnable,
      routingEmail: routingEmail || "",

      settings: {
        primaryColor: primaryColor || "#000000",
        secondaryColor: secondaryColor || "#000000"
      }
    }
  });

  const { setValue, handleSubmit, watch } = methods

  const [updateBrand, { loading }] = useUpdateBrandMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { updateBrand } = data
        const { customerLandingPageUrl: updatedCustomerLandingPageUrl, supportEmail: updatedSupportEmail, routingEmail, gorgiasEnable } = updateBrand
        const updatedBrand = {
          ...selectedBrand,
          id: brandId || "",
          name: name || "",
          attachments: attachments || [],
          createdAt,
          updatedAt,
          customerLandingPageUrl: updatedCustomerLandingPageUrl || '',
          supportEmail: updatedSupportEmail || '',
          gorgiasEnable,
          routingEmail
        }

        if (updateBrand) {
          dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrand })
          setIsEditOpen(false)
          refetchBrand && refetchBrand()
          return Alert.success("Brand updated successfully")
        }
      }
    }
  })

  const onSubmit = async (data: UpdateBrandInput) => {
    const { name, settings, customerLandingPageUrl, supportEmail, gorgiasEnable, routingEmail } = data
    const { primaryColor, secondaryColor } = settings || {}

    if (brandId) {
      await updateBrand({
        variables: {
          updateBrandInput: {
            brandId,
            name,
            supportEmail,
            customerLandingPageUrl,
            gorgiasEnable,
            routingEmail,

            settings: {
              primaryColor,
              secondaryColor
            }
          }
        }
      })
    }
  }

  const handleLogoEdit = () => {
    brandDispatch({ type: BrandActionType.SET_LOGO_EDIT, logoEdit: !logoEdit })
  }

  const handleOnePagerEdit = () => {
    brandDispatch({ type: BrandActionType.SET_ONE_PAGER_EDIT, onePagerEdit: !onePagerEdit })
  }

  useEffect(() => {
    name && setValue('name', name)
    customerLandingPageUrl && setValue('customerLandingPageUrl', customerLandingPageUrl)
    supportEmail && setValue('supportEmail', supportEmail)
    primaryColor && setValue('settings.primaryColor', primaryColor)
    secondaryColor && setValue('settings.secondaryColor', secondaryColor)
    setValue('gorgiasEnable', gorgiasEnable);
    routingEmail && setValue('routingEmail', routingEmail)
    !!gorgiasEnable && dispatch({ type: ActionType.SET_SHOW_GORGIAS_SETTINGS, showGorgiasSettings: gorgiasEnable })
    // eslint-disable-next-line
  }, [name, customerLandingPageUrl, supportEmail, primaryColor, secondaryColor, routingEmail, gorgiasEnable])

  const gorgiasEnableValue = watch('gorgiasEnable');

  useEffect(() => {
    dispatch({ type: ActionType.SET_SHOW_GORGIAS_SETTINGS, showGorgiasSettings: gorgiasEnableValue || false })
    // eslint-disable-next-line
  }, [gorgiasEnableValue])

  return (
    <>
      {isBrandLoading ?
        <ThreeDotsLoader height="calc(100vh - 300px)" />
        :
        <Box sx={{ mt: "2rem" }}>
          <Box display='flex' justifyContent='space-between' alignItems="center">
            <Typography variant="subtitle1" mb={2}>Brand Guidelines</Typography>

            <IconButton color="primary" size="large" onClick={() => setIsEditOpen(!isEditOpen)}>
              {isEditOpen ? <ArrowBackIcon /> : <EditIcon />}
            </IconButton>
          </Box>

          <Box sx={{ borderBottom: `1px dotted ${palette.light}` }} pb={2}>
            <Collapse in={!isEditOpen}>
              <Grid container spacing={2} rowSpacing={2}>
                <Grid item md={4} sm={12} xs={12}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant='body2' textTransform="capitalize">{GORGIAS_SETTINGS}</Typography>

                    <Box display='flex' alignItems='center'>
                      {gorgiasEnableValue ? <VerifiedIcon /> : <NotVerifiedIcon />}

                      <Typography sx={{ marginLeft: '5px', marginY: '10px' }} variant='h4'>
                        {gorgiasEnableValue ? ENABLE : DISABLE}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                  <WidgetHeadingText headingName={BRAND_NAME} headingValue={name || ""} />
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                  <WidgetColorText heading={PRIMARY_COLOR} color={primaryColor || '#000000'} />
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                  <WidgetColorText heading={SECONDARY_COLOR} color={secondaryColor || '#000000'} />
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                  <Typography variant='body2' textTransform="capitalize">{SUPPORT_EMAIL}</Typography>

                  <Typography sx={{ marginY: '10px' }} noWrap>
                    {supportEmail ?
                      <Typography component="a" href={supportEmail ? `mailto:${supportEmail}` : ""} color="primary" variant='h4'>{supportEmail}</Typography>
                      :
                      <Typography color="inherit" component='span'>N/A</Typography>
                    }
                  </Typography>
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                  <Typography variant='body2' textTransform="capitalize">{CUSTOMER_LANDING_PAGE_URL}</Typography>

                  <Typography sx={{ marginY: '10px' }} noWrap>
                    <Tooltip title={customerLandingPageUrl || 'N/A'} placement="bottom-start">
                      {customerLandingPageUrl ?
                        <Typography component="a" target='_blank' href={customerLandingPageUrl || 'N/A'} width='100%' color="primary" variant='h4' noWrap>{customerLandingPageUrl}</Typography>
                        :
                        <Typography color="inherit" component='span'>N/A</Typography>
                      }
                    </Tooltip>
                  </Typography>
                </Grid>

                {!gorgiasEnableValue &&
                  <Grid item md={4} sm={12} xs={12}>
                    <Typography variant='body2' textTransform="capitalize">{ROUTING_EMAIL}</Typography>

                    <Typography sx={{ marginY: '10px' }} noWrap>
                      {routingEmail ?
                        <Typography component="a" color="primary" variant='h4'>{routingEmail}</Typography>
                        :
                        <Typography color="inherit" component='span'>N/A</Typography>
                      }
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Collapse>

            <Collapse in={isEditOpen}>
              <FormProvider  {...methods}>
                <Box pb='10px'>
                  <CustomSwitcher label="Gorgias" defaultValue={!!gorgiasEnable} controllerName="gorgiasEnable" />
                </Box>

                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <CustomController
                    controllerName="name"
                    controllerLabel={`Enter ${BRAND_NAME}`}
                    fieldType='text'
                  />

                  <Grid container spacing={2} rowSpacing={0.5}>
                    <Grid item md={6} xs={12}>
                      <CustomController
                        controllerName="supportEmail"
                        controllerLabel={SUPPORT_EMAIL}
                        fieldType='text'
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <CustomController
                        controllerName="customerLandingPageUrl"
                        controllerLabel={CUSTOMER_LANDING_PAGE_URL}
                        fieldType='text'
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} rowSpacing={0.5}>
                    <Grid item md={6} xs={12}>
                      <CustomColorControl name="settings.primaryColor" label={PRIMARY_COLOR} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <CustomColorControl name="settings.secondaryColor" label={SECONDARY_COLOR} />
                    </Grid>
                  </Grid>

                  {!gorgiasEnableValue && <Grid container spacing={2} rowSpacing={0.5}>
                    <Grid item md={12} xs={12}>
                      <CustomController
                        controllerName="routingEmail"
                        controllerLabel={'Routing Email'}
                        fieldType='text'
                      />
                    </Grid>
                  </Grid>}

                  <Box textAlign="right" pt={2}>
                    <Button color="primary" variant="contained" type="submit"
                      disabled={loading}
                      endIcon={loading && <CircularProgress size={20} color="inherit" />}
                    >
                      Update
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </Collapse>
          </Box>

          <Box marginTop={3}>
            <Box display='flex' justifyContent='space-between' alignItems="center">
              <Typography variant="subtitle1">Upload Brand Logo</Typography>

              {updatedLogoUrl &&
                <IconButton color="primary" size="large" onClick={handleLogoEdit}>
                  {logoEdit ? <ArrowBackIcon /> : <EditIcon />}
                </IconButton>
              }
            </Box>

            <Collapse in={logoEdit}>
              <Box pt={1}>
                <UploadDocument
                  maxSize={IMAGE_MAX_SIZE}
                  accept={ACCEPT_FOR_IMAGES}
                  description={DESCRIPTION_FOR_IMAGE}
                  entityType="brands"
                  typeId={brandId || ""}
                  attachmentType="logo"
                  attachmentId={logoId || undefined}
                />
              </Box>

              <Box pt={1}>
                <PreviewBox>
                  <LazyLoadImage
                    src={updatedLogoUrl || ""}
                    style={{ maxWidth: "400px" }}
                  />
                  <Box component='img' />
                </PreviewBox>
              </Box>
            </Collapse>

            <Collapse in={!logoEdit}>
              {!updatedLogoUrl ?
                <Box pt={1}>
                  <UploadDocument
                    maxSize={IMAGE_MAX_SIZE}
                    accept={ACCEPT_FOR_IMAGES}
                    description={DESCRIPTION_FOR_IMAGE}
                    entityType="brands"
                    typeId={brandId || ""}
                    attachmentType="logo"
                    attachmentId={logoId || undefined}
                  />
                </Box>
                :
                <Box pt={1}>
                  <PreviewBox>
                    <LazyLoadImage
                      src={updatedLogoUrl || ""}
                      style={{ maxWidth: "300px" }}
                    />
                    <Box component='img' />
                  </PreviewBox>
                </Box>
              }
            </Collapse>
          </Box>

          <Box mt={2} mb={2}>
            <Box display='flex' justifyContent='space-between' alignItems="center">
              <Typography variant="subtitle1">Upload Brand One Pager</Typography>

              {updatedOnePagerUrl &&
                <IconButton color="primary" size="large" onClick={handleOnePagerEdit}>
                  {onePagerEdit ? <ArrowBackIcon /> : <EditIcon />}
                </IconButton>
              }
            </Box>

            <Collapse in={onePagerEdit}>
              <Box pt={1}>
                <UploadDocument
                  maxSize={IMAGE_MAX_SIZE}
                  accept={ACCEPT_FOR_IMAGES}
                  description={DESCRIPTION_FOR_IMAGE}
                  entityType="brands"
                  typeId={brandId || ""}
                  attachmentType="onePager"
                  attachmentId={onePagerId || undefined}
                />
              </Box>

              <Box pt={1}>
                <PreviewBox>
                  <LazyLoadImage
                    style={{ maxWidth: "100%", borderRadius: "8px" }}
                    src={updatedOnePagerUrl || ""}
                  />
                </PreviewBox>
              </Box>
            </Collapse>

            <Collapse in={!onePagerEdit}>
              {!updatedOnePagerUrl ?
                <Box pt={1}>
                  <UploadDocument
                    maxSize={IMAGE_MAX_SIZE}
                    accept={ACCEPT_FOR_IMAGES}
                    description={DESCRIPTION_FOR_IMAGE}
                    entityType="brands"
                    typeId={brandId || ""}
                    attachmentType="onePager"
                    attachmentId={onePagerId || undefined}
                  />
                </Box>
                :
                <Box pt={1}>
                  <PreviewBox>
                    <LazyLoadImage
                      style={{ maxWidth: "100%", borderRadius: "8px" }}
                      src={updatedOnePagerUrl || ""}
                    />
                  </PreviewBox>
                </Box>
              }
            </Collapse>
          </Box>

        </Box>
      }
    </>
  )
}