import { Maybe, Widget } from "../../generated";
import { KioskUserStatus, VideoSettingProps, WidgetKioskState } from "../interfaceTypes";

export interface WidgetState {
  widgetsData?: Array<Widget>
  currentWidgetData?: Maybe<Widget> | undefined;
  videoSettings: VideoSettingProps,
  widgetCounter: number,
  widgetKioskState: WidgetKioskState,
  widgetKioskUserStatus: KioskUserStatus,
  widgetKioskActiveSlide: number,
  widgetKioskData: Maybe<Widget>,
  kioskStatus: number,
  fallBack: boolean,
  waiting: boolean,
  idleState: boolean,
  idlePressEnter: boolean
  tooManyKeyStrokes: boolean,
  slideAnimation: boolean
}

export const widgetInitialState: WidgetState = {
  widgetsData: [],
  currentWidgetData: null,
  videoSettings: ({ url: "", played: 0, loaded: 0, pip: false, playing: false, playbackRate: 1, volume: 1, muted: false }),
  widgetCounter: 1,
  widgetKioskState: WidgetKioskState.Idle,
  widgetKioskUserStatus: KioskUserStatus.Initial,
  widgetKioskActiveSlide: 0,
  widgetKioskData: null,
  kioskStatus: 1,
  fallBack: false,
  waiting: false,
  idleState: false,
  idlePressEnter: false,
  tooManyKeyStrokes: false,
  slideAnimation: false
}

export enum WidgetActionType {
  SET_WIDGETS_DATA = 'setWidgetsData',
  SET_CURRENT_WIDGET = 'setCurrentWidget',
  SET_VIDEO_SETTINGS = 'setVideoSettings',
  SET_WIDGET_COUNTER = 'setWidgetCounter',
  SET_WIDGET_KIOSK_STATE = 'setWidgetKioskState',
  SET_WIDGET_KIOSK_USER_STATUS = 'setWidgetKioskUserStatus',
  SET_WIDGET_KIOSK_ACTIVE_SLIDE = 'setWidgetKioskActiveSlide',
  SET_WIDGET_KIOSK_DATA = 'setWidgetKioskData',
  SET_KIOSK_STATUS = 'setKioskStatus',
  SET_FALL_BACK = 'setFallBack',
  SET_WAITING = 'setWaiting',
  SET_IDLE_STATE = 'setIdleState',
  SET_IDLE_PRESS_ENTER = 'setEnterPress',
  SET_TOO_MANY_KEY_STROKES = 'setTooManyKeyStrokes',
  SET_SLIDE_ANIMATION = 'setSlideAnimation'
}

export type WidgetAction =
  | { type: WidgetActionType.SET_WIDGETS_DATA, widgetsData: Array<Widget> }
  | { type: WidgetActionType.SET_CURRENT_WIDGET, currentWidgetData: Maybe<Widget> | undefined }
  | { type: WidgetActionType.SET_VIDEO_SETTINGS, videoSettings: VideoSettingProps }
  | { type: WidgetActionType.SET_WIDGET_COUNTER, widgetCounter: number }
  | { type: WidgetActionType.SET_WIDGET_KIOSK_STATE, widgetKioskState: WidgetKioskState }
  | { type: WidgetActionType.SET_WIDGET_KIOSK_USER_STATUS, widgetKioskUserStatus: KioskUserStatus }
  | { type: WidgetActionType.SET_WIDGET_KIOSK_ACTIVE_SLIDE, widgetKioskActiveSlide: number }
  | { type: WidgetActionType.SET_WIDGET_KIOSK_DATA, widgetKioskData: Maybe<Widget> }
  | { type: WidgetActionType.SET_KIOSK_STATUS, kioskStatus: number }
  | { type: WidgetActionType.SET_FALL_BACK, fallBack: boolean }
  | { type: WidgetActionType.SET_WAITING, waiting: boolean }
  | { type: WidgetActionType.SET_IDLE_STATE, idleState: boolean }
  | { type: WidgetActionType.SET_IDLE_PRESS_ENTER, idlePressEnter: boolean }
  | { type: WidgetActionType.SET_TOO_MANY_KEY_STROKES, tooManyKeyStrokes: boolean }
  | { type: WidgetActionType.SET_SLIDE_ANIMATION, slideAnimation: boolean }

export const widgetReducer = (state: WidgetState, action: WidgetAction): WidgetState => {
  switch (action.type) {
    case WidgetActionType.SET_WIDGETS_DATA:
      return {
        ...state,
        widgetsData: action.widgetsData
      }

    case WidgetActionType.SET_CURRENT_WIDGET:
      return {
        ...state,
        currentWidgetData: action.currentWidgetData
      }

    case WidgetActionType.SET_WIDGET_KIOSK_DATA:
      return {
        ...state,
        widgetKioskData: action.widgetKioskData
      }

    case WidgetActionType.SET_VIDEO_SETTINGS:
      return {
        ...state,
        videoSettings: action.videoSettings
      }

    case WidgetActionType.SET_WIDGET_COUNTER:
      return {
        ...state,
        widgetCounter: action.widgetCounter
      }

    case WidgetActionType.SET_WIDGET_KIOSK_STATE:
      return {
        ...state,
        widgetKioskState: action.widgetKioskState
      }

    case WidgetActionType.SET_WIDGET_KIOSK_USER_STATUS:
      return {
        ...state,
        widgetKioskUserStatus: action.widgetKioskUserStatus
      }

    case WidgetActionType.SET_WIDGET_KIOSK_ACTIVE_SLIDE:
      return {
        ...state,
        widgetKioskActiveSlide: action.widgetKioskActiveSlide
      }

    case WidgetActionType.SET_KIOSK_STATUS:
      return {
        ...state,
        kioskStatus: action.kioskStatus
      }

    case WidgetActionType.SET_FALL_BACK:
      return {
        ...state,
        fallBack: action.fallBack
      }

    case WidgetActionType.SET_WAITING:
      return {
        ...state,
        waiting: action.waiting
      }

    case WidgetActionType.SET_IDLE_STATE:
      return {
        ...state,
        idleState: action.idleState
      }

    case WidgetActionType.SET_IDLE_PRESS_ENTER:
      return {
        ...state,
        idlePressEnter: action.idlePressEnter
      }

    case WidgetActionType.SET_TOO_MANY_KEY_STROKES:
      return {
        ...state,
        tooManyKeyStrokes: action.tooManyKeyStrokes
      }

    case WidgetActionType.SET_SLIDE_ANIMATION:
      return {
        ...state,
        slideAnimation: action.slideAnimation
      }

    default:
      return state
  }
}
