import { Box, useMediaQuery } from '@mui/material'
import { FC } from 'react'
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { carouselMainBox, carouselThumbnailBox } from '../../../theme/styleConstants';
import { CarouselImageBox } from './CarousellImageBox';

export const CarouselThreeVideosMobile: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  const mediumViewport = useMediaQuery('(max-width:500px)');

  return (
    <Box sx={carouselMainBox}>
      <CarouselImageBox marginBottom='16px' width='100%' height={mediumViewport ? '150px' : '300px'} imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />

      <Box sx={carouselThumbnailBox}>
        <CarouselImageBox marginBottom='16px' width='31%' height={mediumViewport ? '50px' : '70px'} imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />

        <CarouselImageBox marginBottom='16px' width='31%' height={mediumViewport ? '50px' : '70px'} imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} />

        <CarouselImageBox marginBottom='16px' width='31%' height={mediumViewport ? '50px' : '70px'} imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} />
      </Box>
    </Box>
  )
}
