import { FC, useContext, useState } from "react";
import { Box, Button, Typography, IconButton, Collapse, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";

import { ShowScript, WidgetBox } from "../../../theme/styleComponents";
import palette from "../../../theme/palette";
import { collapseScript, deleteButtonWidget, widgetBoxSibling, widgetBoxWrapper } from "../../../theme/styleConstants";
import { WidgetCardProps } from "../../../interfaceTypes";
import { AppContext } from "../../../context";
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ActionType } from "../../../context/AppContextReducer";
import { WidgetActionType } from "../../../context/WidgetContextReducer";
import customTheme from "../../../theme";
import { Alert } from "../../common/Alert";
import { TEXT_COPIED } from "../../../constants";
import { WidgetType } from "../../../../generated";

export const WidgetCard: FC<WidgetCardProps> = ({ name, description, mediumViewport, linkBaseUrl, id, ImageComponent, widgetScriptType, widgetType, widget, widgetDispatch, widgetsData, right, paddingRight, paddingLeft, isKiosk }) => {
  const { selectedBrand } = useContext(AppContext)
  const { dispatch } = useContext(AppContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { id: widgetId } = widget || {}
  const currentWidget = widgetsData?.filter((widget) => widget.id === widgetId);
  const updatedWidgetType = (widgetType === 'page-block-widget') ? 'pdp-block-widget' : widgetType
  const currentWidgetData = currentWidget && currentWidget[0]

  const handleScript = () => {
    setIsOpen(!isOpen)
  }

  const handleDeleteClick = () => {
    widgetDispatch && widgetDispatch({ type: WidgetActionType.SET_CURRENT_WIDGET, currentWidgetData })
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: true })
  }

  const { id: brandId } = selectedBrand || {}

  const kioskLink = `${process.env.REACT_APP_BASE_URL}/brand/${brandId}/kiosk/${widgetId}` 
  const scriptStartText = `<!-- CABA ${widgetScriptType} - Begin script --> \n`
  const scriptMiddleText = `<div id="caba-${widgetType}"></div><style>@import url('${process.env.REACT_APP_AWS_BASE_URL}/${updatedWidgetType}/build/main.css');</style><script defer="defer" id="caba-${widgetType}-script" caba-widget-id="${id}" caba-brand-id="${brandId}" caba-x-api-key="" src="${process.env.REACT_APP_AWS_BASE_URL}/${updatedWidgetType}/build/bundle.js"></script> \n`
  const scriptEndText = `<!-- CABA ${widgetScriptType} - End script -->`
  const scriptText = `${scriptStartText} ${scriptMiddleText} ${scriptEndText}`

  const copyText = () => {
    if (scriptText) {
      navigator.clipboard.writeText(scriptText)
      Alert.success(TEXT_COPIED)
    }
  }

  return (
    <>
      <WidgetBox sx={widgetBoxWrapper}>
        <Box sx={widgetBoxSibling}>
          <Box flex='1' position='relative' sx={{ [customTheme.breakpoints.down('sm')]: { width: '100%' } }}>
            <Typography variant="subtitle1">{name}</Typography>

            <Typography variant="h3" sx={{ color: palette.lightColorThree, opacity: '0.6' }}>{description}</Typography>

            {mediumViewport ?
              <IconButton sx={{ position: 'absolute', top: '0', right: { right }, paddingRight: { paddingRight } }} component={Link} to={`${linkBaseUrl}&widgetId=${id}`} color="primary">
                <EditIcon sx={{ height: '16px', width: '16px' }} />
              </IconButton>
              :
              <Button component={Link} to={`${linkBaseUrl}&widgetId=${id}`} sx={{ marginTop: '1rem', paddingX: '0' }}
                color="secondary" startIcon={<EditIcon sx={{ height: '16px', width: '16px' }} />}>
                Edit
              </Button>
            }
          </Box>

          {ImageComponent}

          <ShowScript onClick={handleScript}>
            <IconButton size="small">
              <Tooltip title="Show script">
                <CodeIcon color="primary" fontSize="small" />
              </Tooltip>
            </IconButton>
          </ShowScript>
        </Box>


        <Collapse in={isOpen}>
          <Box sx={collapseScript}>
            <Typography variant="body2" component='code' id="myInput">
              {!isKiosk &&
                <Typography variant="body2" component='p'>{scriptStartText}</Typography>
              }

              {isKiosk ?
                <a href={kioskLink} style={{ color: palette.primary.main }} target="_blank" rel="noreferrer">{kioskLink}</a>
                :
                <>{scriptMiddleText}</>
              }

              {!isKiosk &&
                <Typography variant="body2" component='p'>{scriptEndText}</Typography>
              }
            </Typography>

            {!isKiosk &&
              <Tooltip title='Copy text'>
                <IconButton id="copy-button" onClick={copyText} sx={{ position: 'absolute', right: '10px', top: '5px' }} size="small"><ContentCopyIcon fontSize="small" color="primary" /></IconButton>
              </Tooltip>
            }
          </Box>
        </Collapse>

        {(currentWidgetData?.type === WidgetType.Block || currentWidgetData?.type === WidgetType.Kiosk || currentWidgetData?.type === WidgetType.PopupWidget) && (
          <IconButton sx={{ ...deleteButtonWidget, paddingRight: { paddingRight }, paddingLeft: { paddingLeft }, }} aria-label="delete" onClick={handleDeleteClick}>
            <DeleteIcon sx={{ height: '16px', width: '16px', color: palette.red }} />
          </IconButton>
        )}
      </WidgetBox>
    </>
  )
}