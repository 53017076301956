import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { VideoGridType, VideoLayout } from '../../../generated'
import { PreviewVideoProps } from "../../interfaceTypes"
import { getBorderRadiusValue } from '../../utils'
import { CarousellFiveVideosMobile } from './carousel/CarousellFiveVideoMobile'
import { CarouselThreeVideosMobile } from './carousel/CarousellThreeVideosMobile'
import { GalleryFiveVideoMobile } from './gallery/GalleryFiveVideosMobile'
import { GalleryThreeVideoMobile } from './gallery/GalleryThreeVideosMobile'
import { MassonayFiveVideoMobile } from './massonary/MassonaryFiveVideoMobile'
import { MassonaryThreeVideoMobile } from './massonary/MassonaryThreeVideoMobile'
import { PortraitGalleryVideosMobile } from './portraitGallery/PortraitGalleryVideosMobile'
import { SingleVideoLayout } from './SingleVideoLayout'

export const PreviewVideoLayoutMobile: FC<PreviewVideoProps> = ({ videoGridType, videoBoundingBox, selectedVideos, attachmentsToShow }) => {
  const previewImage = attachmentsToShow?.filter(video => selectedVideos?.some(item => item.id === video.id))?.map((item) => item?.thumbnail?.url || "")
  const borderRadiusValue = getBorderRadiusValue(videoBoundingBox)
  const { watch } = useFormContext();
  const videoLayout = watch('videoLayout');

  switch (videoLayout) {
    case VideoLayout.SingleVideo:
      return <SingleVideoLayout thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

    case VideoLayout.ThreeVideos:
      switch (videoGridType) {
        case VideoGridType.Massonary:
          return <MassonaryThreeVideoMobile thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        case VideoGridType.Carousell:
          return <CarouselThreeVideosMobile thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        case VideoGridType.Gallery:
          return <GalleryThreeVideoMobile thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        default:
          return <></>
      }

    case VideoLayout.FiveVideos:
      switch (videoGridType) {
        case VideoGridType.Massonary:
          return <MassonayFiveVideoMobile thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        case VideoGridType.Carousell:
          return <CarousellFiveVideosMobile thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        case VideoGridType.Gallery:
          return <GalleryFiveVideoMobile thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

        default:
          return <></>
      }
    case VideoLayout.InfiniteVideos:
      return <PortraitGalleryVideosMobile thumbnailUrls={previewImage} borderRadius={borderRadiusValue} />

    default:
      return <></>
  }

}
