import { DefaultEventsMap } from "@socket.io/component-emitter";
import { createContext, FC, Reducer, useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { Socket, io } from "socket.io-client";
import { ChildrenType, SocketResponse, SocketContextReducerType, KioskMutateSocketResponse } from "../interfaceTypes";
import { SocketAction, SocketActionType, socketInitialState, socketReducer, SocketState } from "./SocketContextReducer";
import { useLocation } from "react-router-dom";
import { createSession } from "../utils";

export const SocketContext = createContext<SocketContextReducerType>({
  ...socketInitialState,

  socketDispatch: () => {
    return;
  },

  mutateKioskState: () => {
    return;
  }
});

export const SocketContextProvider: FC<ChildrenType> = ({ children }): JSX.Element => {
  const [socketState, socketDispatch] = useReducer<Reducer<SocketState, SocketAction>>(socketReducer, socketInitialState);
  const socket = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | undefined>();
  const location = useLocation();
  const path = location.pathname.split('/');
  const brandId = path[2]
  const widgetId = path[4];

  const mutateKioskState = useCallback((isRequested: boolean, isFocused: boolean, meeting: boolean, isPaused?: boolean) => {
    socket?.current?.emit('mutate-kiosk-state', {
      brandId,
      kioskWidgetId: widgetId,
      state: { inRequest: isRequested, inFocus: isFocused, inMeeting: meeting, inPause: isPaused },
    });
  }, [brandId, widgetId])

  const store = useMemo(() => ({ ...socketState, socketDispatch }), [socketState]);

  useEffect(() => {
    if (!socket?.current && process.env.REACT_APP_SOCKET_BASE_URL) {
      socket.current = io(`${process.env.REACT_APP_SOCKET_BASE_URL}/kiosk`, {
        transports: ["websocket", 'polling'],
        query: {
          sessionId: createSession()
        }
      });
    }

    socketDispatch({ type: SocketActionType.SET_SOCKET_BRAND_ID, socketBrandId: brandId })
    socketDispatch({ type: SocketActionType.SET_SOCKET_WIDGET_ID, socketWidgetId: widgetId })

    const onConnect = () => {
      socketDispatch({ type: SocketActionType.SET_IS_CONNECTED, isConnected: true })
    }

    const onDisconnect = () => {
      socketDispatch({ type: SocketActionType.SET_IS_CONNECTED, isConnected: false })
    }

    socket?.current?.on('connect', onConnect);

    socket?.current?.on('heartbeat', function (data) { });

    socket?.current?.on('join-meeting', function (data: SocketResponse) {
      socketDispatch({ type: SocketActionType.SET_JOINING_DATA, joiningData: data })
      socketDispatch({ type: SocketActionType.SET_IN_CALL, inCall: true })
    });

    socket?.current?.on('hangup-meeting', function (data: SocketResponse) {
      socketDispatch({ type: SocketActionType.SET_HANGUP_DATA, hangUpData: data })
      socketDispatch({ type: SocketActionType.SET_IN_CALL, inCall: false })
    });

    socket?.current?.on('mutate-kiosk-state', function (data: KioskMutateSocketResponse) {
      socketDispatch({ type: SocketActionType.SET_KIOSK_MUTATE_DATA, kioskMutatedData: data })
    });

    socket?.current?.on('exception', function (data) {
      socketDispatch({ type: SocketActionType.SET_IS_ERROR, isError: true })
    });

    socket?.current?.on('error', function (data) {
      socketDispatch({ type: SocketActionType.SET_IS_ERROR, isError: true })
    });

    socket?.current?.on('disconnect', onDisconnect);

    return () => {
      socket?.current?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, widgetId]);

  return (
    <SocketContext.Provider value={{ ...store, mutateKioskState }}>
      {children}
    </SocketContext.Provider>
  );
}