import { Button, CircularProgress, DialogActions, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { useSetDefaultAssigneeMutation } from '../../../generated';
import { BRAND_ID, SET_DEFAULT_USER_SUCCESSFULLY } from '../../constants';
import { AuthContext } from '../../context';
import { SetDefaultUserProps } from '../../interfaceTypes';
import { firstLetterUppercase } from '../../utils';
import { Alert } from '../common/Alert';
import { CustomModal } from '../common/CustomModal';

export const SetDefaultUser: FC<SetDefaultUserProps> = ({ currentUserData, refetch, brandId, setDefaultUser, defaultUser }) => {
  const { isAdmin } = useContext(AuthContext)
  const { email, id } = currentUserData || {}

  const [setDefaultAssignee, { loading }] = useSetDefaultAssigneeMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ""));
    },

    onCompleted(data) {
      const { setDefaultAssignee } = data

      if (setDefaultAssignee) {
        refetch && refetch();
        return Alert.success(SET_DEFAULT_USER_SUCCESSFULLY);
      }
    }
  })

  const handleDefaultUserClose = async () => {
    setDefaultUser(false)
  };

  const handleDefaultUser = async () => {
    if (id) {
      await setDefaultAssignee({
        variables: {
          setDefaultAssigneeInput: {
            userId: id,
            brandId: (isAdmin ? brandId : localStorage.getItem(BRAND_ID)) || ""
          }
        }
      })
    }
  };

  return (
    <CustomModal title='Set default user' handleClose={handleDefaultUserClose} isOpen={defaultUser} maxWidth="700px">
      <Typography variant="h4" marginBottom={3} paddingLeft={0.5}>Are you sure you want to make this user as a default user having email: {email}?</Typography>

      <DialogActions sx={{ paddingY: '0' }}>
        <Button color="inherit" onClick={handleDefaultUserClose}>Close</Button>

        <Button variant="contained" color="primary" onClick={handleDefaultUser} disabled={loading}
          endIcon={loading && <CircularProgress size={14} color="inherit" />}
        >
          Set Default User
        </Button>
      </DialogActions>
    </CustomModal>
  )
}
