import { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Box, CardContent, Typography } from "@mui/material";
import { ImageOverlay, VideoThumbnail } from "../../../theme/styleComponents";
import { VideoData, VideoSettingProps } from "../../../interfaceTypes";
import { PlayerIcon } from "../../../../assets/svgs";
import { FullDetailVideo } from "./FullDetailVideo";
import { images } from "../../../../assets/images";
import { VideoOrientation } from "../../../../generated";

export const VideoCard: FC<VideoData> = ({ videoData, dispatch, videoStyle }) => {
  const [videoSettings, setVideoSettings] = useState<VideoSettingProps>
    ({ url: "", played: 0, loaded: 0, pip: false, playing: false, playbackRate: 1 });
  const [open, setOpen] = useState(false);

  const { url, pip, playing } = videoSettings

  const handleModal = () => {
    setOpen(!open);
  };

  const handlePlay = () => {
    setVideoSettings({ ...videoSettings, playing: true })
  }

  const onProgressVideo = (event: any) => {
    if (event.playedSeconds > 6) {
      setVideoSettings({ ...videoSettings })
    }
  }

  const loadUrl = (url: string) => {
    setVideoSettings({
      url, played: 0, loaded: 0, pip: false, playing: false
    })
  }

  const { url: videoUrl, thumbnail, title, description, videoOrientation } = videoData || {}
  const { url: thumbnailUrl } = thumbnail || {}
  const thumbnailUrlArray = thumbnailUrl?.split('/upload')
  const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
  const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')

  useEffect(() => {
    loadUrl(videoUrl || "")
    // eslint-disable-next-line 
  }, [videoUrl])

  const videoOrientationLandscape = videoOrientation === VideoOrientation.Landscape

  const descriptionSeeMore = videoOrientationLandscape ? description?.substring(0, 80) : description?.substring(0, 60)

  return (
    <>
      {videoOrientation === VideoOrientation.Landscape ?
        <VideoThumbnail sx={{ ...videoStyle, paddingTop: '56.55%' }}>
          {videoData ?
            <Box position='absolute' top='0' width='100%' height='100%'>
              <ReactPlayer
                className='react-player'
                width='100%'
                height='100%'
                url={url}
                pip={pip}
                controls={true}
                playing={playing}
                muted={true}
                onClickPreview={handlePlay}
                playIcon={<Box sx={{ zIndex: '99' }}><PlayerIcon /></Box>}
                light={updatedThumbnailUrl || ""}
                onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
                onProgress={onProgressVideo}
                playsinline
              />
            </Box>
            :
            <Box component='img' src={images.DUMMY_IMAGE} />
          }

          {!playing &&
            <ImageOverlay />
          }
        </VideoThumbnail>
        :
        <Box sx={{ backgroundColor: '#F5F5F5' }}>
          <VideoThumbnail marginX='auto' sx={{ ...videoStyle, width: '148px' }}>
            {videoData ?
              <ReactPlayer
                className='react-player'
                width='100%'
                height='100%'
                url={url}
                pip={pip}
                controls={true}
                playing={playing}
                muted={true}
                onClickPreview={handlePlay}
                playIcon={<Box sx={{ zIndex: '99' }}><PlayerIcon /></Box>}
                light={updatedThumbnailUrl || ""}
                onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
                onProgress={onProgressVideo}
                playsinline
              /> :
              <Box component='img' src={images.DUMMY_IMAGE} />
            }

            {!playing &&
              <ImageOverlay />
            }
          </VideoThumbnail>
        </Box>
      }

      <CardContent sx={{ width: '100%' }}>
        <Typography gutterBottom variant="subtitle1" component="div" noWrap>
          {title}
        </Typography>

        <Typography variant="body1" color="text.secondary" height='80px'>
          {videoOrientation === VideoOrientation.Landscape ?
            <Box>
              {description && description?.length > 100 ?
                <>
                  {descriptionSeeMore}

                  <Typography component='span' sx={{ marginLeft: '5px', cursor: 'pointer' }} onClick={handleModal} variant="body1" color='primary'>See more</Typography>
                </>
                :
                description
              }
            </Box>
            :
            <Box>
              {description && description?.length > 100 ?
                <>
                  {descriptionSeeMore}

                  <Typography component='span' sx={{ marginLeft: '5px', cursor: 'pointer' }} onClick={handleModal} variant="body1" color='primary'>See more</Typography>
                </>
                :
                description
              }
            </Box>
          }
        </Typography>
      </CardContent>

      <FullDetailVideo
        isOpen={open}
        handleClose={handleModal}
        videoData={videoData}
      />
    </>
  )
}