import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { phone } from 'phone'
import { FC, useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { UpdateUserInput, UserStatus, useUpdateUserMutation } from '../../../generated'
import { AuthContext } from '../../context'
import { UpdateProfileDialogueInterface } from '../../interfaceTypes'
import { updateFullNameSchema } from '../../validationSchema'
import { Alert } from '../common/Alert'
import { CustomController } from '../common/CustomController'
import { CustomPhoneController } from '../common/CustomPhoneController'
import { PROFILE_UPDATED_SUCCESSFULLY } from '../../constants'
import { formatPhoneNumber } from '../../utils'

const UpdateProfileDialogue: FC<UpdateProfileDialogueInterface> = ({ isEditOpen, setIsEditOpen }) => {
  const { currentUser, setUser } = useContext(AuthContext)
  const { id: userId, fullName = undefined, phoneNumber = undefined, status, roles,
    tagLine = undefined, emailVerified, createdAt, updatedAt, email, attachment, attachments } = currentUser || {}

  const methods = useForm<UpdateUserInput>({
    mode: "all",

    resolver: yupResolver(updateFullNameSchema),
    defaultValues: {
      fullName: fullName || "",
      phoneNumber: phoneNumber || "",
      tagLine: tagLine || ""
    }
  });

  useEffect(() => {
    reset({ fullName, phoneNumber, tagLine })
    // eslint-disable-next-line
  }, [isEditOpen])

  const { handleSubmit, setValue, reset } = methods

  const [updateUserProfile, { loading }] = useUpdateUserMutation({
    onError({ message }) {
      Alert.error(message)
    },

    onCompleted(data) {
      const { updateUser } = data
      const { fullName, phoneNumber, tagLine } = updateUser

      const updatedUser = {
        ...currentUser,
        id: userId || "",
        email: email || "",
        fullName: fullName || "",
        phoneNumber: phoneNumber,
        tagLine: tagLine,
        emailVerified: emailVerified || false,
        createdAt: createdAt!,
        updatedAt: updatedAt!,
        status: status || UserStatus.Active,
        attachment: attachment,
        attachments: attachments || [],
        roles: roles
      }

      if (updateUser) {
        setUser(updatedUser)
        setIsEditOpen && setIsEditOpen(false)
        Alert.success(PROFILE_UPDATED_SUCCESSFULLY)
      }
    }
  })


  const onSubmitUser = async (data: UpdateUserInput) => {

    const { fullName, phoneNumber, tagLine } = data
    const validNumber = phoneNumber && formatPhoneNumber(phoneNumber)
    const validatePhoneNumber = phone(validNumber!)
    const { isValid } = validatePhoneNumber

    isValid ?
      await updateUserProfile({
        variables: {
          updateUserInput: {
            userId: userId,
            fullName: fullName || "",
            phoneNumber: phoneNumber || "",
            tagLine: tagLine || ""
          }
        }
      })
      :
      Alert.error('Phone number not valid')
  }

  useEffect(() => {
    fullName && setValue("fullName", fullName)
    phoneNumber && setValue("phoneNumber", phoneNumber)
    tagLine && setValue("tagLine", tagLine)
  }, [fullName, phoneNumber, tagLine, setValue])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitUser)} autoComplete="off">
        <Box mb={2}>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item sm={6} xs={12}>
              <CustomController
                controllerName='fullName'
                controllerLabel='Full Name'
                fieldType='text'
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <CustomPhoneController
                controllerName="phoneNumber"
                controllerLabel="Phone Number"
              />
            </Grid>

            <Grid item xs={12}>
              <CustomController
                controllerName='tagLine'
                controllerLabel='Tagline'
                fieldType='text'
                tooltipText="This will show beneath your name when customers see your videos"
              />
            </Grid>
          </Grid>

          <Box sx={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" variant="contained" disabled={loading} color='primary' endIcon={loading && <CircularProgress size={15} color="inherit" />}>
              Update
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}

export default UpdateProfileDialogue

