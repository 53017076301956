import { yupResolver } from "@hookform/resolvers/yup";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, CircularProgress, DialogActions, FormControl, Alert as InfoAlert, InputLabel, MenuItem, Select, Switch, Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { FC, MouseEvent, ReactNode, useContext, useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PlayerIcon } from "../../../assets/images";
import { TaskTemplate, TaskTemplateType, VideoOrientation, useCreateTaskTemplateMutation, useGetMacrosLazyQuery } from "../../../generated";
import { ASSET_IS_LARGE, BAD_REQUEST, BAD_USER_INPUT, CREATE_TASK_STEPS, GRAPHQL_QUERY_POLICY, TASK_TEMPLATE_STEP_ONE_FIELDS, TASK_TEMPLATE_STEP_THREE_FIELDS } from "../../constants";
import { AppContext } from "../../context";
import { ActionType } from "../../context/AppContextReducer";
import { AttachmentThumbnailUrl, CreateTaskTemplate, VideoSettingProps } from "../../interfaceTypes";
import palette from "../../theme/palette";
import { RenderVideo, UploadArea, UploadBox, UploadIcon } from "../../theme/styleComponents";
import { editorBox, stepperStep } from "../../theme/styleConstants";
import { firstLetterUppercase } from "../../utils";
import { createTaskTemplateValidationSchema } from "../../validationSchema";
import { CreateVideoModalForTasks } from "../brandSetting/videoLibrary/CreateVideoModalForTasks";
import { Alert } from "../common/Alert";
import { CustomController } from "../common/CustomController";
import { CustomMacro } from "../common/CustomMacro";
import { PageHeading } from "../common/PageHeading";
import { PostMacro } from "../common/PostMacro";
import Tags from "./Tags";

export const CreateTaskTemplateStepper: FC = () => {
  const [script, setScript] = useState<EditorState>(EditorState.createEmpty());
  const [scriptMessage, setScriptMessage] = useState<string>('')
  const [taskTemplateMessage, setTaskTemplateMessage] = useState<EditorState>(EditorState.createEmpty());
  const [taskMessage, setTaskMessage] = useState<string>('')
  const { selectedBrand, dispatch } = useContext(AppContext);
  const [currentVideo, setCurrentVideo] = useState<AttachmentThumbnailUrl>()
  const [taskData, setTaskData] = useState<TaskTemplate>()
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [videoSettings, setVideoSettings] = useState<VideoSettingProps>({ url: "", played: 0, loaded: 0, pip: false, playing: false })
  const [videoIsLong, setVideoIsLong] = useState<boolean>(true)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [macros, setMacros] = useState<Array<string>>([])
  const [activeStep, setActiveStep] = useState(0);
  const [moderateStatus, setModerateStatus] = useState<boolean>(false);
  const postMacros = ["{{operatorName}}", "{{brandName}}"];
  const mediumViewport = useMediaQuery('(min-width:768px)');
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const currentValidationSchema = createTaskTemplateValidationSchema[activeStep];
  const { id: brandId, name, createdAt, updatedAt, attachments, taskTemplates } = selectedBrand || {};

  const { url: videoUrl, id: attachmentId, videoOrientation, thumbnailUrl: videoThumbnailUrl } = currentVideo || {}
  const thumbnailUrlArray = videoThumbnailUrl?.split('/upload')
  const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
  const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')
  const { id: taskId } = taskData || {}
  DOMPurify.setConfig({ ADD_ATTR: ['target'] });

  const handleModerate = async () => {
    setModerateStatus(!moderateStatus)
  };

  const methods = useForm<CreateTaskTemplate>({
    mode: "all",
    resolver: yupResolver(currentValidationSchema),
    defaultValues: {
      name: "",
      description: "",
      script: "",
      subject: "",
      videoPageHeading: "",
      videoPageDescription: "",
      segmentAudienceKey: "",
      message: "",
      postGreetMessage: "",
      postGreetTitle: "",
      videoDescription: "",
      videoHeading: "",
      isModerated: false,
      templateType: TaskTemplateType.Manual,
      tags: [],
      smsText: ''
    },
  });

  const onEditorStateChange = (editorState: EditorState) => {
    setScript(editorState)
  };

  const onEditorMessageStateChange = (editorState: EditorState) => {
    setTaskTemplateMessage(editorState)
  };

  const htmlToDraftBlocks = (html: string) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  useEffect(() => {
    setScript(htmlToDraftBlocks(scriptMessage))
  }, [scriptMessage])

  useEffect(() => {
    setTaskTemplateMessage(htmlToDraftBlocks(taskMessage))
  }, [taskMessage])

  const { handleSubmit, setValue, watch, setFocus, control } = methods;
  const templateTypeValue = watch('templateType')
  const navigate = useNavigate();

  const [createTaskTemplate, { loading, reset }] = useCreateTaskTemplateMutation({
    onError({ message }) {
      if (message.toLowerCase() === BAD_REQUEST) {
        return Alert.error(BAD_USER_INPUT);
      }

      return Alert.error(firstLetterUppercase(message || ""));
    },

    onCompleted(data) {
      if (data) {
        const { createTaskTemplate: taskTemplateData } = data
        const { script, message } = taskTemplateData

        const updatedBrand = {
          ...selectedBrand,
          id: brandId || "",
          name: name || "",
          attachments: attachments || [],
          taskTemplates: (taskTemplates && taskTemplates.length > 0) ? [...taskTemplates, taskTemplateData] : [taskTemplateData],
          createdAt,
          updatedAt,
        }

        script && setScriptMessage(script)
        message && setTaskMessage(message)

        dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrand })
        Alert.success("Task created successfully.");
        setTaskData(taskTemplateData)
        reset()
        templateTypeValue === TaskTemplateType.Manual ? navigate("/task-template") : setActiveStep(3)
      }
    },
  });

  const [taskMacros] = useGetMacrosLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() { },

    onCompleted(data) {
      const { getMacros } = data
      setMacros(() => getMacros)
    }
  })

  useEffect(() => {
    taskMacros()
  }, [taskMacros])

  const onSubmit = async (data: CreateTaskTemplate) => {
    const { tags } = data

    const rawContentState = convertToRaw(
      script.getCurrentContent()
    );

    const content = draftToHtml(rawContentState);

    const messageRawContentState = convertToRaw(
      taskTemplateMessage.getCurrentContent()
    );

    const messageContent = draftToHtml(messageRawContentState);

    if (activeStep !== CREATE_TASK_STEPS.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      await createTaskTemplate({
        variables: {
          createTaskTemplateInput: {
            ...data,
            isModerated: moderateStatus,
            tags: tags.join(', '),
            brandId: brandId || "",
            script: content,
            message: messageContent,
          },
        },
      });
    }
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUpload = () => {
    setIsVideoModalOpen(true)
  }

  const videoHandleClose = () => {
    setIsVideoModalOpen(false)
  }
  const loadUrl = (url: string) => {
    setVideoSettings({
      url, played: 0, loaded: 0, pip: false, playing: false
    })
  }

  const handlePlay = () => {
    setVideoSettings({ ...videoSettings, playing: true })
  }

  const { url: playVideoUrl, pip, playing } = videoSettings

  useEffect(() => {
    loadUrl(videoUrl || "")
  }, [videoUrl])

  const handleChangePost = (macro: string) => {
    const jobMacro = macro;
    const { postGreetMessage } = watch();
    if (postGreetMessage) {
      setValue('postGreetMessage', postGreetMessage.concat(" ", jobMacro))
    }
    else {
      setValue('postGreetMessage', postGreetMessage ? postGreetMessage + jobMacro : jobMacro)
    }
    setAnchorElUser(null);
    setFocus("postGreetMessage", { shouldSelect: true })
  };

  const handleChange = (macro: string) => {
    const jobMacro = macro;
    const { smsText } = watch();
    if (smsText) {
      setValue('smsText', smsText.concat(" ", jobMacro))
    }
    else {
      setValue('smsText', smsText ? smsText + jobMacro : jobMacro)
    }
    setAnchorElUser(null);
    setFocus("smsText", { shouldSelect: true })
  };

  const createTaskSteps = templateTypeValue === TaskTemplateType.SelfRunning ? [...CREATE_TASK_STEPS, 'Upload Video'] : CREATE_TASK_STEPS

  const renderUpload = () => (
    <UploadArea onClick={handleUpload} sx={{ cursor: 'pointer' }}>
      <UploadBox>
        <UploadIcon sx={{ marginRight: 0 }}>
          <UploadFileIcon color="primary" />
        </UploadIcon>
      </UploadBox>

      <Typography variant="body2" textAlign='center'>Click to upload</Typography>
    </UploadArea>
  )

  const isLandscape = videoOrientation === VideoOrientation.Landscape

  const renderVideo = () => (
    <RenderVideo height='auto !important'>
      <Box sx={{ backgroundColor: palette.offWhite }} paddingTop={isLandscape ? '56.55%' : '268.11px'} position='relative'>
        <Box position='absolute' top='0' width='100%' height='100%'>
          <Box marginX={isLandscape ? 'none' : 'auto'} width={isLandscape ? "100%" : 148} height='100%'>
            <ReactPlayer
              className='react-player'
              width='100%'
              height='100%'
              url={playVideoUrl}
              pip={pip}
              controls={true}
              playing={playing}
              progressInterval={200}
              onClickPreview={handlePlay}
              playIcon={<PlayerIcon />}
              light={updatedThumbnailUrl || ""}
              onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
              playsinline
            />
          </Box>
        </Box>
      </Box>
    </RenderVideo>
  )


  return (
    <>
      <Box sx={{ width: "100%", marginTop: "2rem" }}>
        <Stepper sx={stepperStep} activeStep={activeStep} orientation={mediumViewport ? 'horizontal' : 'vertical'}>
          {createTaskSteps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: ReactNode } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off">
            {activeStep === 0 && (
              <Box key={activeStep} sx={{ marginTop: "2rem", width: "100%" }}>
                {TASK_TEMPLATE_STEP_ONE_FIELDS.map((field) => {
                  const { name, title, fieldType } = field

                  return (
                    <CustomController
                      key={`${name}-${title}`}
                      controllerName={name}
                      controllerLabel={title}
                      fieldType={fieldType}
                    />
                  )
                })}

                <Tags controllerName='tags' />

                <Box sx={editorBox}>
                  <Typography mt={2} mb='14px' variant='h4' sx={{ fontWeight: '500' }}>Script</Typography>

                  <Editor
                    editorState={script}
                    wrapperClassName="draft-wrapper"
                    onEditorStateChange={(editorState) => onEditorStateChange(editorState)}
                  />
                </Box>

                <Box display='flex' alignItems='center' mt={1}>
                  <Typography variant="h4">Moderated</Typography>

                  <Switch
                    onChange={handleModerate}
                    value={moderateStatus}
                    sx={{ marginRight: '1rem' }}
                    color="secondary"
                    defaultChecked={false}
                  />
                </Box>

              </Box>
            )}

            {activeStep === 1 && (
              <Box key={activeStep} position="relative" sx={{ marginTop: "2rem", width: "100%" }}>
                <CustomController
                  controllerName="segmentAudienceKey"
                  controllerLabel="Audience Key"
                  fieldType="text"
                  tooltipText="This is your Audience ID from Segment"
                />

                <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                  <InputLabel id="templateTypeLabel">Template Type</InputLabel>

                  <Controller
                    name="templateType"
                    control={control}
                    render={({ field }) => (
                      <Select
                        labelId="templateTypeLabel"
                        id="templateType"
                        defaultValue={TaskTemplateType.Manual}
                        {...field}
                        fullWidth
                        label="Template Type"
                      >
                        <MenuItem value={TaskTemplateType.Manual}>Manual</MenuItem>
                        <MenuItem value={TaskTemplateType.SelfRunning}>Self Running</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <Box sx={{ marginTop: "2rem" }}>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <PageHeading
                      variant="h6"
                      title="Text Message"
                      subTitle="Type the text message you want to send the customer when the task is created."
                    />
                  </Box>

                  <Box sx={editorBox} position='relative'>
                    <Editor
                      editorState={taskTemplateMessage}
                      wrapperClassName="draft-wrapper"
                      onEditorStateChange={(editorState) => onEditorMessageStateChange(editorState)}
                    />

                    <CustomMacro
                      macros={macros}
                      handleChange={handleChange}
                      anchorElUser={anchorElUser}
                      handleCloseUserMenu={handleCloseUserMenu}
                      handleOpenUserMenu={handleOpenUserMenu}
                      listMacros
                    />
                  </Box>
                </Box>

                <Box sx={{ marginTop: "2rem" }}>
                  <Box sx={editorBox} position='relative'>
                    <CustomController
                      controllerName="smsText"
                      controllerLabel="Sms text"
                      fieldType='text'
                      rowsLength={4}
                      isMultiLine
                    />

                    <CustomMacro
                      macros={macros}
                      handleChange={handleChange}
                      anchorElUser={anchorElUser}
                      handleCloseUserMenu={handleCloseUserMenu}
                      handleOpenUserMenu={handleOpenUserMenu}
                      listMacros
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {activeStep === 2 && (
              <Box key={activeStep} position="relative" sx={{ marginTop: "2rem", width: "100%" }}>
                {TASK_TEMPLATE_STEP_THREE_FIELDS.map((field) => {
                  const { name, title, fieldType } = field

                  return (
                    <CustomController
                      key={`${name}-${title}`}
                      controllerName={name}
                      controllerLabel={title}
                      fieldType={fieldType}
                    />
                  )
                })}

                <Box sx={{ position: 'relative' }}>
                  <CustomController
                    controllerName="postGreetMessage"
                    controllerLabel="Post Greet Message (Customer)"
                    fieldType='text'
                    rowsLength={4}
                    isMultiLine
                  />

                  <PostMacro
                    postMacros={postMacros.length > 0 ? postMacros : []}
                    handleChangePost={handleChangePost}
                    anchorElTask={anchorElUser}
                    handleCloseUserMenuTask={handleCloseUserMenu}
                    handleOpenUserMenuTask={handleOpenUserMenu}
                  />
                </Box>
              </Box>
            )}

            {activeStep === 3 && (
              <Box key={activeStep} position="relative" sx={{ marginTop: "2rem", width: "100%" }}>
                {currentVideo ?
                  <>
                    {videoIsLong ?
                      <Box maxWidth={350} marginX='auto'>
                        {renderVideo()}
                      </Box>
                      :
                      <Box mt='4rem' mb='3rem'>
                        <InfoAlert sx={{ fontSize: '1rem', maxWidth: '600px', margin: 'auto' }} severity="info">{ASSET_IS_LARGE}</InfoAlert>
                      </Box>
                    }
                  </>
                  :
                  <Box sx={{ pb: 1, mb: 2 }}>
                    {renderUpload()}
                  </Box>
                }
              </Box>
            )}

            <DialogActions sx={{ py: 2, pr: 0 }}>
              {activeStep !== createTaskSteps.length - 1 &&
                <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
              }

              <Box />

              <Button type={activeStep === 3 ? "button" : "submit"} variant="contained"
                color="primary"
                disabled={loading}
                endIcon={loading && <CircularProgress size={20} color="inherit" />}
                onClick={() => activeStep === 3 ? navigate('/task-template') : undefined}
              >
                {activeStep === createTaskSteps.length - 1 ? "Finish" : "Next"}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Box>

      <CreateVideoModalForTasks
        attachmentId={attachmentId}
        isOpen={isVideoModalOpen}
        handleClose={videoHandleClose}
        currentVideo={currentVideo}
        videoOrientation={videoOrientation}
        videoType='video'
        typeId={taskId}
        setCurrentVideo={setCurrentVideo}
        setVideoIsLong={setVideoIsLong}
        videoIsLong={videoIsLong}
      />
    </>
  );
};
