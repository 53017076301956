import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Collapse, DialogActions, Drawer, FormGroup, Grid, RadioGroup, Switch, Typography, useMediaQuery } from "@mui/material";
import { FC, Reducer, useContext, useEffect, useReducer, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import { images } from "../../../../../assets/images";
import {
  Attachment, AttachmentType, useGetWidgetLazyQuery, useUpdateWidgetMutation, VideoGridType, VideoLayout, VideoOrientation, WidgetVideoInput
} from "../../../../../generated";
import { Widget } from '../../../../../generated/index';
import { BRAND_ID, CLIENT_TELLING_FORM, GENERAL_SETTINGS, GRAPHQL_QUERY_POLICY, PAGE_BLOCK_WIDGET, PATH_WAYS_BANNER, TEXT_SETTINGS, THANKYOU_SETTINGS, TIME_SETTINGS_HELPER_TEXT, VIDEO_SETTINGS, WIDGET_SETTING } from "../../../../constants";
import { AppContext } from '../../../../context';
import { ActionType } from '../../../../context/AppContextReducer';
import { AttachmentTypeFE, BrandDataProp, BrandState, UpdateWidget } from "../../../../interfaceTypes";
import palette from "../../../../theme/palette/index";
import { AccordionWrapper, VideoCardWrapper } from "../../../../theme/styleComponents";
import { drawerButton, hookVideoButtonWrapper, hookVideoStylePortrait, previewDrawer, stickyBottom, uploadHookVideoStyle } from "../../../../theme/styleConstants";
import { firstLetterUppercase } from "../../../../utils";
import { updateWidgetSchema } from '../../../../validationSchema';
import { Alert } from "../../../common/Alert";
import { BreadCrumb } from "../../../common/BreadCrumb";
import { CustomAccordion } from "../../../common/CustomAccordion";
import { CustomController } from "../../../common/CustomController";
import { CustomModal } from "../../../common/CustomModal";
import { NoDataFound } from "../../../common/NoDataFound";
import { ThreeDotsLoader } from "../../../common/ThreeDotsLoader";
import { BrandAction, BrandActionType, brandInitialState, brandReducer } from "../../reducer/brandReducer";
import { ButtonFields } from "../../widgetCommon/ButtonFields";
import { CalendlyOverride } from '../../widgetCommon/CalendlyOverride';
import { CustomColorControl } from "../../widgetCommon/CustomColorControl";
import { CustomSwitcher } from "../../widgetCommon/CustomSwitcher";
import { FlyerForm } from '../../widgetCommon/FlyerForm';
import { PathWaysBanner } from '../../widgetCommon/PathWaysBanner';
import { PostForm } from "../../widgetCommon/PostForm";
import { TellingForm } from "../../widgetCommon/TellingForm";
import { WidgetName } from "../../widgetCommon/WidgetName";
import { AddVideo } from '../AddVideo';
import { CreateVideoModal } from '../CreateVideoModal';
import { EditModal } from '../EditModal';
import { VideoCard } from '../VideoCard';
import { VideoLibraryActions } from '../VideoLibraryActions';
import { RenderRadio } from "./RenderRadio";
import { VideoBoundingBox } from "./VideoBoundingBox";
import { VideoGridField } from "./VideoGrid";
import { VideoSelection } from "./VideoSelection";
import { VideoSettingPreview } from './VideoSettingPreview';

export const VideoSettings: FC<BrandDataProp> = ({ brandData }) => {
  const { isEdit, dispatch } = useContext(AppContext)
  const [widgetData, setWidgetData] = useState<Widget>();
  const [items, setItems] = useState<Attachment[]>([]);
  const [idsForVideos, setIdsForVideos] = useState<WidgetVideoInput[]>([])
  const [videosToShow, setVideosToShow] = useState(0);
  const [open, setOpen] = useState(false);
  const [toggleDrawerState, setToggleDrawerState] = useState(false);
  const [attachmentModal, setAttachmentModal] = useState<boolean>(false)
  const [orientation, setOrientation] = useState<VideoOrientation>(VideoOrientation.Landscape)
  const mediumViewport = useMediaQuery('(max-width:500px)');
  const customViewport = useMediaQuery('(max-width:1022px)');
  const brandId = localStorage.getItem(BRAND_ID);

  const portraitItems = items?.filter(video => video.videoOrientation === VideoOrientation.Portrait)
  const landscapeItems = items?.filter(video => video.videoOrientation === VideoOrientation.Landscape)
  const [brandState, brandDispatch] = useReducer<Reducer<BrandState, BrandAction>>(brandReducer, brandInitialState);
  const { videoIds } = brandState
  const { currentVideo } = brandState

  const [searchParams] = useSearchParams()
  const widgetId = searchParams.get('widgetId')

  const handleCreateVideoModalOpen = (videoType: AttachmentTypeFE, modalVideoOrientation: VideoOrientation, videoData?: Attachment) => {
    dispatch({ type: ActionType.SET_IS_BACKDROP_VISIBLE, isBackdropVisible: true })
    setAttachmentModal(!attachmentModal)
    brandDispatch({ type: BrandActionType.SET_CURRENT_VIDEO, currentVideo: videoData || null })
    setOrientation(modalVideoOrientation)
  };

  const handleVideoData = (video: Attachment) => {
    brandDispatch({ type: BrandActionType.SET_CURRENT_VIDEO, currentVideo: video })
  }

  const handleCreateVideoModalClose = () => {
    dispatch({ type: ActionType.SET_IS_BACKDROP_VISIBLE, isBackdropVisible: false })
    setAttachmentModal(false)
  }

  const handleEditClose = () => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: false })
  };

  const numberOfVideosToShow = (videoLayoutType: VideoLayout) => {

    switch (videoLayoutType) {
      case VideoLayout.SingleVideo:
        return setVideosToShow(1);

      case VideoLayout.ThreeVideos:
        return setVideosToShow(3);

      case VideoLayout.FiveVideos:
        return setVideosToShow(5);

      case VideoLayout.InfiniteVideos:
        return setVideosToShow(50000);

      default:
        break;
    }
  }

  const [getWidget, { loading: widgetLoading, refetch }] = useGetWidgetLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() { },

    onCompleted(data) {
      const { getWidget } = data || {}

      if (getWidget) {
        setWidgetData(getWidget)
      }
    }
  });

  const [updateWidget, { loading }] = useUpdateWidgetMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { updateWidget } = data
        if (updateWidget) {
          reset({}, { keepValues: true, keepIsValid: true });
          setWidgetData(updateWidget)

          return Alert.success("Widget updated successfully")
        }
      }
    }
  })

  useEffect(() => {
    if (!widgetData && !!widgetId && brandId) {
      getWidget({
        variables: {
          getWidgetInput: {
            id: widgetId,
            brandId
          },
          widgetId
        }
      })
    }
    //eslint-disable-next-line
  }, [widgetData, widgetId])

  const {
    name, videoGridType, videoLayout, videoBoundingBox, showVideoControls, customPlayerSettings,
    playVideoAfterSeconds, heading, headingColor, description, descriptionColor, tellingFormHeading,
    tellingFormDescription, helperTextDesign, helperTextSupport, postSubmissionHeading, postSubmissionDescription,
    backgroundColor, buttonBackgroundColor, buttonColor, videos, nameLabel, phoneLabel, emailLabel, gorgiasSupportTicketMessage,
    gorgiasSupportTicketSubject, buttonText, postSubmissionButtonText, postSubmissionButtonColor, postSubmissionButtonBackground,
    tellingFormBorder, tellingFormBorderColor, mobileCtaButtonText, hookVideos, mobileCtaButtonStyle, isPortrait, calenderUrl, throttleNumber, gorgiasTags,
    pathWaysButtonBackgroundColor, pathWaysButtonColor, pathWaysButtonText, pathWaysMobileCtaButtonStyle, pathWaysMobileCtaButtonText, showBannerAlways,
    skipVideos, hookVideoFlyerTimer, showCustomerSupport, showDesignAdvice, customerSupportButtonHeading, customerSupportButtonDescription,
    designAdviceButtonHeading, designAdviceButtonDescription, mobileFlyerHeading, mobileFlyerDescription
  } = widgetData || {}

  const hookVideoLandscape = hookVideos?.filter(video => video.videoOrientation === VideoOrientation.Landscape)
  const hookVideoPortrait = hookVideos?.filter(video => video.videoOrientation === VideoOrientation.Portrait)

  const methods = useForm<UpdateWidget>({
    shouldUnregister: false,
    resolver: yupResolver(updateWidgetSchema),

    defaultValues: {
      videoGridType,
      videoLayout,
      videoBoundingBox,
      showVideoControls,
      skipVideos,
      customPlayerSettings,
      headingColor: headingColor || palette.black,
      descriptionColor: descriptionColor || palette.black,
      buttonBackgroundColor: buttonBackgroundColor || palette.black,
      buttonColor: buttonColor || palette.white,
      backgroundColor: backgroundColor || palette.black,
      name: name || "",
      playVideoAfterSeconds: playVideoAfterSeconds || 0,
      heading: heading || "",
      description: description || "",
      tellingFormHeading: tellingFormHeading || "",
      tellingFormDescription: tellingFormDescription || "",
      helperTextDesign: helperTextDesign || "",
      helperTextSupport: helperTextSupport || "",
      postSubmissionHeading: postSubmissionHeading || "",
      postSubmissionDescription: postSubmissionDescription || "",
      nameLabel: nameLabel || "",
      phoneLabel: phoneLabel || "",
      emailLabel: emailLabel || "",
      gorgiasSupportTicketMessage: gorgiasSupportTicketMessage || "",
      gorgiasSupportTicketSubject: gorgiasSupportTicketSubject || "",
      buttonText: buttonText || "",
      videos: videoIds,
      postSubmissionButtonText: postSubmissionButtonText || "",
      postSubmissionButtonColor: postSubmissionButtonColor || palette.white,
      postSubmissionButtonBackground: postSubmissionButtonBackground || palette.black,
      tellingFormBorder,
      tellingFormBorderColor: tellingFormBorderColor || palette.black,
      calenderUrl: calenderUrl || '',
      throttleNumber: throttleNumber || 1,
      mobileCtaButtonText: mobileCtaButtonText || '',
      gorgiasTags: gorgiasTags?.split(', ') || [],
      isPortrait,
      mobileCtaButtonStyle,
      pathWaysButtonBackgroundColor: pathWaysButtonBackgroundColor || palette.black,
      pathWaysButtonColor: pathWaysButtonColor || palette.black,
      pathWaysButtonText: pathWaysButtonText || '',
      pathWaysMobileCtaButtonStyle,
      pathWaysMobileCtaButtonText: pathWaysMobileCtaButtonText || '',
      showBannerAlways,
      hookVideoFlyerTimer: hookVideoFlyerTimer || 0,
      showDesignAdvice: showDesignAdvice,
      showCustomerSupport: showCustomerSupport,
      customerSupportButtonHeading: customerSupportButtonHeading,
      customerSupportButtonDescription: customerSupportButtonDescription,
      designAdviceButtonHeading: designAdviceButtonHeading,
      designAdviceButtonDescription: designAdviceButtonDescription,
      mobileFlyerHeading: mobileFlyerHeading,
      mobileFlyerDescription: mobileFlyerDescription
    }
  });

  const { control, setValue, handleSubmit, watch, getValues, formState: { isDirty }, reset } = methods

  const videoGridTypeValue = watch('videoGridType')
  const videoLayoutValue = watch('videoLayout')
  const headingValue = watch('heading')
  const headingColorValue = watch('headingColor')
  const descriptionValue = watch('description')
  const descriptionColorValue = watch('descriptionColor')
  const isPortraitValue = watch('isPortrait')
  const skipVideosValue = watch('skipVideos')

  const formValues = getValues()

  useEffect(() => {
    const sortedVideos = (videos || []).sort((a, b) => a!.sequenceNo! - b!.sequenceNo!);
    const selectedVideos: WidgetVideoInput[] = []
    // TODO convert the for loop to reduce
    for (let selectedVideo of sortedVideos) {
      if (isPortraitValue && selectedVideo?.videoOrientation === VideoOrientation.Portrait) {
        selectedVideos.push({ id: selectedVideo.id, sequenceNo: selectedVideo.sequenceNo || selectedVideos.length + 1 })
      }

      if (!isPortraitValue && selectedVideo?.videoOrientation === VideoOrientation.Landscape) {
        selectedVideos.push({ id: selectedVideo.id, sequenceNo: selectedVideo.sequenceNo || selectedVideos.length + 1 })
      }
    }

    brandDispatch({ type: BrandActionType.SET_VIDEO_IDS, videoIds: selectedVideos })
    setValue("videos", selectedVideos)
    numberOfVideosToShow(videoLayoutValue as unknown as VideoLayout)
    // eslint-disable-next-line
  }, [isPortraitValue, videos])


  useEffect(() => {
    setValue("videos", videoIds)
  }, [setValue, videoIds])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    name && setValue('name', name)
    videoGridType && setValue('videoGridType', videoGridType)
    videoLayout === VideoLayout.InfiniteVideos && setValue('videoLayout', undefined)
    videoLayout && videoLayout !== VideoLayout.InfiniteVideos && setValue('videoLayout', videoLayout)
    videoLayout && setValue('videoLayout', videoLayout)
    videoBoundingBox && setValue('videoBoundingBox', videoBoundingBox)
    playVideoAfterSeconds && setValue('playVideoAfterSeconds', playVideoAfterSeconds)
    heading && setValue('heading', heading)
    headingColor && setValue('headingColor', headingColor)
    description && setValue('description', description)
    descriptionColor && setValue('descriptionColor', descriptionColor)
    tellingFormHeading && setValue('tellingFormHeading', tellingFormHeading)
    tellingFormDescription && setValue('tellingFormDescription', tellingFormDescription)
    helperTextSupport && setValue('helperTextSupport', helperTextSupport)
    helperTextDesign && setValue('helperTextDesign', helperTextDesign)
    backgroundColor && setValue('backgroundColor', backgroundColor)
    buttonColor && setValue('buttonColor', buttonColor)
    buttonBackgroundColor && setValue('buttonBackgroundColor', buttonBackgroundColor)
    postSubmissionHeading && setValue('postSubmissionHeading', postSubmissionHeading)
    postSubmissionDescription && setValue('postSubmissionDescription', postSubmissionDescription)
    videoIds && setValue('videos', videoIds)
    nameLabel && setValue('nameLabel', nameLabel)
    emailLabel && setValue('emailLabel', emailLabel)
    phoneLabel && setValue('phoneLabel', phoneLabel)
    gorgiasSupportTicketMessage && setValue('gorgiasSupportTicketMessage', gorgiasSupportTicketMessage)
    gorgiasSupportTicketSubject && setValue('gorgiasSupportTicketSubject', gorgiasSupportTicketSubject)
    buttonText && setValue('buttonText', buttonText)
    setValue('showVideoControls', showVideoControls)
    setValue('skipVideos', skipVideos)
    setValue('showBannerAlways', showBannerAlways)
    setValue('isPortrait', isPortrait)
    postSubmissionButtonText && setValue('postSubmissionButtonText', postSubmissionButtonText)
    postSubmissionButtonColor && setValue('postSubmissionButtonColor', postSubmissionButtonColor)
    postSubmissionButtonBackground && setValue('postSubmissionButtonBackground', postSubmissionButtonBackground)
    setValue('customPlayerSettings', customPlayerSettings)
    setValue('tellingFormBorder', tellingFormBorder)
    tellingFormBorderColor && setValue('tellingFormBorderColor', tellingFormBorderColor)
    mobileCtaButtonText && setValue('mobileCtaButtonText', mobileCtaButtonText)
    mobileCtaButtonStyle && setValue('mobileCtaButtonStyle', mobileCtaButtonStyle)
    pathWaysButtonBackgroundColor && setValue('pathWaysButtonBackgroundColor', pathWaysButtonBackgroundColor)
    pathWaysButtonColor && setValue('pathWaysButtonColor', pathWaysButtonColor)
    pathWaysButtonText && setValue('pathWaysButtonText', pathWaysButtonText)
    pathWaysMobileCtaButtonStyle && setValue('pathWaysMobileCtaButtonStyle', pathWaysMobileCtaButtonStyle)
    pathWaysMobileCtaButtonText && setValue('pathWaysMobileCtaButtonText', pathWaysMobileCtaButtonText)
    calenderUrl && setValue('calenderUrl', calenderUrl)
    throttleNumber && setValue('throttleNumber', throttleNumber)
    hookVideoFlyerTimer && setValue('hookVideoFlyerTimer', hookVideoFlyerTimer)
    showCustomerSupport && setValue('showCustomerSupport', showCustomerSupport)
    showDesignAdvice && setValue('showDesignAdvice', showDesignAdvice);
    customerSupportButtonHeading && setValue('customerSupportButtonHeading', customerSupportButtonHeading);
    customerSupportButtonDescription && setValue('customerSupportButtonDescription', customerSupportButtonDescription);
    designAdviceButtonHeading && setValue('designAdviceButtonHeading', designAdviceButtonHeading);
    designAdviceButtonDescription && setValue('designAdviceButtonDescription', designAdviceButtonDescription);
    mobileFlyerHeading && setValue('mobileFlyerHeading', mobileFlyerHeading);
    mobileFlyerDescription && setValue('mobileFlyerDescription', mobileFlyerDescription)

    if (gorgiasTags) {
      setValue("gorgiasTags", gorgiasTags.split(', '))
    }
    //eslint-disable-next-line
  }, [
    name, videoGridType, videoLayout, videoBoundingBox, showVideoControls, customPlayerSettings, playVideoAfterSeconds, heading,
    headingColor, description, descriptionColor, tellingFormHeading, tellingFormDescription, postSubmissionHeading, postSubmissionDescription,
    backgroundColor, buttonColor, buttonBackgroundColor, nameLabel, emailLabel, phoneLabel, gorgiasSupportTicketMessage,
    gorgiasSupportTicketSubject, buttonText, tellingFormBorder, tellingFormBorderColor, mobileCtaButtonText, isPortrait, videos, calenderUrl, throttleNumber, gorgiasTags,
    pathWaysButtonBackgroundColor, pathWaysButtonColor, pathWaysButtonText, pathWaysMobileCtaButtonStyle, pathWaysMobileCtaButtonText, showBannerAlways,
    skipVideos, hookVideoFlyerTimer, showCustomerSupport, showDesignAdvice, customerSupportButtonHeading, customerSupportButtonDescription,
    designAdviceButtonHeading, designAdviceButtonDescription, mobileFlyerHeading, mobileFlyerDescription
  ])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  type Anchor = 'bottom';
  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setToggleDrawerState(!toggleDrawerState);
  };

  useEffect(() => {
    if (videoIds.length > 0) {
      const updatedVideoIds = videoIds.map((video, index) => ({ id: video.id, sequenceNo: index + 1 }))

      setIdsForVideos(updatedVideoIds)
    }
  }, [videoIds])

  const onSubmit = async (data: UpdateWidget) => {
    if (widgetId && brandId) {
      const { playVideoAfterSeconds: videoSeconds, hookVideoFlyerTimer: flyerTimerSeconds, throttleNumber, gorgiasTags } = data

      await updateWidget({
        variables: {
          updateWidgetInput: {
            ...data,
            playVideoAfterSeconds: videoSeconds ? Number.parseFloat(videoSeconds?.toString()) : 0,
            hookVideoFlyerTimer: flyerTimerSeconds ? Number.parseFloat(flyerTimerSeconds?.toString()) : 0,
            videos: idsForVideos,
            throttleNumber: parseInt(throttleNumber?.toString() || '1'),
            gorgiasTags: gorgiasTags.join(', '),
            id: widgetId,
            brandId
          },
          widgetId
        }
      })
    }
  }

  useEffect(() => {
    if (isPortraitValue) {
      setValue('videoGridType', VideoGridType.PortraitGallery)
      setValue('videoLayout', VideoLayout.InfiniteVideos)
    } else {
      videoGridType !== VideoGridType.PortraitGallery ? setValue('videoGridType', videoGridType) : setValue('videoGridType', VideoGridType.Massonary)
      videoLayout !== VideoLayout.InfiniteVideos ? setValue('videoLayout', videoLayout) : setValue('videoLayout', VideoLayout.SingleVideo)

      numberOfVideosToShow(videoLayoutValue as unknown as VideoLayout)
    }
    // eslint-disable-next-line
  }, [isPortraitValue])


  const getWidgetLayout = (videoLayout: VideoLayout, videoGridType: VideoGridType) => {
    switch (videoLayout) {
      case VideoLayout.SingleVideo:
        return images.SINGLE_LAYOUT

      case VideoLayout.ThreeVideos:
        switch (videoGridType) {
          case VideoGridType.Massonary:
            return images.MASSORY_THREE_LAYOUT

          case VideoGridType.Carousell:
            return images.CAROUSELL_THREE_LAYOUT

          case VideoGridType.Gallery:
            return images.GALLERY_THREE_LAYOUT

          default:
            return
        }

      case VideoLayout.FiveVideos:
        switch (videoGridType) {
          case VideoGridType.Massonary:
            return images.MASSORY_FIVE_LAYOUT

          case VideoGridType.Carousell:
            return images.CAROUSELL_FIVE_LAYOUT

          case VideoGridType.Gallery:
            return images.GALLERY_FIVE_LAYOUT
        }
    }
  }

  const { attachments } = brandData || {}

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    const dragAndOver = active && over?.id
    if (dragAndOver && active.id !== over?.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.findIndex((item) => item.id === active.id);
        const newIndex = prevItems?.findIndex((item) => item.id === over.id);

        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }

    brandDispatch({ type: BrandActionType.SET_IS_DRAGGING, isDragging: false })
  }

  useEffect(() => {
    const attachmentsWithoutHookVideo = attachments?.filter(video => video.type !== AttachmentType.HookVideo)
    const remainingVideos = attachmentsWithoutHookVideo?.filter((video) => !videos?.some(item => item.id === video.id)) as Attachment[];
    const attachmentsToShow = videos ? [...videos, ...remainingVideos] : remainingVideos

    items?.length === 0 && setItems(attachmentsToShow || [])
    // eslint-disable-next-line
  }, [attachments, videos])

  const checkVideos = items?.length === 0
  const thumbnailUrls = items?.slice(0, 5)?.map(video => video.thumbnail?.url || "")

  const portraitThumbnailUrls = portraitItems?.slice(0, 5)?.map(video => video.thumbnail?.url || "")
  const landscapeThumbnailUrls = landscapeItems?.slice(0, 5)?.map(video => video.thumbnail?.url || "")

  if (widgetLoading) {
    return <ThreeDotsLoader height="calc(100vh - 300px)" />
  }

  const isButtonDisabled = isPortraitValue ? (loading || videoIds.length === 0) : (loading || videoIds.length !== videosToShow)

  return (
    <>
      {checkVideos ?
        <NoDataFound height='calc(100vh - 285px)' description="Please select videos" button={true} />
        :
        <FormProvider  {...methods}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography variant="subtitle1" sx={{ marginTop: 2 }}>{PAGE_BLOCK_WIDGET}</Typography>

              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <BreadCrumb currentPage={VIDEO_SETTINGS} previousPage={WIDGET_SETTING} />
              </Box>

              <Grid container spacing={1.2} rowSpacing={0.5}>
                <Grid item lg={6} xs={12}>
                  <CustomAccordion expanded={true} label={GENERAL_SETTINGS} id="videoSettings">
                    <WidgetName />

                    <CustomColorControl name="backgroundColor" label="Main background color" />

                    <CustomSwitcher label="Skip Videos" defaultValue={!!skipVideos} controllerName="skipVideos" />

                    <Collapse in={!!!skipVideosValue} unmountOnExit mountOnEnter>
                      <Box marginTop='2rem'>
                        <Typography variant="subtitle1">Video orientation</Typography>

                        <Typography variant="h3" mb={1} sx={{ opacity: '0.8' }}>Choose appropriate aspect ratio for video</Typography>

                        <Box display='flex' mt={1} alignItems='center'>
                          <Typography variant="body1" display='flex'><Box component='img' src={images.LANDSCAPE} mr={1} />Landscape</Typography>

                          <Controller
                            control={control}
                            name='isPortrait'
                            defaultValue={!!isPortrait}
                            render={({ field }) => (
                              <Switch
                                sx={{ marginRight: '1rem' }}
                                color="secondary"
                                value={field.value}
                                checked={!!field.value}
                                onChange={async (_event, value) => {
                                  field.onChange(value)

                                  if (widgetId && brandId) {
                                    await updateWidget({
                                      variables: {
                                        updateWidgetInput: {
                                          ...formValues,
                                          id: widgetId,
                                          brandId,
                                          gorgiasTags,
                                          throttleNumber: parseInt(throttleNumber?.toString() || '1'),
                                          videos: idsForVideos,
                                          isPortrait: !field.value,
                                          videoGridType: !field.value ? VideoGridType.PortraitGallery : VideoGridType.Massonary,
                                          videoLayout: !field.value ? VideoLayout.InfiniteVideos : VideoLayout.SingleVideo
                                        },
                                        widgetId
                                      }
                                    })
                                  }
                                }}
                              />
                            )}
                          />

                          <Typography variant="body1" display='flex'><Box component='img' src={images.PORTRAIT} mr={1} />Portrait</Typography>
                        </Box>
                      </Box>
                    </Collapse>

                    {!isPortraitValue &&
                      <Box marginTop='2rem'>
                        <CustomSwitcher label="Video Controls" defaultValue={!!showVideoControls} controllerName="showVideoControls" />
                      </Box>
                    }
                  </CustomAccordion>

                  <CalendlyOverride />

                  <Collapse in={!!!skipVideosValue} unmountOnExit mountOnEnter>
                    <CustomAccordion expanded={true} label="Video Settings" id="videoSettings">
                      {!isPortraitValue &&
                        <>
                          <VideoGridField />

                          <Box marginTop='1rem'>
                            <Typography variant="caption">Video Layout</Typography>

                            <Controller
                              control={control}
                              name="videoLayout"
                              render={({ field }) => (
                                <RadioGroup
                                  {...field}
                                  sx={{ marginTop: '1rem' }}
                                  value={field.value}
                                  onChange={(_event, value) => {
                                    field.onChange(value)
                                    numberOfVideosToShow(value as unknown as VideoLayout)
                                    brandDispatch({ type: BrandActionType.SET_VIDEO_IDS, videoIds: [] })
                                  }}
                                >
                                  <RenderRadio
                                    value={VideoLayout.SingleVideo}
                                    imageUrl={images.SINGLE_LAYOUT}
                                    isChecked={field.value === VideoLayout.SingleVideo}
                                    label="Single Video Layout"
                                  />

                                  <RenderRadio
                                    value={VideoLayout.ThreeVideos}
                                    imageUrl={getWidgetLayout(VideoLayout.ThreeVideos, videoGridTypeValue as unknown as VideoGridType) || ""}
                                    isChecked={field.value === VideoLayout.ThreeVideos}
                                    label="3 Videos Layout"
                                  />

                                  <RenderRadio
                                    value={VideoLayout.FiveVideos}
                                    imageUrl={getWidgetLayout(VideoLayout.FiveVideos, videoGridTypeValue as unknown as VideoGridType) || ""}
                                    isChecked={field.value === VideoLayout.FiveVideos}
                                    label="5 Videos Layout"
                                  />
                                </RadioGroup>
                              )}
                            />
                          </Box>
                        </>
                      }

                      <VideoBoundingBox />
                    </CustomAccordion>
                  </Collapse>

                  <Collapse in={!!!skipVideosValue} unmountOnExit mountOnEnter>
                    <CustomAccordion expanded={true} label="Select Videos" id="selectVideos">
                      <Box marginTop='1rem'>
                        {isPortraitValue ?
                          <Box>
                            <Typography variant="caption">Portrait videos</Typography>

                            <Typography variant="h3" sx={{ opacity: '0.6' }}>Select portrait videos from below</Typography>
                          </Box>
                          :
                          <Box>
                            <Typography variant="caption">Choose {videoIds.length}/{videosToShow} video</Typography>

                            <Typography variant="h3" sx={{ opacity: '0.6' }}>Select {videosToShow} video</Typography>
                          </Box>
                        }

                        <FormGroup sx={{ paddingTop: 2 }}>
                          <VideoSelection
                            brandState={brandState}
                            videos={isPortraitValue ? portraitItems?.slice(0, 6) : landscapeItems?.slice(0, 6)}
                            gridMd={6}
                            brandDispatch={brandDispatch}
                            videosToShow={videosToShow}
                            handleDragEnd={handleDragEnd}
                            setItems={setItems}
                          />
                        </FormGroup>

                        {(items && items?.length > 6) &&
                          <Box marginTop='1rem' textAlign='center'>
                            <Button variant="text" color='primary' onClick={handleClickOpen}>See More Videos</Button>
                          </Box>
                        }

                        <CustomModal title="All Videos" subTitle="View all your added videos" isOpen={open} handleClose={handleClose} maxWidth="1100px">
                          <FormGroup sx={{ padding: '16px 10px 10px' }}>
                            <VideoSelection
                              brandState={brandState}
                              videos={isPortraitValue ? portraitItems : landscapeItems}
                              gridMd={3}
                              brandDispatch={brandDispatch}
                              videosToShow={videosToShow}
                              handleDragEnd={handleDragEnd}
                              setItems={setItems}
                            />
                          </FormGroup>

                          <DialogActions>
                            <Button variant="text" color="inherit" onClick={handleClose}>Cancel</Button>

                            <Button variant="contained" color="primary" onClick={handleClose}>Ok</Button>
                          </DialogActions>
                        </CustomModal>
                      </Box>
                    </CustomAccordion>
                  </Collapse>

                  <CustomAccordion expanded={true} label="Override Hook Video" subHeading='If you want to choose a specific hook video for this widget only,upload it here' id='overrideHookVideo'>
                    <Grid container spacing={2} rowSpacing={2}>
                      <Grid item md={7} xs={12}>
                        {hookVideoLandscape && hookVideoLandscape.length > 0 ?
                          <>
                            <Box sx={uploadHookVideoStyle}>
                              <Typography variant={customViewport ? 'subtitle1' : "subtitle2"}>Landscape Video</Typography>

                              <Button
                                color="secondary"
                                disabled={false}
                                startIcon={<AddIcon />}
                                sx={{ padding: '10px 6px', fontSize: '14px' }}
                                onClick={() => handleCreateVideoModalOpen('hookVideo', VideoOrientation.Landscape, hookVideoLandscape && hookVideoLandscape[0])}
                              >
                                Upload Video
                              </Button>
                            </Box>

                            <Box sx={hookVideoButtonWrapper} width='100%'>
                              {hookVideoLandscape && hookVideoLandscape.length > 0 &&
                                <VideoCardWrapper>
                                  <Box onClick={() => handleVideoData(hookVideoLandscape[0])}>
                                    <VideoLibraryActions />
                                  </Box>

                                  <VideoCard dispatch={brandDispatch} state={brandState} videoData={hookVideoLandscape[0]} />
                                </VideoCardWrapper>
                              }
                            </Box>
                          </>
                          :
                          <>
                            <Typography variant='subtitle1' mb={2}>Landscape Video</Typography>

                            <AddVideo height="200px" handleModalOpen={() => handleCreateVideoModalOpen('hookVideo', VideoOrientation.Landscape,)} />
                          </>
                        }
                      </Grid>

                      <Grid item md={5} xs={12}>
                        {hookVideoPortrait && hookVideoPortrait.length > 0 ?
                          <>
                            <Box sx={uploadHookVideoStyle}>
                              <Typography variant={customViewport ? 'subtitle1' : "subtitle2"}>Portrait Video</Typography>

                              <Button
                                color="secondary"
                                disabled={false}
                                startIcon={<AddIcon />}
                                sx={{ padding: '10px 6px', fontSize: '14px' }}
                                onClick={() => handleCreateVideoModalOpen('hookVideo', VideoOrientation.Portrait, hookVideoPortrait && hookVideoPortrait[0])}
                              >
                                Upload Video
                              </Button>
                            </Box>

                            <Box sx={hookVideoButtonWrapper} width='100%'>
                              {hookVideoPortrait && hookVideoPortrait.length > 0 &&
                                <VideoCardWrapper>
                                  <Box onClick={() => handleVideoData(hookVideoPortrait[0])}>
                                    <VideoLibraryActions />
                                  </Box>

                                  <VideoCard dispatch={brandDispatch} state={brandState} videoData={hookVideoPortrait[0]} videoStyle={hookVideoStylePortrait} />
                                </VideoCardWrapper>
                              }
                            </Box>
                          </>
                          :
                          <>
                            <Typography variant='subtitle1' mb={2}>Portrait Video</Typography>

                            <AddVideo height="300px" handleModalOpen={() => handleCreateVideoModalOpen('hookVideo', VideoOrientation.Portrait)} />
                          </>
                        }
                      </Grid>
                    </Grid>
                  </CustomAccordion>

                  <Collapse in={!!!skipVideosValue} unmountOnExit mountOnEnter>
                    <CustomAccordion expanded={true} label="Time Settings" subHeading={TIME_SETTINGS_HELPER_TEXT} id="timeSettings">
                      <Box position='relative'>
                        <CustomController
                          controllerName="playVideoAfterSeconds"
                          controllerLabel="Time Settings"
                          fieldType='number'
                        />

                        <Box position='absolute' top='1.8rem' right='2.1rem'>
                          <Typography variant="h3" fontWeight='600'>(seconds)</Typography>
                        </Box>
                      </Box>
                    </CustomAccordion>
                  </Collapse>

                  <CustomAccordion expanded={true} label={TEXT_SETTINGS} id="text">
                    <CustomController
                      controllerName="heading"
                      controllerLabel="Enter heading"
                      fieldType='text'
                    />

                    <CustomColorControl name="headingColor" label="Heading color" />

                    <CustomController
                      controllerName="description"
                      controllerLabel="Describe this widget"
                      fieldType='text'
                      isMultiLine
                      rowsLength={4}
                    />

                    <CustomColorControl name="descriptionColor" label="Description color" />
                  </CustomAccordion>

                  <Collapse in={!!!skipVideosValue} unmountOnExit mountOnEnter>
                    <CustomAccordion expanded={true} label={PATH_WAYS_BANNER} id='PathWaysBanner'>
                      <PathWaysBanner />
                    </CustomAccordion>
                  </Collapse>

                  <CustomAccordion expanded={true} label={CLIENT_TELLING_FORM} id="tellingFormSettings">
                    <TellingForm tellingFormBorder />
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label={THANKYOU_SETTINGS} id="postFormSettings">
                    <PostForm />
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label="Button Settings" id="buttonSettings">
                    <ButtonFields />
                  </CustomAccordion>

                  <Collapse in={true} unmountOnExit mountOnEnter>
                    <CustomAccordion expanded={true} label="Flyer Settings" id="flyerSettings">
                      <FlyerForm />
                    </CustomAccordion>
                  </Collapse>

                  <Box sx={stickyBottom}>
                    <Button color="primary" variant='contained' type="submit" fullWidth
                      endIcon={loading && <CircularProgress size={20} color="inherit" />}
                      disabled={isButtonDisabled || !isDirty}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>

                <Grid item lg={6} xs={12}>
                  <AccordionWrapper position='sticky' top="145px">
                    <Box>
                      <VideoSettingPreview
                        thumbnailUrls={isPortraitValue ? portraitThumbnailUrls : landscapeThumbnailUrls || thumbnailUrls}
                        videoIds={videoIds}
                        thumbnailsToShow={isPortraitValue ? portraitItems : landscapeItems}
                        headingColorValue={headingColorValue}
                        descriptionColorValue={descriptionColorValue}
                        descriptionValue={descriptionValue}
                        headingValue={headingValue}
                        hookVideos={hookVideos}
                      />
                    </Box>
                  </AccordionWrapper>

                  {mediumViewport &&
                    <Box>
                      <Button sx={previewDrawer} onClick={() => toggleDrawer('bottom', true)}>
                        <Box sx={drawerButton}>
                          <Typography variant="subtitle2" sx={{ color: palette.white, textTransform: 'capitalize' }}>Preview</Typography>
                        </Box>
                      </Button>

                      <Drawer
                        anchor='bottom'
                        open={toggleDrawerState}
                        onClose={() => toggleDrawer('bottom', false)}
                      >
                        <VideoSettingPreview
                          thumbnailUrls={isPortraitValue ? portraitThumbnailUrls : landscapeThumbnailUrls}
                          videoIds={videoIds}
                          thumbnailsToShow={items}
                          headingColorValue={headingColorValue}
                          descriptionColorValue={descriptionColorValue}
                          descriptionValue={descriptionValue}
                          headingValue={headingValue}
                          hookVideos={hookVideos}
                        />
                      </Drawer>
                    </Box>
                  }
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>
      }

      <CreateVideoModal
        isOpen={attachmentModal}
        handleClose={handleCreateVideoModalClose}
        currentVideo={currentVideo}
        entityType='widgets'
        refetchWidget={refetch}
        typeId={widgetId || ""}
        isLandscape={orientation === VideoOrientation.Landscape}
        videoType='hookVideo'
      />

      <EditModal
        isOpen={isEdit}
        dispatch={brandDispatch}
        state={brandState}
        refetchWidget={refetch}
        handleClose={handleEditClose}
        videoData={currentVideo}
      />
    </>
  )
}