import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, CircularProgress, Collapse, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { FC, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useUpdateBrandMutation } from '../../../generated'
import { CALENDER, URL } from '../../constants'
import { AppContext } from '../../context'
import { ActionType } from '../../context/AppContextReducer'
import { UpdateBrand } from '../../interfaceTypes'
import { firstLetterUppercase } from '../../utils'
import { calendlySchema } from '../../validationSchema'
import { Alert } from '../common/Alert'
import { CustomController } from '../common/CustomController'

export const Calendly: FC = () => {
  const { selectedBrand, dispatch } = useContext(AppContext)
  const { calenderUrl, id: brandId, name, createdAt, updatedAt, attachments } = selectedBrand || {}
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const methods = useForm<UpdateBrand>({
    mode: "all",
    resolver: yupResolver(calendlySchema),

    defaultValues: {
      calenderUrl: calenderUrl || ''
    }
  });

  const { handleSubmit, setValue, reset } = methods;

  const [updateBrand, { loading }] = useUpdateBrandMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { updateBrand } = data
        const { calenderUrl, gorgiasTags } = updateBrand

        const updatedBrand = {
          ...selectedBrand,
          id: brandId || "",
          name: name || "",
          calenderUrl,
          gorgiasTags,
          attachments: attachments || [],
          createdAt,
          updatedAt,
        }

        if (updateBrand) {
          dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrand })
          setIsEditOpen(false)
          reset()
          return Alert.success("Brand updated successfully")
        }
      }
    }
  })

  const onSubmit = async (data: UpdateBrand) => {
    const { calenderUrl } = data

    if (brandId) {
      await updateBrand({
        variables: {
          updateBrandInput: {
            brandId,
            calenderUrl: calenderUrl || "",
          }
        }
      })
    }
  }

  useEffect(() => {
    calenderUrl && setValue('calenderUrl', calenderUrl)
    //eslint-disable-next-line
  }, [calenderUrl, selectedBrand])

  return (
    <Box sx={{ mt: "2rem" }}>
      <Box display='flex' justifyContent='space-between' alignItems="center">
        <Typography variant="subtitle1" mb={2}>{CALENDER}</Typography>

        <IconButton color="primary" size="large" onClick={() => { setIsEditOpen(!isEditOpen) }}>
          {isEditOpen ? <ArrowBackIcon /> : <EditIcon />}
        </IconButton>
      </Box>

      <Collapse in={!isEditOpen}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item sm={6} xs={12}>
            <Box>
              <Typography variant='body2'>{CALENDER}</Typography>

              <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} noWrap>
                <Tooltip title={calenderUrl || ''} placement="bottom-start">
                  {calenderUrl ?
                    <Typography component="a" href={calenderUrl} target="_blank" color="primary" variant='h4'>{calenderUrl}</Typography>
                    :
                    <Typography color="inherit" variant='h4'>N/A</Typography>
                  }
                </Tooltip>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Collapse>

      <Collapse in={isEditOpen}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item sm={6} xs={12}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <CustomController
                  controllerName='calenderUrl'
                  controllerLabel={URL}
                  fieldType='text'
                />

                <Button sx={{ marginTop: '1rem' }} type='submit' variant='contained' fullWidth color='primary'
                  disabled={loading} endIcon={loading && <CircularProgress size={20} color="inherit" />}>
                  Update
                </Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}
