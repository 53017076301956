import { Maybe, User, UserEmail, UserRoles } from "../../generated";

export interface UserState {
  usersData?: Maybe<Array<Maybe<User>>>
  currentUserData?: Maybe<User>;
  userRole?: UserRoles | string;
  keyword?: string;
  selectedBrand?: { label: string, id: string };
  allEmails?: Maybe<Array<UserEmail>>
  currentEmail?: Maybe<UserEmail>
}

export const userInitialState: UserState = {
  usersData: [],
  currentUserData: null,
  userRole: "",
  keyword: "",
  selectedBrand: { label: 'All', id: 'all-brands' },
  allEmails: [],
  currentEmail: null
}

export enum UserActionType {
  SET_USERS_DATA = 'setUsersData',
  SET_CURRENT_USER = 'setCurrentUser',
  SET_USER_ROLES = 'setUserRoles',
  SET_KEYWORD = 'setKeyword',
  SET_SELECTED_BRAND = 'setSelectedBrand',
  SET_ALL_EMAIL = 'setAllEmails',
  SET_CURRENT_EMAIL = 'setCurrentEmail'
}

export type UserAction =
  { type: UserActionType.SET_USERS_DATA, usersData: Maybe<Array<Maybe<User>>> }
  | { type: UserActionType.SET_CURRENT_USER, currentUserData: Maybe<User> }
  | { type: UserActionType.SET_USER_ROLES, userRole: UserRoles | string }
  | { type: UserActionType.SET_KEYWORD, keyword: string }
  | { type: UserActionType.SET_SELECTED_BRAND, selectedBrand: { label: string, id: string } }
  | { type: UserActionType.SET_ALL_EMAIL, allEmails: Maybe<Array<UserEmail>> }
  | { type: UserActionType.SET_CURRENT_EMAIL, currentEmail: Maybe<UserEmail> }



export const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionType.SET_USERS_DATA:
      return {
        ...state,
        usersData: action.usersData
      }

    case UserActionType.SET_CURRENT_USER:
      return {
        ...state,
        currentUserData: action.currentUserData
      }

    case UserActionType.SET_USER_ROLES:
      return {
        ...state,
        userRole: action.userRole
      }

    case UserActionType.SET_KEYWORD:
      return {
        ...state,
        keyword: action.keyword
      }

    case UserActionType.SET_SELECTED_BRAND:
      return {
        ...state,
        selectedBrand: action.selectedBrand
      }

    case UserActionType.SET_ALL_EMAIL:
      return {
        ...state,
        allEmails: action.allEmails
      }

    case UserActionType.SET_CURRENT_EMAIL:
      return {
        ...state,
        currentEmail: action.currentEmail
      }

    default:
      return state
  }
}
