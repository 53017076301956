import { Box } from "@mui/material";

export const WhereBy = ({ roomUrl }: { roomUrl: string }) => (
  <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
    <iframe src={String(roomUrl)} title="Meeting Link"
      name="wherebyIframe"
      allow="camera; microphone; fullscreen; speaker; display-capture"
      style={{
        height: 'calc(100% - 30px)',
        width: 'calc(100% - 30px)',
        background: 'black',
        border: 0,
        borderRadius: '48px',
        margin: '0 auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    />
  </Box>
)