import {
  Box, Button, CircularProgress,
  Collapse,
  Drawer, Grid,
  Typography, useMediaQuery
} from "@mui/material";
import { FC, Reducer, useContext, useEffect, useReducer, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import { Attachment, useGetWidgetLazyQuery, useUpdateWidgetMutation, VideoOrientation, Widget, WidgetType } from "../../../../generated";
import { ACCEPT_FOR_IMAGES, BRAND_ID, CLIENT_TELLING_FORM, DESCRIPTION_FOR_IMAGE, GENERAL_SETTINGS, GRAPHQL_QUERY_POLICY, IMAGE_MAX_SIZE, SITE_WIDGET_SETTING, THANKYOU_SETTINGS, WIDGET_SETTING } from "../../../constants";
import { AppContext } from "../../../context";
import { ActionType } from "../../../context/AppContextReducer";
import { AttachmentTypeFE, BrandState, UpdateWidget } from "../../../interfaceTypes";
import palette from "../../../theme/palette/index";
import { AccordionWrapper, VideoCardWrapper } from "../../../theme/styleComponents";
import { customIconLinkBox, drawerButton, hookVideoButtonWrapper, hookVideoStylePortrait, previewDrawer, stickyBottom, uploadHookVideoStyle } from "../../../theme/styleConstants";
import { firstLetterUppercase } from "../../../utils";
import { Alert } from "../../common/Alert";
import { BreadCrumb } from "../../common/BreadCrumb";
import { CustomAccordion } from "../../common/CustomAccordion";
import { CustomIconLink } from "../../common/CustomIconLink";
import { ThreeDotsLoader } from "../../common/ThreeDotsLoader";
import { UploadDocument } from "../../common/UploadDocument";
import { BrandAction, BrandActionType, brandInitialState, brandReducer } from "../reducer/brandReducer";
import { AddVideo } from "../videoLibrary/AddVideo";
import { CreateVideoModal } from "../videoLibrary/CreateVideoModal";
import { EditModal } from "../videoLibrary/EditModal";
import { VideoCard } from "../videoLibrary/VideoCard";
import { VideoLibraryActions } from "../videoLibrary/VideoLibraryActions";
import { ButtonFields } from "../widgetCommon/ButtonFields";
import { CalendlyOverride } from "../widgetCommon/CalendlyOverride";
import { CustomColorControl } from "../widgetCommon/CustomColorControl";
import { CustomSwitcher } from "../widgetCommon/CustomSwitcher";
import { FlyerForm } from "../widgetCommon/FlyerForm";
import { PostForm } from "../widgetCommon/PostForm";
import { TellingForm } from "../widgetCommon/TellingForm";
import { SiteWidgetPreviewComponent } from "./SiteWidgetPreview";


export const SiteWidgetSettings: FC = () => {
  const { isEdit, dispatch } = useContext(AppContext)
  const [widgetData, setWidgetData] = useState<Widget>()
  const [attachmentModal, setAttachmentModal] = useState<boolean>(false)
  const [toggleDrawerState, setToggleDrawerState] = useState(false);
  const mediumViewport = useMediaQuery('(max-width:500px)');
  const [searchParams] = useSearchParams()
  const widgetId = searchParams.get('widgetId')
  const { attachment: widgetLogo } = widgetData || {}
  const [orientation, setOrientation] = useState<VideoOrientation>(VideoOrientation.Landscape)
  const [brandState, brandDispatch] = useReducer<Reducer<BrandState, BrandAction>>(brandReducer, brandInitialState);
  const { currentVideo } = brandState
  const brandId = localStorage.getItem(BRAND_ID);
  const siteWidget = `${process.env.REACT_APP_BASE_URL}/widget-preview/brand/${brandId}/widget/${widgetId}?widgetType=${WidgetType.Site}`

  const handleCreateVideoModalOpen = (videoType: AttachmentTypeFE, modalVideoOrientation: VideoOrientation, videoData?: Attachment) => {
    setAttachmentModal(!attachmentModal)
    brandDispatch({ type: BrandActionType.SET_CURRENT_VIDEO, currentVideo: videoData || null })
    dispatch({ type: ActionType.SET_IS_BACKDROP_VISIBLE, isBackdropVisible: true })
    setOrientation(modalVideoOrientation)
  };

  const handleVideoData = (video: Attachment) => {
    brandDispatch({ type: BrandActionType.SET_CURRENT_VIDEO, currentVideo: video })
  }

  const handleCreateVideoModalClose = () => {
    setAttachmentModal(false)
    dispatch({ type: ActionType.SET_IS_BACKDROP_VISIBLE, isBackdropVisible: false })
  }

  const handleEditClose = () => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: false })
  };


  const [getWidget, { loading: widgetDataLoading, refetch }] = useGetWidgetLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() { },

    onCompleted(data) {
      const { getWidget } = data || {}

      if (getWidget) {
        setWidgetData(getWidget)
      }
    }
  });

  const [updateWidget, { loading }] = useUpdateWidgetMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { updateWidget } = data

        if (updateWidget) {
          const updatedWidget = {
            ...updateWidget,
            attachment: widgetLogo
          }

          reset({}, { keepValues: true, keepIsValid: true });
          setWidgetData(updatedWidget)

          return Alert.success("Widget updated successfully")
        }
      }
    }
  })

  useEffect(() => {
    if (!widgetData && widgetId && brandId) {
      getWidget({
        variables: {
          getWidgetInput: {
            id: widgetId,
            brandId
          },
          widgetId
        }
      })
    }

    reset({}, { keepValues: true, keepIsValid: true });
    //eslint-disable-next-line
  }, [widgetData, widgetId])

  const {
    name, showVideoControls, customPlayerSettings, attachment,
    playVideoAfterSeconds, heading, headingColor, description, descriptionColor, tellingFormHeading,
    tellingFormDescription, helperTextDesign, helperTextSupport, postSubmissionHeading, postSubmissionDescription,
    backgroundColor, buttonBackgroundColor, idleBackgroundColor, buttonColor, nameLabel, phoneLabel, emailLabel, gorgiasSupportTicketMessage,
    gorgiasSupportTicketSubject, postSubmissionButtonText, postSubmissionButtonColor, postSubmissionButtonBackground, tellingFormBorder, tellingFormBorderColor,
    calenderUrl, gorgiasTags, hookVideos, showSiteWidgetTitle, hookVideoFlyerTimer, showCustomerSupport, showDesignAdvice, customerSupportButtonHeading, customerSupportButtonDescription,
    designAdviceButtonHeading, designAdviceButtonDescription, mobileFlyerHeading, mobileFlyerDescription
  } = widgetData || {}

  const hookVideoLandscape = hookVideos?.filter(video => video.videoOrientation === VideoOrientation.Landscape)
  const hookVideoPortrait = hookVideos?.filter(video => video.videoOrientation === VideoOrientation.Portrait)

  const { url: logoUrl, id: logoId } = attachment || {}
  const siteWidgetLogo = logoUrl?.split('/upload')
  const updatedSiteWidgetLogo = siteWidgetLogo && [...siteWidgetLogo.slice(0, 1), '/upload/q_auto/', ...siteWidgetLogo.slice(1)]
  const updatedLogo = updatedSiteWidgetLogo?.join('')

  const methods = useForm<UpdateWidget>({
    shouldUnregister: false,

    defaultValues: {
      customPlayerSettings,
      headingColor: headingColor || palette.black,
      descriptionColor: descriptionColor || palette.black,
      idleBackgroundColor: idleBackgroundColor || palette.black,
      buttonColor: buttonColor || palette.white,
      buttonBackgroundColor: buttonBackgroundColor || palette.black,
      name: name || "",
      playVideoAfterSeconds: playVideoAfterSeconds || 0,
      heading: heading || "",
      description: description || "",
      tellingFormHeading: tellingFormHeading || "",
      tellingFormDescription: tellingFormDescription || "",
      helperTextDesign: helperTextDesign || "",
      helperTextSupport: helperTextSupport || "",
      postSubmissionHeading: postSubmissionHeading || "",
      postSubmissionDescription: postSubmissionDescription || "",
      nameLabel: nameLabel || "",
      phoneLabel: phoneLabel || "",
      emailLabel: emailLabel || "",
      gorgiasSupportTicketMessage: gorgiasSupportTicketMessage || "",
      gorgiasSupportTicketSubject: gorgiasSupportTicketSubject || "",
      postSubmissionButtonText: postSubmissionButtonText || "",
      postSubmissionButtonColor: postSubmissionButtonColor || palette.white,
      postSubmissionButtonBackground: postSubmissionButtonBackground || palette.black,
      tellingFormBorder,
      tellingFormBorderColor: tellingFormBorderColor || palette.black,
      calenderUrl: calenderUrl || '',
      gorgiasTags: gorgiasTags?.split(', ') || [],
      showSiteWidgetTitle: false,
      showVideoControls: false,
      hookVideoFlyerTimer: hookVideoFlyerTimer || 0,
      showDesignAdvice: showDesignAdvice,
      showCustomerSupport: showCustomerSupport,
      customerSupportButtonHeading: customerSupportButtonHeading,
      customerSupportButtonDescription: customerSupportButtonDescription,
      designAdviceButtonHeading: designAdviceButtonHeading,
      designAdviceButtonDescription: designAdviceButtonDescription,
      mobileFlyerHeading: mobileFlyerHeading,
      mobileFlyerDescription: mobileFlyerDescription
    }
  });

  const { control, setValue, handleSubmit, getValues, formState: { isDirty }, reset } = methods

  useWatch({ control, name: 'customPlayerSettings' })
  useWatch({ control, name: 'idleBackgroundColor' })

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    name && setValue('name', name)
    description && setValue('description', description)
    descriptionColor && setValue('descriptionColor', descriptionColor)
    tellingFormHeading && setValue('tellingFormHeading', tellingFormHeading)
    tellingFormDescription && setValue('tellingFormDescription', tellingFormDescription)
    helperTextSupport && setValue('helperTextSupport', helperTextSupport)
    helperTextDesign && setValue('helperTextDesign', helperTextDesign)
    backgroundColor && setValue('backgroundColor', backgroundColor)
    buttonColor && setValue('buttonColor', buttonColor)
    idleBackgroundColor && setValue('idleBackgroundColor', idleBackgroundColor)
    buttonBackgroundColor && setValue('buttonBackgroundColor', buttonBackgroundColor)
    postSubmissionHeading && setValue('postSubmissionHeading', postSubmissionHeading)
    postSubmissionDescription && setValue('postSubmissionDescription', postSubmissionDescription)
    nameLabel && setValue('nameLabel', nameLabel)
    emailLabel && setValue('emailLabel', emailLabel)
    phoneLabel && setValue('phoneLabel', phoneLabel)
    gorgiasSupportTicketMessage && setValue('gorgiasSupportTicketMessage', gorgiasSupportTicketMessage)
    gorgiasSupportTicketSubject && setValue('gorgiasSupportTicketSubject', gorgiasSupportTicketSubject)
    postSubmissionButtonText && setValue('postSubmissionButtonText', postSubmissionButtonText)
    postSubmissionButtonColor && setValue('postSubmissionButtonColor', postSubmissionButtonColor)
    postSubmissionButtonBackground && setValue('postSubmissionButtonBackground', postSubmissionButtonBackground)
    setValue('tellingFormBorder', tellingFormBorder)
    tellingFormBorderColor && setValue('tellingFormBorderColor', tellingFormBorderColor)
    setValue('customPlayerSettings', customPlayerSettings)
    calenderUrl && setValue('calenderUrl', calenderUrl)
    setValue('showSiteWidgetTitle', showSiteWidgetTitle)
    setValue('showVideoControls', showVideoControls)
    hookVideoFlyerTimer && setValue('hookVideoFlyerTimer', hookVideoFlyerTimer)
    showCustomerSupport && setValue('showCustomerSupport', showCustomerSupport)
    showDesignAdvice && setValue('showDesignAdvice', showDesignAdvice);
    customerSupportButtonHeading && setValue('customerSupportButtonHeading', customerSupportButtonHeading);
    customerSupportButtonDescription && setValue('customerSupportButtonDescription', customerSupportButtonDescription);
    designAdviceButtonHeading && setValue('designAdviceButtonHeading', designAdviceButtonHeading);
    designAdviceButtonDescription && setValue('designAdviceButtonDescription', designAdviceButtonDescription);
    mobileFlyerHeading && setValue('mobileFlyerHeading', mobileFlyerHeading);
    mobileFlyerDescription && setValue('mobileFlyerDescription', mobileFlyerDescription)

    if (gorgiasTags) {
      setValue("gorgiasTags", gorgiasTags.split(', '))
    }
    //eslint-disable-next-line
  }, [
    name, customPlayerSettings, playVideoAfterSeconds, showVideoControls, showSiteWidgetTitle,
    headingColor, description, descriptionColor, tellingFormHeading, tellingFormDescription, postSubmissionHeading, postSubmissionDescription,
    backgroundColor, buttonColor, idleBackgroundColor, nameLabel, emailLabel, phoneLabel, gorgiasSupportTicketMessage, gorgiasSupportTicketSubject,
    postSubmissionButtonText, postSubmissionButtonColor, postSubmissionButtonBackground, tellingFormBorder, tellingFormBorderColor, calenderUrl, gorgiasTags,
    hookVideoFlyerTimer, showCustomerSupport, showDesignAdvice, customerSupportButtonHeading, customerSupportButtonDescription,
    designAdviceButtonHeading, designAdviceButtonDescription, mobileFlyerHeading, mobileFlyerDescription
  ])

  type Anchor = 'bottom';
  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setToggleDrawerState(!toggleDrawerState);
  };

  const onSubmit = async (data: UpdateWidget) => {
    if (widgetId && brandId) {
      const { playVideoAfterSeconds: videoSeconds, gorgiasTags, hookVideoFlyerTimer: flyerTimerSeconds } = data

      await updateWidget({
        variables: {
          updateWidgetInput: {
            ...data,
            playVideoAfterSeconds: videoSeconds ? Number.parseFloat(videoSeconds?.toString()) : 0,
            hookVideoFlyerTimer: flyerTimerSeconds ? Number.parseFloat(flyerTimerSeconds?.toString()) : 0,
            gorgiasTags: gorgiasTags.join(', '),
            id: widgetId,
            brandId
          },
          widgetId
        }
      })
    }
  }

  const idleBackgroundValue = getValues('idleBackgroundColor')

  return (
    <>
      {widgetDataLoading ?
        <ThreeDotsLoader height="calc(100vh - 300px)" />
        :
        <FormProvider  {...methods}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography sx={{ marginTop: 1 }} variant="subtitle1">{SITE_WIDGET_SETTING}</Typography>

              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <BreadCrumb currentPage={SITE_WIDGET_SETTING} previousPage={WIDGET_SETTING} />
              </Box>

              <Grid container spacing={1.2} rowSpacing={0.5}>
                <Grid item lg={6} xs={12}>
                  <CustomAccordion expanded={true} label={GENERAL_SETTINGS} id="widgetSettings">
                    <CustomColorControl name="idleBackgroundColor" label="Idle background color" />

                    <Box marginTop='2rem'>
                      <CustomSwitcher label="Video Controls" defaultValue={!!showVideoControls} controllerName="showVideoControls" />
                    </Box>

                    <Box marginTop='2rem'>
                      <CustomSwitcher label="Site Widget Title" defaultValue={!!showSiteWidgetTitle} controllerName="showSiteWidgetTitle" />
                    </Box>

                    <Box marginTop='2rem'>
                      <Typography variant="caption">Logo</Typography>

                      <Box marginTop='1rem'>
                        <UploadDocument
                          maxSize={IMAGE_MAX_SIZE}
                          accept={ACCEPT_FOR_IMAGES}
                          description={DESCRIPTION_FOR_IMAGE}
                          entityType="widgets"
                          typeId={widgetId || ""}
                          attachmentType="logo"
                          attachmentId={logoId || undefined}
                          widgetData={widgetData}
                          setWidgetData={setWidgetData}
                        />
                      </Box>
                    </Box>
                  </CustomAccordion>

                  <CalendlyOverride />

                  <CustomAccordion expanded={true} label="Override Hook Video" subHeading='If you want to choose a specific hook video for this widget only,upload it here' id='overrideHookVideo'>
                    <Grid container spacing={2} rowSpacing={2}>
                      <Grid item md={7} xs={12}>
                        {hookVideoLandscape && hookVideoLandscape.length > 0 ?
                          <>
                            <Box sx={uploadHookVideoStyle}>
                              <Typography variant={mediumViewport ? 'subtitle1' : "subtitle2"}>Landscape Video</Typography>

                              <Button
                                color="secondary"
                                disabled={false}
                                startIcon={<AddIcon />}
                                sx={{ padding: '10px 6px', fontSize: '14px' }}
                                onClick={() => handleCreateVideoModalOpen('hookVideo', VideoOrientation.Landscape, hookVideoLandscape && hookVideoLandscape[0])}
                              >
                                Upload Video
                              </Button>
                            </Box>

                            <Box sx={hookVideoButtonWrapper} width='100%'>
                              {hookVideoLandscape && hookVideoLandscape.length > 0 &&
                                <VideoCardWrapper>
                                  <Box onClick={() => handleVideoData(hookVideoLandscape[0])}>
                                    <VideoLibraryActions />
                                  </Box>

                                  <VideoCard dispatch={brandDispatch} state={brandState} videoData={hookVideoLandscape[0]} />
                                </VideoCardWrapper>
                              }
                            </Box>
                          </>
                          :
                          <>
                            <Typography variant='subtitle1' mb={2}>Landscape Video</Typography>

                            <AddVideo height="200px" handleModalOpen={() => handleCreateVideoModalOpen('hookVideo', VideoOrientation.Landscape)} />
                          </>
                        }
                      </Grid>

                      <Grid item md={5} xs={12}>
                        {hookVideoPortrait && hookVideoPortrait.length > 0 ?
                          <>
                            <Box sx={uploadHookVideoStyle}>
                              <Typography variant={mediumViewport ? 'subtitle1' : "subtitle2"}>Portrait Video</Typography>

                              <Button
                                color="secondary"
                                disabled={false}
                                startIcon={<AddIcon />}
                                sx={{ padding: '10px 6px', fontSize: '14px' }}
                                onClick={() => handleCreateVideoModalOpen('hookVideo', VideoOrientation.Portrait, hookVideoPortrait && hookVideoPortrait[0])}
                              >
                                Upload Video
                              </Button>
                            </Box>

                            <Box sx={hookVideoButtonWrapper} width='100%'>
                              {hookVideoPortrait && hookVideoPortrait.length > 0 &&
                                <VideoCardWrapper>
                                  <Box onClick={() => handleVideoData(hookVideoPortrait[0])}>
                                    <VideoLibraryActions />
                                  </Box>

                                  <VideoCard dispatch={brandDispatch} state={brandState} videoData={hookVideoPortrait[0]} videoStyle={hookVideoStylePortrait} />
                                </VideoCardWrapper>
                              }
                            </Box>
                          </>
                          :
                          <>
                            <Typography variant='subtitle1' mb={2}>Portrait Video</Typography>

                            <AddVideo height="300px" handleModalOpen={() => handleCreateVideoModalOpen('hookVideo', VideoOrientation.Portrait)} />
                          </>
                        }
                      </Grid>
                    </Grid>
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label={CLIENT_TELLING_FORM} id="tellingFormSettings">
                    <TellingForm
                      headingLabel='Form Heading'
                      descriptionLabel='Form Description'
                      designHelperText='Design Advice Subheading'
                      supportHelperText='Customer Support Subheading'
                    />
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label={THANKYOU_SETTINGS} id="postFormSettings">
                    <PostForm />
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label="Button Settings" id="buttonSettings">
                    <ButtonFields />
                  </CustomAccordion>

                  <Collapse in={true} unmountOnExit mountOnEnter>
                    <CustomAccordion expanded={true} label="Flyer Settings" id="flyerSettings">
                      <FlyerForm />
                    </CustomAccordion>
                  </Collapse>

                  <Box sx={stickyBottom}>
                    <Button color="primary" variant='contained' type="submit" fullWidth
                      endIcon={loading && <CircularProgress size={20} color="inherit" />}
                      disabled={loading || !isDirty}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <AccordionWrapper margin='auto' position='sticky' top="145px">
                    <Box display='flex' alignItems='center' height='48px' position='relative'>
                      <Typography variant="subtitle1">Preview</Typography>

                      <Box sx={customIconLinkBox}>
                        <CustomIconLink widgetLink={siteWidget} />
                      </Box>
                    </Box>

                    <SiteWidgetPreviewComponent
                      idleBackgroundColor={idleBackgroundValue || ""}
                      logoUrl={updatedLogo || ""}
                    />
                  </AccordionWrapper>
                </Grid>
              </Grid>

              {mediumViewport &&
                <Box>
                  <Button sx={previewDrawer} onClick={() => toggleDrawer('bottom', true)}>
                    <Box sx={drawerButton}>
                      <Typography variant="subtitle2" sx={{ color: palette.white, textTransform: 'capitalize' }}>Preview</Typography>
                    </Box>
                  </Button>

                  <Drawer
                    anchor='bottom'
                    open={toggleDrawerState}
                    onClose={() => toggleDrawer('bottom', false)}
                  >
                    <Box
                      sx={{ width: '100%', padding: '1rem 3rem' }}
                      role="presentation"
                      onClick={() => toggleDrawer('bottom', false)}
                      onKeyDown={() => toggleDrawer('bottom', false)}
                    >
                      <Box display='flex' alignItems='center' height='48px'>
                        <Typography variant="subtitle1">Previews</Typography>
                      </Box>

                      <SiteWidgetPreviewComponent
                        idleBackgroundColor={idleBackgroundValue || ""}
                        logoUrl={logoUrl || ""}
                      />
                    </Box>
                  </Drawer>
                </Box>
              }
            </Box>
          </form>
        </FormProvider>
      }

      <CreateVideoModal
        isOpen={attachmentModal}
        handleClose={handleCreateVideoModalClose}
        currentVideo={currentVideo}
        entityType='widgets'
        refetchWidget={refetch}
        typeId={widgetId || ""}
        isLandscape={orientation === VideoOrientation.Landscape}
        videoType='hookVideo'
      />

      <EditModal
        isOpen={isEdit}
        dispatch={brandDispatch}
        state={brandState}
        refetchWidget={refetch}
        handleClose={handleEditClose}
        videoData={currentVideo}
      />
    </>
  )
}