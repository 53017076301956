import { Box } from '@mui/material'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { images } from '../../../../assets/images'
import { VideoOrientation } from '../../../../generated'
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { GalleryBox } from '../../../theme/styleComponents'
import { bannerImageIdlePreview, idleLeftSlide, idleRightSlide, idleSlideCentered } from '../../../theme/styleConstants'
import { GalleryImageBox } from '../../preview/gallery/GalleryImageBox'

export const VideoPreviewIdle: FC<ThumbnailUrlProps> = ({ thumbnailUrls, widgetData, checkVideoOrientation, bannerImageURL }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  const firstVideoIsLandscape = checkVideoOrientation && checkVideoOrientation[0] === VideoOrientation.Landscape
  const secondVideoIsLandscape = checkVideoOrientation && checkVideoOrientation[1] === VideoOrientation.Landscape
  const thirdVideoIsLandscape = checkVideoOrientation && checkVideoOrientation[2] === VideoOrientation.Landscape
  const { bannerImage } = widgetData || {}
  const { url: widgetBannerImageURL } = bannerImage || {}
  const { watch } = useFormContext()
  const backgroundColor = watch('backgroundColor')

  return (
    <Box padding='4px 4px 4px 6px' height="calc(100% - 12px)" display='flex'>
      <GalleryBox sx={{ backgroundColor: backgroundColor, margin: '2px 1px 0 0' }}>
        <Box display='flex' width='100%' overflow='hidden'>
          <Box sx={idleLeftSlide} >
            {secondImage &&
              <GalleryImageBox border height='100%' width='100%' isLandscape={secondVideoIsLandscape} imageUrl={secondImage || images.DUMMY} />
            }
          </Box>

          <Box sx={idleSlideCentered}>
            {firstImage &&
              <GalleryImageBox border height='100%' width='100%' isLandscape={firstVideoIsLandscape} imageUrl={firstImage || images.DUMMY} zIndex={10} videoIcon={true} />
            }
          </Box>

          <Box sx={idleRightSlide}>
            {thirdImage &&
              <GalleryImageBox border height='100%' width='100%' isLandscape={thirdVideoIsLandscape} imageUrl={thirdImage || images.DUMMY} />
            }
          </Box>
        </Box>

        <Box sx={bannerImageIdlePreview}>
          <LazyLoadImage
            src={bannerImageURL || widgetBannerImageURL || images.BANNER_IMAGE}
            style={{ height: '100%', objectFit: 'cover', maxWidth: '100%' }}
          />
          <Box component='img' />
        </Box>
      </GalleryBox>

    </Box>
  )
}
