import { Box, Tab, Tabs, Typography } from '@mui/material';
import { FC, SyntheticEvent, useState } from 'react';
import { images } from '../../../../assets/images';
import { KioskSettingPreviewProps } from '../../../interfaceTypes';
import { TvPreview } from '../../../theme/styleComponents';
import { tabsStyle, flexCenter, customIconLinkBox } from '../../../theme/styleConstants';
import { handleTabs } from '../../../utils';
import { TabPanel } from '../../common/TabPanel';
import { VideoPreviewFocused } from './VideoPreviewFocused';
import { VideoPreviewIdle } from './VideoPreviewIdle';
import { CustomIconLink } from '../../common/CustomIconLink';
import { useSearchParams } from 'react-router-dom';
import { BRAND_ID } from '../../../constants';

export const KioskSettingPreview: FC<KioskSettingPreviewProps> = ({ thumbnailUrls, videosToShow, widgetData, videoForFeatureImage, bannerImageURL }) => {
  const [preview, setPreview] = useState(0);
  const brandId = localStorage.getItem(BRAND_ID);
  const [searchParams] = useSearchParams();
  const widgetId = searchParams.get('widgetId');
  const kioskWidgetLink = `${process.env.REACT_APP_BASE_URL}/brand/${brandId}/kiosk/${widgetId}`

  const handlePreviewChange = (event: SyntheticEvent, newValue: number) => {
    setPreview(newValue);
  };

  const checkVideoOrientation = videosToShow?.map((videoOrientation) => videoOrientation.videoOrientation)

  return (
    <Box paddingTop='7px'>
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>Preview</Typography>

      <Box sx={tabsStyle} position='relative'>
        <Tabs value={preview} onChange={handlePreviewChange} aria-label="basic tabs example">
          <Tab sx={{ textTransform: 'capitalize' }} label="Idle" {...handleTabs(0)} />

          <Tab sx={{ textTransform: 'capitalize' }} label="Focused" {...handleTabs(1)} />
        </Tabs>

        <Box sx={customIconLinkBox}>
          <CustomIconLink widgetLink={kioskWidgetLink} />
        </Box>
      </Box>

      <TabPanel value={preview} index={0}>
        <Box paddingY={2}>
          <TvPreview>
            {videosToShow && videosToShow.length === 0 ?
              <Box sx={flexCenter} flexDirection="column" padding='20px' marginTop='1rem'>
                <Box component='img' src={images.DUMMY_PREVIEW} width="100%" height='150px' />
                <Typography sx={{ marginTop: '16px' }}>Please select video to generate preview</Typography>
              </Box>
              :
              <VideoPreviewIdle
                videoForFeatureImage={videoForFeatureImage}
                thumbnailUrls={thumbnailUrls}
                bannerImageURL={bannerImageURL}
                widgetData={widgetData}
                checkVideoOrientation={checkVideoOrientation}
              />
            }
          </TvPreview>
        </Box>
      </TabPanel>

      <TabPanel value={preview} index={1}>
        <Box paddingY={2}>
          <TvPreview>
            {videosToShow && videosToShow.length === 0 ?
              <Box sx={flexCenter} flexDirection="column" padding='20px' marginTop='1rem'>
                <Box component='img' src={images.DUMMY_PREVIEW} width="100%" height='150px' />
                <Typography sx={{ marginTop: '16px' }}>Please select video to generate preview</Typography>
              </Box>
              :
              <VideoPreviewFocused
                videoForFeatureImage={videoForFeatureImage}
                thumbnailUrls={thumbnailUrls}
                bannerImageURL={bannerImageURL}
                videosToShow={videosToShow}
                widgetData={widgetData}
                checkVideoOrientation={checkVideoOrientation}
              />
            }
          </TvPreview>
        </Box>
      </TabPanel>
    </Box>
  )
}
