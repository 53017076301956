import { Box, Slide, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react'
import { images } from '../../../assets/images';
import { flexCenter } from '../../theme/styleConstants';
import { MultipleKeyPressProps } from '../../interfaceTypes';
import { formatTime } from '../../utils';
import { SocketContext } from '../../context/SocketContext';

export const MultipleKeyPress: FC<MultipleKeyPressProps> = ({ pauseTimer, state }) => {
  const { slideAnimation } = state
  const { mutateKioskState } = useContext(SocketContext)
  const [timeLeft, setTimeLeft] = useState(pauseTimer || 1);

  useEffect(() => {
    mutateKioskState(false, false, false, true)

    return () => mutateKioskState(false, false, false, false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(prevTime => prevTime - 1);
      }
    }, 1000);

    timeLeft === 0 && window.location.reload();

    return () => {
      clearInterval(timer)
    }
  }, [timeLeft, pauseTimer]);

  return (
    <Slide direction='left' in={slideAnimation} mountOnEnter unmountOnExit>
      <Box sx={{ ...flexCenter, flexDirection: 'column', height: '100vh' }}>
        <Box component='img' src={images.TOO_MANY_ATTEMPT} mb={4} />

        <Typography maxWidth='400px' textAlign='center' variant='h4' mb={2}>Too many key presses detected. Please avoid doing that. Putting the KIOSK in pause state.</Typography>

        <Typography variant='h4'>KIOSK will resume in {formatTime(timeLeft)}</Typography>
      </Box>
    </Slide>
  )
}
