
import { FC, MouseEvent, useEffect, useState } from "react";
import { FullAttachmentDescription, VideoSettingProps } from "../../../interfaceTypes";
import { Box, Button, DialogActions, Popover, Typography } from "@mui/material";
import { PlayerIcon } from "../../../../assets/svgs";
import ReactPlayer from "react-player";
import { CustomModal } from "../../common/CustomModal";
import palette from "../../../theme/palette";

export const FullDetailVideo: FC<FullAttachmentDescription> = ({ isOpen, handleClose, videoData }) => {
  const [anchorElPopover, setAnchorElPopover] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };

  const popOverOpen = Boolean(anchorElPopover);
  const { black, white } = palette || {}

  const [videoSettings, setVideoSettings] = useState<VideoSettingProps>
    ({ url: "", played: 0, loaded: 0, pip: false, playing: false, playbackRate: 1 });

  const { url, pip, playing } = videoSettings

  const handlePlay = () => {
    setVideoSettings({ ...videoSettings, playing: true })
  }

  const onProgressVideo = (event: any) => {
    if (event.playedSeconds > 6) {
      setVideoSettings({ ...videoSettings })
    }
  }

  const loadUrl = (url: string) => {
    setVideoSettings({
      url, played: 0, loaded: 0, pip: false, playing: false
    })
  }

  const { url: videoUrl, thumbnail, title, description } = videoData || {}
  const { url: thumbnailUrl } = thumbnail || {}
  const thumbnailUrlArray = thumbnailUrl?.split('/upload')
  const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
  const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')

  useEffect(() => {
    loadUrl(videoUrl || "")
    // eslint-disable-next-line 
  }, [videoData])

  return (
    <CustomModal maxWidth="700px" isOpen={isOpen} handleClose={handleClose} title={"Video Detail"}>
      <Box pb={2} pt={2} height={300} position="relative">
        <ReactPlayer
          className='react-player'
          width='100%'
          height='100%'
          url={url}
          pip={pip}
          controls={true}
          playing={playing}
          muted={true}
          onClickPreview={handlePlay}
          playIcon={<PlayerIcon />}
          light={updatedThumbnailUrl || ""}
          onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
          onProgress={onProgressVideo}
          playsinline
        />
      </Box>

      <Typography
        gutterBottom
        aria-haspopup="true"
        aria-owns={popOverOpen ? 'mouse-over-popover' : undefined}
        variant="subtitle1"
        component="div"
        noWrap
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {title}
      </Typography>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none', zIndex: 99999 }}
        open={popOverOpen}
        anchorEl={anchorElPopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ maxWidth: '350px', backgroundColor: black, color: white, p: 1 }} variant="h3">{title || 'N/A'}</Typography>
      </Popover>


      <Typography variant="body1" color="text.secondary">
        {description}
      </Typography>

      <DialogActions sx={{ paddingTop: 3 }}>
        <Button onClick={handleClose} variant="outlined">Close</Button>
      </DialogActions>
    </CustomModal>
  )
}