import { DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, CircularProgress, Collapse, Grid, IconButton, Typography } from '@mui/material'
import { FC, Reducer, useContext, useEffect, useReducer, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSearchParams } from 'react-router-dom'
import { Attachment, AttachmentType, VideoGridType, VideoLayout, VideoOrientation, Widget, useGetWidgetLazyQuery, useUpdateWidgetMutation } from '../../../../generated'
import { ACCEPT_FOR_IMAGES, BRAND_ID, DESCRIPTION_FOR_IMAGE, GRAPHQL_QUERY_POLICY, IMAGE_MAX_SIZE, KIOSK_WIDGET, WIDGET_SETTING } from '../../../constants'
import { AppContext } from '../../../context'
import { ActionType } from '../../../context/AppContextReducer'
import { AttachmentTypeFE, BrandDataProp, BrandState, UpdateWidget } from '../../../interfaceTypes'
import palette from '../../../theme/palette'
import { AccordionWrapper, PreviewBox } from '../../../theme/styleComponents'
import { stickyBottom } from '../../../theme/styleConstants'
import { firstLetterUppercase } from '../../../utils'
import { updateWidgetSchema } from '../../../validationSchema'
import { Alert } from '../../common/Alert'
import { BreadCrumb } from '../../common/BreadCrumb'
import { CustomAccordion } from '../../common/CustomAccordion'
import { CustomController } from '../../common/CustomController'
import { NoDataFound } from '../../common/NoDataFound'
import { ThreeDotsLoader } from '../../common/ThreeDotsLoader'
import { UploadDocument } from '../../common/UploadDocument'
import Tags from '../../taskTemplate/Tags'
import { BrandAction, BrandActionType, brandInitialState, brandReducer } from '../reducer/brandReducer'
import { CustomColorControl } from '../widgetCommon/CustomColorControl'
import { AddVideoForKiosk } from './AddVideoForKiosk'
import { EditVideoForKiosk } from './EditVideoForKiosk'
import { KioskSettingPreview } from './KioskSettingPreview'
import { SampleFileContent } from './SampleFileContent'

export const KioskWidgetSettings: FC<BrandDataProp> = ({ brandData }) => {
  const { isOpen, dispatch, selectedBrand, isEdit } = useContext(AppContext)
  const [items, setItems] = useState<Attachment[]>([]);
  const [videoForFeatureImage, setVideoForFeatureImage] = useState<Attachment>()
  const [widgetData, setWidgetData] = useState<Widget>();
  const { primary: { main: primaryColor } } = palette
  const [brandState, brandDispatch] = useReducer<Reducer<BrandState, BrandAction>>(brandReducer, brandInitialState);
  const { videoIds } = brandState
  const [searchParams] = useSearchParams()
  const widgetId = searchParams.get('widgetId')
  const { bannerImageEdit, fallbackImageEdit, waitingImageEdit } = brandState
  const { attachments: brandAttachments } = selectedBrand || {}

  const [getWidget, { loading: widgetLoading, refetch }] = useGetWidgetLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() { },

    onCompleted(data) {
      const { getWidget } = data || {}

      if (getWidget) {
        setWidgetData(getWidget)
      }
    }
  });

  const [updateWidget, { loading }] = useUpdateWidgetMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ''))
    },

    onCompleted(data) {
      if (data) {
        const { updateWidget } = data
        if (updateWidget) {
          reset({}, { keepValues: true, keepIsValid: true });
          setWidgetData(updateWidget)

          return Alert.success("Widget updated successfully")
        }
      }
    }
  })

  useEffect(() => {
    if (!widgetData && brandId && !!widgetId) {
      getWidget({
        variables: {
          getWidgetInput: {
            id: widgetId,
            brandId
          },
          widgetId
        }
      })
    }
    //eslint-disable-next-line
  }, [widgetData, widgetId])

  const handleVideoModalOpen = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: !isOpen })
  }

  const handleVideoEditOpen = (video: Attachment) => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: !isEdit })
    setVideoForFeatureImage(video)
  }

  const { name, isPortrait, bannerImage, waitingImage, fallBackImage, backgroundColor, videos, focusStateTimer, waitingStateTimer, fallbackStateTimer,
    gorgiasTags, wherebyDisplayName, pauseTimer
  } = widgetData || {}
  const { url: bannerImageURL, id: bannerImageId } = bannerImage || {}
  const { url: waitingImageURL, id: waitingImageId } = waitingImage || {}
  const { url: fallbackImageURL, id: fallbackImageId } = fallBackImage || {}
  const videoIdsArray = videos?.map((video, index) => ({ id: video.id, sequenceNo: index + 1, featureImageId: video?.featureImage?.id, bannerImageId: video.bannerImage?.id }))

  const handleBannerImageEdit = () => {
    brandDispatch({ type: BrandActionType.SET_BANNER_IMAGE_EDIT, bannerImageEdit: !bannerImageEdit })
  }

  const handleWaitingImageEdit = () => {
    brandDispatch({ type: BrandActionType.SET_WAITING_IMAGE_EDIT, waitingImageEdit: !waitingImageEdit })
  }

  const handleFallbackImageEdit = () => {
    brandDispatch({ type: BrandActionType.SET_FALLBACK_IMAGE_EDIT, fallbackImageEdit: !fallbackImageEdit })
  }

  const methods = useForm<UpdateWidget>({
    shouldUnregister: false,
    resolver: yupResolver(updateWidgetSchema),

    defaultValues: {
      name: name || "",
      wherebyDisplayName: wherebyDisplayName || '',
      backgroundColor: backgroundColor || "#000000",
      isPortrait,
      focusStateTimer: focusStateTimer || '30',
      waitingStateTimer: waitingStateTimer || '30',
      fallbackStateTimer: fallbackStateTimer || '30',
      gorgiasTags: gorgiasTags?.split(', ') || [],
      pauseTimer: pauseTimer || 60
    }
  })

  const brandId = localStorage.getItem(BRAND_ID);
  const { attachments } = brandData || {}
  const attachmentsToShow = attachments?.filter(video => video.type !== AttachmentType.HookVideo)

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setItems(attachmentsToShow || [])
    const itemIndex = items?.findIndex((item) => item.id === active?.id);

    if (active.id !== over?.id) {
      setItems((prevItems) => {
        const oldIndex = itemIndex;
        const newIndex = prevItems?.findIndex((item) => item.id === over?.id);

        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }

    brandDispatch({ type: BrandActionType.SET_IS_DRAGGING, isDragging: false })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    brandDispatch({ type: BrandActionType.SET_VIDEO_IDS, videoIds: videoIdsArray || [] })
    // eslint-disable-next-line
  }, [videos])

  useEffect(() => {
    name && setValue('name', name)
    wherebyDisplayName && setValue('wherebyDisplayName', wherebyDisplayName)
    backgroundColor && setValue('backgroundColor', backgroundColor)
    isPortrait && setValue('isPortrait', isPortrait)
    setValue('videoGridType', VideoGridType.Kiosk)
    setValue('videoLayout', VideoLayout.InfiniteVideos)
    videoIds && setValue('videos', videoIds)
    focusStateTimer && setValue('focusStateTimer', focusStateTimer)
    waitingStateTimer && setValue('waitingStateTimer', waitingStateTimer)
    fallbackStateTimer && setValue('fallbackStateTimer', fallbackStateTimer)
    pauseTimer && setValue('pauseTimer', pauseTimer)
    if (gorgiasTags) {
      setValue("gorgiasTags", gorgiasTags.split(', '))
    }
    // eslint-disable-next-line
  }, [name, backgroundColor, isPortrait, videoIds, gorgiasTags, wherebyDisplayName, pauseTimer])

  useEffect(() => {
    const attachmentsWithoutHookVideo = attachments?.filter(video => video.type !== AttachmentType.HookVideo)
    const remainingVideos = attachmentsWithoutHookVideo?.filter((video) => !videos?.some(item => item.id === video.id)) as Attachment[];
    const attachmentsToShow = videos ? [...videos, ...remainingVideos] : remainingVideos

    items?.length === 0 && setItems(attachmentsToShow || [])
    // eslint-disable-next-line
  }, [attachments, videos])

  useEffect(() => {
    attachments && attachments?.length > 0 && setItems(attachments)
  }, [attachments])

  useEffect(() => {
    !!videos?.length && setVideoForFeatureImage(videos[0])
  }, [videos])

  const { setValue, handleSubmit, watch, formState: { isDirty }, reset } = methods
  const formValues = watch()

  const onSubmit = async (data: UpdateWidget) => {
    if (widgetId && brandId) {
      const { playVideoAfterSeconds: videoSeconds, pauseTimer: pauseTimerSeconds, gorgiasTags } = data

      await updateWidget({
        variables: {
          updateWidgetInput: {
            ...data,
            gorgiasTags: gorgiasTags.join(', '),
            playVideoAfterSeconds: videoSeconds ? Number.parseFloat(videoSeconds?.toString()) : 0,
            pauseTimer: pauseTimerSeconds ? Number.parseFloat(pauseTimerSeconds?.toString()) : 0,
            videos: videoIds,
            id: widgetId,
            brandId,
          },
          widgetId
        }
      })
    }
  }

  const checkVideos = brandAttachments?.length === 0
  const videosToShow = items.filter(video => video.type !== AttachmentType.HookVideo)

  if (widgetLoading) {
    return <ThreeDotsLoader height="calc(100vh - 300px)" />
  }

  const firstBannerImageURL = videos && (videos[0]?.bannerImage?.url || "")
  const thumbnailUrls = videos?.slice(0, 5)?.map(video => video.thumbnail?.url || "")
  const isButtonDisabled = loading || videos?.length === 0

  const uploadDocument = (attachmentType: AttachmentTypeFE, attachmentId: string | undefined) => (
    <UploadDocument
      maxSize={IMAGE_MAX_SIZE}
      isKiosk
      recommendedSize={attachmentType === "bannerImage" ? "Recommended size (640px * 72px)" : "Recommended size (300px * 600px)"}
      accept={ACCEPT_FOR_IMAGES}
      description={DESCRIPTION_FOR_IMAGE}
      entityType="widgets"
      typeId={widgetId || ''}
      attachmentType={attachmentType}
      isLandscape
      attachmentId={attachmentId}
      refetchWidget={refetch}
    />
  )

  return (
    <Box>
      {checkVideos ?
        <NoDataFound height='calc(100vh - 285px)' description="Please select videos" button={true} />
        :
        <>
          <Typography variant="subtitle1" sx={{ marginTop: 2 }}>{KIOSK_WIDGET}</Typography>

          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <BreadCrumb currentPage={KIOSK_WIDGET} previousPage={WIDGET_SETTING} />
          </Box>

          <FormProvider  {...methods}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1.2} rowSpacing={0.5}>
                <Grid item lg={6} xs={12}>
                  <CustomAccordion expanded={true} label='Block settings' id='blockSettings'>
                    <CustomController
                      controllerLabel='Enter Block Name'
                      controllerName='name'
                    />

                    <CustomController
                      controllerLabel='Whereby display name'
                      controllerName='wherebyDisplayName'
                    />

                    <Box mt={2}>
                      <Typography variant="subtitle1">Selected Videos</Typography>
                      <Typography variant="h3" mb={3} sx={{ opacity: '0.8' }}>You can enhance videos by adding a feature and banner Image to it.</Typography>

                      <Box maxHeight='336px' overflow='auto'>
                        {videos?.map((video) => {
                          const { title, thumbnail, videoOrientation, id } = video || {}
                          const { url: thumbnailUrl } = thumbnail || {}
                          const isPortrait = videoOrientation === VideoOrientation.Portrait

                          return (
                            <Box display='flex' justifyContent='flex-start' gap='10px' mb={1} key={id}>
                              <Box border={`1px solid ${palette.lightBorderColorTwo}`} minWidth='153px' height='85px' sx={{ backgroundColor: isPortrait ? palette.black : palette.white }}>
                                <LazyLoadImage
                                  src={thumbnailUrl || ''}
                                  style={{
                                    width: isPortrait ? "47.81px" : "100%", margin: '0 auto', height: '100%', display: 'block', objectFit: 'cover'
                                  }}
                                />

                                <Box component='img' />
                              </Box>

                              <Box>
                                <Typography mb={.5}>{title}</Typography>

                                <Button sx={{ paddingX: '0' }} onClick={() => handleVideoEditOpen(video)} variant="text" color="primary">Enhance</Button>
                              </Box>
                            </Box>
                          );
                        })}

                      </Box>
                      <Button onClick={handleVideoModalOpen} fullWidth sx={{ border: `1px dashed ${primaryColor}` }} startIcon={<AddIcon />}>Add video</Button>
                    </Box>
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label='Gorgias Tags' id='gorgiasTags'>
                    <Tags controllerName='gorgiasTags' />
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label='Video background color' id='videoBackgroundColor'>
                    <CustomColorControl name="backgroundColor" label="Background color" />
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label='State Timers' id='stateTimer'>
                    <Box position='relative'>
                      <CustomController
                        controllerLabel='Focus state timer'
                        controllerName='focusStateTimer'
                      />

                      <Box position='absolute' top='1.8rem' right='2.1rem'>
                        <Typography variant="h3" fontWeight='600'>(seconds)</Typography>
                      </Box>
                    </Box>

                    <Box position='relative'>
                      <CustomController
                        controllerLabel='Waiting state timer'
                        controllerName='waitingStateTimer'
                      />

                      <Box position='absolute' top='1.8rem' right='2.1rem'>
                        <Typography variant="h3" fontWeight='600'>(seconds)</Typography>
                      </Box>
                    </Box>

                    <Box position='relative'>
                      <CustomController
                        controllerLabel='fallback state timer'
                        controllerName='fallbackStateTimer'
                      />

                      <Box position='absolute' top='1.8rem' right='2.1rem'>
                        <Typography variant="h3" fontWeight='600'>(seconds)</Typography>
                      </Box>
                    </Box>

                    <Box position='relative'>
                      <CustomController
                        controllerLabel='Pause timer'
                        controllerName='pauseTimer'
                      />

                      <Box position='absolute' top='1.8rem' right='2.1rem'>
                        <Typography variant="h3" fontWeight='600'>(seconds)</Typography>
                      </Box>
                    </Box>
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label='Banner image' id='bannerImage'>
                    <Box mt={2}>
                      {bannerImageURL &&
                        <Box display='flex' justifyContent='space-between' alignItems="center">
                          <Typography variant="subtitle1">Banner Image</Typography>

                          <IconButton color="primary" size="large" onClick={handleBannerImageEdit}>
                            {bannerImageEdit ? <ArrowBackIcon /> : <EditIcon />}
                          </IconButton>
                        </Box>
                      }

                      <Collapse in={bannerImageEdit}>
                        <Box pt={1}>
                          {uploadDocument('bannerImage', bannerImageId)}
                        </Box>

                        <Box pt={1}>
                          <PreviewBox>
                            <LazyLoadImage
                              src={bannerImageURL || ""}
                              style={{ maxWidth: "400px" }}
                            />
                            <Box component='img' />
                          </PreviewBox>
                        </Box>
                      </Collapse>

                      <Collapse in={!bannerImageEdit}>
                        {!bannerImageURL ?
                          <Box pt={1}>
                            {uploadDocument('bannerImage', bannerImageId)}
                          </Box>
                          :
                          <Box pt={1}>
                            <PreviewBox>
                              <LazyLoadImage
                                src={bannerImageURL || ""}
                                style={{ maxWidth: "300px" }}
                              />
                              <Box component='img' />
                            </PreviewBox>
                          </Box>
                        }
                      </Collapse>

                      <SampleFileContent attachmentType={AttachmentType.BannerImage} description='Here’s the sample file for banner image.' />
                    </Box>
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label='No operator available state' id='noOperatorAvailableState'>
                    <Box mt={2}>
                      {fallbackImageURL &&
                        <Box display='flex' justifyContent='space-between' alignItems="center">
                          <Typography variant="subtitle1">Fallback Image</Typography>

                          <IconButton color="primary" size="large" onClick={handleFallbackImageEdit}>
                            {fallbackImageEdit ? <ArrowBackIcon /> : <EditIcon />}
                          </IconButton>
                        </Box>
                      }

                      <Collapse in={fallbackImageEdit}>
                        <Box pt={1}>
                          {uploadDocument('fallbackImage', fallbackImageId)}
                        </Box>

                        <Box pt={1}>
                          <PreviewBox>
                            <LazyLoadImage
                              src={fallbackImageURL || ""}
                              style={{ maxWidth: "400px" }}
                            />
                            <Box component='img' />
                          </PreviewBox>
                        </Box>
                      </Collapse>

                      <Collapse in={!fallbackImageEdit}>
                        {!fallbackImageURL ?
                          <Box pt={1}>
                            {uploadDocument('fallbackImage', fallbackImageId)}
                          </Box>
                          :
                          <Box pt={1}>
                            <PreviewBox>
                              <LazyLoadImage
                                src={fallbackImageURL || ""}
                                style={{ maxWidth: "300px" }}
                              />
                              <Box component='img' />
                            </PreviewBox>
                          </Box>
                        }
                      </Collapse>

                      <SampleFileContent attachmentType={AttachmentType.FallBackImage} description='Here’s the sample file for fallBack image.' />
                    </Box>
                  </CustomAccordion>

                  <CustomAccordion expanded={true} label='Waiting image' id='waitingImage'>
                    <Box mt={2}>
                      {waitingImageURL &&
                        <Box display='flex' justifyContent='space-between' alignItems="center">
                          <Typography variant="subtitle1">Waiting Image</Typography>

                          <IconButton color="primary" size="large" onClick={handleWaitingImageEdit}>
                            {waitingImageEdit ? <ArrowBackIcon /> : <EditIcon />}
                          </IconButton>
                        </Box>
                      }

                      <Collapse in={waitingImageEdit}>
                        <Box pt={1}>
                          {uploadDocument('waitingImage', waitingImageId)}
                        </Box>

                        <Box pt={1}>
                          <PreviewBox>
                            <LazyLoadImage
                              src={waitingImageURL || ""}
                              style={{ maxWidth: "400px" }}
                            />
                            <Box component='img' />
                          </PreviewBox>
                        </Box>
                      </Collapse>

                      <Collapse in={!waitingImageEdit}>
                        {!waitingImageURL ?
                          <Box pt={1}>
                            {uploadDocument('waitingImage', waitingImageId)}
                          </Box>
                          :
                          <Box pt={1}>
                            <PreviewBox>
                              <LazyLoadImage
                                src={waitingImageURL || ""}
                                style={{ maxWidth: "300px" }}
                              />
                              <Box component='img' />
                            </PreviewBox>
                          </Box>
                        }
                      </Collapse>

                      <SampleFileContent attachmentType={AttachmentType.WaitingImage} description='Here’s the sample file for waiting image.' />
                    </Box>
                  </CustomAccordion>

                  <Box sx={stickyBottom}>
                    <Button color="primary" variant='contained' type="submit" fullWidth
                      endIcon={loading && <CircularProgress size={20} color="inherit" />}
                      disabled={isButtonDisabled || !isDirty}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <AccordionWrapper position='sticky' top="145px">
                    <KioskSettingPreview
                      videoForFeatureImage={videoForFeatureImage}
                      thumbnailUrls={thumbnailUrls}
                      bannerImageURL={firstBannerImageURL}
                      videosToShow={videos}
                      widgetData={widgetData}
                    />
                  </AccordionWrapper>
                </Grid>
              </Grid>

              <AddVideoForKiosk
                brandAttachments={videosToShow}
                videoIds={videoIds}
                formValues={formValues}
                widgetId={widgetId || undefined}
                updateWidget={updateWidget}
                brandState={brandState}
                videos={videos}
                gridMd={3}
                brandDispatch={brandDispatch}
                videosToShow={5000}
                handleDragEnd={handleDragEnd}
                setItems={setItems}
              />

              <EditVideoForKiosk
                videoForFeatureImage={videoForFeatureImage}
                setVideoForFeatureImage={setVideoForFeatureImage}
                widgetId={widgetId}
                refetch={refetch}
                widgetData={widgetData}
              />
            </form>
          </FormProvider>
        </>
      }
    </Box>
  )
}
