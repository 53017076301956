import { FC, useContext } from 'react';
import { AppContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { CustomModal } from '../common/CustomModal';
import { useRemoveBrandMutation } from '../../../generated';
import { DeleteBrandProps } from '../../interfaceTypes';
import { Box, Button, CircularProgress, DialogActions, Typography } from '@mui/material';
import { Alert } from '../common/Alert';
import { BRAND_DELETE_SUCCESSFULLY } from '../../constants';

export const DeleteBrand: FC<DeleteBrandProps> = ({ brand, brandsData, refetch }) => {
  const { id, name: brandName } = brand || {}
  const { isDeleteOpen, dispatch } = useContext(AppContext)

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
  };

  const [removeBrand, { loading }] = useRemoveBrandMutation({
    onError({ message }) {
      handleClose()
      return Alert.error(message)
    },

    onCompleted(data) {
      if (data) {
        const { removeBrand } = data

        if (removeBrand) {
          const updatedBrands = brandsData?.filter((brand) => brand.id !== id)

          dispatch({ type: ActionType.SET_BRANDS_DATA, brandsData: updatedBrands })
          handleClose()
          refetch && refetch()
          return Alert.success(BRAND_DELETE_SUCCESSFULLY)
        }

      }
    }
  })

  const deleteBrand = async () => {
    if (id) {
      await removeBrand({
        variables: {
          brandId: id
        }
      })
    }
  }

  return (
    <CustomModal maxWidth='700px' isOpen={isDeleteOpen} handleClose={handleClose} title='Delete Brand' infoIcon>
      <Box>
        <Typography variant="h4" marginTop={2} marginBottom={3} paddingLeft={0.5}>Are you sure you want to delete this brand having name {brandName || ''}?</Typography>
      </Box>

      <DialogActions sx={{ paddingRight: '0' }}>
        <Button sx={{ marginLeft: '10px' }} onClick={handleClose}>Cancel</Button>

        <Button variant="contained" onClick={deleteBrand} color="error"
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          Delete
        </Button>
      </DialogActions>
    </CustomModal>
  )
}
