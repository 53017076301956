import { Box, useMediaQuery } from '@mui/material';
import { FC } from 'react'
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { GalleryBox } from '../../../theme/styleComponents'
import { PortraitGalleryImageBox } from './PortraitGalleryImageBox';


export const PortraitGalleryVideosMobile: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const mediumViewport = useMediaQuery('(max-width:575px)');
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  return (

    <Box paddingY={1} minHeight="200px" display='flex'>
      <GalleryBox overflow='hidden' pb={4}>
        <PortraitGalleryImageBox width='250.92px' height={mediumViewport ? '250.56px' : '444.44px'} marginLeft='-190px' scale='0.9' imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />

        <PortraitGalleryImageBox width={mediumViewport ? '146.92px' : '271.92px'} height={mediumViewport ? '259.56px' : '481.78px'} imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} zIndex={9} videoIcon={true} />

        <PortraitGalleryImageBox width='250.92px' height={mediumViewport ? '250.56px' : '444.44px'} marginRight='-190px' scale='0.9' imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} videoIcon={true} />
      </GalleryBox>
    </Box>
  )
}
