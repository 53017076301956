import { FC, useContext } from 'react'
import { Button, CircularProgress, DialogActions, Typography } from '@mui/material'

import palette from '../../theme/palette'
import { RemoveButton } from '../../theme/styleComponents'
import { RemoveTaskProps } from '../../interfaceTypes'
import { useRemoveTaskTemplateMutation } from '../../../generated';
import { firstLetterUppercase } from '../../utils';
import { Alert } from '../common/Alert';
import { CustomModal } from '../common/CustomModal';
import { TaskActionType } from '../../context/TaskContextReducer'
import { AppContext } from '../../context'
import { ActionType } from '../../context/AppContextReducer'

export const TaskDeleteTemplate: FC<RemoveTaskProps> = ({ task, tasksData, taskDispatch }) => {
  const { name, id: taskId } = task || {}
  const { dispatch, isDeleteOpen } = useContext(AppContext)
  const [removeTaskTemplate, { loading }] = useRemoveTaskTemplateMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ""))
    },

    onCompleted(data) {
      const { removeTaskTemplate } = data || {}

      if (removeTaskTemplate) {
        const updatedTasks = tasksData?.filter((user) => user.id !== taskId)
        taskDispatch && taskDispatch({ type: TaskActionType.SET_TASKS_DATA, tasksData: updatedTasks || [] });

        Alert.success("Task deleted successfully.");
        dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
      }
    },
  });

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
  }

  const onSubmit = async () => {
    await removeTaskTemplate({
      variables: {
        id: taskId || "",
      },
    });
  }

  return (
    <CustomModal title='Remove Task' isOpen={isDeleteOpen} handleClose={handleClose} infoIcon maxWidth='700px'>
      <Typography variant="h4" marginBottom={3} paddingLeft={0.5}>Are you sure you want to delete this Task having name: {name}?</Typography>
      <DialogActions sx={{ paddingY: '0' }}>
        <Button sx={{ border: `1px solid ${palette.light}` }} color="inherit" onClick={handleClose}>Close</Button>

        <RemoveButton onClick={onSubmit} variant="contained" color="error" disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          Remove Task
        </RemoveButton>
      </DialogActions>
    </CustomModal>
  )
}
