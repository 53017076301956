import { Box } from '@mui/material'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { images } from '../../../../assets/images'
import { VideoOrientation } from '../../../../generated'
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import palette from '../../../theme/palette'
import { bannerImageFocusedPreview, featureImageStyle } from '../../../theme/styleConstants'
import { GalleryImageBox } from '../../preview/gallery/GalleryImageBox'

export const VideoPreviewFocused: FC<ThumbnailUrlProps> = ({ thumbnailUrls, videosToShow, widgetData, checkVideoOrientation, bannerImageURL }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  const firstVideo = videosToShow && videosToShow[0]
  const firstVideoIsLandscape = checkVideoOrientation && checkVideoOrientation[0] === VideoOrientation.Landscape
  const secondVideoIsLandscape = checkVideoOrientation && checkVideoOrientation[1] === VideoOrientation.Landscape
  const thirdVideoIsLandscape = checkVideoOrientation && checkVideoOrientation[2] === VideoOrientation.Landscape
  const { featureImage } = firstVideo || {}
  const { url: featureImageURL } = featureImage || {}
  const { bannerImage } = widgetData || {}
  const { url: WidgetBannerImageURL } = bannerImage || {}
  const { white, black } = palette || {}
  const { watch } = useFormContext()
  const backgroundColor = watch('backgroundColor')

  return (
    <Box height="calc(100% - 22px)" display='flex'>
      <Box width='calc(70% - 10px)' height='100%' position='relative' sx={{ margin: '6px 0 0 6px', backgroundColor: backgroundColor }}>
        <Box width="100%" height='100%' overflow='hidden'>
          <Box width="70%" margin='auto' height='25%' paddingBottom='20px'>
            {secondImage &&
              <GalleryImageBox border height='100%' isLandscape={secondVideoIsLandscape} imageUrl={secondImage || images.DUMMY} backgroundColor={secondVideoIsLandscape ? white : black} />
            }
          </Box>
          <Box width="70%" margin='auto' height='50%' sx={{ transform: 'scale(1.2)' }}>
            <GalleryImageBox height='100%' border isLandscape={firstVideoIsLandscape} imageUrl={firstImage || images.DUMMY} zIndex={10} videoIcon={true} backgroundColor={firstVideoIsLandscape ? white : black} />
          </Box>

          <Box width="70%" margin='auto' height='25%' paddingTop='20px'>
            {thirdImage &&
              <GalleryImageBox border height='100%' isLandscape={thirdVideoIsLandscape} imageUrl={thirdImage || images.DUMMY} backgroundColor={thirdVideoIsLandscape ? white : black} />
            }
          </Box>

          <Box sx={bannerImageFocusedPreview}>
            <LazyLoadImage
              src={bannerImageURL || WidgetBannerImageURL || images.BANNER_IMAGE}
              style={{ height: '100%', objectFit: 'cover' }}
            />
            <Box component='img' />
          </Box>
        </Box>

      </Box>

      <Box sx={{ ...featureImageStyle, backgroundColor: backgroundColor }}>
        <GalleryImageBox width='100%' margin='20px 0' featureImage={true} height={'auto'} isLandscape={true} imageUrl={featureImageURL || images.FEATURE_IMAGE} backgroundColor={palette.white} />
      </Box>
    </Box>
  )
}
