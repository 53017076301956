import { FC } from 'react'
import { CustomController } from '../../common/CustomController'
import { CustomColorControl } from './CustomColorControl'

export const PostForm: FC = () => (
  <>
    <CustomController
      controllerName="postSubmissionHeading"
      controllerLabel="Thank You Heading"
      fieldType='text'
    />

    <CustomController
      controllerName="postSubmissionDescription"
      controllerLabel="Thank You Description"
      fieldType='text'
      isMultiLine
      rowsLength={4}
    />

    <CustomController
      controllerName="postSubmissionButtonText"
      controllerLabel="Thank You button text"
      fieldType='text'
    />

    <CustomColorControl
      name="postSubmissionButtonColor"
      label='Thank You Button text color'
    />

    <CustomColorControl
      name="postSubmissionButtonBackground"
      label='Thank You Button background color'
    />
  </>
)