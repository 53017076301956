import { Typography, Box, Button, CircularProgress } from '@mui/material'
import React, { FC } from 'react'
import { SendJobVideoInterface } from '../../interfaceTypes'

export const SendJobVideo: FC<SendJobVideoInterface> = ({ sendVideoLoading, videoSend, handleClose }) => (
  <>
    <Typography variant="h4" marginTop={2} marginBottom={3} paddingLeft={0.5}>Are you sure you want to send this video to customer?</Typography>

    <Box display="flex" justifyContent="flex-end">
      <Button sx={{ marginLeft: '10px' }} onClick={handleClose}>Cancel</Button>

      <Button onClick={videoSend} variant="contained" color="primary" disabled={sendVideoLoading} endIcon={sendVideoLoading && <CircularProgress size={20} color="inherit" />}>
        Send
      </Button>
    </Box>
  </>
)