import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'
import { WidgetColorTextInterface } from '../../../interfaceTypes'
import { ColorPicker } from '../../../theme/styleComponents'
import { widgetColorPosition } from '../../../theme/styleConstants'

export const WidgetColorText: FC<WidgetColorTextInterface> = ({ heading, color }) => (
  <Box position="relative">
    <Typography variant='body2' textTransform="capitalize">{heading}</Typography>

    <Box display="flex" alignItems="center">
      <Typography sx={{ marginTop: '10px', marginBottom: '10px' }} variant='h4'>{color}</Typography>

      <ColorPicker sx={{ ...widgetColorPosition(color) }} ml={1} />
    </Box>
  </Box>
)