import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, DialogActions, Typography } from '@mui/material'
import { FC, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ReactPlayer from 'react-player'
import { PlayerIcon } from "../../../../assets/svgs"
import { AttachmentType, UpdateAttachmentInput, useUpdateAttachmentMutation, VideoOrientation } from '../../../../generated'
import { VIDEO_UPDATED_SUCCESSFULLY } from '../../../constants'
import { AppContext, AuthContext } from '../../../context'
import { ActionType } from '../../../context/AppContextReducer'
import { FullAttachmentDescription, VideoSettingProps } from '../../../interfaceTypes'
import palette from '../../../theme/palette'
import { firstLetterUppercase } from '../../../utils'
import { updateVideoSchema } from '../../../validationSchema'
import { Alert } from '../../common/Alert'
import { CustomController } from '../../common/CustomController'
import { CustomModal } from '../../common/CustomModal'
import UploadVideoThumbnail from '../../common/UploadVideoThumbnail'

export const EditModal: FC<FullAttachmentDescription> = ({ isOpen, handleClose, videoData, refetchWidget }) => {
  const [videoSettings, setVideoSettings] = useState<VideoSettingProps>
    ({ url: "", played: 0, loaded: 0, pip: false, playing: false, playbackRate: 1 });
  const { currentUser } = useContext(AuthContext);
  const { selectedBrand, dispatch } = useContext(AppContext)
  const { title, description, thumbnailId, url: videoDataUrl, id: videoId } = videoData || {}
  const { attachments, id: brandId, name, createdAt, updatedAt } = selectedBrand || {}

  const { url, pip, playing } = videoSettings

  const handlePlay = () => {
    setVideoSettings({ ...videoSettings, playing: true })
  }

  const onProgressVideo = (event: any) => {
    if (event.playedSeconds > 6) {
      setVideoSettings({ ...videoSettings })
    }
  }

  const loadUrl = (url: string) => {
    setVideoSettings({
      url, played: 0, loaded: 0, pip: false, playing: false
    })
  }

  const methods = useForm<UpdateAttachmentInput>({
    resolver: yupResolver(updateVideoSchema),

    defaultValues: {
      title: title || "",
      description: description || "",
      thumbnailId: thumbnailId || undefined,
    },
  });

  const { handleSubmit, setValue, reset } = methods

  const [updateAttachment, { loading }] = useUpdateAttachmentMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ""));
    },

    onCompleted(data) {
      if (data) {
        const { updateAttachment } = data;

        if (updateAttachment) {
          const { id } = updateAttachment
          const otherAttachments = attachments?.filter((attachment) => attachment.id !== id)

          const updatedBrand = {
            ...selectedBrand,
            id: brandId || "",
            name: name || "",
            attachments: otherAttachments && otherAttachments.length > 0 ? [updateAttachment, ...otherAttachments] : [updateAttachment],
            createdAt: createdAt!,
            updatedAt: updatedAt!,
          }

          reset();
          refetchWidget && refetchWidget()
          dispatch({ type: ActionType.SET_SELECTED_BRAND, selectedBrand: updatedBrand })
          Alert.success(VIDEO_UPDATED_SUCCESSFULLY)
          handleClose();
        }
      }
    },
  });

  const onSubmit = async (data: UpdateAttachmentInput) => {
    if (videoId) {
      await updateAttachment({
        variables: {
          updateAttachmentInput: {
            id: videoId,
            ...data,
          }
        }
      })
    }
  }

  useEffect(() => {
    title && setValue('title', title)
    description && setValue('description', description)
    thumbnailId && setValue('thumbnailId', thumbnailId)
    videoDataUrl && loadUrl(videoDataUrl)
    // eslint-disable-next-line
  }, [videoDataUrl, title, description, thumbnailId])

  const { attachments: userAttachments } = currentUser || {}
  const userThumbnails = userAttachments?.filter(attachment => attachment.type === AttachmentType.Thumbnail)

  const { thumbnail, videoOrientation } = videoData || {}
  const { url: thumbnailUrl } = thumbnail || {}
  const thumbnailUrlArray = thumbnailUrl?.split('/upload')
  const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
  const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')

  return (
    <CustomModal title='Edit Video' handleClose={handleClose} isOpen={isOpen} maxWidth="710px">
      {videoOrientation === VideoOrientation.Landscape ?
        <Box paddingTop='56.55%' position='relative' mb={2}>
          <Box position='absolute' top='0' width='100%' height='100%'>
            <ReactPlayer
              className='react-player'
              width='100%'
              height='100%'
              url={url}
              pip={pip}
              controls={true}
              playing={playing}
              muted={true}
              onClickPreview={handlePlay}
              playIcon={<PlayerIcon />}
              light={updatedThumbnailUrl || ""}
              onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
              onProgress={onProgressVideo}
              playsinline
            />
          </Box>
        </Box>
        :
        <Box sx={{ backgroundColor: palette.offWhite }} paddingTop='268.11px' position='relative' mb={2}>
          <Box position='absolute' top='0' width='100%' height='100%'>
            <Box marginX='auto' width={148} height='100%'>
              <ReactPlayer
                className='react-player'
                width='100%'
                height='100%'
                url={url}
                pip={pip}
                controls={true}
                playing={playing}
                muted={true}
                onClickPreview={handlePlay}
                playIcon={<PlayerIcon />}
                light={updatedThumbnailUrl || ""}
                onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
                onProgress={onProgressVideo}
                playsinline
              />
            </Box>
          </Box>
        </Box>
      }

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box sx={{ marginBottom: '1rem' }}>
            <CustomController
              controllerName="title"
              controllerLabel="Video Title"
              fieldType='text'
            />

            <Typography variant="h3" sx={{ color: palette.blackOne }}>This will appear on the customer end on the widget.</Typography>
          </Box>

          <Box sx={{ marginBottom: '1rem' }}>
            <CustomController
              controllerName="description"
              controllerLabel="Description"
              fieldType='text'
              isMultiLine
              rowsLength={4}
            />
          </Box>

          <UploadVideoThumbnail isLandscape={true} userThumbnails={userThumbnails || []} fieldName="thumbnailId" />

          <DialogActions sx={{ paddingRight: '0' }}>
            <Button sx={{ marginLeft: '10px' }} onClick={handleClose}>Cancel</Button>

            <Button type="submit" variant="contained" color="primary" disabled={loading}
              endIcon={loading && <CircularProgress size={20} color="inherit" />}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </CustomModal>
  )
}
