import { Box, Tab, Tabs } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { tabsSticky, tabsWrapper } from '../../theme/styleConstants'
import { handleTabs } from '../../utils'
import { InfoApiKeys } from '../common/InfoApiKeys'
import { PageHeading } from '../common/PageHeading'
import { TabPanel } from '../common/TabPanel'
import { Events } from './Events'

export const EventsandApis = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab')
  const [value, setValue] = useState(parseInt(activeTab || '') || 0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSearchParams('')
    setValue(newValue);
  };
  return (
    <>
      <PageHeading title='API and Events' />

      <Box sx={tabsWrapper}>
        <Box sx={tabsSticky}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
            <Tab sx={{ textTransform: 'capitalize' }} label="APIs" {...handleTabs(0)} />

            <Tab sx={{ textTransform: 'capitalize' }} label="Events" {...handleTabs(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <InfoApiKeys />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Events />
        </TabPanel>
      </Box>
    </>
  )
}
