
import { Box, Tab, Tabs } from "@mui/material";
import { FC, SyntheticEvent, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "../../context";
import { ActionType } from "../../context/AppContextReducer";
import { backdropDialog, tabsSticky, tabsWrapper } from "../../theme/styleConstants";
import { handleTabs } from "../../utils";
import { CustomLoaderModal } from "../common/CustomLoaderModal";
import { TabPanel } from "../common/TabPanel";
import { BrandGuidelines } from "./BrandGuidelines";
import { Calendly } from "./Calendly";
import { Gorgias } from "./Gorgias";
import { ApiIntegration } from "./apiKey";
import { VideoLibraryTab } from "./videoLibrary/VideoLibraryTab";
import { WidgetSettings } from "./widgetSettings";
import { GORGIAS_SETTINGS } from "../../constants";

export const BrandTabs: FC = () => {
  const { widgetLoader, isBackdropVisible, dispatch } = useContext(AppContext)
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab')
  const [value, setValue] = useState(parseInt(activeTab || '') || 0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSearchParams('')
    setValue(newValue);
  };

  return (
    <>
      <CustomLoaderModal
        isOpen={widgetLoader}
        handleClose={() => dispatch({ type: ActionType.SET_WIDGET_LOADER, widgetLoader: false })}
      />

      <Box sx={tabsWrapper}>
        <Box sx={tabsSticky}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
            <Tab sx={{ textTransform: 'capitalize' }} label="Brand Guidelines" {...handleTabs(0)} />

            <Tab sx={{ textTransform: 'capitalize' }} label="Video Library" {...handleTabs(1)} />

            <Tab sx={{ textTransform: 'capitalize' }} label="Widget Settings" {...handleTabs(parseInt(activeTab || '') || 2)} />

            <Tab sx={{ textTransform: 'capitalize' }} label={GORGIAS_SETTINGS} {...handleTabs(3)} />

            <Tab sx={{ textTransform: 'capitalize' }} label="Calendar" {...handleTabs(4)} />

            <Tab sx={{ textTransform: 'capitalize' }} label="Api Keys" {...handleTabs(5)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <BrandGuidelines />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <VideoLibraryTab />
        </TabPanel>

        <TabPanel value={value} index={parseInt(activeTab || '') || 2}>
          <WidgetSettings />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <Gorgias />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <Calendly />
        </TabPanel>

        <TabPanel value={value} index={5}>
          <ApiIntegration />
        </TabPanel>
      </Box>

      {isBackdropVisible &&
        <Box sx={backdropDialog} />
      }
    </>
  )
}