import { FC } from 'react'

import { PageHeading } from "../../../components/common/PageHeading"
import { TaskTable } from "../../../components/taskTemplate/TaskTable"

export const Tasks: FC = () => (
  <>
    <PageHeading title="Tasks" subTitle="Create asynchronous tasks for operators" />

    <TaskTable />
  </>
)