import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { Box, Button, Chip, CircularProgress, Collapse, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { images, PlayerIcon } from '../../../assets/images';
import { AttachmentType, Job, JobStatus, useGetJobLazyQuery, useSendJobLinkSmsMutation, useSendVideoMutation } from '../../../generated';
import { GRAPHQL_QUERY_POLICY, JOB_PATH, MESSAGE_SEND_SUCCESSFULLY, VIDEO_UPLOAD_SUCCESSFULLY } from '../../constants';
import { AppContext, AuthContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { VideoSettingProps } from '../../interfaceTypes';
import palette from '../../theme/palette';
import { AnimateBox, PreviewBox, RenderVideo, UploadArea, UploadBox, UploadIcon } from '../../theme/styleComponents';
import { taskDetailBox, taskDetailSmsButton } from '../../theme/styleConstants';
import { formatEnumMember, getJobStatus } from '../../utils';
import { CreateVideoModalForJobs } from '../brandSetting/videoLibrary/CreateVideoModalForJobs';
import { Alert } from '../common/Alert';
import { CustomBreadCrumb } from '../common/CustomBreadCrumb';
import { CustomModal } from '../common/CustomModal';
import { DetailCard } from '../common/DetailCard';
import { NoDataFound } from '../common/NoDataFound';
import { PageHeading } from '../common/PageHeading';
import { RejectJob } from '../jobs/RejectJob';
import { SendJobVideo } from '../jobs/SendJobVideo';
import { AlertRejection } from './AlertRejection';
import { FullDescriptionModal } from './FullDescriptionModal';
import { TaskDetailComponentSkeleton } from './TaskDetailComponentSkeleton';
import DOMPurify, { sanitize } from 'dompurify';

export const TaskDetailComponent: FC = () => {
  const [videoSettings, setVideoSettings] = useState<VideoSettingProps>({ url: "", played: 0, loaded: 0, pip: false, playing: false })
  const { isOperator, isAdmin, isBrandManager } = useContext(AuthContext);
  const { isScript, dispatch } = useContext(AppContext);
  const [jobData, setJobData] = useState<Job>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rejectJob, setRejectJob] = useState<boolean>(false)
  const [sentVideo, setSentVideo] = useState<boolean>(false)
  DOMPurify.setConfig({ ADD_ATTR: ['target'] });
  const params = useParams()
  const jobId = params.id

  const [fetchJob, { loading, refetch }] = useGetJobLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError({ message }) {
      setIsLoading(false)
      return Alert.error(message)
    },

    onCompleted(data) {
      if (data) {
        const { getJob } = data;

        if (getJob) {
          setJobData(getJob)
        }
      }
      setIsLoading(false)
    }
  });

  const [sendVideo, { loading: videoLoading }] = useSendVideoMutation({
    onError({ message }) {
      return Alert.error(message)
    },

    onCompleted(data) {
      if (data) {
        setSentVideo(false)
        refetch()
        return Alert.success(VIDEO_UPLOAD_SUCCESSFULLY)
      }
    }
  })

  const [sendJobSms, { loading: smsLoading }] = useSendJobLinkSmsMutation({
    onError({ message }) {
      return Alert.error(message)
    },
    onCompleted(data) {

      if (data) {
        return Alert.success(MESSAGE_SEND_SUCCESSFULLY)
      }
    }
  })

  const sendSms = async () => {
    if (jobId) {
      await sendJobSms({
        variables: {
          jobId,
        }
      })
    }
  }

  const videoSend = async () => {
    if (jobId) {
      await sendVideo({
        variables: {
          jobId,
        }
      })
    }
  }

  const jobPath = localStorage.getItem(JOB_PATH)

  useEffect(() => {
    localStorage.removeItem(JOB_PATH)

    if (jobId) {
      fetchJob({
        variables: {
          id: jobId || ""
        },
      })
    }
  }, [jobPath, jobId, fetchJob])

  const handleUpload = () => {
    setIsVideoModalOpen(true)
  }

  const handleClose = () => {
    setIsVideoModalOpen(false)
  }

  const handleJobRejectClose = () => {
    setRejectJob(false)
    isOperator && document.location.reload();
  };


  const loadUrl = (url: string) => {
    setVideoSettings({
      url, played: 0, loaded: 0, pip: false, playing: false
    })
  }

  const handlePlay = () => {
    setVideoSettings({ ...videoSettings, playing: true })
  }

  const { lightColorTwo, white, lightNine, lightOne, lightBorderColorTwo } = palette
  const {
    script, segmentAudienceKey, videoPageHeading, videoPageDescription, video, customerEmail, customerPhone,
    taskTemplateName, brand, user, status, statusMessage
  } = jobData || {}
  const { url: videoUrl, thumbnail, id: attachmentId, videoOrientation } = video || {}
  const { url: videoThumbnailUrl } = thumbnail || {}
  const thumbnailUrlArray = videoThumbnailUrl?.split('/upload')
  const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
  const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')
  const { attachments } = brand || {}
  const brandLogoData = attachments?.filter(logo => logo.type === AttachmentType.Logo)
  const brandOnePagerData = attachments?.filter(logo => logo.type === AttachmentType.OnePager)
  const { url: brandLogo } = (brandLogoData && brandLogoData[0]) || {}
  const { url: brandOnePage } = (brandOnePagerData && brandOnePagerData[0]) || {}
  const { id, email: userEmail, phoneNumber: userPhone } = user || {}

  useEffect(() => {
    loadUrl(videoUrl || "")
  }, [videoUrl])

  const { url: playVideoUrl, pip, playing } = videoSettings

  const handleFullDescriptionOpen = () => {
    dispatch({ type: ActionType.SET_IS_SCRIPT, isScript: !isScript })
  }

  const renderUpload = () => (
    <UploadArea onClick={handleUpload} sx={{ cursor: 'pointer' }}>
      <UploadBox>
        <UploadIcon sx={{ marginRight: 0 }}>
          <UploadFileIcon color="primary" />
        </UploadIcon>
      </UploadBox>

      <Typography variant="body2" textAlign='center'>Click to upload</Typography>
    </UploadArea>
  )

  const renderVideo = () => (
    <RenderVideo>
      <ReactPlayer
        className='react-player'
        width='100%'
        height='100%'
        url={playVideoUrl}
        pip={pip}
        controls={true}
        playing={playing}
        progressInterval={200}
        onClickPreview={handlePlay}
        playIcon={<PlayerIcon />}
        light={updatedThumbnailUrl || ""}
        onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
        playsinline
      />
    </RenderVideo>
  )

  if (loading || isLoading) return <TaskDetailComponentSkeleton />
  if (!loading && !isLoading && jobId !== id && !jobData) return <NoDataFound height='calc(100vh - 64px)' description="No job found" button={false} />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '30px 0 10px', alignItems: 'center' }}>
        <PageHeading marginTop='0' title={taskTemplateName || "Task"} subTitle={segmentAudienceKey || ""} />

        <Chip label={formatEnumMember(status || "")} color={getJobStatus(status || JobStatus.InReview)} variant="outlined" />
      </Box>

      <CustomBreadCrumb currentPage='View Job' previousPage='Jobs' href='/jobs' />

      {statusMessage && status === JobStatus.Rejected && <AlertRejection statusMessage={statusMessage || ""} />}

      <Box marginTop='1rem' sx={{ border: `1px solid ${lightColorTwo}`, borderRadius: '4px' }} id='animation'>
        <Box padding={2} sx={{ backgroundColor: white }}>
          <Box sx={taskDetailBox}>
            <Box display='flex' justifyContent='start' alignItems='center'>
              <Box marginRight='1rem' display='flex' alignItems='center'>
                <Box component='img' maxWidth={100} maxHeight={46} src={brandLogo || images.CREATE_TASK} />
              </Box>

              <Box>
                <Typography variant='h4' sx={{ fontWeight: '500', marginBottom: '5px' }}>
                  {videoPageHeading || "Thank you"}
                </Typography>

                <Typography variant='subtitle2'>
                  {videoPageDescription || "N/A"}
                </Typography>
              </Box>
            </Box>

            {isOperator &&
              <Box sx={taskDetailSmsButton}>
                <Tooltip title="Send me this task link on mobile">
                  <Button variant='outlined' color='secondary'
                    sx={{ textTransform: 'none', backgroundColor: lightNine, border: '1px solid transparent' }}
                    startIcon={<PhoneIphoneIcon />}
                    onClick={sendSms}
                    disabled={smsLoading}
                    endIcon={smsLoading && <CircularProgress size={20} color="inherit" />}
                  >
                    Send this via SMS
                  </Button>
                </Tooltip>
              </Box>
            }
          </Box>
        </Box>
      </Box>

      <Box marginTop='1rem'>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item lg={8} md={7} xs={12}>
            <Box padding={2} sx={{ backgroundColor: white }}>
              <Typography paddingBottom='10px' borderBottom={`1px solid ${lightBorderColorTwo}`} variant='subtitle1'>
                Task Information
              </Typography>

              <Box marginTop={2}>
                <Box mt={1} display='flex' justifyContent='space-between'>
                  <Typography variant='h6'>Script</Typography>

                  {(script?.length || 0) > 400 &&
                    <IconButton onClick={handleFullDescriptionOpen}>
                      <VisibilityIcon color='primary' />
                    </IconButton>
                  }
                </Box>

                {(script?.length || 0) > 400 ?
                  <Box position='relative' mt={1} height={200} overflow="hidden" id='animation'>
                    <AnimateBox>
                      <Box mt='10px' mb='20px'
                        dangerouslySetInnerHTML={{
                          __html: sanitize(script || 'N/A'),
                        }}
                      />
                    </AnimateBox>
                  </Box>
                  :
                  <Typography sx={{ marginBottom: '1rem' }} variant='subtitle2'>
                    <Box mt='10px' mb='20px'
                      dangerouslySetInnerHTML={{
                        __html: sanitize(script || 'N/A'),
                      }}
                    />
                  </Typography>
                }
              </Box>

              <Box marginTop='3rem'>
                <Typography variant='subtitle1'>Brand One Pager</Typography>

                <Box pt={1}>
                  <PreviewBox sx={{ backgroundColor: lightOne }}>
                    <Box component='img'
                      src={brandOnePage || images.DUMMY_IMAGE}
                      sx={{ maxWidth: '100%', borderRadius: 1 }}
                    />
                  </PreviewBox>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={4} md={5} xs={12}>
            {(isAdmin || isBrandManager) &&
              <Box padding={2} mb={3} sx={{ backgroundColor: white }}>
                <DetailCard
                  title={'Assignee Information'}
                  email={userEmail || ''}
                  phone={userPhone || ''}
                />
              </Box>
            }

            <Box padding={2} sx={{ backgroundColor: white }}>
              <Box display='flex' justifyContent='space-between' alignItems="center">
                <Typography paddingBottom='10px' variant='subtitle1'>Upload Video</Typography>

                {video &&
                  <IconButton color="primary" size="large"
                    onClick={() => {
                      setIsEditOpen(!isEditOpen)
                      setVideoSettings({ ...videoSettings, playing: !playing })
                    }}
                  >
                    {isEditOpen ? <ArrowBackIcon /> : <EditIcon />}
                  </IconButton>
                }
              </Box>

              {!video && renderUpload()}

              <Collapse in={isEditOpen}>
                <>
                  <Box sx={{ pb: 1 }}>
                    {renderUpload()}
                  </Box>

                  {video && renderVideo()}
                </>
              </Collapse>

              <Collapse in={!!video && !isEditOpen}>
                {renderVideo()}
              </Collapse>


              {(isAdmin || isBrandManager) && status === JobStatus.InReview &&
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", mt: 2 }}>
                  <Button variant="contained" sx={{ marginRight: '10px' }} color="primary" onClick={() => setSentVideo(true)}>Approve</Button>

                  <Button variant="contained" color="error" onClick={() => setRejectJob(true)}>Reject</Button>
                </Box>
              }

              <Box marginTop={2}>
                <DetailCard
                  title={'Customer Information'}
                  email={customerEmail || ''}
                  phone={customerPhone || ''}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {(isAdmin || isBrandManager) &&
        <CustomModal
          maxWidth='700px'
          title="Reject Video"
          isOpen={rejectJob}
          handleClose={handleJobRejectClose}
        >
          <RejectJob
            jobId={jobId || ""}
            refetchJob={refetch}
            handleCloseReject={handleJobRejectClose} />
        </CustomModal>
      }

      {(isAdmin || isBrandManager) &&
        <CustomModal
          maxWidth="700px"
          title="Send Video"
          isOpen={sentVideo}
          handleClose={() => setSentVideo(false)}>
          <SendJobVideo
            sendVideoLoading={videoLoading}
            videoSend={videoSend}
            handleClose={() => setSentVideo(false)}
          />
        </CustomModal >
      }

      <CreateVideoModalForJobs
        attachmentId={attachmentId}
        isOpen={isVideoModalOpen}
        handleClose={handleClose}
        currentVideo={video}
        videoOrientation={videoOrientation}
        videoType='video'
        typeId={jobId}
        entityType="jobs"
        refetchJob={refetch}
      />

      <FullDescriptionModal script={script} />
    </>
  )
}
