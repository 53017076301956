import { FC, useContext } from 'react'
import { Box, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import palette from '../../../theme/palette';
import { AppContext } from '../../../context';
import { ActionType } from '../../../context/AppContextReducer';

export const VideoLibraryActions: FC<{ isDeleteVideo?: boolean }> = ({ isDeleteVideo }) => {
  const { dispatch } = useContext(AppContext)

  const handleEditOpen = () => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: true })
  }

  const handleDeleteOpen = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: true })
  }

  return (
    <Box className="videoLibraryActions">
      <IconButton sx={{ backgroundColor: palette.white, marginRight: '5px' }} onClick={handleEditOpen}>
        <EditIcon color='primary' />
      </IconButton>

      {isDeleteVideo &&
        <IconButton sx={{ backgroundColor: palette.white }} onClick={handleDeleteOpen}>
          <DeleteIcon color='error' />
        </IconButton>
      }
    </Box>
  )
}
