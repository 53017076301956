import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import palette from '../../../theme/palette'
import { ctaButtonStyle, radioButtonStyles } from '../../../theme/styleConstants'
import { CustomController } from '../../common/CustomController'
import { CustomColorControl } from './CustomColorControl'
import { CustomSwitcher } from './CustomSwitcher'

export const PathWaysBanner = () => {
  const mediumViewport = useMediaQuery('(max-width:575px)');
  const { control, watch } = useFormContext();
  const pathWaysMobileCtaButtonTextValue = watch('pathWaysMobileCtaButtonText')
  const isPortraitValue = watch('isPortrait')
  const isShowBanner = watch('showBannerAlways')

  return (
    <>
      <Box mb='1rem'>
        <CustomSwitcher label="Show banner always" defaultValue={!!isShowBanner} controllerName="showBannerAlways" />
      </Box>

      {!isShowBanner &&
        <CustomController
          controllerName='throttleNumber'
          controllerLabel='Throttle number'
          fieldType='number'
          min='1'
          tooltipText={`Number of times the hook video should play upon customer's retract.`}
        />
      }

      <CustomController
        controllerLabel='Button text'
        controllerName='pathWaysButtonText'
      />

      <CustomColorControl name="pathWaysButtonColor" label="Button text color" />

      <CustomColorControl name="pathWaysButtonBackgroundColor" label="Button background color" />
      
      {isPortraitValue &&
        <>
          <CustomController
            controllerLabel='CTA button text (Mobile)'
            controllerName='pathWaysMobileCtaButtonText'
          />

          <Box my={1}>
            <Typography variant="subtitle1" mb={2}>CTA button styles (Mobile)</Typography>
            
            <Controller
              control={control}
              name="pathWaysMobileCtaButtonStyle"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  defaultValue="0px"
                  name="pathWaysMobileCtaButtonStyle"
                  sx={{ flexDirection: 'row' }} >
                  <FormControlLabel sx={ctaButtonStyle}
                    value="0px"
                    control={<Radio sx={radioButtonStyles} />}
                    label={<Button variant='outlined' sx={{ border: `1px solid ${palette.primary.main}`, borderRadius: '0px', width: '100%' }}><Typography noWrap maxWidth={mediumViewport ? 200 : 80}>{pathWaysMobileCtaButtonTextValue || "- -"}</Typography></Button>}
                  />
                  <FormControlLabel sx={ctaButtonStyle} value="4px"
                    control={<Radio sx={radioButtonStyles} />}
                    label={<Button variant='outlined' sx={{ border: `1px solid ${palette.primary.main}`, borderRadius: '4px', width: '100%' }}><Typography noWrap maxWidth={mediumViewport ? 200 : 80}>{pathWaysMobileCtaButtonTextValue || "- -"}</Typography></Button>}
                  />

                  <FormControlLabel sx={ctaButtonStyle} value="60px"
                    control={<Radio sx={radioButtonStyles} />}
                    label={<Button variant='outlined' sx={{ border: `1px solid ${palette.primary.main}`, borderRadius: '60px', width: '100%' }}><Typography noWrap maxWidth={mediumViewport ? 200 : 80}>{pathWaysMobileCtaButtonTextValue || "- -"}</Typography></Button>}
                  />
                </RadioGroup>
              )}
            />
          </Box>
        </>
      }
    </>


  )
}
