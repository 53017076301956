import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { VideoArrow } from "../../../../assets/svgs";
import { SiteWidgetPreviewProps } from "../../../interfaceTypes";
import palette from "../../../theme/palette/index";
import { ImageBox, MuteButton, SiteWidgetPreview, TitleBox, VideoArrowIcon, VideoBox, VideoWidgetTitle } from "../../../theme/styleComponents";
import { SiteWidgetBubble } from "./SiteWidgetBubble";

export const SiteWidgetPreviewComponent: FC<SiteWidgetPreviewProps> = ({ idleBackgroundColor, logoUrl }) => {
  const { watch } = useFormContext();
  const showSiteWidgetTitle = watch('showSiteWidgetTitle')

  return (
    <Box>
      <SiteWidgetPreview>
        <ImageBox>
          <SiteWidgetBubble
            idleBackgroundColor={idleBackgroundColor}
            logoUrl={logoUrl}
          />
        </ImageBox>

        <TitleBox>
          <Typography variant="h4" sx={{ fontWeight: '500', color: palette.white }}>The Bubble</Typography>
        </TitleBox>
      </SiteWidgetPreview>

      <SiteWidgetPreview>
        <Box padding={3} display='flex' height={showSiteWidgetTitle ? '250px' : '210px'}>
          <Box width="100%" border='1px solid #E0E0E0' borderRadius='6px'>
            {showSiteWidgetTitle && <VideoWidgetTitle><Typography variant="h4" color='primary' >Video Title</Typography></VideoWidgetTitle>}

            <VideoBox>
              <MuteButton variant="contained" sx={{ cursor: 'auto' }} disableRipple color="primary">Unmute</MuteButton>

              <VideoArrowIcon>
                <VideoArrow />
              </VideoArrowIcon>
            </VideoBox>
          </Box>
        </Box>
        <TitleBox>
          <Typography variant="h4" sx={{ fontWeight: '500', color: palette.white }}>Video Widget</Typography>
        </TitleBox>
      </SiteWidgetPreview>
    </Box>
  )
}