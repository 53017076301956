import { FC, Reducer, useContext, useReducer, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Table, TableBody, TableCell, TableRow, TableContainer, Typography, Box, TableHead, Tooltip, Chip
} from '@mui/material';
import { useGetAllTaskTemplatesQuery } from '../../../generated';
import { BRAND_ID, GRAPHQL_QUERY_POLICY, TOOLTIP_POPPER_PROPS } from '../../constants';
import { AppContext } from '../../context';
import palette from '../../theme/palette';
import { TaskInfo, TooltipComponent } from '../../theme/styleComponents';
import { TaskTemplateSkeleton } from '../skeletons/TaskTemplateSkeleton';
import { TaskAction, TaskActionType, taskInitialState, taskReducer, TaskState } from "../../context/TaskContextReducer";
import { Tasks } from '../menus/Task';
import { TaskDeleteTemplate } from './TaskDeleteTemplate';
import { NoDataFound } from '../common/NoDataFound';
import { TaskActions } from './TaskActions';
import { NotVerifiedIcon, VerifiedIcon } from '../../../assets/images';

export const TaskTable: FC = () => {
  const [keyword, setKeyword] = useState<string>("")
  const { isEdit, isDeleteOpen } = useContext(AppContext)
  const [taskState, taskDispatch] = useReducer<Reducer<TaskState, TaskAction>>(taskReducer, taskInitialState);
  const { currentTaskData: taskData, tasksData } = taskState

  const brandId = localStorage.getItem(BRAND_ID);

  const { loading } = useGetAllTaskTemplatesQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: {
      brandId: brandId || "",

      SearchTaskTemplateInput: {
        keyword: keyword.length > 2 ? keyword : "",
      }
    },

    onError() { },

    onCompleted(data) {
      const { getAllTaskTemplates } = data

      if (getAllTaskTemplates) {
        taskDispatch({ type: TaskActionType.SET_TASKS_DATA, tasksData: getAllTaskTemplates })
      }
    }
  })

  const isEmpty = tasksData?.length === 0 && !loading

  return (
    <>
      <TaskActions loading={loading} setKeyword={setKeyword} />

      <TableContainer sx={{ marginTop: '2rem' }}>
        {isEmpty ?
          <NoDataFound height='calc(100vh - 285px)' description='Record not found' />
          :
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Task Name</TableCell>

                <TableCell sx={{ minWidth: '200px' }}>Gorgias Ticket Subject</TableCell>

                <TableCell>Tags</TableCell>

                <TableCell align="center" sx={{ minWidth: '120px' }}>Jobs Created</TableCell>

                <TableCell align="center" sx={{ minWidth: '120px' }}>Moderated</TableCell>

                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            {loading ?
              <TaskTemplateSkeleton noOfRows={10} />
              :
              <TableBody>
                {tasksData?.map((task, index) => {
                  const { id, name, description, segmentAudienceKey, subject, jobs, tags, isModerated } = task || {}
                  const remainingTags = tags?.split(', ').slice(2)
                  const remainingTagsLength = remainingTags?.length

                  return (
                    <TableRow key={id} hover>
                      <TableCell align="left">
                        <Box display='flex' alignItems='center'>
                          <Tooltip title={name} placement="bottom-start" PopperProps={TOOLTIP_POPPER_PROPS}>
                            <Typography variant='body2' color='inherit' sx={{ marginRight: '10px', maxWidth: '250px' }} noWrap>{name}</Typography>
                          </Tooltip>

                          <Tooltip title={segmentAudienceKey} placement="bottom-start" PopperProps={TOOLTIP_POPPER_PROPS}>
                            <TaskInfo
                              sx={{ maxWidth: '100px', whiteSpace: 'nowrap', justifyContent: 'left', height: '25px' }}
                              icon={<ArrowForwardIcon sx={{ height: '0.8em', width: '0.8em', color: palette.pink }} />}
                              label={segmentAudienceKey} />
                          </Tooltip>
                        </Box>

                        <Tooltip title={description} placement="bottom-start" PopperProps={TOOLTIP_POPPER_PROPS}>
                          <Typography variant='h3' mt={1} sx={{ fontWeight: '500', color: palette.blackTwo, maxWidth: '250px' }} noWrap>{description}</Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Box>
                          <TooltipComponent maxWidth="200px">
                            <Typography variant='body2' noWrap>
                              {subject || ''}
                            </Typography>
                            <Box className="customTooltip">
                              {subject || ''}
                            </Box>
                          </TooltipComponent>
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        {tags ?
                          <Typography variant='body2' maxWidth="250px">
                            {tags.split(', ').slice(0, 2).map((tag) => (
                              <Chip key={tag} color='primary' sx={{ marginRight: '5px', marginBottom: '5px' }} label={tag} />
                            ))}

                            {remainingTagsLength !== 0 &&
                              <Tooltip title={
                                <Box>
                                  {remainingTags?.map((tag, index) => (
                                    <Typography key={tag} component='span' color='white' marginRight='5px'>
                                      {tag}{remainingTagsLength! - 1 === index ? '' : ', '}
                                    </Typography>
                                  ))}
                                </Box>
                              }>
                                <Chip variant='outlined' color='primary' label={`+${remainingTagsLength}`} />
                              </Tooltip>
                            }

                          </Typography>
                          :
                          <Typography variant='body2'>N/A</Typography>
                        }
                      </TableCell>

                      <TableCell align="center">{jobs?.length || 0}</TableCell>

                      <TableCell align="center"> <Box display='flex' justifyContent='center'>
                        {isModerated ? <VerifiedIcon /> : <NotVerifiedIcon />}
                      </Box></TableCell>

                      <TableCell align="center">
                        <Tasks task={task} taskDispatch={taskDispatch} tasksData={tasksData} isOpen={isEdit} isDelete={isDeleteOpen} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            }

            <TaskDeleteTemplate
              taskDispatch={taskDispatch}
              tasksData={tasksData}
              task={taskData || null}
              isOpen={isDeleteOpen}
            />
          </Table>
        }
      </TableContainer>
    </>
  )
}