import { Box, Typography } from "@mui/material"
import { SummaryComponentProps } from "../../interfaceTypes"
import { formatTimeToCustomFormat, timeToShow } from "../../utils";
import { PlayCircleOutline as PlayCircleOutlineIcon } from '@mui/icons-material';
import { playCircleIcon, timerButtonStyle } from "../../theme/styleConstants"

export const SummaryComponent = ({ summaryArray, setSeekingTime }: SummaryComponentProps) => {
  const { start_time, point } = summaryArray

  const playCircleIconClicker = () => {
    setSeekingTime(formatTimeToCustomFormat(Number(start_time)) || undefined)
  }

  return (
    <Box component="li">
      <Box display='flex'>
        <Box component='span' position='relative' sx={{ p: '5px 10px', borderRadius: '8px', display: 'flex', gap: '20px', alignItems: 'center' }}>
          {point?.replace('spk_0', 'Speaker 0').replace('spk_1', 'Speaker 1').replace('spk_2', 'Speaker 2')}

          <Box sx={{ py: 0.5, pr: 1 }}>
            <Box sx={timerButtonStyle} onClick={playCircleIconClicker}>
              <PlayCircleOutlineIcon sx={playCircleIcon} />

              <Typography component='p' color="black" fontSize='14px' fontWeight='600'>
                {timeToShow(Number(start_time))}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}