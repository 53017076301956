import { Box } from '@mui/material'
import { FC } from 'react'
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { childVideoBox } from '../../../theme/styleConstants';
import { CarouselImageBox } from './CarousellImageBox';

export const CarouselThreeVideos: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]
  return (
    <Box padding={1} minHeight="200px" display='flex' flexDirection='column'>
      <CarouselImageBox marginBottom='16px' width='100%' height='200px' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />

      <Box sx={childVideoBox}>
        <CarouselImageBox marginBottom='16px' width='31%' height='70px' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />

        <CarouselImageBox marginBottom='16px' width='31%' height='70px' imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} />

        <CarouselImageBox marginBottom='16px' width='31%' height='70px' imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} />
      </Box>
    </Box>
  )
}
