import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { images } from "../../../assets/images";
import { PlayTriangleIcon } from "../../../assets/svgs";
import { useGetSignedUrlWithKeyMutation } from "../../../generated";
import { BRAND_ID } from "../../constants";
import { VideoSearchCardProps } from "../../interfaceTypes";
import { CustomVideoPlayer, OverLay, VideoSearchListCard, VideoSearchListWrap } from "../../theme/styleComponents";
import { playerIconStyle, videoListingImageBox } from "../../theme/styleConstants";
import { formatDateAndTimeToCustomFormat, getUrl, urlAppend } from "../../utils";
import { Transcription } from "./Transcription";

export const VideoSearchCard: FC<VideoSearchCardProps> = ({ transcriptions, scheduleEvent, searchText }): JSX.Element => {
  const [videoUrl, setVideoUrl] = useState<string | undefined>('')
  const [getSingedUrlWithKey] = useGetSignedUrlWithKeyMutation()
  const urlToAppend = '/upload/c_scale,h_200/e_loop/dl_200,vs_40/'
  const brandId = localStorage.getItem(BRAND_ID)

  // const brandId = localStorage.getItem(BRAND_ID);

  const { attachments, widget, eventCreatedAt, id } = scheduleEvent ?? {}
  const { name, type } = widget ?? {}

  useEffect(() => {
    getUrl(attachments, getSingedUrlWithKey, setVideoUrl)
    // eslint-disable-next-line
  }, [])

  return (
    <VideoSearchListWrap>
      <VideoSearchListCard>
        {videoUrl ?
          <CustomVideoPlayer>
            <Link to={`/brand/${brandId}/recorded-videos/${id}`}>
              <Box className="videoContentCard">

                <Box sx={{ ...playerIconStyle, height: '50px', width: '50px' }} className="playerIcon">
                  <PlayTriangleIcon />
                </Box>

                <Box
                  className="jpgImage"
                  component='img'
                  src={urlAppend(videoUrl, urlToAppend, "jpg")}
                  sx={videoListingImageBox}
                />

                <OverLay />

                <Box
                  className="gifImage"
                  component='img'
                  src={urlAppend(videoUrl, urlToAppend, "gif")}
                  sx={videoListingImageBox}
                />

              </Box>
            </Link>
          </CustomVideoPlayer>
          :
          <Box component='img'
            src={images.DUMMY_VIDEO_IMAGE}
            alt="video placeholder"
            sx={{ maxHeight: '100%', width: '100%', borderRadius: '6px' }}
          />
        }
      </VideoSearchListCard>

      <Box flex={1} sx={{ width: { sm: 'calc(100% - 160px)', xs: '100%' } }}>
        <Link to={`/brand/${brandId}/recorded-videos/${id}`} style={{ color: 'inherit' }}>
          <Typography variant="h2" mb={0.5}>{name ? name : 'No record found' || ''} - {type || ''}</Typography>
        </Link>

        <Typography variant="h4" mb={0.5} mt={0.5}>{formatDateAndTimeToCustomFormat(eventCreatedAt)}</Typography>

        <Typography variant="h2" mb={0.5} mt={1}>Transcript</Typography>

        <Transcription transcriptions={transcriptions} searchText={searchText} id={id} />
      </Box>
    </VideoSearchListWrap>
  )
}
