import { FormControl, TextField } from '@mui/material';
import { FC } from 'react';
import { SearchComponentProps } from '../../interfaceTypes';
import { InfoTooltip } from './InfoTooltip';

export const Search: FC<SearchComponentProps> = ({ infoText, onChange, disable }) => {

  return (
    <FormControl sx={{ width: '100%' }}>
      <TextField
        id="Search"
        label="Search"
        type="text"
        autoComplete="off"
        size='small'
        onChange={onChange}
        disabled={disable}

        InputProps={{
          endAdornment: (
            infoText ? <InfoTooltip title={infoText ? infoText : 'Search'} /> : ''
          )
        }}
      />
    </FormControl>
  )
}
