import { Box, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { TransparentCloseIcon, DesignAdviceIcon, ArrowIcon, SupportIcon } from '../../../assets/images'
import palette from '../../theme/palette'

export const PreviewHookVideoForm = () => {
  const { watch } = useFormContext()
  const tellingFormHeadingValue = watch('tellingFormHeading')
  const tellingFormDescriptionValue = watch('tellingFormDescription')
  const tellingFormBorderColorValue = watch('tellingFormBorderColor')
  const designAdviceButtonHeadingValue = watch('designAdviceButtonHeading')
  const designAdviceButtonDescriptionValue = watch('designAdviceButtonDescription')
  const customerSupportButtonHeadingValue = watch('customerSupportButtonHeading')
  const customerSupportButtonDescriptionValue = watch('customerSupportButtonDescription,')

  return (
    <Box sx={{ height: '100%', backgroundColor: palette.white, border: `1px solid ${tellingFormBorderColorValue}` }}
      py={2} px={2}>
      <Box display='flex' alignItems='center' mb={1}>
        <Typography flex='1 1'>{tellingFormHeadingValue}</Typography>

        {TransparentCloseIcon()}
      </Box>

      <Typography variant='h3' mb={2} sx={{ height: '100px', overflowY: 'auto' }}>{tellingFormDescriptionValue}</Typography>

      <Box p={2} border='1px solid' mb={2}>
        <Box display='flex' alignItems='center'>
          <Box flex='1 1'>
            <Box display='flex' alignItems='flex-start'>
              <Box mr={2} >{DesignAdviceIcon()}</Box>

              <Box>
                <Typography variant='subtitle2'>{designAdviceButtonHeadingValue}</Typography>

                <Typography variant='h3' sx={{ opacity: '0.5' }}>{designAdviceButtonDescriptionValue}</Typography>
              </Box>
            </Box>
          </Box>

          <Box>{ArrowIcon()}</Box>
        </Box>
      </Box>

      <Box p={2} border='1px solid' mb={2}>
        <Box display='flex' alignItems='center'>
          <Box flex='1 1'>
            <Box display='flex' alignItems='flex-start'>
              <Box mr={2}>{SupportIcon()}</Box>

              <Box>
                <Typography variant='subtitle2'>{customerSupportButtonHeadingValue}</Typography>

                <Typography variant='h3' sx={{ opacity: '0.5' }}>{customerSupportButtonDescriptionValue}</Typography>
              </Box>
            </Box>
          </Box>

          <Box>{ArrowIcon()}</Box>
        </Box>
      </Box>
    </Box>
  )
}
