import { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ThumbnailInterface } from '../../interfaceTypes'
import { ThumbnailWrapper } from '../../theme/styleComponents'
import { DeleteArea } from '../common/DeleteArea';

export const ThumbnailCard: FC<ThumbnailInterface> = ({ thumbnail }) => {
  const { url, id } = thumbnail
  const thumbnailUrlArray = url?.split('/upload')
  const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
  const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')

  return (
    <ThumbnailWrapper>
      <DeleteArea itemId={id} itemType="thumbnail" />

      <LazyLoadImage height='100%' width='100%' src={updatedThumbnailUrl || ''} />
    </ThumbnailWrapper>
  )
}