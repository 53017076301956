import { Box, Popover, Typography } from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import { SketchPicker } from "react-color";
import OutsideClickHandler from "react-outside-click-handler";

import { InputMaybe } from "../../../generated";
import palette from "../../theme/palette";
import { ColorPicker } from "../../theme/styleComponents";
import { customInputField, sketchPicker, sketchPickerClose } from "../../theme/styleConstants";

export const CustomColorPicker: FC<{ onChange: Function; value: InputMaybe<string> | undefined, label: string }> = ({ onChange, value, label }) => {
  const [hidden, setHidden] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePicker = () => {
    setHidden(!hidden)
  }

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={customInputField}>
      <Box display='flex' alignItems='center'>
        <Typography variant="body2" mr='8px' maxWidth={150} noWrap
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >{label} :</Typography>{value || "#000000"}
      </Box>

      <ColorPicker sx={{ backgroundColor: value, cursor: "pointer" }} onClick={handlePicker} />

      {hidden &&
        <OutsideClickHandler onOutsideClick={handlePicker}>
          <Box sx={sketchPicker}>
            <SketchPicker
              color={value || ""}
              onChange={updatedColor => onChange(updatedColor.hex)}
              disableAlpha
            />

            <Box sx={sketchPickerClose} onClick={handlePicker}>Close</Box>
          </Box>
        </OutsideClickHandler>
      }

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ backgroundColor: palette.black, color: palette.white, p: 1 }} variant="h3">{label}</Typography>
      </Popover>
    </Box>
  )
}