import { Box, Grid, Skeleton } from "@mui/material";
import { recordedVideoSkeleton } from '../../theme/styleConstants';

/**
 * Component for displaying skeleton until data loads.
 * @returns {JSX.Element}
 */
export const VideoDetailSkeleton = (): JSX.Element => (
  <Box pb='32px'>
    <Grid container spacing={2} rowSpacing={2}>
      <Grid item md={6} xs={12}>
        <Box display="flex" alignItems="center">
          <Skeleton animation="wave" variant="circular" width={40} height={40} />

          <Box pl={1}>
            <Skeleton variant="text" width={100} height={15} />

            <Skeleton variant="text" width={100} height={15} />
          </Box>
        </Box>

        <Box pt='14px' pl={1}>
          <Skeleton variant="text" width={100} height={15} />
          <Skeleton variant="text" width={200} height={15} />
          <Skeleton variant="text" width={150} height={15} />
        </Box>
      </Grid>

      <Grid item md={6} xs={12}>
        <Skeleton variant="text" sx={recordedVideoSkeleton} />
      </Grid>
    </Grid>

    <Box marginTop={5} marginLeft={5}>
      <Box>
        {new Array(10).fill(0).map((_, index) => (
          <Box key={`transcriptionSkeleton-${index}`} marginTop={2}>
            <Skeleton variant="text" width="100%" />
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
)

