import { Box } from '@mui/material';
import { FC } from 'react'
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { PreviewImageBox } from '../PreviewImageBox';

export const MassonaryThreeVideo: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]

  return (
    <Box p={1} minHeight='200px' display='flex' justifyContent='space-between'>
      <Box width="65%" position='relative'>
        <PreviewImageBox height='200px' imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} />
      </Box>

      <Box width='30%'>
        <PreviewImageBox height='95px' marginBottom='10px' imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} />

        <PreviewImageBox height='95px' imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} />
      </Box>
    </Box>
  )
}
