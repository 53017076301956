import { Box, Grid, Skeleton } from '@mui/material'
import { taskDetailBox, taskDetailSmsButton } from '../../theme/styleConstants'

export const TaskDetailComponentSkeleton = () => (
  <Box>
    <Skeleton sx={{ marginTop: "2rem" }} variant="text" width={200} height={30} />

    <Skeleton variant="text" width={100} height={20} />

    <Box marginTop='1rem'>
      <Box padding={2}>
        <Box sx={taskDetailBox}>
          <Box display='flex' justifyContent='start' alignItems='center'>
            <Box marginRight='1rem' display='flex' alignItems='center'>
              <Skeleton variant="circular" width={40} height={40} />
            </Box>

            <Box>
              <Skeleton variant="text" width={100} height={20} />

              <Skeleton variant="text" width={100} height={20} />
            </Box>
          </Box>

          <Box sx={taskDetailSmsButton}>
            <Skeleton sx={{ marginBottom: '1rem' }} variant="rectangular" width={100} height={20} />
          </Box>
        </Box>
      </Box>
    </Box>

    <Box marginTop='1rem'>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item lg={8} md={7} xs={12}>
          <Box padding={2}>
            <Skeleton sx={{ paddingBottom: '10px' }} variant="text" width='40%' height={20} />

            <Box marginTop={2}>
              <Skeleton sx={{ marginBottom: '1rem' }} variant="text" width='40%' height={20} />

              <Skeleton sx={{ marginBottom: '1rem' }} variant="rectangular" width='100%' height={200} />
            </Box>

            <Box marginTop='3rem'>
              <Skeleton sx={{ marginBottom: '1rem' }} variant="text" width='40%' height={20} />

              <Box pt={1}>
                <Skeleton sx={{ marginBottom: '1rem' }} variant="rectangular" width='100%' height={400} />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={4} md={5} xs={12}>
          <Box padding={2}>
            <Box>
              <Skeleton sx={{ marginBottom: '1rem' }} variant="text" width='40%' height={20} />

              <Skeleton sx={{ marginBottom: '1rem' }} variant="rectangular" width='100%' height={200} />
            </Box>

            <Box marginTop='2rem'>
              <Skeleton sx={{ marginBottom: '1rem' }} variant="text" width='60%' height={20} />

              <Skeleton sx={{ marginBottom: '1rem' }} variant="text" width={100} height={20} />

              <Skeleton sx={{ marginBottom: '1rem' }} variant="text" width={100} height={20} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Box>
)
