import { Box, Tab, Tabs } from '@mui/material'
import { FC, SyntheticEvent, useContext, useState } from 'react'

import { VideoOrientation } from '../../../generated'
import { HOOK_VIDEO } from '../../constants'
import { AppContext } from '../../context'
import { PreviewHookVideoLayoutProps } from '../../interfaceTypes'
import { tabsStyle } from '../../theme/styleConstants'
import { handleTabs } from "../../utils"
import { MobilePreview } from '../common/MobilePreview'
import { TabPanel } from '../common/TabPanel'
import { PreviewHookVideoLayoutMobile } from '../preview/PreviewHookVideoLayoutMobile'
import { PreviewDesktopHookVideo } from './PreviewDesktopHookVideo'
import { PreviewHookVideoForm } from './PreviewHookVideoForm'
import { useFormContext } from 'react-hook-form';
import DefaultVideoThumbnail from '../../../assets/images/video-thumbnail-3.jpg';

export const PopupSettingPreview: FC<PreviewHookVideoLayoutProps> = ({ hookVideos }) => {
  const [preview, setPreview] = useState(0);
  const { selectedBrand } = useContext(AppContext)
  const { attachments } = selectedBrand || {}
  const brandHookVideos = attachments?.filter(attachment => attachment.type === HOOK_VIDEO)
  const brandHookVideoLandscape = brandHookVideos?.find(video => video.videoOrientation === VideoOrientation.Landscape)
  const widgetHookVideoLandscape = hookVideos?.find(video => video.videoOrientation === VideoOrientation.Landscape)
  const { thumbnail: widgetThumbnail } = widgetHookVideoLandscape || {}
  const { url: widgetHookVideoThumbnail } = widgetThumbnail || {}
  const { thumbnail } = brandHookVideoLandscape || {}
  const { url: brandHookVideoThumbnail } = thumbnail || {}

  const { watch } = useFormContext()

  const showClientFormHookVideoValue = watch('showClientFormHookVideo')

  const handlePreviewChange = (event: SyntheticEvent, newValue: number) => {
    setPreview(newValue);
  };

  return (
    <>
      <Box sx={tabsStyle}>
        <Tabs value={preview} onChange={handlePreviewChange} aria-label="basic tabs example">
          <Tab sx={{ textTransform: 'capitalize' }} label="Desktop" {...handleTabs(0)} />

          <Tab sx={{ textTransform: 'capitalize' }} label="Mobile" {...handleTabs(1)} />
        </Tabs>
      </Box>

      <TabPanel value={preview} index={0}>
        <Box sx={{ transform: 'scale(0.50)', transformOrigin: 'left top', width: 'calc(100% + 537px)' }}>
          <Box padding={1} display='flex' gap={1}>
            {showClientFormHookVideoValue &&
              <Box width='62%' sx={{ backgroundColor: 'black' }}>
                <PreviewDesktopHookVideo hookVideoThumbnail={widgetHookVideoThumbnail || brandHookVideoThumbnail || DefaultVideoThumbnail} />
              </Box>
            }

            <Box width='38%' sx={{ margin: !showClientFormHookVideoValue ? '0 auto' : '0' }}>
              <PreviewHookVideoForm />
            </Box>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value={preview} index={1}>
        {showClientFormHookVideoValue ?
          <Box>
            <MobilePreview>
              <PreviewHookVideoLayoutMobile
                hookVideos={hookVideos}
              />
            </MobilePreview>
          </Box> :
          <Box width='100%' sx={{ margin: !showClientFormHookVideoValue ? '0 auto' : '0' }}>
            <MobilePreview>
              <Box py={9} px={2}>
                <PreviewHookVideoForm />
              </Box>
            </MobilePreview>

          </Box>
        }
      </TabPanel>
    </>
  )
}
