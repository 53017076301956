import { Box } from '@mui/material';
import { FC } from 'react'
import { images } from '../../../../assets/images';
import { ThumbnailUrlProps } from '../../../interfaceTypes'
import { GalleryBox } from '../../../theme/styleComponents'
import { GalleryImageBox } from './GalleryImageBox';

export const GalleryThreeVideo: FC<ThumbnailUrlProps> = ({ thumbnailUrls, borderRadius }) => {
  const firstImage = thumbnailUrls && thumbnailUrls[0]
  const secondImage = thumbnailUrls && thumbnailUrls[1]
  const thirdImage = thumbnailUrls && thumbnailUrls[2]

  return (
    <Box padding={1} minHeight="200px" display='flex'>
      <GalleryBox>
        <GalleryImageBox width='33.33%' height='100px' isLandscape={true} imageUrl={secondImage || images.DUMMY} borderRadius={borderRadius} />

        <GalleryImageBox width='33.33%' height='100px' isLandscape={true} imageUrl={firstImage || images.DUMMY} borderRadius={borderRadius} scale='1.2' zIndex={10} videoIcon={true} />

        <GalleryImageBox width='33.33%' height='100px' isLandscape={true} imageUrl={thirdImage || images.DUMMY} borderRadius={borderRadius} />
      </GalleryBox>
    </Box>
  )
}
