import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FC } from 'react';
import { CustomMacroProps } from '../../interfaceTypes';
import { CustomMenu } from '../../theme/styleComponents';
import { btnAddMacro } from '../../theme/styleConstants';

export const CustomMacro: FC<CustomMacroProps> = ({ macros, handleChange, anchorElUser, handleCloseUserMenu, handleOpenUserMenu, top, listMacros }) => (
  <>
    <Button sx={{ ...btnAddMacro, top: top || '10px' }} aria-label="delete" size="small" onClick={handleOpenUserMenu}>
      {listMacros ? 'Macros List' : 'Add Macros'} <ArrowDropDownIcon fontSize="inherit" />
    </Button>

    <CustomMenu
      sx={{ mt: '30px' }}
      id="headerCompaniesSelect"
      anchorEl={anchorElUser}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <Box maxHeight={150} sx={{ overflowY: 'auto' }}>
        {macros && macros.map((macro) => <MenuItem key={macro} onClick={() => handleChange(macro)}>{macro}</MenuItem>)}
      </Box>
    </CustomMenu>
  </>
)
