// packages import
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Box, Button, Divider, Drawer, ListItemButton, MenuItem, Typography } from "@mui/material";
import { FC, KeyboardEvent, MouseEvent, useContext, useState } from "react";
import { Link, NavLink, Link as ReactRouterLink } from "react-router-dom";
// others
import { images } from "../../../assets/images";
import { BRAND_ID, DASHBOARD_LINK, devOnlyUrl } from "../../constants";
import { AppContext, AuthContext } from "../../context";
import palette from "../../theme/palette";
import { CompanyNameBox, CustomMenu, DrawerList } from "../../theme/styleComponents";
import { companyData, companyNameBanner, drawerImage, flexCenter } from "../../theme/styleConstants";
import { isSuperAdmin } from '../../utils';

type Anchor = 'left';

export const MobileDrawer: FC<{ logout: () => void }> = ({ logout }) => {
  const { currentUser, isAdmin, isBrandManager, isOperator } = useContext(AuthContext)
  const { roles } = currentUser || {}
  const { selectedBrand } = useContext(AppContext)
  const { attachment, fullName } = currentUser || {}
  const selectedBrandId = localStorage.getItem(BRAND_ID)
  const { url: profileUrl } = attachment || {}
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [toggleDrawerState, setToggleDrawerState] = useState(false);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' ||
        (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setToggleDrawerState(!toggleDrawerState);
  };

  const { name, id, logo, taskTemplates } = selectedBrand || {}
  const { url } = logo || {}
  const brandId = localStorage.getItem(BRAND_ID)
  const recordedVideoUrl = `/brand/${brandId}/recorded-videos`
  const superAdmin = isSuperAdmin(roles)

  return (
    <Box sx={{ padding: '16px 18px' }}>
      <Box sx={{ ...flexCenter, justifyContent: 'space-between' }}>
        <Box sx={flexCenter}>
          {((isAdmin || isBrandManager) && !!selectedBrandId) &&
            <Button onClick={toggleDrawer('left', true)} sx={{ padding: 0, minWidth: '30px', marginRight: '15px' }}>
              <MenuIcon />
            </Button>
          }

          <Link to='/'>
            <Box component='img' sx={{ maxWidth: '100px', width: '100%', display: 'block' }} src={images.LOGO} alt='Logo' />
          </Link>
        </Box>

        <Box sx={flexCenter}>
          <Box display="flex" justifyContent='space-between'>
            <Box sx={{ flexGrow: 0 }}>
              <Button onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Box display='flex' justifyContent='start' alignItems='center'>
                  <Avatar sx={{ marginRight: '10px' }} src={profileUrl || ''} />

                  <Box textAlign='left'>
                    <Typography variant="body1" textTransform="capitalize">{fullName}</Typography>
                  </Box>
                </Box>
              </Button>

              <CustomMenu
                sx={{ mt: '45px' }}
                id="headerCompaniesSelect"
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorElUser)}
                PaperProps={{
                  style: { maxHeight: '215px' }
                }}
                onClose={handleCloseUserMenu}
              >
                {(isAdmin && !!selectedBrandId) &&
                  <CompanyNameBox>
                    <MenuItem key={id}>
                      <Avatar src={url || ''} sx={companyData} />

                      <Box flex={1} maxWidth="65%" paddingRight="10px">
                        <Typography variant="body1" noWrap>{name}</Typography>

                        <Typography variant="subtitle2" sx={{ color: palette.blackOne }}>{taskTemplates?.length || 0} Task(s)</Typography>
                      </Box>

                      <Box>
                        <Button
                          sx={{ minWidth: 'auto', padding: 0, fontSize: '12px' }}
                          variant="text"
                          color="primary"
                          component={Link}
                          to='/select-brand'
                          onClick={handleCloseUserMenu}
                        >
                          Switch
                        </Button>
                      </Box>
                    </MenuItem>
                  </CompanyNameBox>
                }

                <Box>
                  {(!!selectedBrandId || isOperator) &&
                    <MenuItem>
                      <Box width='100%'>
                        <ReactRouterLink to='/profile'>
                          <Typography variant="h6" color={palette.black} onClick={handleCloseUserMenu}>Profile</Typography>
                        </ReactRouterLink>
                      </Box>
                    </MenuItem>
                  }

                  <MenuItem>
                    <Box width='100%'>
                      <ReactRouterLink to='/login'>
                        <Typography variant="h6" color={palette.black} onClick={logout}>Logout</Typography>
                      </ReactRouterLink>
                    </Box>
                  </MenuItem>

                  {(!!selectedBrand && (isAdmin || isBrandManager)) && <MenuItem>
                    <Box width='100%'>
                      <ReactRouterLink to='/js-apis'>
                        <Typography variant="h6" color={palette.black} onClick={handleCloseUserMenu}>Docs</Typography>
                      </ReactRouterLink>
                    </Box>
                  </MenuItem>
                  }

                </Box>
              </CustomMenu>
            </Box>
          </Box>
        </Box>
      </Box>
      {((isAdmin || isBrandManager) && !!selectedBrandId) &&
        <Drawer
          anchor='left'
          open={toggleDrawerState}
          onClose={toggleDrawer('left', false)}
        >
          <Box
            sx={{ width: '300px', padding: '10px 18px' }}
            role="presentation"
            onClick={toggleDrawer('left', false)}
            onKeyDown={toggleDrawer('left', false)}
          >
            <Link to='/'>
              <Box component='img' sx={{ ...drawerImage }} src={images.LOGO} alt='logo' />
            </Link>

            <Divider sx={{ marginBottom: '25px', marginInline: '-18px' }} />
            {DASHBOARD_LINK.map((item) => {
              const { link, title } = item

              return (
                <DrawerList key={title}>
                  <ListItemButton component={NavLink} to={link} key={title} sx={{ paddingLeft: 0, textTransform: 'none' }}>{title}</ListItemButton>
                </DrawerList>
              )
            })}
            <DrawerList>
              <ListItemButton component={NavLink} to={recordedVideoUrl} key='Recorded Videos' sx={{ paddingLeft: 0, textTransform: 'none' }}>Recorded Videos</ListItemButton>
            </DrawerList>

            {superAdmin && <DrawerList>
              <ListItemButton component={NavLink} to={devOnlyUrl} key='Dev Only' sx={{ paddingLeft: 0, textTransform: 'none' }}>Dev Only</ListItemButton>
            </DrawerList>}
          </Box>
        </Drawer>
      }

      {(isAdmin && !!selectedBrandId) &&
        <Box sx={companyNameBanner}>
          <Typography variant="body1" textTransform="none" fontWeight='600' color={palette.white} py={.5}>{name} <Typography component='span' variant="subtitle2" textTransform='none'>({taskTemplates?.length || 0} Task(s))</Typography></Typography>
        </Box>
      }
    </Box>
  );
}