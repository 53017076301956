// packages block
import { ApolloProvider } from "@apollo/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
// components block
import { CloseButton, SnackbarUtilsConfiguration } from "./components/common/Alert";
// others
import { useEffect, useState } from "react";
import client from "./apollo";
import { LandscapeOrientation } from "./components/common/LandscapeOrientation";
import { AuthContextProvider } from "./context";
import { AppContextProvider } from "./context/AppContext";
import { MainRoutes } from "./routes";
import customTheme from "./theme";

export const App = () => {
  const [orientation, setOrientation] = useState('landscape')
  const [isMobile, setIsMobile] = useState(false)

  window.onresize = function () {
    setIsMobile(typeof window.orientation !== 'undefined')

    if (isMobile) {
      setOrientation(window.innerWidth > window.innerHeight ? "landscape" : "portrait")
    }
  }

  useEffect(() => {
    setIsMobile(typeof window.orientation !== 'undefined')
    setOrientation(window.innerWidth > window.innerHeight ? "landscape" : "portrait")
  }, [orientation])

  if (orientation === 'landscape' && isMobile) return <LandscapeOrientation />

  return (
    <SnackbarProvider
      maxSnack={5} autoHideDuration={5000} action={key => <CloseButton id={key} />}
      preventDuplicate={true} anchorOrigin={{ vertical: "top", horizontal: "right" }}
      classes={{ containerRoot: 'snackbarProvider' }}
    >
      <SnackbarUtilsConfiguration />

      <ThemeProvider theme={customTheme}>
        <CssBaseline />

        <ApolloProvider client={client}>
          <AuthContextProvider>
            <AppContextProvider>
              <MainRoutes />
            </AppContextProvider>
          </AuthContextProvider>
        </ApolloProvider>
      </ThemeProvider>
    </SnackbarProvider>
  )
};
