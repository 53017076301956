import { useEffect } from "react";
import { useLocation, Navigate, useParams } from "react-router-dom";
import { BRAND_ID, JOB_PATH, VIDEO_DETAIL_PATH } from "../constants";
import { getToken } from "../utils";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const windowLocation = window.location.href
  const splitWindowLocation = windowLocation.split("/brand")
  const lastIndex = splitWindowLocation[splitWindowLocation.length - 1]
  const joinedUrl = `/brand${lastIndex}`
  const pathName = location.pathname
  const isJob = pathName.includes('job')
  const isVideoDetail = pathName.includes('recorded-video')
  const { brandId } = useParams();

  useEffect(() => {
    isJob && localStorage.setItem(JOB_PATH, pathName)
    if (isVideoDetail && brandId) {
      localStorage.setItem(VIDEO_DETAIL_PATH, joinedUrl)
      const brandIdAvailable = localStorage.getItem(BRAND_ID)
      !brandIdAvailable && localStorage.setItem(BRAND_ID, brandId)
    }
    // eslint-disable-next-line
  }, [pathName])

  if (!getToken()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
}