import { Skeleton, Box } from '@mui/material'

export const KioskWidgetLoader = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
    <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
      <Box sx={{ width: 140, height: '75vh' }}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>

      <Box sx={{ flex: 1, height: '87vh', px: 3 }}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>

      <Box sx={{ width: 140, height: '75vh' }}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
    </Box>
  </Box>
)