import { Box, Button, Slide, Tab, Tabs, Typography, useMediaQuery } from '@mui/material'
import { FC, SyntheticEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { useSearchParams } from 'react-router-dom'
import { images, MoveArrow } from '../../../../../assets/images'
import { VideoBox, VideoGridType, VideoLayout, WidgetType } from '../../../../../generated'
import { BRAND_ID } from '../../../../constants'
import { VideoSettingPreviewProps } from '../../../../interfaceTypes'
import palette from '../../../../theme/palette'
import { VideoPlayIcon } from '../../../../theme/styleComponents'
import { customIconLinkBox, flexCenter, pathWaysBannerStyle, portraitMobileButtonStyle, tabsStyle } from '../../../../theme/styleConstants'
import { handleTabs } from "../../../../utils"
import { CustomIconLink } from '../../../common/CustomIconLink'
import { MobilePreview } from '../../../common/MobilePreview'
import { TabPanel } from '../../../common/TabPanel'
import { PreviewHookVideoLayout } from '../../../preview/PreviewHookVideoLayout'
import { PreviewHookVideoLayoutMobile } from '../../../preview/PreviewHookVideoLayoutMobile'
import { PreviewVideoLayout } from '../../../preview/PreviewVideoLayout'
import { PreviewVideoLayoutMobile } from '../../../preview/PreviewVideoLayoutMobile'

export const VideoSettingPreview: FC<VideoSettingPreviewProps> = ({ thumbnailUrls, videoIds, thumbnailsToShow, headingValue, headingColorValue, descriptionValue, descriptionColorValue, portraitThumbnailUrls, landscapeThumbnailUrls, hookVideos }) => {
  const [preview, setPreview] = useState(0);
  const mediumViewport = useMediaQuery('(max-width:575px)');
  const brandId = localStorage.getItem(BRAND_ID);
  const [searchParams] = useSearchParams();
  const widgetId = searchParams.get('widgetId');

  const handlePreviewChange = (event: SyntheticEvent, newValue: number) => {
    setPreview(newValue);
  };

  const { watch } = useFormContext()
  const isPortraitValue = watch('isPortrait')
  const videoGridTypeValue = watch('videoGridType')
  const videoLayoutValue = watch('videoLayout')
  const videoBoundingBoxValue = watch('videoBoundingBox')
  const backgroundColorValue = watch('backgroundColor')
  const pathWaysButtonTextValue = watch('pathWaysButtonText')
  const pathWaysMobileCtaButtonStyleValue = watch('pathWaysMobileCtaButtonStyle')
  const pathWaysButtonBackgroundColorValue = watch('pathWaysButtonBackgroundColor')
  const pathWaysButtonColorValue = watch('pathWaysButtonColor')
  const pathWaysMobileCtaButtonTextValue = watch('pathWaysMobileCtaButtonText')
  const tellingFormBorderColorValue = watch('tellingFormBorderColor')
  const skipVideosValue = watch('skipVideos')
  const blockWidgetLink = `${process.env.REACT_APP_BASE_URL}/widget-preview/brand/${brandId}/widget/${widgetId}?widgetType=${WidgetType.Block}`


  return (
    <>
      <Box sx={{ ...tabsStyle, position: 'relative' }}>
        <Tabs value={preview} onChange={handlePreviewChange} aria-label="basic tabs example">
          <Tab sx={{ textTransform: 'capitalize' }} label="Desktop" {...handleTabs(0)} />

          <Tab sx={{ textTransform: 'capitalize' }} label="Mobile" {...handleTabs(1)} />
        </Tabs>

        <Box sx={customIconLinkBox}>
          <CustomIconLink widgetLink={blockWidgetLink} />
        </Box>
      </Box>

      <TabPanel value={preview} index={0}>
        <Box>
          {videoIds && videoIds.length === 0 ?
            <Box sx={flexCenter} flexDirection="column" padding='20px' marginTop='1rem'>
              <Box component='img' src={images.DUMMY_PREVIEW} width="100%" height='150px' />
              <Typography sx={{ marginTop: '16px' }}>Please select video to generate preview</Typography>
            </Box>
            :
            <Box sx={{ backgroundColor: backgroundColorValue }} paddingTop='1rem'>
              <Typography px={3} variant='body2' textAlign='center' sx={{ color: headingColorValue, fontSize: '18px' }}>{headingValue}</Typography>

              <Typography px={3} variant='body2' textAlign='center' sx={{ color: descriptionColorValue, fontSize: '13px' }}>{descriptionValue}</Typography>

              <Slide in={skipVideosValue} direction='left' mountOnEnter unmountOnExit>
                <Box>
                  <Box height={270}>
                    {skipVideosValue &&
                      <PreviewHookVideoLayout hookVideos={hookVideos} />
                    }
                  </Box>
                </Box>
              </Slide>

              <Slide in={!skipVideosValue} direction='left' mountOnEnter unmountOnExit>
                <Box>
                  {!skipVideosValue &&
                    <>
                      <PreviewVideoLayout
                        selectedVideos={videoIds}
                        attachmentsToShow={thumbnailsToShow}
                        thumbnailUrls={isPortraitValue ? portraitThumbnailUrls : landscapeThumbnailUrls || thumbnailUrls}
                        videoGridType={videoGridTypeValue as unknown as VideoGridType}
                        videoLayout={videoLayoutValue as unknown as VideoLayout}
                        videoBoundingBox={videoBoundingBoxValue as unknown as VideoBox}
                      />

                      <Box sx={pathWaysBannerStyle}>
                        <Box border={`1px solid ${tellingFormBorderColorValue}`} display='flex' justifyContent='center' alignItems='center' gap='1rem'>
                          <Box>
                            <Box display='flex' justifyContent='flex-start' alignItems='center'>
                              <Box position='relative' mr={2}>
                                <Box component='img' src={images.VIDEO_THUMBNAIL_1} width={mediumViewport ? '100px' : '162px'} height='90px' display='block' />

                                <VideoPlayIcon>
                                  <PlayCircleIcon sx={{ color: palette.white, width: '30px', height: '30px' }} />
                                </VideoPlayIcon>
                              </Box>
                              <Box>
                                <Typography fontSize='1.125rem' maxWidth='200px' noWrap>video heading goes here...</Typography>
                                <Typography fontSize='.7rem' maxWidth='200px' noWrap>video description goes here...</Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Box>
                            <Button sx={{ background: `${pathWaysButtonBackgroundColorValue} !important`, marginRight: '1rem' }}>
                              <Typography maxWidth='70px' noWrap sx={{ color: pathWaysButtonColorValue }}>{pathWaysButtonTextValue || 'N/A'}</Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                </Box>
              </Slide>
            </Box>
          }
        </Box>
      </TabPanel >

      <TabPanel value={preview} index={1}>
        <Box>
          <MobilePreview>
            <Box position='relative' top={64} padding={2} marginTop='1rem'>
              {videoIds && videoIds.length === 0 ?
                <Box sx={flexCenter} flexDirection="column" padding='20px'>
                  <Box component='img' src={images.DUMMY_PREVIEW} width="100%" height='150px' />

                  <Typography sx={{ marginTop: '16px' }}>Please select video to generate preview</Typography>
                </Box>
                :
                <Box sx={{ backgroundColor: backgroundColorValue }} paddingTop='1rem'>
                  {!skipVideosValue &&
                    <>
                      <Typography px={3} textAlign='center' variant='body2' sx={{ color: headingColorValue }}>{headingValue}</Typography>

                      <Typography px={3} variant='body2' textAlign='center' sx={{ color: descriptionColorValue }}>{descriptionValue}</Typography>
                    </>
                  }

                  {skipVideosValue ?
                    <PreviewHookVideoLayoutMobile
                      hookVideos={hookVideos}
                    />
                    :
                    <>
                      <PreviewVideoLayoutMobile
                        selectedVideos={videoIds}
                        attachmentsToShow={thumbnailsToShow}
                        thumbnailUrls={isPortraitValue ? portraitThumbnailUrls : landscapeThumbnailUrls || thumbnailUrls}
                        videoGridType={videoGridTypeValue as unknown as VideoGridType}
                        videoLayout={videoLayoutValue as unknown as VideoLayout}
                        videoBoundingBox={videoBoundingBoxValue as unknown as VideoBox}
                      />

                      {isPortraitValue ?
                        <Box sx={portraitMobileButtonStyle}>
                          <Button sx={{ borderRadius: pathWaysMobileCtaButtonStyleValue, background: 'rgba(0,0,0,.4) !important', border: `1px solid ${palette.white}` }}>
                            <>
                              <Typography maxWidth='70px' noWrap sx={{ color: pathWaysButtonColorValue, marginRight: '5px' }}>{pathWaysMobileCtaButtonTextValue || 'N/A'}</Typography>
                              {MoveArrow()}
                            </>
                          </Button>
                        </Box>
                        :
                        <Box sx={pathWaysBannerStyle}>
                          <Box display='flex' justifyContent='space-between' alignItems='center' py={2} px={4}>
                            <Box mr={mediumViewport ? 3 : 10}>
                              <Typography fontSize='1.125rem' maxWidth='150px' noWrap>video heading goes here...</Typography>
                              <Typography fontSize='.7rem' maxWidth='150px' noWrap>video description goes here...</Typography>
                            </Box>

                            <Box>
                              <Button sx={{ background: `${pathWaysButtonBackgroundColorValue} !important` }}>
                                <Typography maxWidth='70px' noWrap sx={{ color: pathWaysButtonColorValue }}>{pathWaysButtonTextValue || 'N/A'}</Typography>
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      }
                    </>
                  }
                </Box>
              }
            </Box>
          </MobilePreview>
        </Box>
      </TabPanel>
    </>
  )
}
