import { Box } from '@mui/material'
import { FC, useContext } from 'react'
import { AppContext } from '../../context'
import { ActionType } from '../../context/AppContextReducer'
import { BrandSelectionInterface } from '../../interfaceTypes'
import { CustomModal } from '../common/CustomModal'
import { AddBrandStepper } from './AddBrandStepper'

export const AddBrandModal: FC<BrandSelectionInterface> = ({ brandState, brandDispatch, refetch }) => {
  const { isOpen, dispatch } = useContext(AppContext)

  const handleBrandClose = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: false })
  };

  return (
    <CustomModal
      title="Add Brand"
      maxWidth='700px'
      isOpen={isOpen}
      handleClose={handleBrandClose}
    >
      <Box>
        <AddBrandStepper brandState={brandState} brandDispatch={brandDispatch} refetch={refetch} />
      </Box>
    </CustomModal>
  )
}
