import { Box, Grid, Skeleton } from '@mui/material'
import { FC } from 'react'
import { SelectBrandArea } from '../../theme/styleComponents'
import { BrandSkeletonProps } from "../../interfaceTypes";

export const BrandSelectionSkeleton: FC<BrandSkeletonProps> = ({ noOfItems }) => (
  <Grid container spacing={2} rowSpacing={2}>
    {new Array(noOfItems).fill(0).map((_, index) => (
      <Grid item lg={3} md={4} sm={12} xs={12} key={`brand-skeleton-${index}`}>
        <Box>
          <SelectBrandArea>
            <Skeleton animation="wave" sx={{ height: "120px", width: '100%' }} />
            <Skeleton animation="wave" sx={{ width: '100%' }} />
          </SelectBrandArea>
        </Box>
      </Grid>
    ))}
  </Grid>
)