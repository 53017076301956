// packages import
import { Box, Chip, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import moment from "moment"
import { FC, Reducer, useContext, useEffect, useReducer } from "react"
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
// other import
import { NotVerifiedIcon, PendingIcon, VerifiedIcon } from '../../../assets/images'
import { useGetAllUsersLazyQuery, UserRoles } from "../../../generated"
import { BRAND_ID, DATE_FORMAT, GRAPHQL_QUERY_POLICY, NO_USER_FOUND, TIME_FORMAT } from "../../constants"
import { AppContext, AuthContext } from "../../context"
import { ActionType } from "../../context/AppContextReducer"
import { UserAction, UserActionType, userInitialState, userReducer, UserState } from "../../context/UserContextReducer"
import palette from "../../theme/palette"
import { firstLetterUppercase } from "../../utils"
import { Alert } from "../common/Alert"
import { CustomPagination } from "../common/CustomPagination"
import { NoDataFound } from "../common/NoDataFound"
import { StickyTable } from "../common/StickyTable"
import { Users } from "../menus/Users"
import { UsersSkeleton } from "../skeletons/UsersSkeleton"
import { UsersActions } from "./UsersActions"

export const UserListing: FC = () => {
  const { pageLimit, currentPage, dispatch } = useContext(AppContext)
  const { isBrandManager, isAdmin } = useContext(AuthContext)
  const [userState, userDispatch] = useReducer<Reducer<UserState, UserAction>>(userReducer, userInitialState);
  const { usersData, userRole, keyword, selectedBrand } = userState
  const userRoles = userRole === "" ? [UserRoles.Admin, UserRoles.Operator, UserRoles.BrandManager] : userRole === UserRoles.Admin ? [UserRoles.Admin] : userRole === UserRoles.BrandManager ? [UserRoles.BrandManager] : userRole === UserRoles.Operator ? [UserRoles.Operator] : [UserRoles.Admin, UserRoles.Operator, UserRoles.BrandManager]

  const [getAllUsers, { loading, refetch }] = useGetAllUsersLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() {
      Alert.error(NO_USER_FOUND)
      userDispatch({ type: UserActionType.SET_USERS_DATA, usersData: [] })
    },

    onCompleted(data) {
      const { getAllUsers } = data

      if (getAllUsers) {
        const { users, pagination } = getAllUsers

        if (users) {
          userDispatch({ type: UserActionType.SET_USERS_DATA, usersData: users })
        }

        if (pagination) {
          const { totalPages } = pagination

          dispatch({ type: ActionType.SET_TOTAL_PAGE_COUNT, totalPageCount: totalPages || 0 })
        }
      }
    }
  })

  const filterBrandId = () => {
    if (selectedBrand?.id === "all-brands" && isAdmin) return { brandId: undefined }
    if (selectedBrand?.id !== "" && isAdmin) return { brandId: selectedBrand?.id }

    if (isBrandManager) {
      return { brandId: localStorage.getItem(BRAND_ID) }
    }
  }

  useEffect(() => {
    getAllUsers({
      variables: {
        userInput: {
          keyword: (keyword && keyword.length > 2) ? keyword : "",
          roles: userRoles,
          ...(filterBrandId()),

          paginationOptions: {
            page: currentPage,
            limit: pageLimit
          }
        }
      }
    })
    // eslint-disable-next-line
  }, [selectedBrand?.id, userRole, keyword, currentPage])

  useEffect(() => {
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
    // eslint-disable-next-line
  }, [])

  const isEmpty = usersData?.length === 0 && !loading

  return (
    <>
      <UsersActions loading={loading} dispatch={userDispatch} refetch={refetch} state={userState} />

      {isEmpty ?
        <NoDataFound height='calc(100vh - 285px)' description='Record not found' />
        :
        <StickyTable pagination={<CustomPagination loading={loading} />}>
          <TableHead>
            <TableRow>
              <TableCell>Staff</TableCell>
              <TableCell sx={{ minWidth: '150px' }}>Joined on</TableCell>
              <TableCell sx={{ minWidth: '150px' }}>Email verification</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Moderated</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          {loading ?
            <UsersSkeleton noOfRows={10} />
            :
            <TableBody>
              {usersData?.map(user => {
                const { id, fullName, status, email, emailVerified, createdAt, updatedAt, roles, isModerated, userBrands } = user || {}
                const createdDate = moment(createdAt).format(DATE_FORMAT)
                const updatedTime = moment(updatedAt).format(TIME_FORMAT)
                const userRoles = roles?.map(userRole => {
                  const role = userRole?.role.split("_").join(" ")
                  return firstLetterUppercase(role || '')
                })

                const isDefaultAssignee = isAdmin ? userBrands?.filter(userBrand => userBrand?.brandId === selectedBrand?.id) : userBrands?.filter(userBrand => userBrand?.brandId === localStorage.getItem(BRAND_ID))

                return (
                  <TableRow key={id} hover>
                    <TableCell>
                      <Box maxWidth='250px'>
                        <Box display='flex' alignItems='center'>
                          {isDefaultAssignee?.pop()?.defaultAssignee && <AssignmentIndIcon fontSize="small" color="primary" />}

                          <Tooltip title={fullName} placement="bottom-start">
                            <Typography variant='body2' color='inherit' noWrap>{fullName}</Typography>
                          </Tooltip>
                        </Box>
                        <Typography variant='h3' sx={{ fontWeight: '500', color: palette.blackTwo }}>{email}</Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Typography variant='body2' color='inherit'>{createdDate}</Typography>
                      <Typography variant='h3' sx={{ fontWeight: '500', color: palette.blackTwo }}>{updatedTime}</Typography>
                    </TableCell>

                    <TableCell>
                      <Box display='flex'>
                        {emailVerified ? <VerifiedIcon /> : <PendingIcon />}
                        <Typography fontWeight='600' sx={{ marginLeft: '1rem' }} variant='body2'>{emailVerified ? 'Verified' : 'Pending'}</Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: 'flex', gap: '10px' }}>
                        {userRoles?.map((role) =>
                          <Chip key={role} sx={{ fontWeight: '600', border: `1px solid ${palette.primary.main}` }} label={role} variant="outlined" color="default" />
                        )}
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Chip sx={{ fontWeight: '600', border: `1px solid ${palette.primary.main}` }} label={firstLetterUppercase(status || '')} variant="outlined" color="default" />
                    </TableCell>

                    <TableCell>
                      <Box display='flex' justifyContent='center'>
                        {isModerated ? <VerifiedIcon /> : <NotVerifiedIcon />}
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Users currentUserData={user} usersData={usersData} state={userState} dispatch={userDispatch} refetch={refetch} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          }
        </StickyTable>
      }
    </>
  )
}