import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { Reducer, useContext, useEffect, useReducer, useState } from "react";
import { useGetWidgetDetailsLazyQuery } from "../../generated";
import { GRAPHQL_QUERY_POLICY, VariantAlert } from "../constants";
import { SocketContext } from "../context/SocketContext";
import { WidgetAction, WidgetActionType, widgetInitialState, widgetReducer, WidgetState } from "../context/WidgetContextReducer";
import { KioskUserStatus, WidgetKioskState } from "../interfaceTypes";
import palette from "../theme/palette";
import { KioskWidgetAlert, KioskWidgetWrapper } from "../theme/styleComponents";
import KioskWidgetFocus from "./focusWidget";
import { KioskWidgetIdle } from "./idleWidget";
import { KioskWidgetLoader } from "./KioskWidgetLoader";
import { MultipleKeyPress } from "./focusWidget/MultipleKeyPress";

export const KioskWidget = () => {
  const [state, dispatch] = useReducer<Reducer<WidgetState, WidgetAction>>(widgetReducer, widgetInitialState)
  const { socketBrandId, socketWidgetId, isConnected, hangUpData } = useContext(SocketContext)
  const { widgetKioskState, widgetKioskData, tooManyKeyStrokes } = state
  const [disconnected, setDisconnected] = useState(false)
  const [connected, setConnected] = useState(false)
  const { backgroundColor, pauseTimer } = widgetKioskData || {}

  const [getWidget, { loading, refetch }] = useGetWidgetDetailsLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError() { },

    onCompleted(data) {
      const { getWidgetDetails } = data || {}
      getWidgetDetails && dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_DATA, widgetKioskData: getWidgetDetails })
    }
  });

  useEffect(() => {
    if (socketBrandId && socketWidgetId) {
      getWidget({
        variables: {
          widgetId: socketWidgetId,
          brandId: socketBrandId
        },
      })
    }
  }, [socketBrandId, socketWidgetId, getWidget, hangUpData.data.message]);

  const toggleKioskWidget = () => {
    dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_STATE, widgetKioskState: WidgetKioskState.Idle })
    dispatch({ type: WidgetActionType.SET_WIDGET_KIOSK_USER_STATUS, widgetKioskUserStatus: KioskUserStatus.Initial })
  }

  useEffect(() => {
    if (isConnected) {
      setConnected(true)
      setDisconnected(false)
      refetch()
    } else {
      setDisconnected(true)
    }
    // eslint-disable-next-line
  }, [isConnected])


  useEffect(() => {
    let connectTimer: string | number | NodeJS.Timeout | undefined;
    if (isConnected) {
      connectTimer = setTimeout(() => setConnected(false), 1000);
    }
    return () => clearTimeout(connectTimer);
  }, [isConnected]);


  return (
    tooManyKeyStrokes ?
      <MultipleKeyPress state={state} pauseTimer={pauseTimer} />
      :
      <Box sx={{ backgroundColor: backgroundColor, width: '100%', overflow: 'hidden', height: '100vh' }}>
        <AnimatePresence>
          {disconnected &&
            <KioskWidgetAlert
              sx={{ backgroundColor: '#d32f2f', }}
              variants={VariantAlert}
              initial="initial"
              animate="visible"
              exit="exit"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ErrorOutline sx={{ marginRight: '8px' }} />

                <Typography sx={{ color: palette.white, p: 0.5, fontSize: '24px' }}>You are disconnected!</Typography>
              </Box>
            </KioskWidgetAlert>
          }
        </AnimatePresence>

        <AnimatePresence>
          {connected &&
            <KioskWidgetAlert
              sx={{ backgroundColor: '#07941d' }}
              variants={VariantAlert}
              initial="initial"
              animate="visible"
              exit="exit"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CheckCircleOutline sx={{ marginRight: '8px' }} />

                <Typography sx={{ color: palette.white, p: 0.5, fontSize: '24px' }}>You are connected!</Typography>
              </Box>
            </KioskWidgetAlert>
          }
        </AnimatePresence>

        <>
          {loading ?
            <KioskWidgetLoader />
            :
            widgetKioskState === WidgetKioskState.Idle ?
              <AnimatePresence>
                <KioskWidgetWrapper className={`${widgetKioskState === WidgetKioskState.Idle ? 'with-active' : ''}`}>
                  <motion.div
                    initial={{ x: "100%" }}
                    animate={{ x: 0 }}
                    exit={{ x: "-100%" }}
                    transition={{ type: "just", delay: 1 }}
                  >
                    <KioskWidgetIdle
                      state={state}
                      dispatch={dispatch}
                    />
                  </motion.div>
                </KioskWidgetWrapper>
              </AnimatePresence>
              :
              <KioskWidgetWrapper className={`${widgetKioskState === WidgetKioskState.Focused ? 'with-active' : ''}`}>
                <KioskWidgetFocus
                  state={state}
                  dispatch={dispatch}
                  toggleKioskWidget={toggleKioskWidget}
                />
              </KioskWidgetWrapper>
          }
        </>
      </Box>
  )
}
