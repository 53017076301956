import { Box, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@mui/material'
import { FC, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AppContext, AuthContext } from '../../context';
import { ActionType } from '../../context/AppContextReducer';
import { UploadVideoThumbnailInterface } from '../../interfaceTypes';
import { images } from '../../../assets/images';
import palette from '../../theme/palette';
import { thumbnailCardWrapper, UserThumbnail } from '../../theme/styleConstants';
import { UploadImageModal } from '../profile/UploadImageModal';

const UploadVideoThumbnail: FC<UploadVideoThumbnailInterface> = ({ userThumbnails, isLandscape }) => {
  const { currentUser } = useContext(AuthContext)
  const { isOpen, dispatch } = useContext(AppContext)
  const { id: userId } = currentUser || {}
  const { control, formState: { errors } } = useFormContext()
  const { thumbnailId } = errors

  const handleProfileDialog = () => {
    dispatch({ type: ActionType.SET_IS_OPEN, isOpen: !isOpen })
  };

  return (
    <>
      <Box display="flex" justifyContent="between">
        <Box flex={1}>
          <Typography variant="h6">Select Thumbnail</Typography>

          <Typography variant="subtitle2" sx={{ opacity: '0.6' }}>Choose appropriate thumbnail or upload a custom image.</Typography>
        </Box>

        <Box width='65px' height='65px' sx={{ cursor: "pointer" }} onClick={() => { handleProfileDialog() }}>
          <Box component='img' src={images.UPLOAD_PHOTO_THUMBNAIL} sx={{ width: '100%' }} />
        </Box>
      </Box>

      <Box marginTop='1rem' display="flex" alignItems='center'>
        <Controller
          control={control}
          name="thumbnailId"
          render={({ field }) => (
            <RadioGroup
              {...field}
              value={field.value}
              onChange={(_event, value) => field.onChange(value)}
              row
              sx={{ gap: '10px', width: '100%' }}
            >
              {userThumbnails?.map((thumbnail) => {
                const { id, url } = thumbnail || {}
                const thumbnailUrlArray = url?.split('/upload')
                const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
                const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')
                const isChecked = field.value === id

                return (
                  <Box key={id} sx={{ ...thumbnailCardWrapper, borderRadius: '4px', position: 'relative', border: `1px solid ${isChecked ? palette.primary.main : palette.lightGray}` }}>
                    <FormControlLabel
                      value={id}
                      control={<Radio checked={isChecked} />}
                      label={false}
                      sx={{ position: 'absolute', height: '100%', width: '100%', opacity: 0, top: 0, left: 0, margin: 0 }}
                    />

                    <Box component='img' src={updatedThumbnailUrl || ""} sx={UserThumbnail} />

                    {isChecked &&
                      <Box sx={{ position: 'absolute', top: '-13px', right: '-8px' }}>
                        <CheckCircleIcon color='primary' sx={{ backgroundColor: palette.white, borderRadius: '50%' }} />
                      </Box>
                    }
                  </Box>
                )
              })}
            </RadioGroup>
          )}
        />
      </Box>

      {thumbnailId && <FormHelperText error>{thumbnailId.message as string}</FormHelperText>}

      <UploadImageModal
        isOpen={isOpen}
        handleClose={handleProfileDialog}
        entityType="users"
        isLandscape={isLandscape}
        typeId={userId || ""}
        attachmentType="thumbnail"
        attachmentId={undefined}
      />
    </>
  )
}

export default UploadVideoThumbnail