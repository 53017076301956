import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CustomMenu } from '../../theme/styleComponents'
import { btnAddMacro } from '../../theme/styleConstants'
import { PostMacroProps } from '../../interfaceTypes';

export const PostMacro: FC<PostMacroProps> = ({ postMacros, handleChangePost, anchorElTask, handleOpenUserMenuTask, handleCloseUserMenuTask, top }) => (
  <>
    <Button sx={{ ...btnAddMacro, top: top || '10px' }} aria-label="delete" size="small" onClick={handleOpenUserMenuTask}>
      Add Macros <ArrowDropDownIcon fontSize="inherit" />
    </Button>

    <CustomMenu
      sx={{ mt: '30px' }}
      id="headerCompaniesSelect"
      anchorEl={anchorElTask}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorElTask)}
      onClose={handleCloseUserMenuTask}
    >
      <Box maxHeight={150} sx={{ overflowY: 'auto' }}>
        {postMacros && postMacros.map((macro) => <MenuItem key={macro} onClick={() => handleChangePost(macro)}>{macro}</MenuItem>)}
      </Box>
    </CustomMenu>
  </>
)
