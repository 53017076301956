import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Popover, Select, Switch, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import DOMPurify, { sanitize } from "dompurify";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import ReactPlayer from 'react-player';
import { useNavigate, useParams } from 'react-router-dom';
import { NotVerifiedIcon, PlayerIcon, VerifiedIcon } from '../../../assets/images';
import { TaskTemplate, TaskTemplateType, VideoOrientation, useGetMacrosLazyQuery, useGetTaskTemplateLazyQuery, useUpdateTaskTemplateMutation } from '../../../generated';
import { GRAPHQL_QUERY_POLICY } from '../../constants';
import { TaskActionType } from '../../context/TaskContextReducer';
import { EditTaskProps, UpdateTaskTemplate, VideoSettingProps } from '../../interfaceTypes';
import palette from '../../theme/palette';
import { AlertWarning, RenderVideo, TaskVideoBox, UploadArea, UploadBox, UploadIcon } from '../../theme/styleComponents';
import { editorBox, taskOrders } from '../../theme/styleConstants';
import { firstLetterUppercase, formatEnumMember } from '../../utils';
import { editTaskTemplateValidationSchema } from '../../validationSchema';
import { CreateVideoModalForTasks } from '../brandSetting/videoLibrary/CreateVideoModalForTasks';
import { FullDetailVideo } from '../brandSetting/videoLibrary/FullDetailVideo';
import { Alert } from '../common/Alert';
import { CustomBreadCrumb } from '../common/CustomBreadCrumb';
import { CustomController } from '../common/CustomController';
import { CustomMacro } from '../common/CustomMacro';
import { PageHeading } from '../common/PageHeading';
import { PostMacro } from '../common/PostMacro';
import Tags from './Tags';
import { TaskEditTemplateSkeleton } from './TaskEditTemplateSkeleton';

export const TaskEditTemplate: FC<EditTaskProps> = ({ tasksData, taskDispatch }) => {
  const [editScript, setEditScript] = useState<EditorState>(EditorState.createEmpty());
  const [scriptMessage, setScriptMessage] = useState<string>('')
  const [taskTemplateMessage, setTaskTemplateMessage] = useState<EditorState>(EditorState.createEmpty());
  const [taskMessage, setTaskMessage] = useState<string>('')
  const [videoSettings, setVideoSettings] = useState<VideoSettingProps>({ url: "", played: 0, loaded: 0, pip: false, playing: false })
  const [open, setOpen] = useState(false);
  const [checkType, setCheckType] = useState<boolean>(false)
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isVideoEditOpen, setIsVideoEditOpen] = useState<boolean>(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [smsTextMacros, setSmsTextMacros] = useState<Array<string>>([])
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<TaskTemplate>()
  const [moderateStatus, setModerateStatus] = useState<boolean>(false);
  const [anchorElTask, setAnchorElTask] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const postMacros = ["{{operatorName}}", "{{brandName}}"];
  const params = useParams()
  const taskId = params.id
  const [anchorElPopover, setAnchorElPopover] = useState<HTMLElement | null>(null);
  DOMPurify.setConfig({ ADD_ATTR: ['target'] });

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };

  const popOverOpen = Boolean(anchorElPopover);

  const {
    name, description, message, segmentAudienceKey, script, subject, videoPageDescription, videoPageHeading, videoDescription,
    videoHeading, postGreetMessage, postGreetTitle, tags, isModerated, templateType, video, smsText
  } = taskData || {}

  const handleModerate = async () => {
    setModerateStatus(!moderateStatus)
  };

  const methods = useForm<UpdateTaskTemplate>({
    mode: "all",
    resolver: yupResolver(editTaskTemplateValidationSchema),

    defaultValues: {
      name: name || "",
      description: description || "",
      segmentAudienceKey: segmentAudienceKey || "",
      subject: subject || "",
      script: script || "",
      message: message || "",
      videoPageDescription: videoPageDescription || "",
      videoPageHeading: videoPageHeading || "",
      postGreetMessage: postGreetMessage || "",
      postGreetTitle: postGreetTitle || "",
      videoDescription: videoDescription || "",
      videoHeading: videoHeading || "",
      tags: tags?.split(', ') || [],
      templateType: templateType || TaskTemplateType.Manual,
      isModerated,
      smsText: smsText || ""
    },
  });

  const resetFields = () => {
    reset({
      name: name || "",
      description: description || "",
      segmentAudienceKey: segmentAudienceKey || "",
      subject: subject || "",
      script: script || "",
      message: message || "",
      videoPageDescription: videoPageDescription || "",
      videoPageHeading: videoPageHeading || "",
      videoHeading: videoHeading || "",
      videoDescription: videoDescription || "",
      postGreetMessage: postGreetMessage || "",
      postGreetTitle: postGreetTitle || "",
      tags: [],
      isModerated,
      templateType,
      smsText: smsText || ""
    });
  }

  const onEditorStateChange = (editorState: EditorState) => {
    setEditScript(editorState)
  };

  const onEditorMessageStateChange = (editorState: EditorState) => {
    setTaskTemplateMessage(editorState)
  };

  const htmlToDraftBlocks = (html: string) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  useEffect(() => {
    setEditScript(htmlToDraftBlocks(scriptMessage))
  }, [scriptMessage])

  useEffect(() => {
    setTaskTemplateMessage(htmlToDraftBlocks(taskMessage))
  }, [taskMessage])

  const { handleSubmit, reset, setValue, getValues, watch, setFocus, control } = methods;
  const formValues = getValues()

  const [getTask, { loading: taskLoading, refetch }] = useGetTaskTemplateLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: {
      id: taskId || ""
    },

    onError({ message }) {
      return Alert.error(message)
    },

    onCompleted(data) {
      const { getTaskTemplate } = data || {}
      const { script, message } = getTaskTemplate || {}

      if (getTaskTemplate) {
        setTaskData(getTaskTemplate)
        script && setScriptMessage(script)
        message && setTaskMessage(message)
      }
    }
  })

  useEffect(() => {
    if (taskId) {
      getTask()
    }
  }, [taskId, getTask])

  const [taskMacros] = useGetMacrosLazyQuery({
    ...GRAPHQL_QUERY_POLICY,
    variables: undefined,

    onError({ message }) {
      return Alert.error(message)
    },

    onCompleted(data) {
      const { getMacros } = data
      setSmsTextMacros(() => getMacros)
    }
  })

  useEffect(() => {
    taskMacros()
  }, [taskMacros])

  const handleChange = (macro: string) => {
    const jobMacro = macro;
    const { smsText } = watch();
    if (smsText) {
      setValue('smsText', smsText.concat(" ", jobMacro))
    }
    else {
      setValue('smsText', smsText ? smsText + jobMacro : jobMacro)
    }
    setAnchorElUser(null);
    setFocus("smsText", { shouldSelect: true })
  };

  useEffect(() => {
    name && setValue("name", name);
    description && setValue("description", description);
    segmentAudienceKey && setValue("segmentAudienceKey", segmentAudienceKey);
    script && setValue("script", script);
    message && setValue("message", message);
    smsText && setValue("smsText", smsText);
    subject && setValue("subject", subject);
    videoPageDescription && setValue("videoPageDescription", videoPageDescription);
    videoPageHeading && setValue("videoPageHeading", videoPageHeading)
    postGreetMessage && setValue("postGreetMessage", postGreetMessage)
    postGreetTitle && setValue("postGreetTitle", postGreetTitle)
    videoHeading && setValue("videoHeading", videoHeading)
    videoDescription && setValue("videoDescription", videoDescription)
    if (tags) {
      setValue("tags", tags.split(', '))
    }
  }, [
    name, description, segmentAudienceKey, script, message, subject, videoPageDescription, postGreetTitle, videoPageHeading,
    postGreetMessage, videoHeading, videoDescription, tags, setValue, moderateStatus, isEditOpen, smsText
  ]);
  const { url: videoUrl, thumbnail, id: attachmentId, videoOrientation, title, description: uploadedVideoDescription } = video || {}
  const { url: videoThumbnailUrl } = thumbnail || {}
  const thumbnailUrlArray = videoThumbnailUrl?.split('/upload')
  const updatedThumbnailUrlArray = thumbnailUrlArray && [...thumbnailUrlArray.slice(0, 1), '/upload/q_auto', ...thumbnailUrlArray.slice(1)]
  const updatedThumbnailUrl = updatedThumbnailUrlArray?.join('')

  const [updateTaskTemplate, { loading }] = useUpdateTaskTemplateMutation({
    onError({ message }) {
      return Alert.error(firstLetterUppercase(message || ""))
    },

    onCompleted(data) {
      const { updateTaskTemplate } = data;
      const { script, message } = updateTaskTemplate || {}
      if (updateTaskTemplate) {
        setTaskData(updateTaskTemplate)
        script && setScriptMessage(script)
        message && setTaskMessage(message)

        const updatedTaskTemplates = tasksData?.map((task) => {
          if (task.id === updateTaskTemplate.id) {
            return updateTaskTemplate;
          }
          return task;
        })

        taskDispatch && taskDispatch({ type: TaskActionType.SET_TASKS_DATA, tasksData: updatedTaskTemplates || [] });
      }

      Alert.success("Task edited successfully.");
      reset();

      if (checkType === false) {
        setIsEditOpen(false)
      }
    }
  });

  const handleModal = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    navigate('/task-template')
  }

  const handleUpload = () => {
    setIsVideoModalOpen(true)
  }

  const videoHandleClose = () => {
    setIsVideoModalOpen(false)
  }

  const handleOpenUserMenuTask = (event: MouseEvent<HTMLElement>) => {
    setAnchorElTask(event.currentTarget);
  };

  const handleCloseUserMenuTask = () => {
    setAnchorElTask(null);
  };

  const handleChangePost = (postMacro: string) => {
    const jobMacro = postMacro;
    const { postGreetMessage } = watch();
    if (postGreetMessage) {
      setValue('postGreetMessage', postGreetMessage.concat(" ", jobMacro))
    }
    else {
      setValue('postGreetMessage', postGreetMessage ? postGreetMessage + jobMacro : jobMacro)
    }
    setAnchorElTask(null);
    setFocus("postGreetMessage", { shouldSelect: true })
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const loadUrl = (url: string) => {
    setVideoSettings({
      url, played: 0, loaded: 0, pip: false, playing: false
    })
  }

  const handlePlay = () => {
    setVideoSettings({ ...videoSettings, playing: true })
  }

  const { url: playVideoUrl, pip, playing } = videoSettings

  useEffect(() => {
    loadUrl(videoUrl || "")
  }, [videoUrl])
  const onSubmit = async (data: UpdateTaskTemplate) => {
    const { tags } = data
    setCheckType(false)
    const rawContentState = convertToRaw(editScript.getCurrentContent());
    const content = draftToHtml(rawContentState);
    const messageRawContentState = convertToRaw(taskTemplateMessage.getCurrentContent());
    const messageContent = draftToHtml(messageRawContentState);

    await updateTaskTemplate({
      variables: {
        updateTaskTemplateInput: {
          ...data,
          templateType,
          tags: tags.join(', '),
          id: taskId || "",
          isModerated: moderateStatus,
          script: content,
          message: messageContent
        }
      },
    });
  }

  const renderUpload = () => (
    <UploadArea onClick={handleUpload} sx={{ cursor: 'pointer' }}>
      <UploadBox>
        <UploadIcon sx={{ marginRight: 0 }}>
          <UploadFileIcon color="primary" />
        </UploadIcon>
      </UploadBox>

      <Typography variant="body2" textAlign='center'>Click to upload</Typography>
    </UploadArea>
  )

  const isLandscape = videoOrientation === VideoOrientation.Landscape

  const renderVideo = () => (
    <RenderVideo height='auto !important'>
      <Box sx={{ backgroundColor: palette.offWhite }} paddingTop={isLandscape ? '56.55%' : '268.11px'} position='relative' mb={2}>
        <Box position='absolute' top='0' width='100%' height='100%'>
          <Box marginX={isLandscape ? 'none' : 'auto'} width={isLandscape ? "100%" : 148} height='100%'>
            <ReactPlayer
              className='react-player'
              width='100%'
              height='100%'
              url={playVideoUrl}
              pip={pip}
              controls={true}
              playing={playing}
              progressInterval={200}
              onClickPreview={handlePlay}
              playIcon={<PlayerIcon />}
              light={updatedThumbnailUrl || ""}
              onError={error => console.log('>>>>>>>>>>>>>>>ON ERROR>>>>>>>>>>>>', error)}
              playsinline
            />
          </Box>
        </Box>
      </Box>
    </RenderVideo>
  )

  const descriptionSeeMore = uploadedVideoDescription?.substring(0, 280)

  return (
    <>
      {!taskLoading ?
        <Box mb={2} position='relative'>
          <PageHeading title="Edit Task" subTitle='Edit your Task' />

          <Box position='absolute' right={0} top={-12}>
            <IconButton color="primary" size="large" onClick={() => { setIsEditOpen(!isEditOpen); resetFields(); }}>
              {isEditOpen ? <ArrowBackIcon /> : <EditIcon />}
            </IconButton>
          </Box>

          <CustomBreadCrumb currentPage='Edit Task' previousPage='Task template' href='/task-template' />

          <Box>
            <Collapse in={!isEditOpen}>
              <Grid container spacing={2} rowSpacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Moderated</Typography>

                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>
                    {isModerated ? <VerifiedIcon /> : <NotVerifiedIcon />}
                  </Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Task Name</Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{name || 'N/A'}</Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Description</Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{description || 'N/A'}</Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Gorgias Ticket Subject</Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{subject || 'N/A'}</Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Audience Key</Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{segmentAudienceKey || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body2'>Tags</Typography>
                  {tags ?
                    <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>
                      {tags.split(', ').map(tag => (
                        <Chip color='primary' key={tag} sx={{ marginRight: '5px' }} label={tag} />
                      ))}
                    </Typography>
                    :
                    <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>N/A</Typography>
                  }
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body2'>Post Greet Title </Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{postGreetTitle || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body2'>Post Greet Message </Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{postGreetMessage || 'N/A'}</Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Video Heading Customer </Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{videoHeading || 'N/A'}</Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Video Description Customer </Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{videoDescription || 'N/A'}</Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Job Heading </Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{videoPageHeading || 'N/A'}</Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='body2'>Job Description </Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{videoPageDescription || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body2'>Script</Typography>
                  <Box mt='10px' mb='20px'
                    dangerouslySetInnerHTML={{
                      __html: sanitize(script || ''),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body2'>Message</Typography>
                  <Box mt='10px' mb='20px'
                    dangerouslySetInnerHTML={{
                      __html: sanitize(message || ''),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body2'>Sms text</Typography>
                  <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{smsText || 'N/A'}</Typography>
                </Grid>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <Typography variant='body2'>Template Type</Typography>
                <Typography sx={{ marginTop: '10px', marginBottom: '20px' }} variant='h4'>{formatEnumMember(templateType || 'N/A')}</Typography>
              </Grid>

              {templateType === TaskTemplateType.SelfRunning && video &&
                <Grid item xs={12}>
                  <TaskVideoBox>
                    <Box sx={taskOrders}>
                      {renderVideo()}
                    </Box>

                    <Box sx={taskOrders}>
                      <Typography
                        gutterBottom
                        aria-haspopup="true"
                        aria-owns={popOverOpen ? 'mouse-over-popover' : undefined}
                        variant="subtitle1"
                        component="div"
                        noWrap
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        {title}
                      </Typography>

                      <Popover
                        id="mouse-over-popover"
                        sx={{ pointerEvents: 'none', zIndex: 99999 }}
                        open={popOverOpen}
                        anchorEl={anchorElPopover}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <Typography sx={{ maxWidth: '350px', backgroundColor: palette.black, color: palette.white, p: 1 }} variant="h3">{title || 'N/A'}</Typography>
                      </Popover>

                      <Typography variant="body1" color="text.secondary">
                        <Box>
                          {uploadedVideoDescription && uploadedVideoDescription?.length > 100 ?
                            <>
                              {descriptionSeeMore}

                              <Typography component='span' sx={{ marginLeft: '5px', cursor: 'pointer' }} onClick={handleModal} variant="body1" color='primary'>See more</Typography>
                            </>
                            :
                            uploadedVideoDescription
                          }
                        </Box>
                      </Typography>

                    </Box>
                  </TaskVideoBox>
                </Grid>
              }

              <Box display='flex' justifyContent='flex-end' mt={2}>
                <Button onClick={handleClose} variant='contained' color='primary'>Back</Button>
              </Box>
            </Collapse>

            <Collapse in={isEditOpen}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off">
                  <Box sx={{ width: "100%" }}>
                    <Grid container spacing={2} rowSpacing={2}>
                      <Grid item xs={12}>
                        <Box display='flex' alignItems='center' mt={1}>
                          <Typography variant="h4">Moderated</Typography>

                          <Switch
                            onChange={handleModerate}
                            value={moderateStatus}
                            sx={{ marginRight: '1rem' }}
                            color="secondary"
                            defaultChecked={!!isModerated}
                          />
                        </Box>
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <CustomController
                          controllerName="name"
                          controllerLabel="Template Name"
                          fieldType="text"
                        />
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <CustomController
                          controllerName="description"
                          controllerLabel="Description"
                          fieldType="text"
                        />
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <CustomController
                          controllerName="subject"
                          controllerLabel="Gorgias Ticket Subject"
                          fieldType="text"
                        />
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <CustomController
                          controllerName="segmentAudienceKey"
                          controllerLabel="Audience Key"
                          fieldType="text"
                          tooltipText="This is your Audience ID from Segment"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Tags controllerName='tags' />
                      </Grid>

                      <Grid item xs={12}>
                        <CustomController
                          controllerName="postGreetTitle"
                          controllerLabel="Post Greet Title"
                          fieldType='text'
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ position: 'relative' }}>
                          <CustomController
                            controllerName="postGreetMessage"
                            controllerLabel="Post Greet Message"
                            fieldType='text'
                            rowsLength={4}
                            isMultiLine
                          />

                          <PostMacro
                            postMacros={postMacros}
                            handleChangePost={handleChangePost}
                            anchorElTask={anchorElTask}
                            handleOpenUserMenuTask={handleOpenUserMenuTask}
                            handleCloseUserMenuTask={handleCloseUserMenuTask}
                          />
                        </Box>
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <CustomController
                          controllerName="videoHeading"
                          controllerLabel="Video Heading Customer"
                          fieldType="text"
                        />
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <CustomController
                          controllerName="videoDescription"
                          controllerLabel="Video Description Customer"
                          fieldType="text"
                        />
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <CustomController
                          controllerName="videoPageHeading"
                          controllerLabel="Job Heading"
                          fieldType="text"
                        />
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <CustomController
                          controllerName="videoPageDescription"
                          controllerLabel="Job Description"
                          fieldType="text"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={editorBox}>
                          <Typography mb={2} variant='h4' sx={{ fontWeight: '500' }}>Script</Typography>

                          <Editor
                            editorState={editScript}
                            wrapperClassName="draft-wrapper"
                            onEditorStateChange={(editorState) => onEditorStateChange(editorState)}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box position='relative' sx={editorBox}>
                          <Typography mb={2} variant='h4' sx={{ fontWeight: '500' }}>Message</Typography>

                          <Editor
                            editorState={taskTemplateMessage}
                            wrapperClassName="draft-wrapper"
                            onEditorStateChange={(editorState) => onEditorMessageStateChange(editorState)}
                          />

                          <CustomMacro
                            top='46px'
                            macros={smsTextMacros}
                            handleChange={handleChange}
                            anchorElUser={anchorElUser}
                            handleCloseUserMenu={handleCloseUserMenu}
                            handleOpenUserMenu={handleOpenUserMenu}
                            listMacros
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={editorBox} position='relative'>
                          <CustomController
                            controllerName="smsText"
                            controllerLabel="Sms text"
                            fieldType='text'
                            rowsLength={4}
                            isMultiLine
                          />

                          <CustomMacro
                            macros={smsTextMacros}
                            handleChange={handleChange}
                            anchorElUser={anchorElUser}
                            handleCloseUserMenu={handleCloseUserMenu}
                            handleOpenUserMenu={handleOpenUserMenu}
                            listMacros
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl sx={{ marginTop: '.5rem' }} fullWidth>
                          <InputLabel id="templateTypeLabel">Template Type</InputLabel>

                          <Controller
                            name="templateType"
                            control={control}
                            render={({ field }) => (
                              <Select
                                labelId="templateTypeLabel"
                                id="templateType"
                                defaultValue={templateType}
                                fullWidth
                                label="Template Type"
                                onChange={async (event) => {
                                  const { target: { value } } = event
                                  field.onChange(value)
                                  setCheckType(true)

                                  await updateTaskTemplate({
                                    variables: {
                                      updateTaskTemplateInput: {
                                        ...formValues,
                                        id: taskId || "",
                                        tags,
                                        templateType: value as TaskTemplateType
                                      }
                                    },
                                  });
                                }}
                              >
                                <MenuItem value={TaskTemplateType.Manual}>Manual</MenuItem>
                                <MenuItem value={TaskTemplateType.SelfRunning} >Self Running</MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>

                      {templateType === TaskTemplateType.SelfRunning &&
                        <Grid item xs={12}>
                          <Collapse in={templateType === TaskTemplateType.SelfRunning}>
                            <Box display='flex' justifyContent='space-between' alignItems="center" mb={2}>
                              <Typography variant='subtitle1'>Upload Video</Typography>

                              {video &&
                                <IconButton color="primary" size="large"
                                  onClick={() => {
                                    setIsVideoEditOpen(!isVideoEditOpen)
                                    setVideoSettings({ ...videoSettings, playing: !playing })
                                  }}
                                >
                                  {isVideoEditOpen ? <ArrowBackIcon /> : <EditIcon />}
                                </IconButton>
                              }
                            </Box>

                            {!video && templateType === TaskTemplateType.SelfRunning && renderUpload()}

                            <Collapse in={isVideoEditOpen}>
                              <>
                                <Box sx={{ pb: 1, mb: 2 }}>
                                  {renderUpload()}
                                </Box>

                                <Box maxWidth={350} marginX='auto'>
                                  {video && renderVideo()}
                                </Box>
                              </>
                            </Collapse>

                            <Collapse in={!!video && !isVideoEditOpen}>
                              <TaskVideoBox>
                                <Box sx={taskOrders}>
                                  {renderVideo()}
                                </Box>

                                <Box sx={taskOrders}>
                                  <Typography
                                    gutterBottom
                                    aria-haspopup="true"
                                    aria-owns={popOverOpen ? 'mouse-over-popover' : undefined}
                                    variant="subtitle1"
                                    component="div"
                                    noWrap
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                  >
                                    {title}
                                  </Typography>

                                  <Popover
                                    id="mouse-over-popover"
                                    sx={{ pointerEvents: 'none', zIndex: 99999 }}
                                    open={popOverOpen}
                                    anchorEl={anchorElPopover}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                  >
                                    <Typography sx={{ maxWidth: '350px', backgroundColor: palette.black, color: palette.white, p: 1 }} variant="h3">{title || 'N/A'}</Typography>
                                  </Popover>

                                  <Typography variant="body1" color="text.secondary">
                                    <Box>
                                      {uploadedVideoDescription && uploadedVideoDescription?.length > 100 ?
                                        <>
                                          {descriptionSeeMore}

                                          <Typography component='span' sx={{ marginLeft: '5px', cursor: 'pointer' }} onClick={handleModal} variant="body1" color='primary'>See more</Typography>
                                        </>
                                        :
                                        uploadedVideoDescription
                                      }
                                    </Box>
                                  </Typography>

                                </Box>
                              </TaskVideoBox>
                            </Collapse>
                          </Collapse>
                        </Grid>
                      }
                    </Grid>
                  </Box>

                  <Box display='flex' justifyContent='flex-end' mt={2}>
                    <Button type="submit" variant="contained" color="primary" disabled={loading} endIcon={loading && <CircularProgress size={20} color="inherit" />}>Update</Button>
                  </Box>
                </form>
              </FormProvider>
            </Collapse>

            {templateType === TaskTemplateType.SelfRunning && !video &&
              <Box position='sticky' bottom={20} display='flex' justifyContent='center'>
                <AlertWarning severity="warning">Video missing !!! Please upload a video to this template, the current type support self running mechanism which might cause problems for video delivery to the customer.</AlertWarning>
              </Box>
            }
          </Box>
        </Box>
        :
        <TaskEditTemplateSkeleton />
      }

      <CreateVideoModalForTasks
        attachmentId={attachmentId}
        isOpen={isVideoModalOpen}
        handleClose={videoHandleClose}
        currentVideo={video}
        videoOrientation={videoOrientation}
        videoType='video'
        typeId={taskId}
        refetchJob={refetch}
      />

      <FullDetailVideo
        isOpen={open}
        handleClose={handleModal}
        videoData={video}
      />
    </>
  )
}