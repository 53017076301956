import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { FC, useContext } from "react"
import { WidgetType } from "../../../generated"
import { WIDGET_TYPE_ARRAY } from "../../constants"
import { AppContext } from "../../context"
import { ActionType } from "../../context/AppContextReducer"
import { VideoSearchFiltersProps } from "../../interfaceTypes"
import customTheme from "../../theme"
import { Search } from "../common/Search"
import { OperatorSearchField } from "./OperatorSearchField"
let timeCustomerSearch = 0;
let timeTextSearch = 0;

/**
 * Component for displaying a search filter fields.
 * @returns {JSX.Element}
 */
export const VideoSearchFilters: FC<VideoSearchFiltersProps> = ({ videoSearchKeys, setVideoSearchKeys, loading }): JSX.Element => {
  const { dispatch } = useContext(AppContext)

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event
    if (!value || value?.length > 2) {
      window.clearTimeout(timeTextSearch);
      timeTextSearch = window.setTimeout(() => {
        videoSearchKeys && setVideoSearchKeys({ ...videoSearchKeys, searchText: event.target.value })
      }, 1000);
    }
  }

  const handleChangeCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event
    if (!value || value?.length > 2) {
      window.clearTimeout(timeCustomerSearch);
      timeCustomerSearch = window.setTimeout(() => {
        dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
        videoSearchKeys && setVideoSearchKeys({ ...videoSearchKeys, keyword: event.target.value })
      }, 1000);
    }
  };

  const handleWidgetTypeChange = (event: SelectChangeEvent<string>) => {
    const { target: { value } } = event || {}
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 })
    videoSearchKeys && setVideoSearchKeys({ ...videoSearchKeys, widgetType: value as unknown as WidgetType })
  }

  return (
    <Box py={3}>
      <Grid container spacing={2}>
        <Grid item xl={3.25} lg={3.75} sm={6} xs={12}>
          <Box>
            <Search
              onChange={handleChangeSearch}
              infoText="Search Transcription"
              disable={loading}
            />
          </Box>
        </Grid>

        <Grid item xl={2} lg={2} sm={6} xs={12}>
          <OperatorSearchField loading={loading} videoSearchKeys={videoSearchKeys} setVideoSearchKeys={setVideoSearchKeys} />
        </Grid>

        <Grid item xl={2} lg={2} sm={6} xs={12}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="widgetLabel" shrink>Widget Type</InputLabel>
            <Select
              labelId="widgetLabel"
              id="selectWidget"
              disabled={loading}
              notched
              defaultValue={''}
              displayEmpty={true}
              sx={{ width: '100%' }}
              size="small"
              label="Widget Type"
              onChange={handleWidgetTypeChange}
            >
              <MenuItem value="">All</MenuItem>
              {WIDGET_TYPE_ARRAY.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xl={4.75} lg={4.25} sm={6} xs={12}>
          <Box sx={{ textAlign: 'right' }}>
            <TextField
              id="Search"
              label="Customer Name Or Email "
              type="text"
              autoComplete="off"
              size='small'
              onChange={handleChangeCustomer}
              disabled={loading}
              sx={{ [customTheme.breakpoints.down('lg')]: { width: '100%' }, }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}