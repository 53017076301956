
import { Box, Grid, Typography } from "@mui/material";
import { FC, Reducer, useContext, useReducer, useState } from "react";

import { Attachment, AttachmentType, VideoOrientation } from "../../../../generated";
import { AppContext } from "../../../context";
import { ActionType } from "../../../context/AppContextReducer";
import { AttachmentTypeFE, BrandState } from "../../../interfaceTypes";
import { uploadVideoDescription } from "../../../theme/styleConstants";
import { DeleteAttachmentModal } from "../../common/DeleteAttachmentModal";
import { BrandAction, BrandActionType, brandInitialState, brandReducer } from "../reducer/brandReducer";
import { CreateVideoModal } from "./CreateVideoModal";
import { EditModal } from "./EditModal";
import { UploadVideoSection } from "./UploadVideoSection";
import { VideoLibraryInnerTabs } from "./VideoLibraryInnerTabs";


export const VideoLibraryTab: FC = () => {
  const { selectedBrand, isEdit, isDeleteOpen, dispatch } = useContext(AppContext)
  const [videoType, setVideoType] = useState<AttachmentTypeFE>('hookVideo')
  const [attachmentModal, setAttachmentModal] = useState<boolean>(false)
  const [orientation, setOrientation] = useState<VideoOrientation>(VideoOrientation.Landscape)

  const [brandState, brandDispatch] = useReducer<Reducer<BrandState, BrandAction>>(brandReducer, brandInitialState);

  const handleModal = (videoType: AttachmentTypeFE, modalVideoOrientation: VideoOrientation, videoData?: Attachment) => {
    setAttachmentModal(!attachmentModal)
    dispatch({ type: ActionType.SET_IS_BACKDROP_VISIBLE, isBackdropVisible: true })
    brandDispatch({ type: BrandActionType.SET_CURRENT_VIDEO, currentVideo: videoData || null })
    setVideoType(videoType)
    setOrientation(modalVideoOrientation)
  };

  const { currentVideo } = brandState

  const handleClose = () => {
    dispatch({ type: ActionType.SET_IS_BACKDROP_VISIBLE, isBackdropVisible: false })
    setAttachmentModal(!attachmentModal)
  };

  const handleEditClose = () => {
    dispatch({ type: ActionType.SET_IS_EDIT, isEdit: false })
  };

  const handleDeleteClose = () => {
    dispatch({ type: ActionType.SET_IS_DELETE_OPEN, isDeleteOpen: false })
  };

  const { attachments, id: brandId } = selectedBrand || {}

  const hookVideo = attachments?.filter(video => video.type === AttachmentType.HookVideo)
  const brandVideoLandscape = hookVideo?.filter(video => video.videoOrientation === VideoOrientation.Landscape)
  const brandVideoPortrait = hookVideo?.filter(video => video.videoOrientation === VideoOrientation.Portrait)
  const brandVideos = attachments?.filter(video => video.type !== AttachmentType.HookVideo)

  const handleVideoData = (video: Attachment) => {
    brandDispatch({ type: BrandActionType.SET_CURRENT_VIDEO, currentVideo: video })
  }

  return (
    <Box sx={{ mt: "2rem" }}>
      <Box mb={4}>
        <Typography variant="subtitle1">Hook Videos</Typography>

        <Typography sx={uploadVideoDescription}>Upload landscape/portrait hook video with which you can influence the customer to get in touch.</Typography>
      </Box>

      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item md={7} xs={12}>
          <UploadVideoSection
            height="100%"
            isLandscape
            handleVideoData={handleVideoData}
            brandDispatch={brandDispatch}
            brandState={brandState}
            title="Landscape Hook Video"
            handleModalOpen={() => handleModal("hookVideo", VideoOrientation.Landscape, brandVideoLandscape && brandVideoLandscape[0])}
          />
        </Grid>

        <Grid item md={5} xs={12}>
          <UploadVideoSection
            height="100%"
            title="Portrait Hook Video"
            handleVideoData={handleVideoData}
            brandDispatch={brandDispatch}
            brandState={brandState}
            handleModalOpen={() => handleModal("hookVideo", VideoOrientation.Portrait, brandVideoPortrait && brandVideoPortrait[0])}
          />
        </Grid>
      </Grid>

      <Box my={4}>
        <Typography variant="subtitle1">Video Library</Typography>

        <Typography sx={uploadVideoDescription}>Add infomercial video content videos that can appear on the product page widget</Typography>
      </Box>

      <Box pb={3}>
        <VideoLibraryInnerTabs
          brandVideos={brandVideos}
          handleModal={handleModal}
          handleVideoData={handleVideoData}
          brandDispatch={brandDispatch}
          brandState={brandState}
        />
      </Box>

      <CreateVideoModal
        isOpen={attachmentModal}
        handleClose={handleClose}
        currentVideo={currentVideo}
        entityType='brands'
        typeId={brandId}
        isLandscape={orientation === VideoOrientation.Landscape}
        videoType={videoType}
      />

      <EditModal
        isOpen={isEdit}
        dispatch={brandDispatch}
        state={brandState}
        handleClose={handleEditClose}
        videoData={currentVideo}
      />

      <DeleteAttachmentModal
        isOpen={isDeleteOpen}
        dispatch={brandDispatch}
        state={brandState}
        attachmentId={currentVideo?.id}
        handleClose={handleDeleteClose}
        attachmentType='video'
      />
    </Box>
  )
}