import { Box } from "@mui/material"
import { FC } from "react"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { KioskImageWrapperInterface } from "../interfaceTypes"

export const KioskImageWrapper: FC<KioskImageWrapperInterface> = ({ imageUrl, isFeature }) => (
  <Box sx={{ height: isFeature ? '100%' : '90%', position: 'relative' }}>
    {imageUrl &&
      <LazyLoadImage style={{ objectFit: 'contain' }} height='100%' width='100%' src={imageUrl || ''} />
    }
  </Box>
)