import { Button, Typography } from "@mui/material"
import { FC, useState } from "react"
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from "@mui/material";
import palette from "../../theme/palette";
import { Link } from "react-router-dom";
import { AUTH_LINKS } from "../../constants";

export const VerifyEmailForm: FC = (): JSX.Element => {
  const [verified] = useState<string>('verify');
  const isVerify = verified !== 'verify'

  return (
    <>
      <Box textAlign='center'>
        <Box marginBottom='1rem'>
          <CheckCircleIcon sx={{ width: '64px', height: '64px', fill: palette.green }} />
        </Box>

        <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>Email Verified!</Typography>

        <Button component={Link} to={AUTH_LINKS.LOGIN_LINK} variant="contained" color="primary" sx={{ textTransform: 'capitalize' }}>Login</Button>
      </Box>
      {isVerify &&
        <Box textAlign='center'>
          <Box marginBottom='1rem'>
            <ErrorIcon sx={{ width: '64px', height: '64px', fill: palette.red }} />
          </Box>

          <Typography variant="h5" sx={{ marginBottom: '.5rem', fontWeight: '500' }}>Email not verified!</Typography>

          <Typography variant="h6">Session token is expired. <Link to="/">Click</Link>  here to resend email.</Typography>
        </Box>
      }
    </>
  )
}