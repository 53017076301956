// packages block
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { images } from "../../../assets/images";
// component block
import { AuthLayoutContainer } from "../../theme/styleComponents";
// others
import { AuthLayoutProps } from "../../interfaceTypes";

export const AuthLayout: FC<AuthLayoutProps> = ({ children, title, subTitle }) => (
  <AuthLayoutContainer>
    <Box pb={4} textAlign="center">
      <Box component='img' src={images.LOGO} alt='Operator Logo' width='150px' />
    </Box>

    <Box width='100%'>
      <Typography variant="h5" fontWeight='500'>{title}</Typography>

      {subTitle &&
        <Box height={24} marginBottom='15px'>
          <Typography variant="subtitle2" component='p'>{subTitle}</Typography>
        </Box>
      }

      {children}
    </Box>
  </AuthLayoutContainer>
)